import { postRequest, getRequest } from 'utils/http';
import { name } from './slice';
import { LoginResponse } from './types';

export function postPostLogin(code: string) {
  return postRequest<LoginResponse>(`${name}/login`, { code, time: Date.now() });
}

export function postRefresh() {
  return postRequest<LoginResponse>(`${name}/refresh`, { time: Date.now() });
}

export function postGetUser(idToken: string) {
  return postRequest<LoginResponse>(`${name}/get-user`, { idToken });
}

export function getGetRegion(): Promise<any> {
  return getRequest(`${name}/region`);
}
