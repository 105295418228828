import { ResponseItem } from '../incidentResponses/types';
import { CommentItem } from '../comments/types';
import { IncidentAttachment } from '../attachments/types';
import {
  IncidentDetails,
} from '../incidents/types';

export interface IncidentHistorySagaItem {
  changedBy: string;
  changedAt: number;
  operation: IncidentHistoryOperation;
  entityType: IncidentHistoryEntityType;
  entityId: string;
  entity: IncidentDetails | ResponseItem | CommentItem | IncidentAttachment;
}

export interface IncidentHistoryItem {
  changedBy: string;
  changedAt: number;
  operation: IncidentHistoryOperation;
  entityType: IncidentHistoryEntityType;
  before?: IncidentHistoryItemValue;
  after?: IncidentHistoryItemValue;
}

export type IncidentHistoryItemValue = Record<string, {
  value: any;
  trKey: string;
  isMarkdown: boolean;
}>;

export enum IncidentHistoryEntityType {
  Incident = 'incident',
  Comment = 'comment',
  Attachment = 'attachment',
  Response = 'response',
  EDRResponse = 'edrResponse',
}

export enum IncidentHistoryOperation {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum IncidentHistoryFilteringEntityType {
  All = 'all',
  Incidents = 'incidents',
  Responses = 'responses',
  Communication = 'communication'
}

export type IncidentHistoryState = {
  list: IncidentHistoryItem[];
  filters?: any;
  columns?: any;
  isLoading: boolean;
  count: number;
  error: string | null;
  search?: string;
  filteringEntities?: IncidentHistoryFilteringEntityType;
};
