import React, {
  useState,
  FormEventHandler,
  RefObject,
  ChangeEvent,
  useEffect,
  useCallback,
  FormEvent,
} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
} from '@material-ui/core';
import isURL from 'validator/lib/isURL';
import { KLInput } from 'components/KLInput';
import { useConfirmation } from 'components/Confirmation';
import styles from './styles';

type LinkProps = {
  onChange: (...args: any[]) => void;
  config: { [key: string]: any };
  currentState: { [key: string]: any };
  onExpandEvent: () => void;
  doCollapse: () => void;
  editorRef?: RefObject<any>;
  translations: { [key: string]: string };
};

type LinkFormProps = {
  text: string;
  link: string;
  translations: { [key: string]: string };
  onSubmit: (text: string, link: string) => FormEventHandler;
};

const useStyles = makeStyles(styles);

const LinkForm: React.FC<LinkFormProps> = props => {
  const { text: defaultText, link: defaultLink, translations, onSubmit } = props;
  const [text, setText] = useState(defaultText);
  const [link, setLink] = useState(defaultLink);
  const classes = useStyles();
  const { setConfirmationOptions } = useConfirmation();

  useEffect(() => {
    setConfirmationOptions({
      isDisabled: !link || !isURL(link) || !text,
      onConfirm: onSubmit(text, link),
    });
  }, [link, text, setConfirmationOptions, onSubmit]);

  return (
    <form>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel shrink htmlFor="linkTitle">
          {translations['components.controls.link.linkTitle']}
        </InputLabel>
        <KLInput
          id="linkTitle"
          defaultValue={text}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => setText(ev.target.value)}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel shrink htmlFor="linkTarget">
          {translations['components.controls.link.linkTarget']}
        </InputLabel>
        <KLInput
          id="linkTarget"
          defaultValue={link}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => setLink(ev.target.value)}
        />
      </FormControl>
    </form>
  );
};

export const Link: React.FC<LinkProps> = props => {
  const { config, onChange, currentState, onExpandEvent, doCollapse, editorRef, translations } = props;
  const [open, setOpen] = useState(false);
  const { openConfirmation, closeConfirmation, setConfirmationOptions } = useConfirmation();

  const handleClose = useCallback(() => {
    closeConfirmation();
    setOpen(false);
    doCollapse();
  }, [closeConfirmation, doCollapse]);

  const handleSubmit = (text: string, link: string) => (ev: FormEvent) => {
    ev.preventDefault();
    onChange('link', text, link, config.defaultTargetOption);
    setTimeout(() => {
      handleClose();
      if (editorRef) {
        editorRef.current.blur();
      }
    });
  };

  const handleOpen = () => {
    openConfirmation({
      confirmText: translations['generic.add'],
      dismissText: translations['generic.cancel'],
      onDismiss: handleClose,
      isDisabled: true,
      dialogProps: {
        onClick: (ev) => {
          ev.stopPropagation();
          ev.nativeEvent.stopImmediatePropagation();
        },
        onFocusCapture: (ev) => ev.stopPropagation(),
        onBlurCapture: (ev) => ev.stopPropagation(),
      },
    });
    setOpen(true);
    onExpandEvent();
  };

  useDeepCompareEffect(() => {
    const text = open ? currentState.link?.title || currentState.selectionText : '';
    const link = open ? currentState.link?.target : '';
    setConfirmationOptions({
      content: (
        <LinkForm
          text={text}
          link={link}
          translations={translations}
          onSubmit={handleSubmit}
        />
      ),
    });
  }, [open, currentState]);

  return (
    <div
      aria-haspopup="true"
      aria-expanded={open}
      aria-label="rdw-link-control"
      aria-selected={!!currentState.link}
      onClick={handleOpen}
      className={config.link.className}
      title={config.link.title || translations['components.controls.link.link']}
    >
      <img src={config.link.icon} alt="" />
    </div>
  );
};
