import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  tabRoot: {
    borderBottom: 'none',
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(3),
  },
  tab: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 2,
    paddingLeft: 0,
    paddingRight: 0,
  },
  tabIndicator: {
    height: 2,
  },
});
