import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    marginBottom: theme.spacing(5),
  },
  row: {
    // padding: theme.spacing(2, 0),
  },
  link: {
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(1, 0),
    color: theme.palette.primary.main,
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  btn: {
    marginTop: 2,
    marginBottom: 2,
    fontWeight: 300,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
