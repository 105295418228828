import { createAction, PayloadAction, PrepareAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from 'store/slice';
import {
  AssetListItem,
  AssetListPayload,
  AssetListSagaPayload,
  AssetListFilters,
  AssetListSagaFilters,
  AssetDetails,
  AssetSuggestion,
  AssetListColumns,
  AssetListColumnsPayload,
  AssetSuggestionPayload,
} from './types';

const name = 'assets';

const prepareListRequest: PrepareAction<AssetListSagaPayload> = (payload: AssetListPayload) => {
  const { firstSeen, lastSeen, ...restFilter } = payload.filter;
  Object.keys(restFilter).forEach(key => restFilter[key] === undefined && delete restFilter[key]);
  const filter: AssetListSagaFilters = restFilter;

  if (firstSeen?.start) {
    filter.minFirstSeen = firstSeen.start;
  }

  if (firstSeen?.end) {
    filter.maxFirstSeen = firstSeen.end;
  }

  if (lastSeen?.start) {
    filter.minLastSeen = lastSeen.start;
  }

  if (lastSeen?.end) {
    filter.maxLastSeen = lastSeen.end;
  }

  return {
    payload: {
      ...payload,
      filter,
    },
  };
};

export const listRequest = createAction(`${name}/list`, prepareListRequest);
export const detailsRequest = createAction<string>(`${name}/details`);
export const incidentsRequest = createAction<string>(`${name}/incidents`);
export const suggestionRequest = createAction<AssetSuggestionPayload | string>(`${name}/suggestion`);
export const columnsGetRequest = createAction(`${name}/get-columns`);
export const columnsSetRequest = createAction<AssetListColumnsPayload>(`${name}/set-columns`);

export type AssetState = EntityState<AssetListItem, AssetDetails, AssetListFilters, AssetListColumns> & {
  suggestion?: AssetSuggestion[];
  suggestionLoading?: boolean;
};

const initialState: AssetState = {
  list: [],
  page: 1,
  pageSize: 10,
  pageStep: 0,
  filters: {},
  columns: null,
  search: '',
  isLoading: false,
  count: 0,
  error: null,
  details: null,
  incidents: [],
  suggestion: [],
  suggestionLoading: false,
  lastVisited: null,
};

const assetListSlice = createEntitySlice({
  name,
  initialState,
  reducers: {
    incidentsStart: (state) => {
      state.isLoading = true;
    },
    incidentsSuccess: (state, action: any) => {
      state.isLoading = false;
      state.incidents = action.payload;
    },
    startSuggestion: (state: AssetState) => {
      state.suggestionLoading = true;
    },
    setSuggestion: (state: AssetState, action: PayloadAction<AssetSuggestion[]>) => {
      state.suggestionLoading = false;
      state.suggestion = action.payload;
    },
  },
});

export const {
  setPage,
  setPageSize,
  setSearch,
  setFilters,
  setColumns,
  listStart,
  listSuccess,
  listCount,
  detailsStart,
  detailsSuccess,
  setLastVisited,
  incidentsStart,
  incidentsSuccess,
  startSuggestion,
  setSuggestion,
  error,
} = assetListSlice.actions;

export default assetListSlice.reducer;
