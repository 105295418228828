import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { telemetryStatsRequest } from 'services/statistics/slice';
import { useSelector } from 'store/hook';
import { Loading } from 'components/Loading';
import { RootState } from 'store/reducer';
import { Badge, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { listRequest } from 'services/tenants/slice';
import { KLCheckBox } from 'components/KLCheckBox';
import { KLButton } from 'components/KLButton';
import { FilterIcon } from 'assets/icons/FilterIcon';
import { TenantItem } from 'services/tenants/types';
import { load, remove, save } from 'utils/storage';
import styles from './styles';

const useStyles = makeStyles(styles);
const daysSince2000 = (new Date().getFullYear() - 2000) * 365;
const ranges = [
  { key: 'Yesterday', days: 1 },
  { key: '7 days', days: 7 },
  { key: '30 days', days: 30 },
  { key: '90 days', days: 91 },
  { key: '180 days', days: 182 },
  { key: '1 year', days: 365 },
  { key: 'All time', days: daysSince2000 },
] as const;

export const TelemetryStatsFilterTenantIds = 'TelemetryStatsFilterTenantIds';
export const TelemetryStatsPeriodDays = 'TelemetryStatsPeriodDays';

const BILLION = 10 ** 9;
const TRILLION = 10 ** 12;
const QUADRILLION = 10 ** 15;

function triplets(n: number): string {
  if (n < 1000) {
    return n.toString();
  }
  const s = n.toString();
  let result = '';
  for (let i = 1; i <= s.length; i++) {
    if (i % 3 === 1) {
      result = ` ${result}`;
    }
    result = s[s.length - i] + result;
  }
  return result;
}

function formatNumber(n: number, t: (s: string, p?: any) => string): string {
  if (n <= BILLION) {
    return triplets(n);
  }
  if (n > BILLION && n < TRILLION) {
    return t('n billions', { n: triplets(Math.round(n / BILLION)) });
  }

  if (n >= TRILLION && n < QUADRILLION) {
    return t('n trillions', { n: triplets(Math.round(n / TRILLION)) });
  }
  return t('quadrillion');
}

export function TelemetryStats() {
  
  const styles = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const dispatch = useDispatch();
  const { clientId, userId, roleName, isActiveOrganization } = useSelector(state => state.auth);
  
  const { list }: any = useSelector(state => state.tenants);
  const [anchorGear, setAnchorGear] = useState<null | HTMLElement>(null);
  const [tenantsListOpened, setTenantsListOpened] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [periodDays, setPeriodDays] = useState(load(TelemetryStatsPeriodDays, true) || 30);

  const [selectedTenantIds, setSelectedTenantIds] = useState<Dict<boolean>>({});
  const [initialTenantSelections, setInitialTenantSelections] = useState<Dict<boolean>>({});
  const [isSelectionChanged, setIsSelectionChanged] = useState(false);

  const tenantIds = useMemo(() => Object.keys(selectedTenantIds).filter(tenant => selectedTenantIds[tenant]), 
    [selectedTenantIds]);

  const filteredTenants = useMemo(
    () => [
      { tenantId: '-', name: t('Without tenants') }, 
      ...list.filter((tenant: TenantItem) => tenant?.name?.toLowerCase().includes(searchTerm.toLowerCase())),
    ], 
    [list, searchTerm, t],
  );
  
  const allSelected = useMemo(
    () => filteredTenants.every((tenant: TenantItem) => selectedTenantIds[tenant.tenantId as string]), 
    [filteredTenants, selectedTenantIds],
  );
  
  useEffect(() => {
    if (!userId || !clientId) {
      return;
    }
    dispatch(listRequest({ clientId, userId }));
  }, [clientId, userId, roleName, isActiveOrganization]);
  
  useEffect(() => {
    const tenantIdsFromStorage = load(TelemetryStatsFilterTenantIds, true);
    if (tenantIdsFromStorage && Array.isArray(tenantIdsFromStorage)) {
      const tenantSelectionFlags = Object.fromEntries(
        tenantIdsFromStorage.map(tenantId => [tenantId, true]),
      );
      setSelectedTenantIds(tenantSelectionFlags);
      setInitialTenantSelections(tenantSelectionFlags);
    }
    dispatch(telemetryStatsRequest({ dayRange: periodDays, tenantIds: tenantIdsFromStorage || tenantIds }));
  }, []);

  useEffect(() => {
    const allKeys = new Set([...Object.keys(selectedTenantIds), ...Object.keys(initialTenantSelections)]);
    const isChanged = Array.from(allKeys)
      .some(key => selectedTenantIds[key] !== (initialTenantSelections[key] || false));
    setIsSelectionChanged(isChanged);
  }, [selectedTenantIds, initialTenantSelections]);

  const handleTenantsListOpened = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTenantsListOpened(true);
    setAnchorGear(event.currentTarget);
  };
  
  const handleTenantsListClosed = () => {
    setTenantsListOpened(false);
    setAnchorGear(null);
    setSearchTerm('');
    setSelectedTenantIds(initialTenantSelections);
  };

  const submitTelemetryRequest = () => {
    dispatch(telemetryStatsRequest({ dayRange: periodDays, tenantIds }));
    setTenantsListOpened(false);
    setAnchorGear(null);
    setSearchTerm('');
    setInitialTenantSelections(selectedTenantIds);
    if (tenantIds.length) {
      save(TelemetryStatsFilterTenantIds, tenantIds, true);
    } else {
      remove(TelemetryStatsFilterTenantIds, true);
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (tenantId: string, isChecked: boolean) => {
    setSelectedTenantIds(prev => ({ ...prev, [tenantId]: isChecked }));
  };
  
  const handleSelectAllChange = (isChecked: boolean) => {
    const newSelections = Object.fromEntries(
      filteredTenants.map((tenant: TenantItem) => [tenant.tenantId, isChecked]),
    );
    setSelectedTenantIds(newSelections);
  };

  const {
    telemetryStats,
    telemetryStatsDaysCount,
    telemetryStatsLoading,
    telemetryStatsError,
  } = useSelector(({ statistics }: RootState) => statistics);
  
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2 className={styles.subheader}>{t('Telemetry statistics')}</h2>
        <Tooltip title={t('Filter by tenant') as string}>
          <IconButton
            onClick={handleTenantsListOpened}
            disableRipple
          >
            <Badge color="primary" variant={tenantIds.length ? 'dot' : 'standard'}>
              <FilterIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorGear}
        onClose={handleTenantsListClosed}
        open={tenantsListOpened}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: styles.menu }}
      >
        <div className={styles.searchContainer}>
          {t('Tenant')}
          <input
            className={styles.searchInput}
            type="text"
            placeholder={t('Tenant search placeholder')}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        {filteredTenants.length > 1 ? (
          <div className={styles.scrollContainer}>
            <div className={styles.menuItemContainer}>
              <KLCheckBox
                color="primary"
                checked={allSelected}
                onChange={e => handleSelectAllChange(e.target.checked)}
              />
              <MenuItem>
                {t('Select all')}
              </MenuItem>
            </div>
            {filteredTenants.map((tenant: TenantItem) => (
              <div className={styles.menuItemContainer}>
                <KLCheckBox
                  color="primary"
                  value={tenant.tenantId}
                  checked={selectedTenantIds[tenant.tenantId] === true}
                  onChange={e => handleCheckboxChange(tenant.tenantId, e.target.checked)}
                />
                <MenuItem>
                  {tenant.name}
                </MenuItem>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.noOptionsContainer}>
            {t('No options')}
          </div>
        )}
        <div className={styles.tenantFilterFooter}>
          <KLButton
            size="medium"
            color="primary"
            variant="contained"
            disabled={!isSelectionChanged}
            onClick={submitTelemetryRequest}
          >
            {t('Save')}
          </KLButton>
          <KLButton
            color="primary"
            onClick={handleTenantsListClosed}
          >
            {t('Cancel')}
          </KLButton>
        </div>
      </Menu>
      {
        (telemetryStatsError === null) && (
        <div className={styles.rangeButtonGroup}>
          {
          ranges.map(
            period => (
              <button
                key={period.key}
                className={
                  classNames(
                    styles.rangeButton,
                    { [styles.rangeButtonSelected]: period.days === telemetryStatsDaysCount, 
                    },
                  )
                }
                onClick={() => {
                  dispatch(telemetryStatsRequest({ 
                    dayRange: period.days,
                    tenantIds,
                  }));
                  setPeriodDays(period.days);
                  save(TelemetryStatsPeriodDays, period.days, true);
                }}
              >
                {t(`Telemetry statistics range.${period.key}`)}
              </button>
            ),
          )
        }
          <div className={styles.utcNotice}>
            {t('Data shown since notice timezone')}
          </div>
        </div>
        )
      }
      {
        telemetryStatsError !== null && (
          <div className={styles.noDataMessage}>
            {t('No data message')}
          </div>
        )
      }
      {
        telemetryStatsLoading && (
          <div className={styles.loadingWrap}>
            <Loading />
          </div>
        )
      }
      {
        telemetryStats !== null && telemetryStatsError === null && (
          <>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td className={classNames(styles.tableCell, styles.tableCellEvents)}>
                    {formatNumber(telemetryStats.eventsCount, t)}
                  </td>
                  <td className={classNames(styles.tableCell)}>
                    {t('Telemetry events')}
                  </td>
                </tr>
                <tr>
                  <td className={classNames(styles.tableCell, styles.tableCellSuspiciousEvents)}>
                    {formatNumber(telemetryStats.tagEventsSum, t)}
                  </td>
                  <td className={classNames(styles.tableCell)}>
                    {t('Suspicious events')}
                  </td>
                </tr>
                <tr>
                  <td className={classNames(styles.tableCell, styles.tableCellAlerts)}>
                    {formatNumber(telemetryStats.alertsCount, t)}
                  </td>
                  <td className={classNames(styles.tableCell)}>
                    {t('Alerts')}
                  </td>
                </tr>
                <tr>
                  <td className={classNames(styles.tableCell, styles.tableCellIncidents)}>
                    {formatNumber(telemetryStats.incidentsCount, t)}
                  </td>
                  <td className={classNames(styles.tableCell)}>
                    <Link to="/incidents" className={styles.incidentsLink}>
                      {t('Incidents')}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.dataSinceNotice}>
              {t('Detection rules triggered')}: {telemetryStats.huntsCount}
            </div>
            <div className={styles.dataSinceNotice}>
              {t('Data shown since notice')}
            </div>
          </>
        )
      }
    </div>
  );
}
