import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { KLSelect } from 'components/KLSelect';
import {
  IncidentHistoryFilteringEntityType as IHFEntityType, IncidentHistoryState,
} from 'services/incidentHistory/types';
import {
  setEventTypeFilter,
} from 'services/incidentHistory/slice';
import { FilterTableTitleItem as FTTItem } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const FilterTableTitle = () => {
  const classes = useStyles();
  const [queryParam, setQueryParam] = useQueryParam('entityTypes', StringParam);
  const { t } = useTranslation('incidentHistoryFilters');
  const dispatch = useDispatch();

  const {
    filteringEntities,
  } = useSelector((state: { incidentHistory: IncidentHistoryState }) => state.incidentHistory);
  const curentValue = queryParam || filteringEntities;

  const items: FTTItem[] = [
    { label: t('All events'), value: IHFEntityType.All },
    { label: t('Only incidents'), value: IHFEntityType.Incidents },
    { label: t('Only responses'), value: IHFEntityType.Responses },
    { label: t('Only communication'), value: IHFEntityType.Communication },
  ];

  const handleOnChange = ({ target: { value } }: any) => {
    setQueryParam(value);
    dispatch(setEventTypeFilter(value));
  };

  return (
    <KLSelect
      iconClass={classes.icon}
      items={items}
      value={curentValue}
      onChange={handleOnChange}
      className={classes.selectRoot}
    />
  );
};
