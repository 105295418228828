import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { MDRLogo } from 'assets/icons/MDRLogo';
import { KLLogo } from 'assets/icons/KLLogo';
import { GuideEntry } from '../GuidePage/components/GuideEntry';
import { LegalDocs } from './components/LegalDocs';
import styles from './styles';

const useStyles = makeStyles(styles);

export const AboutPage = () => {
  const classes = useStyles();
  const { t } = useTranslation('AboutPage');

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} component="h1" variant="h2">{t('About')}</Typography>
      <Box className={classes.about}>
        <MDRLogo className={classes.logoMDR} withTitle={false} />
        <Box>
          <Typography className={classes.name} component="h2" variant="h2">
            {t('Kaspersky Managed Detection and Response')}
          </Typography>
          <Box className={classes.version}>{t('version')}</Box>
          <Box className={classes.description}>{t('Description')}</Box>
          <LegalDocs />
          <Box className={classes.help}>
            <GuideEntry
              text={'helpfulSources.help text'}
              link={'helpfulSources.help link'}
              linkName={'helpfulSources.help linkName'}
              ns={'GuidePage'}
            />
            <GuideEntry
              text={'helpfulSources.support text'}
              link={'helpfulSources.support link'}
              linkName={'helpfulSources.support linkName'}
              ns={'GuidePage'}
            />
          </Box>
          <Box className={classes.copyright}>
            <KLLogo className={classes.logoKL} />
            <Box>{t('Kaspersky Lab')}</Box>
            <Box>{t('All Rights Reserved')}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
