import React, { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import filesize from 'filesize';
import { useTranslation } from 'react-i18next';
import { addSeconds, formatDuration, intervalToDuration, Locale } from 'date-fns';
import { enUS as enLocale, ru as ruLocale } from 'date-fns/locale';
import { EntityType } from 'store/slice';
import { markAsReadRequest, setItemsWereReadLocally, setItemWasReadLocally } from 'services/incidentResponses/slice';
import { newResponseStatus, ResponseItem, ResponseStatus } from 'services/incidentResponses/types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DateTime } from 'components/DateTime';
import { KLChip, KLChipProps } from 'components/KLChip';
import { KLCheckBox } from 'components/KLCheckBox';
import { ColumnDescription, KLTableV, RowMouseClick } from 'components/KLTable/KLTableV';
import { usePermissions } from 'components/Permissions/hooks';
import { getLang, getT } from 'utils/i18n';
import { CompositeTableCell } from '../CompositeTableCell';
import { CompositeTableCellField } from '../CompositeTableCell/types';
import { ResponsesDeclineDialog } from '../ResponsesDeclineDialog';
import { ResponsesDescDialog } from '../ResponsesDescDialog';
import { ResponseActions } from '../ResponsesActions';
import { ResponsesProps } from './types';
import styles, { processedRowsStyle } from './styles';

const useStyles = makeStyles(styles);

const textMaxSize = 25;

export const formatParameters = (parameters: {[key: string]: any}): object => {
  const localeMap: { [key: string]: Locale } = {
    en: enLocale,
    ru: ruLocale,
  };

  function monthsToDays(duration: Duration, curValue: number) {
    if (duration.months) {
      duration.days = Math.floor(curValue / 3600 / 24);
    }
    return duration;
  }
  return Object.keys(parameters)
    .reduce((acc, key) => {
      if (['p2p_verdict'].includes(key)) {
        return acc;
      }
      const curValue = parameters[key];
      const tr = getT(`IncidentResponses:parameters.${key}`);
      if (curValue === null || curValue === undefined) {
        return acc;
      }
      if (curValue instanceof Array && typeof curValue[0] === 'object') {
        return { ...acc, [tr]: curValue.map(p => formatParameters(p)) };
      }
      if (typeof curValue === 'object' && !(curValue instanceof Array)) {
        return { ...acc, [tr]: formatParameters(curValue) };
      }
      let value;
      switch (key) {
        case 'file_size_limit':
          value = filesize(Number(curValue), {
            locale: getLang(),
            symbols: { B: 'Б' },
          });
          break;
        case 'direction':
        case 'dump_type':
        case 'recursive':
          value = getT(`IncidentResponses:parameters.${curValue}`);
          break;
        case 'auto_turnoff_timeout_in_sec':
          value = formatDuration(monthsToDays(
            intervalToDuration({
              start: Date.now(),
              end: addSeconds(Date.now(), curValue),
            }),
            curValue,
          ), {
            format: ['days', 'hours', 'minutes', 'seconds'],
            locale: localeMap[getLang()],
          });
          break;
        default:
          value = curValue;
      }
      return { ...acc, [tr]: value };
    }, {});
};

const getDetailsFields = (parameters: string): CompositeTableCellField[] => {
  const params: {[key: string]: any } = formatParameters(JSON.parse(parameters));
  return Object.keys(params).map((key) => {
    let value = params[key];
    if (typeof value === 'object') {
      value = value instanceof Array ? '[...]' : '{...}';
    }
    return { label: key, value };
  });
};

export const Responses: React.FC<ResponsesProps> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('IncidentResponses');
  const [editResponsePerm] = usePermissions(['edit_incident_response']);

  const [isRejectDialogOpened, setRejectDialogState] = useState(false);
  const [descDialogData, setDescDialogData] = useState(null);
  const [isDescDialogOpen, setDescDialogOpen] = useState(false);
  const initialSelectedItems: string[] = [];
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  const { items, isLoading, update } = props;

  const waitingItems = items.reduce(
    (acc, { status }: ResponseItem) => (status === ResponseStatus.waiting ? acc + 1 : acc), 0,
  );
  const selectedItemsCount = selectedItems.length;
  const isAllSelected = waitingItems > 0 && waitingItems === selectedItemsCount;

  const selectAllHandler = (toState: boolean | null) => {
    if (toState === false || isAllSelected) {
      return setSelectedItems([]);
    }
    return setSelectedItems(items.filter(item => item.status === ResponseStatus.waiting).map(item => item.responseId));
  };

  const rowClickHandler = ({ rowData }: RowMouseClick) => {
    const { responseId } = rowData;

    if (!rowData.wasRead) {
      dispatch(setItemWasReadLocally(responseId));
    }

    setDescDialogData(rowData);

    setDescDialogOpen(true);
  };

  const openRejectDialog = () => setRejectDialogState(true);
  const closeRejectDialog = () => {
    setRejectDialogState(false);
    selectAllHandler(false);
  };
  const closeDescDialogHandler = () => {
    setDescDialogOpen(false);
  };

  const sendUpdate = (newStatus: newResponseStatus, commentText: string, selectedItems: string[]): void => {
    update(newStatus, commentText, selectedItems);
    closeRejectDialog();
    closeDescDialogHandler();
  };

  const approveHandler = () => sendUpdate(newResponseStatus.approved, '', selectedItems);
  const rejectHandler = (comment: string) => sendUpdate(newResponseStatus.rejected, comment, selectedItems);
  const declineAndSendHandler = (responseId: string, comment: string) => (
    sendUpdate(newResponseStatus.rejected, comment, [responseId])
  );
  const confirmAndSendHandler = (responseId: string, comment: string) => (
    sendUpdate(newResponseStatus.approved, comment, [responseId])
  );

  const getShortText = (text: string) => {
    const result = (text && text.length > textMaxSize) ? `${text.slice(0, textMaxSize)}...` : text;
    return <>{result}</>;
  };

  useEffect(() => {
    const notRead = items.filter(item => !item.wasRead);
    const ids = notRead.map(item => item.responseId);
    if (ids.length) {
      dispatch(markAsReadRequest({
        entityType: EntityType.INCIDENT_RESPONSE,
        entityIds: ids,
      }));
    }
    const autoApprovedIds = notRead.filter(item => item.status !== ResponseStatus.waiting).map(item => item.responseId);
    if (autoApprovedIds.length) {
      dispatch(setItemsWereReadLocally(autoApprovedIds));
    }
  }, [items, dispatch, update]);

  const chipProps: KLChipProps = {
    bgColor: '#FF4455',
    fontColor: theme.palette.common.white,
    bold: true,
    uppercase: true,
    fontSize: 12,
    lineHeight: 24,
  };
  const responseStatusLabel = (status: string) => {
    let label;

    switch (status) {
      case ResponseStatus.waiting:
        label = t('New');
        break;
      case ResponseStatus.rejected:
        label = t('Declined');
        break;
      case ResponseStatus.approved:
        label = t('Confirmed');
        break;
      default:
        label = status;
    }

    return status === ResponseStatus.waiting
      ? (<KLChip label={label} {...chipProps} />)
      : (<span className={classes.status}>{label}</span>);
  };

  const checkboxClickHandler = (event: MouseEvent) => event.stopPropagation();

  const selectOneHandler = (responseId: string) => {
    setSelectedItems(prevState => (prevState.includes(responseId)
      ? prevState.filter(resId => resId !== responseId)
      : [...prevState, responseId]));
  };

  const isPartiallySelected = selectedItemsCount > 0 && selectedItemsCount !== waitingItems;

  const columns: ColumnDescription[] = [];

  if (editResponsePerm && !!waitingItems) {
    columns.push({
      title: t('Status'),
      headerRenderer: () => (
        <KLCheckBox
          checked={isAllSelected}
          onChange={() => selectAllHandler(null)}
          onClick={checkboxClickHandler}
          indeterminate={isPartiallySelected}
        />
      ),
      field: 'checkbox',
      width: 64,
      render: ({ rowData: { responseId, status } }: RowMouseClick) => (
        status !== ResponseStatus.waiting ? null : (
          <KLCheckBox
            checked={selectedItems.includes(responseId)}
            onChange={() => selectOneHandler(responseId)}
            onClick={checkboxClickHandler}
          />
        )
      ),
    });
  }

  const columns2 = columns.concat([
    {
      title: t('Status'),
      field: 'status',
      width: 150,
      render: ({ rowData: { status } }: RowMouseClick) => responseStatusLabel(status),
      locator: 'status',
    },
    {
      title: t('Asset ID'),
      field: 'assetId',
      width: 165,
      locator: 'asset-id',
    },
    {
      title: t('Type'),
      field: 'type',
      width: 150,
      render: ({ rowData: { type } }: RowMouseClick) => t(type),
      locator: 'type',
    },
    {
      title: t('Details'),
      field: 'details',
      width: 300,
      render: ({ rowData: { filePath, maxFileSize, parameters } }: RowMouseClick) => (
        <CompositeTableCell
          fields={parameters ? getDetailsFields(parameters as string) : [
            { label: t('File path'), value: filePath },
            { label: t('Maximum file size'), value: maxFileSize ? filesize(Number(maxFileSize)) : null },
          ]}
        />
      ),
      locator: 'details',
    },
    {
      title: t('Comment'),
      field: 'comment',
      width: 200,
      render: (
        {
          rowData: {
            comment,
            description,
          },
        }: RowMouseClick,
      ) => (comment ? getShortText(comment) : getShortText(description)),
    },
    // {
    //   title: t('Changed by'),
    //   field: 'changedBy',
    //   width: 175,
    //   render: ({ rowData: { changedBy } }) => (changedBy === 'autoresponder' ? t('Autoresponder') : changedBy),
    // },
    {
      title: t('Update time'),
      field: 'update',
      width: 185,
      render: (
        { rowData: {
          updateTime,
          creationTime,
        },
        }: RowMouseClick,
      ) => (updateTime ? (<DateTime timestamp={updateTime} withTime />)
        : <DateTime timestamp={creationTime} withTime />),
    },
  ]);

  return (
    <div className={classes.container}>
      <ResponsesDeclineDialog
        open={isRejectDialogOpened}
        onCancel={closeRejectDialog}
        onSubmit={rejectHandler}
      />
      <ResponseActions
        selectedCount={selectedItemsCount}
        isLoading={isLoading}
        onDecline={openRejectDialog}
        onConfirm={approveHandler}
      />
      <ResponsesDescDialog
        open={isDescDialogOpen}
        data={descDialogData}
        onDecline={declineAndSendHandler}
        onConfirm={confirmAndSendHandler}
        onClose={closeDescDialogHandler}
      />
      <KLTableV
        columns={columns2}
        data={items}
        isLoading={isLoading}
        onRowClick={rowClickHandler}
        rowStyle={(props: any) => (
          (selectedItems[props?.responseId])
            ? processedRowsStyle
            : {}
        )}
      />
    </div>
  );
};
