import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  icon: {
    top: '-330px',
  },
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
});
