import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& p': {
      margin: `${theme.spacing(2)}px auto`,
    },
  },
}));

export const DeleteConfirmation: React.FC<{email: string; role: string}> = ({ email, role }) => {
  const classes = useStyles();
  const { t } = useTranslation('SettingsPage');

  return (
    <div className={classes.root}>
      <p>{t('Are you sure that you want to delete the following user?')}</p>
      <p>{`${t('Email')}: ${email}`}</p>
      <p>{`${t('Role')}: ${t(`UserRoles.${role}`)}`}</p>
    </div>
  );
};
