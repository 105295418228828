import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {},
  icon: {
    cursor: 'pointer',
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
  },
});
