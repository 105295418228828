import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import { KLCheckBox } from 'components/KLCheckBox';
import { useSelector } from 'store/hook';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'components/Permissions/hooks';
import { KLButton } from 'components/KLButton';
import { BottomDrawer } from 'components/BottomDrawer';
import { getGeneralConfigRequest, setGeneralConfigRequest } from 'services/settings/slice';
import { KLTextField } from 'components/KLTextField';
import { InfoIcon } from 'assets/icons/InfoIcon';
import styles from './styles';

type GeneralSettingsProps = {
  setWasChanges: (wasChanges: boolean) => void;
};

const useStyles = makeStyles(styles);

export const GeneralSettings: React.FC<GeneralSettingsProps> = props => {
  const { setWasChanges } = props;
  const {
    enableExtendedNotifications,
    daysForStatusOffline: originalDaysForStatusOffline,
    hideAbsentAssets,
    isLoading,
  } = useSelector((state) => state.settings);

  const userId = useSelector((state) => state.auth.userId);
  const classes = useStyles(props);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation(['SettingsPage', 'common']);
  const [
    isMdrAdmin,
  ] = usePermissions(['edit_organization_license']);
  const [extendedNotificationValue, setExtendedNotificationValue] = useState<boolean>(enableExtendedNotifications);
  const [
    daysForStatusOffline,
    setDaysForStatusOffline,
  ] = useState<string | number>(originalDaysForStatusOffline);
  const [hideAbsentAssetsValue, setHideAbsentAssetsValue] = useState<boolean>(hideAbsentAssets);
  const [isAgreementChecked, setIsAgreementChecked] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getGeneralConfigRequest(userId));
  }, [userId, dispatch]);
  
  useEffect(() => {
    setExtendedNotificationValue(enableExtendedNotifications);
  }, [enableExtendedNotifications]);

  useEffect(() => {
    setDaysForStatusOffline(originalDaysForStatusOffline);
  }, [originalDaysForStatusOffline]);

  useEffect(() => {
    setHideAbsentAssetsValue(hideAbsentAssets);
  }, [hideAbsentAssets]);
  
  const wasChanges = useMemo(() => {
    let notificationsChanged = false;
    if (enableExtendedNotifications !== extendedNotificationValue) {
      if (extendedNotificationValue && !isAgreementChecked) {
        return false;
      }
      notificationsChanged = true;
    }
    const daysChanged = originalDaysForStatusOffline !== daysForStatusOffline;
    const hideAbsentChanged = hideAbsentAssets !== hideAbsentAssetsValue;
    return notificationsChanged || daysChanged || hideAbsentChanged;
  }, [
    originalDaysForStatusOffline,
    daysForStatusOffline,
    hideAbsentAssetsValue,
    hideAbsentAssets,
    enableExtendedNotifications,
    extendedNotificationValue,
    isAgreementChecked,
  ]);
  
  useEffect(() => {
    setWasChanges(wasChanges);
  }, [wasChanges, setWasChanges]);
  
  const daysError = useMemo(() => (
    !daysForStatusOffline
    || daysForStatusOffline > 29
    || daysForStatusOffline < 2),
  [daysForStatusOffline]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, checked } = event.target;
    switch (name) {
      case 'extendedNotification':
        setExtendedNotificationValue(checked);
        break;
      case 'hideAbsent':
        setHideAbsentAssetsValue(checked);
        break;
      default:
        break;
    }
  };

  const handleChangeDays: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    if (name === 'daysForStatusOffline') {
      if (!value) {
        setDaysForStatusOffline(value);
        return;
      }
      const newValue = Number(value);
      if (newValue < 30) {
        setDaysForStatusOffline(newValue);
      }
    }
  };

  const handleSaveChanges = () => {
    dispatch(setGeneralConfigRequest(
      {
        config: {
          enableExtendedNotifications: extendedNotificationValue,
          hideAbsentAssets: hideAbsentAssetsValue,
          daysForStatusOffline: Number(daysForStatusOffline),
        },
        message: t('GeneralSettings.Settings were saved successfully'), 
      },
    ));
  };

  const handleCancelChanges = () => {
    setExtendedNotificationValue(enableExtendedNotifications);
    setHideAbsentAssetsValue(hideAbsentAssetsValue);
    setDaysForStatusOffline(originalDaysForStatusOffline);
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.subheader}>{t('GeneralSettings.Assets')}</h2>
      <FormControlLabel
        classes={{
          root: classes.controlCentered,
          label: classes.label,
          disabled: classes.disabled,
        }}
        control={(
          <Switch
            name="hideAbsent"
            checked={hideAbsentAssetsValue}
            onChange={handleChange}
          />
        )}
        label={(
          <>
            <span>
              <span>{t('GeneralSettings.Hide absent')}</span>
              <Tooltip
                classes={{ tooltip: classes.afterFieldTooltip }}
                title={t('GeneralSettings.Hide absent tooltip') as string}
                interactive
                placement="right-start"
                leaveDelay={1000}
              >
                <span className={classes.afterFieldTooltipInfoSpan}>
                  <InfoIcon fill={theme.palette.info.contrastText} className={classes.afterFieldTooltipInfoIcon} />
                </span>
              </Tooltip>
            </span>
            <span className={classes.note}>{t('GeneralSettings.Hide absent note')}</span>
          </>
        )}
      />
      <span>{t('GeneralSettings.days range header')}</span>
      <span className={classes.daysField}>
        <KLTextField
          name="daysForStatusOffline"
          type="number"
          variant="outlined"
          onChange={handleChangeDays}
          value={daysForStatusOffline}
          InputProps={{ inputProps: { min: 2, max: 29 } }}
          FormHelperTextProps={{
            classes: {
              root: classes.daysHelperText,
              contained: classes.daysContained,
            },
          }}
          error={daysError}
          helperText={daysError && t('GeneralSettings.Invalid days')}
        />
        <span className={classes.daysAfterField}>{t('GeneralSettings.days from the host last seen')}</span>
        <Tooltip
          classes={{ tooltip: classes.afterFieldTooltip }}
          title={t('GeneralSettings.days range tooltip') as string}
          interactive
          placement="right-start"
          leaveDelay={1000}
        >
          <span className={classes.afterFieldTooltipInfoSpan}>
            <InfoIcon fill={theme.palette.info.contrastText} className={classes.afterFieldTooltipInfoIcon} />
          </span>
        </Tooltip>
      </span>
      <h2 className={classes.subheader}>{t('GeneralSettings.Notifications')}</h2>
      <FormControlLabel
        classes={{
          root: classes.controlCentered,
          label: classes.label,
          disabled: classes.disabled,
        }}
        control={(
          <Switch
            name="extendedNotification"
            checked={extendedNotificationValue}
            onChange={handleChange}
          />
        )}
        label={(
          <>
            <span>{t('GeneralSettings.Extended notifications')}</span>
            <span className={classes.note}>{t('GeneralSettings.Extended notification note')}</span>
          </>
        )}
      />
      <span className={classes.extendedNotificationsText}>{t('GeneralSettings.AgreementPart1')}&nbsp;
        <a
          href={t('GeneralSettings.AgreementLink')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('GeneralSettings.AgreementLinkName')}
        </a>.
      </span>
      {!enableExtendedNotifications
        && (
        <FormControlLabel
          classes={{
            root: classes.control,
            label: classes.label,
            disabled: classes.disabled,
          }}
          control={(
            <KLCheckBox
              color="primary"
              checked={isAgreementChecked}
              onChange={(event) => setIsAgreementChecked(event.target.checked)}
              disabled={false}
            />
          )}
          label={(
            <span>{t('GeneralSettings.AgreementPart2')}</span>
          )}
        />
        )}
      <BottomDrawer open={wasChanges}>
        <KLButton
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          className={classes.saveButton}
          isLoading={isLoading}
          disabled={!isMdrAdmin}
        >
          {t('NotificationSettings.Save')}
        </KLButton>
        <KLButton
          variant="text"
          color="primary"
          onClick={handleCancelChanges}
          className={classes.cancelButton}
          disabled={isLoading}
        >
          {t('NotificationSettings.Cancel')}
        </KLButton>
      </BottomDrawer>
    </div>
  );
};
