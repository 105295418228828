import { postRequest, downloadFile } from 'utils/http';
import { TenantSuggestion } from './types';

const url = 'tenants';

export function getTenantsList() {
  return postRequest(`${url}/list`);
}

export function deleteTenant(tenantId: string) {
  return postRequest(`${url}/delete`, { tenantId });
}

export function validateTenant(tenantId?: string) {
  return postRequest(`${url}/validate`, { tenantId });
}

export function createTenant(tenant: any) {
  return postRequest(`${url}/create`, { ...tenant }, {
    timeout: 120000, // 2 min
  });
}

export function updateTenant(tenant: any) {
  return postRequest(`${url}/update`, { ...tenant }, {
    timeout: 120000, // 2 min
  });
}

export function archiveTenant(data: any) {
  return postRequest(`${url}/archive`, { ...data });
}

export function downloadTenantConfig(tenantId: string, blobId: string, purpose?: number) {
  return downloadFile(`${url}/archive`, { tenantId, blobId, purpose });
}

export function postTenantSuggestion(searchPhrase: string) {
  return postRequest<TenantSuggestion[]>(`${url}/suggestion`, { searchPhrase });
}

export function postHasAccessToRoot() {
  return postRequest<boolean>(`${url}/hasAccessToRoot`);
}
