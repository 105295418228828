import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select, {
  components,
  IndicatorComponentType,
  MultiValueProps,
  OptionProps,
  OptionsType,
  Styles,
  ValueType,
  ActionMeta,
  ValueContainerProps,
} from 'react-select';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import { Chip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KLCheckBox } from 'components/KLCheckBox';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { KLMultiSelectProps, KLMultiSelectType, KLMultiSelectValue } from './types';
import styles from './styles';

const createOption = (label: string | KLMultiSelectValue): KLMultiSelectValue => {
  if (typeof label === 'string') {
    return {
      label,
      value: label,
    };
  }
  return label;
};

const useChipStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: theme.spacing(3),
    borderRadius: 2,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    margin: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    padding: '0 6px',
  },
  closeIcon: {
    margin: '0 6px 0 0',
    width: 12,
    height: 12,
  },
}));

const useStyles = makeStyles(styles);

const customStyles: Styles = {
  control: (provided) => ({
    ...provided,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#B3B3B3',
    borderRadius: 3,
    minHeight: 32,
    boxShadow: 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05)',
    '&:hover': {
      borderColor: '#B3B3B3',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 0 1px 4px',
    fontSize: '14px',
    lineHeight: '16px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#B3B3B3',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    marginTop: 2,
    borderRadius: 0,
    zIndex: 10,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 14,
    lineHeight: '32px',
    padding: '0 8px',
    backgroundColor: 'transparent',
    fontWeight: 300,
    cursor: 'pointer',
    color: '#000000',
    '& label': {
      cursor: 'pointer',
    },
    '&:hover': {
      fontWeight: 600,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  }),
  input: (provided) => ({
    ...provided,
    flex: 1,
    zIndex: 1,
    '& div': {
      width: '100%',
    },
    '& input': {
      width: '100% !important',
    },
  }),
};

export const
  KLMultiSelect: React.FC<KLMultiSelectProps> = props => {
    const { t } = useTranslation('common');
    const {
      onChange,
      value: defaultValue,
      options = [],
      loading = false,
      load,
      placeholder = t('Search'),
      disabled = false,
      menuPlacement = 'auto',
      isMulti = true,
      type = KLMultiSelectType.default,
      allowSelectAll = false,
      selectAllLabel = t('All'),
    } = props;
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<OptionsType<KLMultiSelectValue>>(defaultValue.map(createOption));
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const ref = useRef<Select | null>(null);

    const selectAllOption = useMemo(() => ({
      label: t('Select all'),
      value: '*',
    }), []);

    const checkedOptions = useMemo(() => (
      allowSelectAll && options.length > 0 ? [selectAllOption, ...options] : options
    ), [options, allowSelectAll]);

    const handleChange = (value: ValueType<KLMultiSelectValue>, actionMeta: ActionMeta) => {
      let newValue: OptionsType<KLMultiSelectValue> = [];

      if (!value) {
        onChange([]);
        setValue([]);
        return;
      }

      newValue = newValue.concat(value);

      if (allowSelectAll && newValue.length > 0) {
        if (newValue[newValue.length - 1].value === selectAllOption.value && actionMeta.action === 'select-option') {
          newValue = [selectAllOption, ...options.map(createOption)];
        } else if (newValue.length === options.length) {
          if (newValue.includes(selectAllOption)) {
            newValue = newValue.filter(option => option.value !== selectAllOption.value);
          } else if (actionMeta.action === 'select-option') {
            newValue = [selectAllOption, ...options.map(createOption)];
          } else {
            newValue = [];
          }
        }
      }

      onChange(newValue.map(v => v.value).filter(v => v !== selectAllOption.value));
      setValue(newValue);
    };

    const handleInputChange = (inputValue: string) => {
      setInputValue(inputValue);
    };

    const handleInputChangeAsync = useMemo(() => debounce(300, (inputValue: any) => {
      if (load) {
        load(inputValue);
      }
    }), [load]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setInputValue('');
          handleChange([...value, createOption(inputValue)], { action: 'select-option' });
          event.preventDefault();
          break;
        default:
          break;
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (ref && ref.current && ref.current.select && ref.current.select.menuListRef
        && (ref.current.select.menuListRef as unknown as HTMLElement).contains(event.target as HTMLElement)) {
        // inside click
        return;
      }
      // outside click
      setMenuIsOpen(false);
    };

    useEffect(() => {
      if (menuIsOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [menuIsOpen]);

    const ClearIndicator: IndicatorComponentType<KLMultiSelectValue> = (props) => {
      const { clearValue } = props;
      return (
        <div
          className={classes.icon}
          onClick={clearValue}
          onMouseDown={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
          }}
        >
          <CloseIcon stroke="#979797" size={10} />
        </div>
      );
    };

    const DropdownIndicator: IndicatorComponentType<KLMultiSelectValue> = () => (
      <div
        className={classes.icon}
        onClick={() => setMenuIsOpen(true)}
      >
        <ArrowIcon fill="#979797" direction="bottom" />
      </div>
    );

    const ValueContainer: ComponentType<ValueContainerProps<KLMultiSelectValue>> = props => {
      const { children, getValue } = props;
      const currentValues = getValue();
      let toBeRendered = children;
      if (currentValues && children) {
        if ([...currentValues].some(val => val.value === selectAllOption.value)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          toBeRendered = [[children[0][0]], children[1]];
          // toBeRendered = [children[0].slice(1), children[1]];
        }
      }

      return (
        <components.ValueContainer {...props}>
          {toBeRendered}
        </components.ValueContainer>
      );
    };

    const MultiValue: ComponentType<MultiValueProps<KLMultiSelectValue>> = props => {
      const { data, removeProps: { onClick } } = props;
      const classes = useChipStyles();
      const labelToDisplay = data.value === selectAllOption.value ? selectAllLabel : data.label;

      return (
        <Chip
          color="primary"
          classes={{ root: classes.root, label: classes.label }}
          label={labelToDisplay}
          onDelete={onClick}
          onMouseDown={ev => {
            ev.preventDefault();
            ev.stopPropagation();
          }}
          deleteIcon={(<CloseIcon classes={{ root: classes.closeIcon }} size={12} stroke="#FFFFFF" strokeWidth={1} />)}
        />
      );
    };

    const Option: ComponentType<OptionProps<KLMultiSelectValue>> = (props) => {
      const { isSelected, label } = props;

      return (
        <div>
          <components.Option {...props}>
            <KLCheckBox
              color="primary"
              checked={isSelected}
              onChange={() => null}
            />
            <label>{label}</label>
          </components.Option>
        </div>
      );
    };

    useEffect(() => {
      if (allowSelectAll && value.length === options.length && value.length) {
        handleChange([selectAllOption, ...value], { action: 'select-option' });
      }
    }, [allowSelectAll, value, options]);

    switch (type) {
      case KLMultiSelectType.creatable:
        return (
          <CreatableSelect
            className={classes.root}
            components={{
              DropdownIndicator: null,
              ClearIndicator,
              MultiValue,
            }}
            inputValue={inputValue}
            isClearable
            isMulti={isMulti}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            value={value}
            styles={customStyles}
            noOptionsMessage={() => null}
            menuPosition="fixed"
            formatCreateLabel={(inputValue) => <span>{`${t('Create')} "${inputValue}"`}</span>}
            isDisabled={disabled}
          />
        );
      case KLMultiSelectType.async:
        return (
          <Select
            className={classes.root}
            components={{
              DropdownIndicator: null,
              ClearIndicator,
              MultiValue,
            }}
            onChange={handleChange}
            onInputChange={handleInputChangeAsync}
            isMulti={isMulti}
            isClearable
            filterOption={() => true}
            options={options.map(createOption)}
            isLoading={loading}
            placeholder={placeholder}
            value={value}
            styles={customStyles}
            noOptionsMessage={() => t('No options')}
            loadingMessage={() => t('Loading...')}
            isDisabled={disabled}
            openMenuOnClick={false}
          />
        );
      case KLMultiSelectType.checked:
        return (
          <Select
            ref={ref}
            className={classes.root}
            components={{
              DropdownIndicator,
              ClearIndicator,
              MultiValue,
              Option,
              ValueContainer,
            }}
            options={checkedOptions.map(createOption)}
            value={value}
            onChange={handleChange}
            isClearable
            isMulti={isMulti}
            placeholder={placeholder}
            styles={customStyles}
            menuPlacement={menuPlacement}
            isDisabled={disabled}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            menuIsOpen={menuIsOpen}
            noOptionsMessage={() => t('No options')}
            onFocus={() => {
              setMenuIsOpen(true);
            }}
          />
        );
      case KLMultiSelectType.default:
      default:
        return (
          <Select
            className={classes.root}
            components={{
              DropdownIndicator,
              ClearIndicator,
              MultiValue,
            }}
            options={options.map(createOption)}
            value={value}
            onChange={handleChange}
            isClearable
            isMulti={isMulti}
            placeholder={placeholder}
            styles={customStyles}
            noOptionsMessage={() => null}
            menuPlacement={menuPlacement}
            isDisabled={disabled}
          />
        );
    }
  };

export * from './types';
