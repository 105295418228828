import React, { useState, useCallback, useMemo, MouseEvent, createContext } from 'react';
import { arc, pie, PieArcDatum } from 'd3-shape';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import colorMatching from '../../utils/colorMatching';
import nameMatching from '../../utils/nameMatching';
import shouldShowData from '../../utils/shouldShowData';
import { useHoverHooks } from '../../utils/hoverHooks';
import { VerticalLegend } from '../VerticalLegend';
import { DonutChartProps, DonutDataItem } from './types';

import styles from './styles';

export const ItemsContext = createContext<any>({
  onMouseEnter: () => { },
  onMouseLeave: () => { },
  data: [],
  options: {},
  classes: {},
});

const useStyles = makeStyles(styles);
const colors = ['#EC64A1', '#F3A536', '#51AEE2', '#4767AB', '#47AB58'];
const _arc = arc();

export const DonutChart = ({ data = [], options = {}, children }: DonutChartProps) => {
  const { t } = useTranslation(['StatisticsPage']);
  const classes = useStyles({ options });
  const WIDTH = 150;
  const HEIGHT = 150;
  const totalValue = data.reduce((prev: number, item: any) => prev + item.value, 0);
  const onePercent = totalValue / 100;
  const ranges = pie()(data.map((d: DonutDataItem) => d.value));
  const arcs = ranges.map(({ startAngle, endAngle }: PieArcDatum<any>): string => _arc({
    innerRadius: 65,
    outerRadius: 65,
    startAngle,
    endAngle,
  }) ?? '');

  const [linePosition, setLinePosition] = useState<[number, number]>([0, 0]);
  const [activeItem, setActiveItem] = useState<false | string>(false);
  const { onMouseEnter, onMouseLeave } = useHoverHooks(
    (target) => {
      if (target) {
        const id = target.dataset?.id || target.getAttribute('data-id');

        setActiveItem(id || false);
      }
    },
    () => {
      setActiveItem(false);
      setLinePosition([0, 0]);
    },
  );

  const onMouseMove = useCallback((e: MouseEvent) => {
    const evt = e.target as HTMLElement;
    const parent = evt.parentNode as HTMLElement;
    const dim = parent.getBoundingClientRect();
    const x = e.clientX - dim.left;
    const y = e.clientY - dim.top;
    setLinePosition([x, y]);
  }, [setLinePosition]);

  const ctxValue = useMemo(() => ({
    onMouseEnter, 
    onMouseLeave, 
    data, 
    options, 
    classes,
  }), [
    onMouseEnter, 
    onMouseLeave, 
    data, 
    options, 
    classes,
  ]);

  if (!shouldShowData<DonutDataItem>(data)) {
    return (
      <div>{ t('No data') }</div>
    );
  }

  return (
    <div className={classes.DonutChart}>
      <svg className={classes.svgWrapper} width={WIDTH} height={HEIGHT}>
        <g transform={`translate(${WIDTH / 2}, ${HEIGHT / 2})`}>
          {
            arcs.map((path: string, i: number) => (
              <path
                key={i}
                data-id={i}
                strokeWidth={activeItem && +activeItem === i ? 14 : 7}
                strokeLinecap="butt"
                d={path}
                fill={colorMatching[data[i].name] || colors[i]}
                stroke={colorMatching[data[i].name] || colors[i]}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
              />
            ))
          }
        </g>

        <g transform={`translate(${WIDTH / 2}, ${HEIGHT / 2 + 7})`}>
          {
            activeItem ? (
              <text className={classes.text}>{`${data[+activeItem].value}`}</text>
            ) : (
              <text className={classes.text}>{`${options.total || totalValue}`}</text>
            )
          }
        </g>
      </svg>

      {
        (activeItem && (linePosition[0] || linePosition[1])) ? (
          <div
            className={classes.popup}
            style={{
              position: 'absolute',
              left: `${linePosition[0]}px`,
              top: `${linePosition[1]}px`,
            }}
          >
            {`${t(nameMatching[data[+activeItem].name] || data[+activeItem].name)} ${data[+activeItem].value}
              (${Math.round(data[+activeItem].value / onePercent)}%)`}
          </div>
        ) : null
      }

      <div className={classes.list}>
        <ItemsContext.Provider value={ctxValue}>
          {
            children || (
              <VerticalLegend />
            )
          }
        </ItemsContext.Provider>
      </div>
    </div>
  );
};
