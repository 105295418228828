export default {
  high: 'rgb(255, 68, 85)',
  normal: '#FFAA59',
  low: '#51AEE2',
  new: '#FFAA59',
  rejected: 'rgb(255, 68, 85)',
  approved: '#51AEE2',
  currentHostsCount: '#51AEE2',
  licenseLimitHostsCount: '#FFAA59',
  onHold: '#47AB58',
  ok: '#47AB58',
  offline: '#51AEE2',
  absent: '#4767AB',
  critical: 'rgb(255, 68, 85)',
  warning: '#F3A536',
} as Record<string, string>;
