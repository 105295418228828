import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { statsRequest, checkRequest } from 'services/statistics/slice';
import { usePermissions } from 'components/Permissions/hooks';
import { Loading } from 'components/Loading';
import { AssetStatus } from 'services/assets/types';
import { TelemetryStats } from 'pages/StatisticsPage/TelemetryStats';
import classNames from 'classnames';
import LineChart from './components/LineChart';
import { DonutChart } from './components/DonutChart';
import { CustomLegendStatus } from './components/CustomLegends';
import { UnderConstruction } from './components/UnderConstruction';
import styles from './styles';

const useStyles = makeStyles(styles);

export const StatisticsPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const [
    viewLicensePerm,
  ] = usePermissions([
    'view_organization_license',
  ]);

  const {
    data: {
      activeIncidentsStatsByPriority = {},
      assetsByStatus = [],
      licenseStats = {},
      responsesStatsByStatus = {},
      stats = {},
    },
    isLoading,
    isChecking,
    isAvailable,
  } = useSelector(state => state.statistics);

  const { assetsCount } = useSelector(state => state.activation);
  const { hideAbsentAssets } = useSelector(state => state.settings);
  const assetByStatusData = assetsByStatus.map(({ status, count }: { status: number; count: number }) => {
    switch (status) {
      case AssetStatus.Absent:
        return { name: 'absent', value: count };
      case AssetStatus.Offline:
        return { name: 'offline', value: count };
      case AssetStatus.Ok:
        return { name: 'ok', value: count };
      case AssetStatus.Warning:
        return { name: 'warning', value: count };
      case AssetStatus.Critical:
        return { name: 'critical', value: count };
      default:
        return undefined;
    }
  })
    .filter((d: { name: string; value: number } | undefined): d is { name: string; value: number } => d !== undefined)
    .filter((d: { name: string; value: number }) => (hideAbsentAssets ? d.name !== 'absent' : true));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isAvailable) {
      dispatch(statsRequest());
    }
  }, [isAvailable, dispatch]);

  if (isChecking || isAvailable === null) {
    return (<Loading />);
  }

  if (isAvailable === false) {
    return <UnderConstruction />;
  }

  return (
    <div>
      <div className={classes.root}>
        <div style={{ justifyContent: 'space-between' }}>
          <div>
            <h1 className={classes.title}>{t('Summary')}</h1>
          </div>
        </div>
      </div>
      <div className={classes.root}>
        {
          isLoading
            ? (<CircularProgress />)
            : (
              <>
                <div className={classes.row}>
                  <div className={classNames(classes.cell, classes.cellSm)}>
                    {viewLicensePerm && (
                      <div className={classes.chartContainer}>

                        <div className={classes.chartTile}>
                          <h2 className={classes.subheader}>
                            {t('Maximal number of assets for this license')} {`${assetsCount || ''}`}
                          </h2>
                          <DonutChart data={licenseStats.data} options={licenseStats.options} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classNames(classes.cell, classes.cellLg)}>
                    <div className={classes.chartContainer}>
                      <div className={classes.chartTile}>
                        <TelemetryStats />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classNames(classes.cell, classes.cellSm)}>
                    <div className={classes.chartContainer}>
                      <div className={classes.chartTile}>
                        <h2 className={classes.subheader}>{t('Assets by status')}</h2>
                        <DonutChart
                          data={assetByStatusData}
                          options={{}}
                        >
                          <CustomLegendStatus />
                        </DonutChart>
                      </div>
                      <div className={classes.chartTile}>
                        <h2 className={classes.subheader}>{t('Active incidents')}</h2>
                        <DonutChart
                          data={activeIncidentsStatsByPriority.data}
                          options={activeIncidentsStatsByPriority.options}
                        >
                          <CustomLegendStatus />
                        </DonutChart>
                      </div>
                    </div>
                  </div>
                  <div className={classNames(classes.cell, classes.cellLg)}>
                    <div className={classes.chartContainer}>
                      <div className={classes.chartTile}>
                        <h2 className={classes.subheader}>{t('Number of incidents')}</h2>
                        <div>
                          <LineChart data={stats.data} options={stats.options} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classNames(classes.cell, classes.cellSm)}>
                    <div className={classes.chartContainer}>
                      <div className={classes.chartTile}>
                        <h2 className={classes.subheader}>{t('Responses')}</h2>
                        <DonutChart
                          data={responsesStatsByStatus.data}
                          options={responsesStatsByStatus.options}
                        >
                          <CustomLegendStatus />
                        </DonutChart>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
        }
      </div>
    </div>
  );
};
