import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from 'store/slice';
import update from 'immutability-helper';
import { ResponseItem, ResponseMarkAsReadPayload, UpdateRequestSagaPayload } from './types';

const name = 'incidentResponses';

export const listRequest = createAction<string>(`${name}/list`);
export const updateRequest = createAction<UpdateRequestSagaPayload>(`${name}/update`);
export const markAsReadRequest = createAction<ResponseMarkAsReadPayload>(`${name}/mark-as-read`);
export const detailsRequest = createAction<string>(`${name}/details`);

const initialState: EntityState<ResponseItem, ResponseItem> = {
  list: [],
  isLoading: false,
  count: 0,
  error: null,
  details: null,
};

function markAsReadInList(state: EntityState<ResponseItem, ResponseItem>, list: ResponseItem[], responseId: string) {
  const commentIndex = list.findIndex((c) => c.responseId === responseId);

  const updatedComment = update(list[commentIndex], { wasRead: { $set: true } });
      
  state.list = update(list, {
    $splice: [[commentIndex, 1, updatedComment]],
  });
}

const incidentResponsesListSlice = createEntitySlice({
  name,
  initialState,
  reducers: {
    setItemWasReadLocally(state, action: PayloadAction<string>) {
      const responseId = action.payload;
      const { list } = state;
      markAsReadInList(state, list, responseId);
    },
    setItemsWereReadLocally(state, action: PayloadAction<string[]>) {
      const responseIds = action.payload;
      const { list } = state;

      responseIds.forEach(responseId => markAsReadInList(state, list, responseId));
    },
  },
});

export const {
  listStart,
  listSuccess,
  createSocketUp,
  updateSocket,
  error,
  setItemWasReadLocally,
  setItemsWereReadLocally,
  detailsStart,
  detailsSuccess,
} = incidentResponsesListSlice.actions;

export default incidentResponsesListSlice.reducer;
