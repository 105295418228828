import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ItemsContext } from '../DonutChart';
import colorMatching from '../../utils/colorMatching';
import nameMatching from '../../utils/nameMatching';

const nameToUrlMatching: Record<string, string> = {
  low: '/incidents?priorities=1&statuses=Open&statuses=OnHold',
  normal: '/incidents?priorities=2&statuses=Open&statuses=OnHold',
  high: '/incidents?priorities=3&statuses=Open&statuses=OnHold',
  new: '/incidents?responseStatuses=Waiting',
  rejected: '/incidents?responseStatuses=Rejected',
  approved: '/incidents?responseStatuses=Approved',
  ok: '/assets?lastSeen=all&statuses=0',
  warning: '/assets?lastSeen=all&statuses=1',
  critical: '/assets?lastSeen=all&statuses=2',
  offline: '/assets?lastSeen=all&statuses=4',
  absent: '/assets?lastSeen=all&statuses=5',
};

const ConditionalWrapper = ({ condition, wrapper, children }: any) => (condition ? wrapper(children) : children);

export const CustomLegendStatus = () => {
  const { t } = useTranslation(['StatisticsPage']);
  return (
    <ItemsContext.Consumer>
      {({ onMouseEnter, onMouseLeave, data, options, classes }) => (
        <>
          {
            data?.map((item: any, i: number) => (

              <div
                className={classes.listItem}
                key={item.name}
                data-id={i}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >

                <div
                  data-id={i}
                  className={classes.marker}
                  style={{ backgroundColor: colorMatching[item.name] }}
                />
                <div data-id={i} className={classes.names}>
                  <ConditionalWrapper
                    key={item.name}
                    condition={nameToUrlMatching[item.name]}
                    wrapper={(children: any) => <Link to={nameToUrlMatching[item.name]}>{children}</Link>}
                  >
                    {t(nameMatching[item.name] || item.name)}
                  </ConditionalWrapper>
                </div>
                <div data-id={i} className={classes.values}>{item.value}</div>
              </div>
            ))
          }

          {options?.limit ? (
            <div className={classes.listItem}>
              <span>{`${t('License limit exceeded by')} ${options.limit}`}</span>
            </div>
          ) : null}
        </>

      )}
    </ItemsContext.Consumer>
  );
};
