import { EntityType } from '../../store/slice';

export type ReportSettings = {
    enabled: boolean;
    emails: string[];
    period?: string[];
    time?: string;
    type: string;
    schedule: string;
    format: string;
    source: string;
    description: string;
    tenantId?: string;
}

export type ReportSettingsRecord = {
    clientReportSchedule: ReportSettings;
    createdAt: string;
    updatedAt: string;
}

export type ReportSettingsPayload = {
    // settings: Omit<ReportSettings, 'time' | 'period'>;
    settings: ReportSettings;
    client: string | null;
    message?: string;
};

export type DeleteReportSchedulePayload = {
    client: string | null;
    type: string;
};

export type ClientInformerState = {
    settingsToSet: ReportSettings;
    settingsReceived: ReportSettingsRecord[];
    notifications: ClientNotification[];
    isLoading: boolean;
    error: string | null;
  }

export type IncidentEmail = {
    issueKey: string;
    issueType: 'jira' | 'hive';
}

export type ClientNotification = {
    id: string;
    type: 'success' | 'warning' | 'error' | 'info';
    text: {
        'ru': string;
        'en': string;
    };
    link?: {
        type?: 'URL' | 'route';
        text: {
            'ru': string;
            'en': string;
        };
        href: string;
        localized_href?: {
            'ru': string;
            'en': string;
        };
    };
    close_mode?: 'each_user' | 'any_user';
}

export enum NotificationType {
    MDR,
    KSC,
}

export type NotificationPayload = {
    type: NotificationType;
    unreadOnly: boolean;
}

export type NotificationMarkAsReadPayload = {
    entityIds: string[];
    entityType: EntityType;
}
