import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getAttachmentFile } from 'services/attachments/api';
import { IncidentAttachment } from 'services/attachments/types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const CommunicationImage = (
  props: Partial<HTMLImageElement> & { clientId: string | null; secret: string; item: IncidentAttachment },
) => {
  const { clientId, secret, item } = props;
  const classes = useStyles();
  const [updatedSrc, setSrc] = useState<string>('');

  useEffect(() => {
    if (clientId && secret && item.origin === 'Service') {

      const fileName = decodeURI(item.fileName);
      if (['jpeg', 'jpg', 'png', 'gif'].some(v => fileName.endsWith(`.${v}`))) {

        getAttachmentFile(clientId, secret, item.attachmentId).then(blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setSrc(base64data);
          };
        }).catch(() => null);
      }

    }
  }, [clientId, secret, item]);

  return <img className={classes.image} src={updatedSrc} alt="" />;

};
