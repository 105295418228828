import axios from 'axios';
import { postRequest, putRequest, deleteRequest } from 'utils/http';
import { IncidentAttachment, IncidentAttachmentMarkAsReadPayload } from './types';

const url = 'attachments';

export function getAttachmentList(incidentId: string) {
  return postRequest<IncidentAttachment[]>(`${url}/list`, {
    incidentId,
  });
}

export function getAttachmentFile(clientId: string, secret: string, attachmentId: string) {
  return axios.get(`${clientId}/${url}/${attachmentId}`, {
    baseURL: '/',
    headers: { Authorization: `Bearer ${secret}` },
    responseType: 'blob',
  }).then(response => response.data);
}

export function createAttachment(clientId: string, secret: string, incidentId: string, caption: string, file: File) {
  const formData = new FormData();
  // eslint-disable-next-line @typescript-eslint/camelcase
  formData.append('meta', JSON.stringify({ caption, incident_id: incidentId }));
  formData.append('file', file, file.name);

  return axios.post(`${clientId}/attachments`, formData, {
    baseURL: '/',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${secret}`,
    },
  }).then(response => {
    const { attachment } = response.data.success;
    return {
      ...attachment,
      creationTime: new Date(attachment.creationTime).getTime(),
    };
  });
}

export function updateAttachment(attachmentId: string, caption: string) {
  return putRequest<IncidentAttachment>(`${url}/update`, {
    attachmentId,
    caption,
  });
}

export function deleteAttachment(attachmentId: string) {
  return deleteRequest<IncidentAttachment>(`${url}/delete`, {
    data: {
      attachmentId,
    },
  });
}

export function markAsReadAttachment(payload: IncidentAttachmentMarkAsReadPayload) {
  return postRequest<never>(`${url}/mark-as-read`, {
    ...payload,
  });
}
