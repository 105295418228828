import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  outerContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    justifyContent: 'center',
  },
  miniInnerContainer: {
    maxWidth: 1024,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  innerContainer: {
    maxWidth: 1024,
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  greyBackground: {
    backgroundColor: '#F7F7F7',
  },
  reverse: {
    flexDirection: 'row-reverse',
  },
});
