import React, { Component } from 'react';
import { MessageLayer } from 'components/MessageLayer';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

interface ErrorCheckerState {
  hasError: boolean;
}

class ErrorBoundary extends Component<any, ErrorCheckerState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(/* error: any, errorInfo: any */) {
    // You can also log the error to an error reporting service
    // TODO: Add logging in future
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <MessageLayer
          title={'App has crashed'}
          icon={<CancelOutlinedIcon style={{ color: 'red' }} />}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
