import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
  },
  fieldset: {
    position: 'static',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  collapse: {
    width: '100%',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
  },
  legend: {
    marginBottom: theme.spacing(1.5),
    marginLeft: -theme.spacing(1.5),
    cursor: 'pointer',
  },
  legendIcon: {
    marginRight: theme.spacing(0.5),
  },
  label: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  labelNoValue: {
    marginTop: theme.spacing(1),
  },
  date: {
    width: 210,
  },
});
