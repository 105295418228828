import React, { useState, useCallback, useRef, useMemo } from 'react';
import { scaleLinear, scaleTime, ScaleTime, ScaleLinear } from 'd3-scale';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { enUS as en, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import LegendItem from '../LegendItem';
import LineChartMark from '../LineChartMark';
import colorMatching from '../../utils/colorMatching';
import { useHoverHooks } from '../../utils/hoverHooks';
import { LineChartProps, DataItem, DataPoint } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);
const colors = ['#51AEE2', '#ED815E', '#4767AB', '#47AB58'];

const LineChart = ({ data = [], options = {} }: LineChartProps) => {
  const { t, i18n } = useTranslation(['StatisticsPage']);
  const classes = useStyles();
  const WIDTH = 1000;
  const HEIGHT = 500;
  const PADDING = {
    top: 30,
    right: 25,
    left: 50,
    bottom: 12,
  };
  const TICK_COUNT = 4;

  const [activeItems, toggleActiveItems] = useState<Record<string, boolean>>(
    data.reduce((obj: Record<string, boolean>, item: DataItem) => (
      { ...obj, [item.name]: true }
    ), {}),
  );

  const rawX: number[] = data.map((d: DataItem) => d.values)
    .flat()
    .map((d: DataPoint) => d.date)
    .filter((value, index, self) => self.indexOf(value) === index);

  const maxX: number = Math.max(...rawX);
  const actualMinX = Math.min(...rawX);

  const minDates = 7;

  if (rawX.length && (rawX.length < minDates)) {
    const diff = minDates - rawX.length;
    for (let i = 1; i <= diff; i++) {
      rawX.unshift(maxX - (24 * 60 * 60 * 1000 * i));
    }
  }
  
  const minX: number = Math.min(...rawX);

  const _x: ScaleTime<number, number> = scaleTime()
    .range([PADDING.left, WIDTH - PADDING.right])
    .domain([minX, maxX])
    .nice(7);

  const _y = useMemo(() => {
    const maxY: number = Math.max(
      ...data
        .filter((d: DataItem) => activeItems[d.name])
        .map((d: DataItem) => d.values)
        .flat()
        .map((d: DataPoint) => d.value),
    );

    const _y: ScaleLinear<number, number> = scaleLinear()
      .range([HEIGHT - PADDING.top, PADDING.bottom])
      .domain([0, maxY])
      .nice(TICK_COUNT);

    return _y;
  }, [
    activeItems,
  ]);

  const lines: string[][] = data.map(({ values, name }: DataItem) => [
    name,
    `M${_x(values[0].date)} ${_y(values[0].value)} 
                ${values.slice(1).map(d => `L${_x(d.date)} ${_y(d.value)}`).join(' ')}`,
  ]);

  const containerRef = useRef(null);
  const [linePosition, setLinePosition] = useState<[number, number]>([_x(actualMinX), 0]);
  const [popup, togglePopup] = useState<false | string>(false);

  const [popupPos, setPopupPos] = useState<[] | [number, number]>([]);

  const { onMouseEnter, onMouseLeave } = useHoverHooks(
    (target, { clientX, clientY }) => {
      if (target) {
        const cx = parseInt(target.getAttribute('cx') ?? '0', 10);
        const cy = parseInt(target.getAttribute('cy') ?? '0', 10);
        const name = target.getAttribute('name') ?? 'n/a';
        const container = containerRef.current! as HTMLDivElement;
        const { left, top } = container.getBoundingClientRect();

        setPopupPos([clientX - left, clientY - top]);
        setLinePosition([cx, cy]);
        togglePopup(name);
      }
    },
    () => {
      togglePopup(false);
      setPopupPos([]);
    },
  );

  const toggleItem = useCallback((e: React.SyntheticEvent) => {
    const t = e.target as HTMLElement;
    const name = t.getAttribute('title')!;
    const newActiveItems = {
      ...activeItems,
      [name]: !activeItems[name],
    };
    const items = Object.values(newActiveItems).filter(e => e);

    if (!items.length) {
      return;
    }

    toggleActiveItems(newActiveItems);
  }, [activeItems]);

  if (!data.length) {
    return (
      <div>{ t('No data') }</div>
    );
  }

  return (
    <div
      className={classes.LineChart}
      style={{
        maxWidth: '100%',
        maxHeight: `${HEIGHT * 2}px`,
      }}
      ref={containerRef}
    >
      <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`} style={{ maxHeight: `${HEIGHT}px` }}>
        <g className={classes.ticks}>
          {
            _y
              .ticks(TICK_COUNT)
              .map((t: number, i: number) => (
                <g key={i} transform={`translate(0, ${_y(t)})`}>
                  <text
                    x="25"
                    y="0"
                    alignmentBaseline="middle"
                    textAnchor="end"
                  >
                    {t}
                  </text>
                  <line
                    x1="30"
                    y1="0"
                    x2={WIDTH}
                    y2="0"
                    stroke="#ECF0F4"
                    strokeWidth="1"
                    strokeDasharray={i !== 0 ? 4 : undefined}
                  />
                </g>
              ))
          }
        </g>

        <g className={classes.ticks}>
          {
            rawX
              .map((t: number, i: number) => (
                <text
                  key={i}
                  x={_x(t)}
                  y={HEIGHT - 5}
                  alignmentBaseline="middle"
                  textAnchor="middle"
                >
                  {format(t, 'cccccc', { locale: i18n.language.includes('ru') ? ru : en })}
                </text>
              ))
          }
        </g>

        <g transform={`translate(${linePosition[0]}, 0)`} className={classes.line}>
          <line
            x1="0"
            y1={_y.range()[0]}
            x2="0"
            y2={_y.range()[1]}
            stroke="#979797"
            strokeWidth="1"
          />
        </g>

        {
          lines.map(([name, d], i: number) => (
            activeItems[name] ? (
              <g key={i}>
                <path key={i} d={d} stroke={colorMatching[name] || colors[i]} />
                {
                  data[i].values.map((p: DataPoint, j: number) => (
                    <LineChartMark
                      key={j}
                      x={_x(p.date)}
                      y={_y(p.value)}
                      color={colorMatching[name] || colors[i]}
                      name={`${p.date}`}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    />
                  ))
                }
              </g>
            ) : null
          ))
        }
      </svg>

      <div className={classes.legendBlock}>
        {
          data.map(({ name }: any, i: number) => (
            <LegendItem
              key={name}
              active={activeItems[name]}
              color={colorMatching[name] || colors[i]}
              title={name}
              onClick={toggleItem}
            >
              {t(name)}
            </LegendItem>
          ))
        }

      </div>

      {
        popup ? (
          <div
            className={classes.popup}
            style={{
              position: 'absolute',
              left: `${popupPos[0]}px`,
              top: `${(popupPos[1] || 0) + 10}px`,
            }}
          >
            {
              options.sortingHelper[popup] ? (
                options.sortingHelper[popup].map(([key, value]: [string, number]) => (
                  <div key={key}>{t(key)}: {value}</div>
                ))
              ) : popup
            }
          </div>
        ) : null
      }
    </div>
  );
};

export default LineChart;
