import React, { SVGProps } from 'react';
import { ReactComponent as BlackTitleVerticalLogo } from './black_title_v.svg';
import { ReactComponent as WhiteTitleHorizontalLogo } from './white_title_h.svg';
import { ReactComponent as WhiteNoTitleLogo } from './white_no_title.svg';
import { ReactComponent as BlackNoTitleLogo } from './black_no_title.svg';

type MDRLogoProps = {
  color?: 'white' | 'black';
  type?: 'horizontal' | 'vertical';
  withTitle?: boolean;
};

const defaultProps: MDRLogoProps = {
  color: 'black',
  type: 'horizontal',
  withTitle: false,
};

export const MDRLogo: React.FC<SVGProps<SVGSVGElement> & MDRLogoProps> = (props) => {
  const { color, type, withTitle, ...restProps } = props;

  return (
    <>
      {color === 'black' && type === 'vertical' && withTitle && <BlackTitleVerticalLogo {...restProps} />}
      {color === 'white' && type === 'horizontal' && withTitle && <WhiteTitleHorizontalLogo {...restProps} />}
      {color === 'white' && !withTitle && <WhiteNoTitleLogo {...restProps} />}
      {color === 'black' && !withTitle && <BlackNoTitleLogo {...restProps} />}
    </>
  );
};

MDRLogo.defaultProps = defaultProps;
