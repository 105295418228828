import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  NotificationState,
  NotificationGetResponse,
  NotificationSetPayload,
  NotificationTelegramResponse,
  NotificationTelegramResetResponse,
  NotificationTelegramResetPayload,
} from './types';

const name = 'notifications';

export const getRequest = createAction<string>(`${name}/get`);
export const setRequest = createAction<NotificationSetPayload>(`${name}/set`);
export const getTelegramRequest = createAction<string>(`${name}/get-telegram`);
export const resetTelegramRequest = createAction<NotificationTelegramResetPayload>(`${name}/reset-telegram`);
export const getChannelsRequest = createAction<string>(`${name}/getChannels`);

const initialState: NotificationState = {
  config: {},
  configStateToken: '',
  telegramStateToken: '',
  telegramActivated: false,
  isActivating: false,
  isLoading: false,
  channels: {
    email: false,
    telegram: false,
  },
  error: null,
};

const notificationSlice = createSlice({
  name,
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = null;
    },
    getSuccess(state, action: PayloadAction<NotificationGetResponse>) {
      state.config = action.payload.config;
      state.configStateToken = action.payload.configStateToken;
      state.isLoading = false;
    },
    setSuccess(state, action: PayloadAction<NotificationGetResponse>) {
      state.config = action.payload.config;
      state.configStateToken = action.payload.configStateToken;
      state.isLoading = false;
    },
    getTelegramSuccess(state, action: PayloadAction<NotificationTelegramResponse>) {
      const { configStateToken, activated, notActivated, pendingActivation } = action.payload;
      if (notActivated) {
        state.telegramActivated = false;
      }
      if (activated) {
        state.telegramActivated = true;
      }
      if (pendingActivation) {
        state.telegramActivationToken = pendingActivation.token;
        state.telegramActivationExpirationTime = pendingActivation.expirationTime;
      }
      state.telegramStateToken = configStateToken;
    },
    startResetTelegram(state) {
      state.isActivating = true;
      state.error = null;
    },
    resetTelegramSuccess(state, action: PayloadAction<NotificationTelegramResetResponse>) {
      const { notActivated, pendingActivation, configStateToken } = action.payload;
      if (notActivated) {
        state.telegramActivated = false;
        state.telegramActivationToken = undefined;
        state.telegramActivationExpirationTime = undefined;
      }
      if (pendingActivation) {
        state.telegramActivationToken = pendingActivation.token;
        state.telegramActivationExpirationTime = pendingActivation.expirationTime;
      }
      state.telegramStateToken = configStateToken;
      state.isActivating = false;
    },

    startGetChannels(state) {
      state.isLoading = true;
      state.error = null;
    },

    getChannelsSuccess(state, action: any) {
      const { channels, configStateToken } = action.payload;
      state.channels = channels;
      state.configStateToken = configStateToken;
      state.isLoading = false;
    },

    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isActivating = false;
      state.error = action.payload;
    },
  },
});

export const {
  start,
  getSuccess,
  setSuccess,
  getTelegramSuccess,
  startResetTelegram,
  resetTelegramSuccess,
  error,
  startGetChannels,
  getChannelsSuccess,
} = notificationSlice.actions;

export default notificationSlice.reducer;
