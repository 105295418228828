import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'store/hook';
import { useSocket } from 'components/SocketIO';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EntityType } from 'store/slice';
import {
  detailsRequest,
  detailsSocket,
  IncidentState,
  markAsReadRequest,
  listRequest,
  setPage,
  setLastVisited,
  incrementLoadingCount,
} from 'services/incidents/slice';
import { incidentHistoryRequest } from 'services/incidentHistory/slice';
import { IncidentHistoryState } from 'services/incidentHistory/types';
import { newResponseStatus, ResponseNotification } from 'services/incidentResponses/types';
import { CommentNotification } from 'services/comments/types';
import {
  IncidentNotification,
  IncidentStatus,
  IncidentDetails,
} from 'services/incidents/types';
import { IncidentAttachment, IncidentAttachmentNotification } from 'services/attachments/types';
import {
  createSocket as commentsCreateSocket,
  deleteSocket as commentsDeleteSocket,
  listRequest as commentsRequest,
  updateSocket as commentsUpdateSocket,
} from 'services/comments/slice';
import {
  createSocketUp as responsesCreateSocket,
  listRequest as responsesRequest,
  updateRequest,
  updateSocket as responsesUpdateSocket,
} from 'services/incidentResponses/slice';
import {
  createSocket as incidentAttachmentCreateSocket,
  deleteSocket as incidentAttachmentDeleteSocket,
  listRequest as attachmentsRequest,
  updateSocket as incidentAttachmentUpdateSocket,
} from 'services/attachments/slice';
import {
  listRequest as assetListRequest,
} from 'services/assets/slice';
import {
  INCIDENT_ATTACHMENT_CREATE_SOCKET,
  INCIDENT_ATTACHMENT_DELETE_SOCKET,
  INCIDENT_ATTACHMENT_UPDATE_SOCKET,
  INCIDENT_COMMENT_CREATE_SOCKET,
  INCIDENT_COMMENT_DELETE_SOCKET,
  INCIDENT_COMMENT_UPDATE_SOCKET,
  INCIDENT_RESPONSE_CREATE_SOCKET,
  INCIDENT_RESPONSE_UPDATE_SOCKET,
  INCIDENT_UPDATE_SOCKET,
} from 'global/sockets';
import { NOT_FOUND, INCIDENT_REVOKED, ACCESS_DENIED } from 'global/errors';
import { Title } from 'components/Title';
import { Loading } from 'components/Loading';
import { KLTab, KLTabs } from 'components/KLTabs';
import { usePermissions } from 'components/Permissions/hooks';
import { MessageLayer } from 'components/MessageLayer';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { KLButton } from 'components/KLButton';
import { MovePrevNext } from 'components/MovePrevNext';
import { sendCreateIncidentEmailRequest } from 'services/clientInformer/slice';
import { INCIDENT_LIST_PAGE } from 'global/routes';
import { DetailsDialog } from '../AssetListPage/components/DetailsDialog';
import { Summary } from './components/Summary';
import { Responses } from './components/Responses';
import { Communication } from './components/Communication';
import { assetRowClickHandler } from './components/Summary/types';
import { History } from './components/History';

import styles from './styles';

const useStyles = makeStyles(styles);

export function filteredAttachments(attachments: IncidentAttachment[]) {
  return attachments.filter(attachment => !attachment.hideInComments);
}
export const IncidentDetailsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['IncidentDetailsPage', 'IncidentResponses']);
  const { id } = useParams();
  const {
    list: incidentList,
    page,
    pageSize,
    pageStep,
    count,
    details,
    isLoading,
    error,
    filters,
    search,
    loadingCount = 0,
  } = useSelector((state: { incidents: IncidentState }) => state.incidents);
  const browserHistory = useHistory();

  const {
    list: history,
  } = useSelector((state: { incidentHistory: IncidentHistoryState }) => state.incidentHistory);

  const {
    list: comments,
    isLoading: isLoadingComments,
    isCreating: isCreatingComment,
    isCreated: isCreatedComment,
    isUpdating: isUpdatingComment,
    isUpdated: isUpdatedComment,
    isDeleting: isDeletingComment,
    isDeleted: isDeletedComment,
    error: errorComments,
  } = useSelector(state => state.comments);
  const {
    list: incidentResponses,
    isLoading: isLoadingIncidentResponses,
    error: errorIncidentResponses,
  } = useSelector(state => state.incidentResponses);
  const {
    list: attachments,
    isLoading: isLoadingAttachments,
    isCreating: isCreatingAttachment,
    isCreated: isCreatedAttachment,
    isUpdating: isUpdatingAttachment,
    isUpdated: isUpdatedAttachment,
    isDeleting: isDeletingAttachment,
    isDeleted: isDeletedAttachment,
    error: errorAttachments,
  } = useSelector(state => state.attachments);
  const { isActiveOrganization } = useSelector(state => state.auth);

  const [
    viewCommentsPerm,
    viewResponsesPerm,
    viewAttachmentsPerm,
    createCommentPerm,
    exportIncidentPerm,
  ] = usePermissions([
    'view_incident_comments_list',
    'view_incident_responses_list',
    'view_incident_attachments_list',
    'create_incident_comment',
    'export_incident',
  ]);

  useEffect(() => {
    dispatch(detailsRequest(id as string));
  }, [id, dispatch]);

  useEffect(() => {
    if (!details?.incidentId) {
      return;
    }

    dispatch(commentsRequest(details.incidentId as string));
    dispatch(responsesRequest(details.incidentId as string));
    dispatch(attachmentsRequest(details.incidentId as string));
    dispatch(incidentHistoryRequest(details.incidentId as string));
    dispatch(setLastVisited(details.incidentId as string));
  }, [details?.incidentId, dispatch]);

  useEffect(() => {
    if (details?.affectedHosts.length) {
      dispatch(assetListRequest({
        paging: { page: 0, pageSize: details.affectedHosts.length },
        filter: {
          relatedIncidentsIds: [details.caseId],
        },
        searchPhrase: '',
      }));
    }
  }, [id, details?.caseId, details?.affectedHosts.length, dispatch]);

  useEffect(() => {
    if (
      details
      && loadingCount > 3
      && !(
        details.wasRead
        || isLoading
        || isLoadingIncidentResponses
        || isLoadingComments
        || isLoadingAttachments
        || comments.find(item => !item.wasRead)
        || filteredAttachments(attachments).find(item => !item.wasRead)
        || incidentResponses.find(item => !item.wasRead)
      )
    ) {
      dispatch(markAsReadRequest({ entityIds: [details.incidentId], entityType: EntityType.INCIDENT }));
    } else {
      dispatch(incrementLoadingCount());
    }
  }, [
    details,
    comments,
    attachments,
    incidentResponses,
    isLoading,
    isLoadingIncidentResponses,
    isLoadingComments,
    isLoadingAttachments,
    dispatch,
  ]);

  const isSameIncident = (incidentId: string) => details?.incidentId === incidentId;

  useSocket(INCIDENT_UPDATE_SOCKET, (data: IncidentNotification) => {
    if (isSameIncident(data.incident.incidentId)) {
      dispatch(detailsSocket(data.incident));
    }
  });

  useSocket(INCIDENT_COMMENT_CREATE_SOCKET, (data: CommentNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(commentsCreateSocket(data.comment));
    }
  });

  useSocket(INCIDENT_COMMENT_UPDATE_SOCKET, (data: CommentNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(commentsUpdateSocket({ ...data.comment, idField: 'commentId' }));
    }
  });

  useSocket(INCIDENT_COMMENT_DELETE_SOCKET, (data: CommentNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(commentsDeleteSocket({ idField: 'commentId', id: data.comment.commentId }));
    }
  });

  useSocket(INCIDENT_RESPONSE_CREATE_SOCKET, (data: ResponseNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(responsesCreateSocket(data.response));
    }
  });

  useSocket(INCIDENT_RESPONSE_UPDATE_SOCKET, (data: ResponseNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(responsesUpdateSocket({ ...data.response, idField: 'responseId' }));
    }
  });

  useSocket(INCIDENT_ATTACHMENT_CREATE_SOCKET, (data: IncidentAttachmentNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(incidentAttachmentCreateSocket(data.attachment));
    }
  });

  useSocket(INCIDENT_ATTACHMENT_UPDATE_SOCKET, (data: IncidentAttachmentNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(incidentAttachmentUpdateSocket({ ...data.attachment, idField: 'attachmentId' }));
    }
  });

  useSocket(INCIDENT_ATTACHMENT_DELETE_SOCKET, (data: IncidentAttachmentNotification) => {
    if (isSameIncident(data.incidentId)) {
      dispatch(incidentAttachmentDeleteSocket({ idField: 'attachmentId', id: data.attachment.attachmentId }));
    }
  });

  const [selectedAssetId, setSelectedAssetId] = useState('');

  const onRowClickHandler: assetRowClickHandler = (event, rowData) => {
    const { id: assetId } = rowData ?? { host: '', id: '' };
    if (assetId) {
      setSelectedAssetId(assetId);
    }
  };

  const onDialogCloseHandler = () => {
    setSelectedAssetId('');
  };

  const onMovePrevNextClickHandler = useCallback((id: string) => {
    browserHistory.push(`${INCIDENT_LIST_PAGE}/${id}`);
  }, []);

  const onMovePrevNextPageChangeHandler = (page?: number) => {
    if (!page) return;
    dispatch(setPage(page));
    dispatch(listRequest({ paging: { page: page - 1, pageSize }, filter: filters, searchPhrase: search }));
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error === NOT_FOUND) {
    return (
      <MessageLayer
        title={t('Incident not found')}
        icon={<CancelOutlinedIcon style={{ color: 'red' }} />}
      />
    );
  }

  if (error === INCIDENT_REVOKED) {
    return (
      <MessageLayer
        title={t('Incident revoked')}
        icon={<CancelOutlinedIcon style={{ color: 'red' }} />}
      />
    );
  }

  if (error === ACCESS_DENIED) {
    return (
      <MessageLayer
        title={t('Access to the incident denied')}
        icon={<CancelOutlinedIcon style={{ color: 'red' }} />}
      />
    );
  }

  if (!details) {
    return null;
  }

  const updateResponses = (newStatus: newResponseStatus, comment: string, responsesIds: string[]) => (
    dispatch(updateRequest({
      newStatus,
      comment,
      responsesIds,
      successMessage: (count) => (newStatus === newResponseStatus.approved
        ? t('IncidentResponses:Confirmed responses', { count })
        : t('IncidentResponses:Declined responses', { count })),
      errorMessage: (count) => (newStatus === newResponseStatus.approved
        ? t('IncidentResponses:Responses that couldn\'t be confirmed', { count })
        : t('IncidentResponses:Responses that couldn\'t be declined', { count })),
    }))
  );

  const tabs: KLTab[] = [{
    label: t('Summary'),
    component: (
      <Summary
        details={details}
        onRowClickHandler={onRowClickHandler}
      />
    ),
    path: '#summary',
    isDefault: true,
    id: 'incident-details_summary',
  }];

  if (viewResponsesPerm) {
    tabs.push({
      label: `${t('Responses')} (${incidentResponses.length})`,
      component: (
        <Responses
          incidentId={details.incidentId}
          items={incidentResponses}
          isLoading={isLoadingIncidentResponses}
          hasError={!!errorIncidentResponses}
          update={updateResponses}
        />
      ),
      path: '#responses',
      id: 'incident-details_reponses',
    });
  }

  if (viewCommentsPerm || viewAttachmentsPerm) {
    tabs.push({
      label: `${t('Communication')} (${comments.length + filteredAttachments(attachments).length})`,
      component: (
        <Communication
          incidentId={details.incidentId}
          comments={comments}
          attachments={filteredAttachments(attachments)}
          isLoading={isLoadingComments || isLoadingAttachments}
          isCreating={!!isCreatingComment || !!isCreatingAttachment}
          isCreated={!!isCreatedComment || !!isCreatedAttachment}
          isUpdating={!!isUpdatingComment || !!isUpdatingAttachment}
          isUpdated={!!isUpdatedComment || !!isUpdatedAttachment}
          isDeleting={!!isDeletingComment || !!isDeletingAttachment}
          isDeleted={!!isDeletedComment || !!isDeletedAttachment}
          hasError={!!errorComments || !!errorAttachments}
          readonly={details.status === IncidentStatus.Closed || !createCommentPerm || !isActiveOrganization}
        />
      ),
      path: '#communication',
      id: 'incident-details_communication',
    });
  }
  tabs.push({
    label: `${t('History')}${history ? ` (${history.length})` : ''}`,
    component: (<History history={history} />),
    path: '#history',
    id: 'incident-details_history',
  });

  return (
    <Grid item xs={12}>
      <DetailsDialog
        assetId={selectedAssetId}
        onClose={onDialogCloseHandler}
        open={selectedAssetId !== ''}
      />
      <Card className={classes.card} elevation={0}>
        <Grid container className={classes.cardHeader}>
          <Title component="h2">
            {`${t('Incident')} `}
            <Typography variant="inherit" component="span" color="primary">
              {details.caseId}
            </Typography>
          </Title>
          <Box className={classes.cardHeaderActions}>
            <MovePrevNext<IncidentDetails>
              list={incidentList}
              onClick={onMovePrevNextClickHandler}
              onPageChange={onMovePrevNextPageChangeHandler}
              entityId={id || details.incidentId}
              entityIdField={'incidentId'}
              page={page}
              pageSize={pageSize}
              pageStep={pageStep}
              count={count}
            />
            {exportIncidentPerm && isActiveOrganization ? (
              <KLButton
                color="primary"
                variant="text"
                onClick={() => {
                  dispatch(sendCreateIncidentEmailRequest({ issueKey: details.caseId, issueType: 'thehive' }));
                }}
              >
                {t('Receive a PDF report')}
              </KLButton>
            ) : null}
          </Box>
        </Grid>
        <CardContent className={classes.cardContent}>
          <KLTabs
            tabs={tabs}
            withRouter
            classes={{
              tab: classes.tab,
            }}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
