import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    padding: theme.spacing(0, 4),
  },
  helpLink: {
    fontWeight: 600,
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  helpLinkIcon: {
    marginLeft: '4px',
  },
  statusValue: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    alignItems: 'center',
    padding: theme.spacing(0.5, 2, 0.5, 2),
    border: '1px solid #D9DDE2;',
    borderRadius: '4px',
  },
  currentProblems: {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
  },
  statusValueWithDescription: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },
  statusDescription: {
    borderTop: 'none',
    border: '1px solid #D9DDE2;',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    padding: theme.spacing(1.5, 1, 1.5, 2),
  },
  statusTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  property: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
  },
  value: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    alignItems: 'center',
  },
  linkIcon: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'middle',
  },
  nonCritical: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
  statusDate: {
    fontWeight: 400,
    color: '#2735448C',
  },
  link: {
    fontSize: theme.typography.pxToRem(14),
    marginLeft: '4px',
    maxWidth: '90%',
    padding: 0,
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  ul: {
    paddingLeft: theme.spacing(2),
  },
  protectionIssue: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0',
  },
});
