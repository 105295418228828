import { takeLatest, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { addNotification } from 'services/system/slice';
import {
  listRequest,
  listStart,
  listSuccess,
  createRequest,
  createStart,
  createSuccess,
  updateRequest,
  updateStart,
  updateSuccess,
  deleteRequest,
  deleteStart,
  deleteSuccess,
  fileRequest,
  markAsReadRequest,
  error,
} from './slice';
import * as api from './api';
import {
  IncidentAttachmentCreateRequestPayload,
  IncidentAttachmentUpdateRequestPayload,
  IncidentAttachmentDeleteRequestPayload,
  IncidentAttachmentFileRequestPayload,
  IncidentAttachmentMarkAsReadPayload,
} from './types';

function* fetchAttachmentList(action: PayloadAction<string>) {
  yield put(listStart());

  try {
    const items = yield call(api.getAttachmentList, action.payload);
    yield put(listSuccess(items));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* createAttachment(action: PayloadAction<IncidentAttachmentCreateRequestPayload>) {
  const { clientId, secret } = yield select(store => store.auth);

  yield put(createStart());

  try {
    const { incidentId, caption, file } = action.payload;
    const item = yield call(api.createAttachment, clientId, secret, incidentId, caption, file);
    yield put(createSuccess(item));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* updateAttachment(action: PayloadAction<IncidentAttachmentUpdateRequestPayload>) {
  yield put(updateStart());

  try {
    const { attachmentId, caption } = action.payload;
    const item = yield call(api.updateAttachment, attachmentId, caption);
    yield put(updateSuccess({ ...item, idField: 'attachmentId' }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* deleteAttachment(action: PayloadAction<IncidentAttachmentDeleteRequestPayload>) {
  try {
    yield put(deleteStart());
    yield call(api.deleteAttachment, action.payload.attachmentId);
    yield put(deleteSuccess({ idField: 'attachmentId', id: action.payload.attachmentId }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* downloadFile(action: PayloadAction<IncidentAttachmentFileRequestPayload>) {
  const { clientId, secret } = yield select(store => store.auth);
  const { attachmentId, fileName } = action.payload;

  try {
    const response = yield call(api.getAttachmentFile, clientId, secret, attachmentId);
    fileDownload(response, fileName);
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* markAsReadAttachment(action: PayloadAction<IncidentAttachmentMarkAsReadPayload>): any {
  try {
    yield call(api.markAsReadAttachment, action.payload);
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const attachmentSaga = [
  takeLatest(listRequest.type, fetchAttachmentList),
  takeLatest(createRequest.type, createAttachment),
  takeLatest(updateRequest.type, updateAttachment),
  takeLatest(deleteRequest.type, deleteAttachment),
  takeLatest(fileRequest.type, downloadFile),
  takeLatest(markAsReadRequest.type, markAsReadAttachment),
];
