import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Table,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  RowMouseEventHandlerParams,
} from 'react-virtualized';
import { Loading } from '../Loading';
import styles from './styles.v';

import 'react-virtualized/styles.css';

const useStyles = makeStyles(styles);

const _cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 60,
  defaultHeight: 60,
});

const headerHeight = 49;

export const KLTableV = ({ columns, data, onRowClick, isLoading, emptyDataSourceMessage, rowStyle }: any) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const cellRender = useMemo(() => (props: any) => {
    const { dataKey, columnIndex, parent, rowIndex, cellData } = props;

    const { render, locator } = columns[columnIndex];

    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div 
          style={{
            whiteSpace: 'normal',
            padding: '16px 0',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
          }}
          id={`${props.rowData?.responseId}_${props.dataKey}`}
          data-locator={locator}
        >
          {render ? render(props) : cellData}
        </div>
      </CellMeasurer>
    );
  }, [columns]);

  return (
    <>
      <AutoSizer onResize={() => _cache.clearAll()}>
        {({ height, width }) => (
          <Table
            deferredMeasurementCache={_cache}
            className={classes.table}
            headerClassName={classes.headerCell}
            width={width < 1000 ? 1000 : width}
            height={height}
            headerHeight={headerHeight}
            rowHeight={_cache.rowHeight}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
            overscanRowCount={2}
            onRowClick={onRowClick}
            rowStyle={({ index }: any) => (rowStyle ? rowStyle(data[index]) : {})}
          >
            {
              columns.map(({ title, field, width, headerRenderer }: any, i: number) => {
                const p: any = {
                  label: title,
                  dataKey: field,
                  width: parseInt(width, 10),
                  headerRenderer,
                };

                p.cellRenderer = cellRender;

                return <Column key={i} {...p} />;
              })
            }
          </Table>

        )}
      </AutoSizer>

      {
        isLoading ? (
          <div style={{ height: 400 }}>
            <div style={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
            }}
            >
              <Loading />
            </div>
          </div>
        ) : null
      }

      {
        data.length === 0 ? (
          <div style={{ height: 400 }}>
            <div className={classes.empty}>
              {emptyDataSourceMessage || t('No records to display')}
            </div>
          </div>
        ) : null
      }
    </>
  );
};

export type RowMouseClick = RowMouseEventHandlerParams;

export type ColumnDescription = {
  title: string;
  field: string;
  width?: number;
  locator?: string;

  render?: (item: RowMouseEventHandlerParams) => JSX.Element | null;
  headerRenderer?: (item: RowMouseEventHandlerParams) => JSX.Element | null;
}
