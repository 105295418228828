import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    padding: theme.spacing(4),
    display: (isFlex: boolean) => (isFlex ? 'flex' : 'block'),
    height: (isFlex: boolean) => (isFlex ? '100%' : 'inherit'),
    width: '100%',
    flexDirection: 'column',
  },
});
