import React from 'react';
import { Typography, StandardProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { TitleProps } from './types';

const useStyles = makeStyles(styles);

export const Title: React.FC<StandardProps<TitleProps, 'root'>> = props => {
  const { component, children } = props;
  const classes = useStyles(props);

  return (
    <Typography className={classes.root} variant={component || 'h1'} color={'textPrimary'}>
      {children}
    </Typography>
  );
};
