import { takeLatest, put, call } from 'redux-saga/effects';
import { addNotification } from 'services/system/slice';
import { RawResponse, TelemetryStatsRequest } from './types';

import {
  statsRequest,
  start,
  dataSuccess,
  error,
  checkRequest,
  startCheck,
  setAvailability, telemetryStatsStart, telemetryStatsSuccess, telemetryStatsError, telemetryStatsRequest,
} from './slice';

import {
  getStats,
  getCheck, getTelemetryStats,
} from './api';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchStats() {
  yield put(start());
  try {
    const stats: RawResponse[] = yield call(getStats);
    yield put(dataSuccess(stats));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchTelemetryStats({ payload }: PayloadAction<TelemetryStatsRequest>) {
  yield put(telemetryStatsStart(payload));
  try {
    const stats: any = yield call(getTelemetryStats, payload);
    yield put(telemetryStatsSuccess(stats));
  } catch (err) {
    const message = err.message || err;
    yield put(telemetryStatsError(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchCheck() {
  yield put(startCheck());
  try {
    yield call(getCheck);
    yield put(setAvailability(true));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(setAvailability(false));
  }
}

export const statisticsSaga = [
  takeLatest(telemetryStatsRequest.type, fetchTelemetryStats),
  takeLatest(statsRequest.type, fetchStats),
  takeLatest(checkRequest.type, fetchCheck),
];
