import './wdyr';
import 'core-js/stable/array/flat';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import 'utils/i18n';
import { SocketIOProvider } from 'components/SocketIO';
import ErrorBoundaries from 'components/ErrorBoundaries';
import { HeadWrapper } from 'components/HeadWrapper';
import theme from './themes/default';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true;
  }
  return false;
};

const render = () => {
  ReactDOM.render(
    <HeadWrapper>
      <ErrorBoundaries>
        <Provider store={store}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <StylesProvider>
                <ThemeProvider theme={theme}>
                  <SocketIOProvider>
                    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
                      <CssBaseline />
                      <App />
                    </DndProvider>
                  </SocketIOProvider>
                </ThemeProvider>
              </StylesProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </Provider>
      </ErrorBoundaries>
    </HeadWrapper>,
    document.getElementById('root'),
  );
};

render();

serviceWorker.unregister();
