import { createStyles, Theme } from '@material-ui/core/styles';
import theme from '../../../../themes/default';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4, 6),
    display: 'flex',
    flexDirection: 'column',
  },
  note: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: '#58595B',
    display: 'block',
  },
  control: {
    alignItems: 'start',
    marginLeft: theme.spacing(4),
  },
  extendedNotificationsText: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    maxWidth: theme.spacing(120),
  },
  subheader: {
    fontSize: '24px',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    marginBottom: theme.spacing(2),
  },
  controlCentered: {
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  label: {
    marginTop: -1,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    whiteSpace: 'pre-wrap',
  },
  disabled: {
    color: 'inherit',
    backgroundColor: 'white',
  },
  saveButton: {
    marginRight: theme.spacing(3),
    minWidth: theme.spacing(13),
  },
  cancelButton: {
    minWidth: theme.spacing(13),
  },
  daysField: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  daysAfterField: {
    marginBottom: 'auto',
    marginTop: 'auto',
    marginLeft: theme.spacing(1),
  },
  daysContained: {
    marginLeft: 0,
  },
  daysHelperText: {
    width: theme.spacing(50),
    position: 'absolute',
    bottom: theme.spacing(-3),
  },
  afterFieldTooltip: {
    color: 'white',
    backgroundColor: '#1D1D1B',
  },
  afterFieldTooltipInfoIcon: {
    verticalAlign: 'sub',
  },
  afterFieldTooltipInfoSpan: {
    marginBottom: 'auto',
    marginLeft: theme.spacing(1),
    marginTop: 'auto',
  },
});
