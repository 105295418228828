import { createAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from 'store/slice';
import {
  CommentCreateRequestPayload,
  CommentUpdateRequestPayload,
  CommentDeleteRequestPayload,
  CommentMarkAsReadPayload,
  CommentItem,
} from './types';

const name = 'comments';

export const listRequest = createAction<string>(`${name}/list`);

export const createRequest = createAction<CommentCreateRequestPayload>(`${name}/create`);
export const updateRequest = createAction<CommentUpdateRequestPayload>(`${name}/update`);
export const deleteRequest = createAction<CommentDeleteRequestPayload>(`${name}/delete`);
export const markAsReadRequest = createAction<CommentMarkAsReadPayload>(`${name}/mark-as-read`);

const initialState: EntityState<CommentItem, never> = {
  list: [],
  isLoading: false,
  count: 0,
  error: null,
  isCreating: false,
  isCreated: false,
  isUpdating: false,
  isUpdated: false,
  isDeleting: false,
  isDeleted: false,
};

export type CommentSortingOrder = 'ASC' | 'DESC';

export type CommentSorting<T> = {
  order: CommentSortingOrder;
  field: keyof T;
};

const commentListSlice = createEntitySlice({
  name,
  initialState,
  reducers: {},
});

export const {
  listStart,
  listSuccess,
  listSort,
  listCount,
  createStart,
  createSuccess,
  createSocket,
  updateStart,
  updateSuccess,
  updateSocket,
  deleteStart,
  deleteSuccess,
  deleteSocket,
  markAsRead,
  error,
} = commentListSlice.actions;

export default commentListSlice.reducer;
