import { EntityType } from 'store/slice';

export enum ResponseStatus {
  waiting = 'Waiting',
  rejected = 'Rejected',
  approved = 'Approved',
}

export enum newResponseStatus {
  rejected = 'Rejected',
  approved = 'Approved',
}

export type ScriptState = { script: null | {fileName: 'string'; content: string }; scriptIsLoading: boolean };

export enum ResponseType {
  File = 'File',
  MemoryDump = 'MemoryDump',
  RemoveKeyRegistry = 'RemoveKeyRegistry',
  Isolate = 'Isolate',
  RunShell = 'RunShell',
  QuarantineFile = 'QuarantineFile',
  RestoreFromQuarantine = 'RestoreFromQuarantine',
}

export interface ResponseItem {
  responseId: string;
  filePath: string;
  maxFileSize: string;
  assetId: string;
  status: ResponseStatus | newResponseStatus;
  maxFileSizeFormatted: string;
  type: ResponseType;
  changedBy: string;
  updateTime: number | null;
  comment: string;
  description: string;
  wasRead: boolean;
  creationTime: number;
  parameters: string;
}

export interface UpdateRequestSagaPayload {
  newStatus: newResponseStatus;
  comment?: string;
  responsesIds: string[];
  successMessage?: (count: number) => string;
  errorMessage?: (count: number) => string;
}

export interface UpdateApiPayload {
  responsesIds: string[];
  comment: string;
  status: string;
}

export interface ResponseNotification {
  incidentId: string;
  response: ResponseItem;
}

export type ResponseMarkAsReadPayload = {
  entityIds: string[];
  entityType: EntityType;
}
