import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2, 4),
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  item: {
    flexBasis: 'auto !important',
  },
  /* todo remove */
  leftPaddedItem: {
    flexBasis: 'auto !important',
    paddingLeft: '32px !important',
  },
  link: {
    display: 'inline-flex',
    justifyContent: 'baseline',
    color: '#00A88E',
    textDecoration: 'underline',
    '&:hover, &:active,&:visited': {
      color: '#00A88E',
    },
    '& svg': {
      height: '14px',
      width: '14px',
      position: 'relative',
      top: '3px',
      fill: '#00A88E',
      marginLeft: '4px',
    },
  },
  label: {
    marginBottom: theme.spacing(1),
    color: '#58595B',
    '&.Mui-error + textarea': {
      borderColor: '#ED2939',
    },
  },
  mailList: {
    borderRadius: 3,
    border: '1px solid #B3B3B3',
    boxShadow: 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05);',
    padding: '5px 8px',
    resize: 'vertical',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    '&:focus': {
      border: '1px solid #00A88E',
      outline: 'none',
    },
  },
  errorMessage: {
    display: 'none',
    '&.Mui-error': {
      display: 'block',
    },
  },
  saveButton: {
    marginRight: theme.spacing(3),
    minWidth: theme.spacing(13),
  },
  cancelButton: {
    minWidth: theme.spacing(13),
  },
  buttons: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button + button': {
      marginLeft: theme.spacing(1),
    },
  },
  deleteIcon: {
    fill: '#000000',
    opacity: 0,
    '.MuiTableRow-root:hover &': {
      opacity: 1,
    },
  },
});
