import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { EditorState, RichUtils, Modifier } from 'draft-js';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { getSelectionInlineStyle } from 'draftjs-utils';

type InlineProps = {
  translations?: { [key: string]: string };
  editorState?: EditorState;
  onChange?: (editorState: EditorState) => void;
  className?: string;
  options: Option[];
};

interface Option {
  style: string;
  icon?: string;
  className?: string;
  title?: string;
}

type CurrentStyles = { [key: string]: boolean };

export const Inline: React.FC<InlineProps> = props => {
  const { translations, editorState, onChange, className, options } = props;
  const [currentStyles, setCurrentStyles] = useState<CurrentStyles>({});

  const disabled = useMemo(() => (
    editorState === undefined ? false : RichUtils.getCurrentBlockType(editorState) === 'code-block'
  ), [editorState]);

  const toggleInlineStyle = (style: string) => {
    if (!editorState || !onChange) return;

    const newStyle = style === 'monospace' ? 'CODE' : style.toUpperCase();
    let newState = RichUtils.toggleInlineStyle(editorState, newStyle);
    if (style === 'subscript' || style === 'superscript') {
      const removeStyle = style === 'subscript' ? 'SUPERSCRIPT' : 'SUBSCRIPT';
      const contentState = Modifier.removeInlineStyle(
        newState.getCurrentContent(),
        newState.getSelection(),
        removeStyle,
      );
      newState = EditorState.push(
        newState,
        contentState,
        'change-inline-style',
      );
    }
    if (newState) {
      onChange(newState);
    }
  };

  const changeKeys = (style?: CurrentStyles) => {
    if (style) {
      return Object.keys(style).reduce<CurrentStyles>((acc, key) => ({
        ...acc, [key === 'CODE' ? 'monospace' : key.toLowerCase()]: style[key],
      }), {});
    }
    return undefined;
  };

  const handleClick = (style: string) => () => {
    // setCurrentStyles({ ...currentStyles, [style]: !currentStyles[style] });
    toggleInlineStyle(style);
  };

  useEffect(() => {
    setCurrentStyles(changeKeys(getSelectionInlineStyle(editorState)) || {});
  }, [editorState]);

  return (
    <div className={classNames('rdw-inline-wrapper', className)} aria-label="rdw-inline-control">
      {
        options
          .map(({ style, icon, className, title }, index: number) => {
            const active = currentStyles?.[style] || (style === 'MONOSPACE' && currentStyles?.CODE);
            return (
              <div
                key={index}
                onClick={handleClick(style)}
                className={classNames(
                  'rdw-option-wrapper',
                  className,
                  {
                    'rdw-option-active': active,
                    'rdw-option-disabled': disabled,
                  },
                )}
                title={title || translations?.[`components.controls.inline.${style}`]}
                aria-selected={active}
                aria-disabled={disabled}
              >
                <img
                  alt=""
                  src={icon}
                />
              </div>
            );
          })
      }
    </div>
  );
};
