import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2.5, 6, 3),
  },
  summary: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: '#666666',
    padding: theme.spacing(0, 3, 2, 0),
    verticalAlign: 'top',
  },
  field: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    paddingBottom: theme.spacing(2),
    verticalAlign: 'top',
  },
  fieldMd: {
    lineHeight: '1.75rem',
    paddingBottom: theme.spacing(2),
    verticalAlign: 'top',
  },
  priority: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  affectedTitle: {
    marginBottom: theme.spacing(1),
  },
  affected: {
    borderBottom: 'none',
    padding: 0,
  },
  affectedTab: {
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(1.5, 0),
  },
  affectedTabIndicator: {
    height: 2,
  },
  affectedPanel: {
    marginBottom: theme.spacing(4),
  },
  descriptionTitle: {
    marginBottom: theme.spacing(2),
  },
  description: {
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  clientDescription: {
    marginBottom: theme.spacing(4),
  },
  actionTitle: {
    marginBottom: theme.spacing(2),
  },
  actionDescription: {
    marginBottom: theme.spacing(2),
  },
});
