import React from 'react';
import { Helmet } from 'react-helmet';
import { BUILD_NUMBER } from 'global/environments';

export const HeadWrapper = (props: any) => {
  const { children } = props;
  // todo: we can change the title depending on the page
  return (
    <>
      <Helmet>
        <title>MDR Web Console</title>
        <meta name="mdr:build-number" content={BUILD_NUMBER} />
      </Helmet>
      {children}
    </>
  );
};
