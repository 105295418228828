import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3, 6),
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    padding: 0,
  },
  title: {
    marginRight: theme.spacing(6),
  },
  spacer: {
    flex: 0,
    margin: 12,
    '&::before': {
      display: 'block',
      content: '""',
      width: 1,
      height: 24,
      backgroundColor: '#C4C4C4',
    },
  },
  searchField: () => ({
    display: 'none',
  }),
  search: {
    marginLeft: 4,
    paddingLeft: 7,
    border: '1px solid #B3B3B3',
    borderRadius: 3,
    width: 200,
    outline: 'none',
    '& svg': {
      flexShrink: 0,
    },
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    height: 'auto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    padding: '5px 0',
  },
  extraActionsSpacer: {
    marginLeft: 'auto',
  },
});
