import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Select, SelectProps, InputBase, MenuItem, StandardProps } from '@material-ui/core';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { CheckIcon } from 'assets/icons/CheckIcon';
import styles from './styles';

export type KLSelectProps = {
  items: { value: any; label: string }[];
  dense?: boolean;
  iconClass?: string;
};

type ClassKeys = 'root' | 'icon' | 'input' | 'paper' | 'list' | 'item' | 'itemSelected';

const useStyles = makeStyles(styles);

export const KLSelect = React.forwardRef<HTMLUListElement, StandardProps<KLSelectProps & SelectProps, ClassKeys>>(
  (props, ref) => {
    const { items, dense, iconClass, ...restProps } = props;
    const classes = useStyles({ items, dense, ...restProps });
    const children = items.map(({ value, label }, idx) => (
      <MenuItem
        key={idx}
        value={value}
        disableRipple
        classes={{
          root: classes.item,
          selected: classes.itemSelected,
        }}
      >
        <>{label}<CheckIcon className={classes.itemSelectedIcon} /></>
      </MenuItem>
    ));

    return (
      <Select
        {...restProps}
        IconComponent={React.forwardRef((props, ref) => (
          <ArrowIcon {...ref} direction="bottom" classes={{ root: classNames(classes.icon, iconClass) }} />
        ))}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          getContentAnchorEl: null,
          marginThreshold: 0,
          elevation: 0,
          classes: {
            paper: classes.paper,
            list: classes.list,
          },
        }}
        input={<InputBase classes={{ root: classes.input }} />}
        classes={{ root: classes.root }}
        ref={ref}
      >
        {children}
      </Select>
    );
  },
);
