import { postRequest } from 'utils/http';
import { ResponseItem, UpdateApiPayload, ResponseMarkAsReadPayload } from './types';

const url = 'incidents/responses';

export function getList(incidentId: string) {
  return postRequest<ResponseItem[]>(`${url}/list`, { incidentId });
}

export function update(payload: UpdateApiPayload) {
  return postRequest<ResponseItem[]>(`${url}/update`, {
    ...payload,
  });
}

export function postMarkAsRead(payload: ResponseMarkAsReadPayload) {
  return postRequest<never>(`${url}/mark-as-read`, {
    ...payload,
  });
}

export function getDetails(responseId: string) {
  return postRequest<ResponseItem>(`${url}/details`, { responseId });
}

export function getScript(responseId: string) {
  return postRequest<void>(`${url}/response-script`, { responseId });
}
