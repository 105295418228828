import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MessageLayerProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const MessageLayer = (props: MessageLayerProps) => {
  const classes = useStyles();

  const { children, title, icon, controls } = props;

  return (
    <Box className={classes.container}>
      <Box className={classes.messageBox}>
        {icon ? (
          React.cloneElement(icon, { className: `${classes.icon} ${icon.props.className}` })
        ) : null}

        {title ? (
          <Box>
            <Typography variant="h1" component="h1">
              {title}
            </Typography>
          </Box>
        ) : null}

        {children ? (
          <Box className={classes.marginTop}>
            {children}
          </Box>
        ) : null}

        {controls ? (
          <Box className={classes.marginTop}>
            {controls}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
