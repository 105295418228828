import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  ListItem,
  ListItemProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  Badge,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

export type ListItemLinkProps = {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  selected?: boolean;
  listItemProps?: ListItemProps;
  listItemIconProps?: Omit<ListItemIconProps, 'children'>;
  listItemTextProps?: ListItemTextProps;
  count?: number;
  countColor?: string;
  countBackgroundColor?: string;
  dense?: boolean;
};

const useStyles = makeStyles(styles);

export const ListItemLink: React.FC<ListItemLinkProps> = (props) => {
  const {
    icon,
    primary,
    to,
    selected,
    listItemProps,
    listItemIconProps,
    listItemTextProps,
    count = 0,
    dense = false,
  } = props;
  const classes = useStyles(props);

  const renderLink = React.useMemo<React.ElementType>(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <Badge
        component="div"
        color="primary"
        badgeContent={count}
        max={99}
        variant={dense ? 'dot' : 'standard'}
        classes={{
          root: classes.badgeRoot,
          badge: classes.badge,
        }}
      >
        <ListItem {...listItemProps} button disableRipple component={renderLink} selected={selected}>
          {icon ? (
            <ListItemIcon {...listItemIconProps}>
              {icon}
            </ListItemIcon>
          ) : null}
          <ListItemText
            {...listItemTextProps}
            className={classNames(listItemTextProps?.className, classes.text)}
            primary={primary}
          />
        </ListItem>
      </Badge>
    </li>
  );
};
