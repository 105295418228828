export type KLMultiSelectChangeHandler = (value: string[]) => void;

export enum KLMultiSelectType {
  default,
  creatable,
  async,
  checked
}

export type KLMultiSelectProps = {
  value: (KLMultiSelectValue | string)[];
  onChange: KLMultiSelectChangeHandler;
  type?: KLMultiSelectType;
  options?: (KLMultiSelectValue | string)[];
  loading?: boolean;
  load?: (searchPhrase: string) => void;
  placeholder?: string;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  isMulti?: boolean;
  allowSelectAll?: boolean;
  selectAllLabel?: string;
};

export type KLMultiSelectValue = {
  label: string;
  value: string;
};
