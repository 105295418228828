import React, { useState, useRef, UIEvent } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import classNames from 'classnames';
import { Drawer, Box, Container, Typography, IconButton, StandardProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { HelpButton } from 'components/HelpButton';
import { MovePrevNext } from 'components/MovePrevNext';
import styles from './styles';
import { KLDrawerProps, KLDrawerClassKey } from './types';

export type Props<T> = KLDrawerProps<T> & { scrollPos?: number; maxScroll?: number };

const useStyles = makeStyles(styles);

export function KLDrawer<T extends { [key: string]: any } = {}>(props: StandardProps<Props<T>, KLDrawerClassKey>) {
  const {
    children,
    onClose,
    open,
    title,
    controls,
    helpId,
    classes: propClasses,
    MovePrevNextProps,
    preventClose = false,
  } = props;
  const [scrollPos, setScrollPos] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const classes = useStyles({ ...props, scrollPos, maxScroll });
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = (ev: UIEvent<HTMLDivElement>) => {
    setScrollPos(ev.currentTarget.scrollTop);
    setMaxScroll(ev.currentTarget.scrollHeight - ev.currentTarget.clientHeight);
  };

  const handleResize = () => {
    if (contentRef.current) {
      setScrollPos(contentRef.current.scrollTop);
      setMaxScroll(contentRef.current.scrollHeight - contentRef.current.clientHeight);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      disableBackdropClick={preventClose}
      disableEnforceFocus={preventClose}
    >
      <Container classes={{ root: classes.container }}>
        <Box className={classes.controls}>
          {MovePrevNextProps && (<MovePrevNext<T> short {...MovePrevNextProps} />)}
          { helpId ? (<HelpButton id={helpId} />) : null }
          <IconButton color="secondary" className={classes.control} disableRipple size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.title}>
          {title ? (
            <Typography variant="h3" component="h3">
              {title}
            </Typography>
          ) : null}
        </Box>
        <div
          ref={contentRef}
          className={classNames(classes.content, propClasses?.content)}
          onScroll={handleScroll}
        >
          {children}
          <ReactResizeDetector handleHeight onResize={handleResize} />
        </div>
        { controls ? (
          <Box className={classes.extControls}>
            {controls}
          </Box>
        ) : null}
      </Container>
    </Drawer>
  );
}
