import { Theme, createStyles } from '@material-ui/core/styles';

const noActiveColor = '#999999';

export const processedRowsStyle = {
  background: '#DBF1EE',
};

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4, 6, 2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  checkbox: {
    color: noActiveColor,
  },
  status: {
    fontWeight: 'bold',
    color: '#666',
    cursor: 'initial',
  },
  btn: {
    '&.Mui-disabled': {
      background: 'none',
    },
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
    },
  },
  container: {
    paddingBottom: 64,
    height: '100%',
    // minHeight: "100%",
  },
});
