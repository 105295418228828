import React, { useCallback, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

export function EmailSpoiler({ emails, collapseLimit = 3 }: {emails: string[]; collapseLimit?: number}) {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation(['SettingsPage']);

  const toggleCollapsed = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setCollapsed(c => !c);
    },
    [],
  );
  if (emails.length <= collapseLimit) {
    return (<>{emails.join(', ')}</>);
  }
  return collapsed
    ? (
      <>
        {emails.slice(0, collapseLimit).join(', ')}
        <br />
        <a href="#" onClick={toggleCollapsed}>{t('ReportSettings.TableColumnEmailsSpoilerExpand')}</a>
      </>
    )
    : (
      <>
        {emails.join(', ')}
        <br />
        <a href="#" onClick={toggleCollapsed}>{t('ReportSettings.TableColumnEmailsSpoilerCollapse')}</a>
      </>
    );
}
