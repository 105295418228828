import React, { useMemo } from 'react';
import { Modifier, EditorState, RichUtils, ContentState, SelectionState } from 'draft-js';

type CodeBlockProps = {
  title: string;
  className: string;
  icon: string;
  translations?: { [key: string]: string };
  editorState?: EditorState;
  onChange?: (editorState: EditorState) => void;
};

function _getTextSelection(contentState: ContentState, selection: SelectionState, blockDelimiter = '\n') {
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const blocks = contentState.getBlockMap();

  let lastWasEnd = false;
  const selectedBlock = blocks
    .skipUntil((block) => block?.getKey() === startKey)
    .takeUntil((block) => {
      const result = lastWasEnd;

      if (block?.getKey() === endKey) {
        lastWasEnd = true;
      }

      return result;
    });

  return selectedBlock
    .map((block) => {
      const key = block?.getKey();
      let text = block?.getText() || '';

      let start = 0;
      let end = text.length;

      if (key === startKey) {
        start = selection.getStartOffset();
      }
      if (key === endKey) {
        end = selection.getEndOffset();
      }

      text = text.slice(start, end);
      return text;
    })
    .join(blockDelimiter);
}

export const CodeBlock: React.FC<CodeBlockProps> = props => {
  const { translations, title, className, icon, editorState, onChange } = props;

  const handleClick = () => {
    if (!editorState || !onChange) return;

    let content: ContentState;
    if (RichUtils.getCurrentBlockType(editorState) === 'code-block') {
      content = Modifier.setBlockType(editorState.getCurrentContent(), editorState.getSelection(), 'unstyled');
    } else {
      const currentContentState = editorState.getCurrentContent();
      const selectedText = _getTextSelection(currentContentState, editorState.getSelection());
      content = Modifier.replaceText(currentContentState, editorState.getSelection(), selectedText);
      const state = EditorState.push(editorState, content, 'insert-characters');
      content = Modifier.setBlockType(state.getCurrentContent(), state.getSelection(), 'code-block');

    }
    const newEditorState = EditorState.push(editorState, content, 'change-block-type');
    onChange(newEditorState);
  };

  const isActive = useMemo(() => (
    editorState === undefined ? false : RichUtils.getCurrentBlockType(editorState) === 'code-block'
  ), [editorState]);

  return (
    <div
      aria-haspopup="false"
      aria-expanded="false"
      aria-label="rdw-code-control"
      aria-selected={isActive}
      className={className}
      title={title || translations?.['components.controls.blocktype.Code']}
      onClick={handleClick}
    >
      <img src={icon} alt="" />
    </div>
  );
};
