import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4, 6),
  },
  control: {
    alignItems: 'flex-start',
  },
  label: {
    marginTop: -1,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
  },
  disabled: {
    color: 'inherit',
    backgroundColor: 'white',
  },
  note: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: '#58595B',
    display: 'block',
  },
  saveButton: {
    marginRight: theme.spacing(3),
    minWidth: theme.spacing(13),
  },
  cancelButton: {
    minWidth: theme.spacing(13),
  },
  tenantsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
  },
  radioGroup: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  tenantCheckbox: {
    paddingBottom: theme.spacing(1),
  },

});
