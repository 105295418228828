import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { logoutRequest, setClientIdRequest } from 'services/auth/slice';
import { UserIcon } from 'assets/icons/UserIcon';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'components/Permissions/hooks';
import { changeLanguage, getLang } from 'utils/i18n';
import { UIS_PROFILE_URL, UIS_EU_PROFILE_URL } from 'global/environments';
import { GUIDE_PAGE } from 'global/routes';
import { useSelector } from 'store/hook';
import styles from './styles';
import { UserMenuProps } from './types';

const useStyles = makeStyles(styles);

export const UserMenu: React.FC<UserMenuProps> = props => {
  const { t } = useTranslation(['UserMenu', 'userRoles', 'languages']);
  const { appBarOpen, disabled } = props;
  const classes = useStyles();
  const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' });
  const dispatch = useDispatch();
  const lang = getLang();
  const { organizations, clientId, roleName, region, isSocAnalyst } = useSelector(state => state.auth);
  const [editLicensePerm] = usePermissions(['edit_organization_license']);

  const handleUserMenuLogout = () => {
    dispatch(logoutRequest());
  };

  const changeClientIdHandler = (id: string) => () => {
    dispatch(setClientIdRequest(id));
  };

  const isShowOrganizationsList = (organizations.length > 1 && !isSocAnalyst);

  const getOrganizations = () => organizations.map(({ organizationId, socOrganizationId }) => (
    <MenuItem
      key={organizationId}
      onClick={changeClientIdHandler(organizationId)}
      classes={{
        root: classNames({
          [classes.menuItem]: true,
          [classes.activeMenuItem]: organizationId === clientId,
        }),
      }}
    >
      {socOrganizationId || organizationId}
    </MenuItem>
  ));

  return (
    <Box className={classes.root}>
      <Button
        startIcon={<UserIcon />}
        endIcon={appBarOpen && <ArrowIcon classes={{ root: classes.arrowIcon }} />}
        fullWidth
        classes={{
          root: classes.button,
          text: classes.buttonText,
          startIcon: classes.buttonStartIcon,
          endIcon: classNames(classes.buttonEndIcon, popupState.isOpen && classes.buttonEndIconOpen),
        }}
        {...bindTrigger(popupState)}
      >
        <span className={classes.userName}>{appBarOpen && t('Account settings') }</span>
      </Button>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        marginThreshold={0}
        elevation={0}
        classes={{
          paper: classes.menu,
          list: classes.menuList,
        }}
      >
        {isShowOrganizationsList ? (
          <MenuItem dense classes={{ dense: classes.menuItemDense }}>{t('Organizations')}</MenuItem>
        ) : null}
        {isShowOrganizationsList ? getOrganizations() : null}
        {editLicensePerm && !disabled && (
          <MenuItem
            classes={{ root: classNames(classes.menuItem, classes.menuItemAlone) }}
            component={Link}
            to={GUIDE_PAGE}
            id="link_getting-started"
          >
            {t('Start guide')}
          </MenuItem>
        )}
        <MenuItem dense classes={{ dense: classes.menuItemDense }}>{t('Language')}</MenuItem>
        <MenuItem
          onClick={changeLanguage('ru')}
          classes={{
            root: classNames({
              [classes.menuItem]: true,
              [classes.activeMenuItem]: lang === 'ru',
            }),
          }}
        >
          {t('languages:Russian')}
        </MenuItem>
        <MenuItem
          onClick={changeLanguage('en')}
          classes={{
            root: classNames({
              [classes.menuItem]: true,
              [classes.activeMenuItem]: lang === 'en',
            }),
          }}
        >
          {t('languages:English')}
        </MenuItem>
        <MenuItem dense classes={{ dense: classes.menuItemDense }}>{t('Account')}</MenuItem>
        <MenuItem dense classes={{ root: classes.menuItemDenseInactive }}>
          {roleName && t(`userRoles:${roleName}`)}
        </MenuItem>
        <MenuItem
          component="a"
          href={region === 'eu' ? UIS_EU_PROFILE_URL : UIS_PROFILE_URL}
          target="_blank"
          classes={{ root: classes.menuItem }}
        >
          {t('Profile')}
        </MenuItem>
        <MenuItem 
          id="link_logout" 
          onClick={handleUserMenuLogout} 
          classes={{ root: classes.menuItem }}
        >
          {t('Sign out')}
        </MenuItem>
      </Menu>
    </Box>
  );
};
