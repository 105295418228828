import React from 'react';
import { FormControlLabel } from '@material-ui/core';
import { KLRadio } from 'components/KLRadio';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hook';
import { SUPERVISOR } from 'services/auth/constants';

type AutoResponseRadioProps = {
  value: string;
  labelKey: string;
  noteKeys: string[];
  classes: {
    control: string;
    label: string;
    disabled: string;
    note: string;
  };
};

const AutoResponseRadio: React.FC<AutoResponseRadioProps> = ({
  value,
  labelKey,
  noteKeys,
  classes,
}) => {

  const { t } = useTranslation('SettingsPage');
  const { roleName } = useSelector(state => state.auth);

  return (
    <FormControlLabel
      value={value}
      classes={{
        root: classes.control,
        label: classes.label,
        disabled: classes.disabled,
      }}
      disabled={roleName !== SUPERVISOR}
      control={<KLRadio />}
      label={(
        <>
          <span>{t(labelKey)}</span>
          {noteKeys.map(noteKey => (
            <span key={noteKey} className={classes.note}>
              {t(noteKey)}
            </span>
          ))}
        </>
      )}
    />
  );
};

export default AutoResponseRadio;
