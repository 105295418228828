// https://react-dnd.github.io/react-dnd/examples/sortable/simple
import React, { ChangeEventHandler, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';
import { KLCheckBox } from 'components/KLCheckBox';
import { DragIcon } from 'assets/icons/DragIcon';
import styles from './styles';

export type TableColumnProps = {
  id: any;
  index: number;
  name: string;
  checked: boolean;
  title: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  locator?: string;
};

export interface TableColumnDragItem {
  id: string;
  index: number;
  type: string;
}

const ItemType = 'column';
const useStyles = makeStyles(styles);

export const TableColumn: React.FC<TableColumnProps> = props => {
  const { id, index, name, checked, onChange, title, moveColumn, locator } = props;
  const classes = useStyles();
  const previewRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: TableColumnDragItem, monitor: DropTargetMonitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveColumn(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemType, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  preview(drop(previewRef));

  return (
    <div
      ref={previewRef}
      className={classes.label}
      style={{ opacity: isDragging ? 0 : 1 }}
      id={locator ? `${locator}_${name}` : undefined}
    >
      <FormControlLabel
        control={(
          <KLCheckBox
            color="primary"
            name={name}
            checked={checked}
            onChange={onChange}
          />
        )}
        label={title}
      />
      <div ref={ref} className={classes.drag}>
        <DragIcon />
      </div>
    </div>
  );
};
