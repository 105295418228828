import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { createEntitySlice } from '../../store/slice';
import {
  IncidentHistoryState,
  IncidentHistoryFilteringEntityType as IHFEntityType,
} from './types';

const name = 'incidentHistory';

export const incidentHistoryRequest = createAction<string>(`${name}/history`);

const initialState: IncidentHistoryState = {
  list: [],
  isLoading: false,
  count: 0,
  error: null,
  filteringEntities: IHFEntityType.All,
};

const incidentHistorySlice = createEntitySlice({
  name,
  initialState,
  reducers: {
    setEventTypeFilter(state, action: PayloadAction<IHFEntityType>) {
      state.filteringEntities = action.payload;
    },
  },
});

export const {
  setFilters,
  setColumns,
  setSearch,
  setEventTypeFilter,
  listStart,
  listSuccess,
  error,
} = incidentHistorySlice.actions;

export default incidentHistorySlice.reducer;
