import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { formatParameters } from 'pages/IncidentDetailsPage/components/Responses';
import styles from './styles';

type ParametersProps = {
  parameters: string | undefined;
};

const useStyles = makeStyles(styles);

export const Parameters = (props: ParametersProps) => {
  const { parameters } = props;
  const { t } = useTranslation('AssetListPage');
  const classes = useStyles();

  if (!parameters) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <ReactJson
        name={t('Parameters') as string}
        src={formatParameters(JSON.parse(parameters))}
        enableClipboard={false}
        displayObjectSize={false}
        displayDataTypes={false}
        style={{
          wordBreak: 'break-all',
        }}
        theme={{
          base00: 'rgba(0, 0, 0, 0)',
          base01: 'rgb(245, 245, 245)',
          base02: 'rgb(235, 235, 235)',
          base03: '#93a1a1',
          base04: 'rgba(0, 0, 0, 0.3)',
          base05: '#586e75',
          base06: '#073642',
          base07: '#666666',
          base08: '#d33682',
          base09: '#000000',
          base0A: '#dc322f',
          base0B: '#859900',
          base0C: '#6c71c4',
          base0D: '#586e75',
          base0E: '#2aa198',
          base0F: '#000000',
        }}
      />
    </Box>
  );
};
