import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Typography } from '@material-ui/core';
import { useSelector } from 'store/hook';
import { IncidentNewItem } from 'services/incidents/types';
import { AssetState, setSuggestion, suggestionRequest } from 'services/assets/slice';
import { AssetSuggestion } from 'services/assets/types';
import {
  setSuggestion as setTenantsSuggestions,
  suggestionRequest as tenantsSuggestionsRequest,
  hasAccessToRootRequest,
  TenantState,
} from 'services/tenants/slice';
import { TenantSuggestion } from 'services/tenants/types';
import { addNotification } from 'services/system/slice';
import { KLInput } from 'components/KLInput';
import { KLEditor } from 'components/KLEditor';
import { KLMultiSelect, KLMultiSelectType } from 'components/KLMultiSelect';
import styles from './styles';

export type NewIncidentFormProps = {
  state: IncidentNewItem;
  setState: (state: IncidentNewItem) => void;
  files: File[];
  setFiles: (files: File[]) => void;
  disabled?: boolean;
};

const useStyles = makeStyles(styles);

export const NewIncidentForm: React.FC<NewIncidentFormProps> = props => {
  const { state, setState, disabled = false, files, setFiles } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['NewIncident', 'filters']);
  const { suggestion, suggestionLoading } = useSelector((state: { assets: AssetState }) => state.assets);
  const {
    suggestion: tenantsSuggestion,
    suggestionLoading: tenantsSuggestionLoading,
    hasAccessToRoot,
  } = useSelector((state: { tenants: TenantState }) => state.tenants);

  useEffect(() => {
    dispatch(hasAccessToRootRequest());
  }, [dispatch]);

  const makeSuggestion = ({ hostName, assetId }: AssetSuggestion) => ({
    label: `${hostName}:${assetId}`,
    value: `${hostName}:${assetId}`,
  });

  const makeTenantsSuggestion = ({ tenantName, tenantId }: TenantSuggestion) => ({
    label: `${tenantName}:${tenantId}`,
    value: `${tenantName}:${tenantId}`,
  });

  const handleLoadSuggestions = (tenant?: string) => (searchPhrase: string) => {
    const tenantName: string | undefined = tenant?.substring(0, tenant?.indexOf(':'));
    const tenantsNames = tenantName ? [tenantName] : [];
    if (searchPhrase) {
      dispatch(suggestionRequest({ searchPhrase, tenantsNames }));
    } else {
      dispatch(setSuggestion([]));
    }
  };

  const handleLoadTenantSuggestions = (searchPhrase: string) => {
    if (searchPhrase) {
      dispatch(tenantsSuggestionsRequest(searchPhrase));
    } else {
      dispatch(setTenantsSuggestions([]));
    }
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel className={classes.label} shrink htmlFor="summary">{`${t('Summary')} *`}</InputLabel>
        <KLInput
          classes={{ input: classes.input }}
          id="summary"
          value={state.summary}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => setState({ ...state, summary: ev.target.value })}
          disabled={disabled}
          maxLength={256}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth>
        <KLEditor
          text={state.clientDescription}
          setText={(text) => setState({ ...state, clientDescription: text || '' })}
          files={files}
          setFiles={setFiles}
          label={`${t('Describe what happened')} *`}
          toolbarPosition="top"
          minRows={7}
          maxRows={7}
          fontSize={14}
          lineHeight={20}
          readOnly={disabled}
          uploaded
          sendNotification={(message, type) => {
            dispatch(addNotification({
              message,
              options: {
                variant: type,
              },
            }));
          }}
        />
      </FormControl>

      <FormControl className={classes.formControl} fullWidth>
        <Typography
          component="label"
          className={classes.label}
        >
          {`${t('Tenant')} ${!hasAccessToRoot ? '*' : ''}`}
        </Typography>
        <KLMultiSelect
          value={state.tenantId ? [state.tenantId] : []}
          onChange={([value]) => {
            setState({ ...state, tenantId: value, affectedHosts: [] });
          }}
          options={tenantsSuggestion?.map(makeTenantsSuggestion)}
          loading={tenantsSuggestionLoading}
          load={handleLoadTenantSuggestions}
          disabled={disabled}
          isMulti={false}
          placeholder={t('filters:Start entering name')}
          type={KLMultiSelectType.async}
        />
      </FormControl>

      <FormControl className={classes.formControl} fullWidth>
        <Typography component="label" className={classes.label}>{`${t('Assets')} *`}</Typography>
        <KLMultiSelect
          key={`affected_hosts_${state.affectedHosts}`}
          value={state.affectedHosts}
          onChange={(value) => setState({ ...state, affectedHosts: value })}
          options={suggestion?.map(makeSuggestion)}
          loading={suggestionLoading}
          load={handleLoadSuggestions(state.tenantId)}
          disabled={disabled}
          placeholder={t('filters:Start entering name')}
          type={KLMultiSelectType.async}
        />
      </FormControl>
    </div>
  );
};
