import { Theme, createStyles } from '@material-ui/core/styles';
import { KLTabsProps } from './types';

export default (theme: Theme) => createStyles({
  root: {
    minHeight: 'auto',
    marginLeft: -40, // left scroll button
    borderBottom: (props: KLTabsProps) => (props.noBorder ? 0 : '1px solid #E4E4E4'),
    padding: theme.spacing(0, 6),
    flexShrink: 0,
  },
  indicator: {
    height: 4,
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    minWidth: 'auto',
    minHeight: 'auto',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    padding: theme.spacing(1.75, 1),
    marginRight: theme.spacing(3),
  },
  tabTextColorInherit: {
    opacity: 1,
  },
  tabSelected: {
    fontWeight: theme.typography.fontWeightBold,
  },
  tabDisabled: {
    opacity: '1 !important',
    background: 'transparent',
    color: '#999999',
  },
  panel: {
    marginBottom: theme.spacing(1),
  },
});
