import { createAction, PayloadAction, PrepareAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from 'store/slice';
import {
  IncidentAutoResponse,
  IncidentAutoResponsePayload,
  IncidentClosePayload,
  IncidentColumns,
  IncidentColumnsPayload,
  IncidentCreatePayload,
  IncidentDetails,
  IncidentFilters,
  IncidentListPayload,
  IncidentListSagaPayload,
  IncidentMarkAsReadPayload,
  IncidentSagaFilters,
  IncidentStatus,
} from './types';

const name = 'incidents';

const prepareListRequest: PrepareAction<IncidentListSagaPayload> = (payload: IncidentListPayload) => {
  const { creationTime, updateTime, ...restFilter } = payload.filter;
  Object.keys(restFilter).forEach(key => restFilter[key] === undefined && delete restFilter[key]);
  const filter: IncidentSagaFilters = restFilter;

  if (creationTime?.start) {
    filter.minCreationTime = creationTime.start;
  }

  if (creationTime?.end) {
    filter.maxCreationTime = creationTime.end;
  }

  if (updateTime?.start) {
    filter.minUpdateTime = updateTime.start;
  }

  if (updateTime?.end) {
    filter.maxUpdateTime = updateTime.end;
  }

  return {
    payload: {
      ...payload,
      filter,
    },
  };
};

export const listRequest = createAction(`${name}/list`, prepareListRequest);
export const detailsRequest = createAction<string>(`${name}/details`);
export const markAsReadRequest = createAction<IncidentMarkAsReadPayload>(`${name}/mark-as-read`);
export const newCountRequest = createAction(`${name}/new-count`);
export const createRequest = createAction<IncidentCreatePayload>(`${name}/create`);
export const columnsGetRequest = createAction(`${name}/get-columns`);
export const columnsSetRequest = createAction<IncidentColumnsPayload>(`${name}/set-columns`);
export const autoResponseGetRequest = createAction(`${name}/get-auto-response`);
export const autoResponseSetRequest = createAction<IncidentAutoResponsePayload>(`${name}/set-auto-response`);
export const closeRequest = createAction<IncidentClosePayload>(`${name}/close`);

export type IncidentState = EntityState<IncidentDetails, IncidentDetails, IncidentFilters, IncidentColumns> & {
  autoResponse?: IncidentAutoResponse | null;
  closeLoading?: boolean;
  loadingCount?: number;
};

const initialState: IncidentState = {
  list: [],
  page: 1,
  pageSize: 10,
  pageStep: 0,
  filters: {},
  columns: null,
  search: '',
  loadingCount: 0,
  isLoading: false,
  count: 0,
  details: null,
  error: null,
  unreadCount: 0,
  autoResponse: null,
  closeLoading: false,
  lastVisited: null,
};

const incidentListSlice = createEntitySlice({
  name,
  initialState,
  reducers: {
    startAutoResponse: (state: IncidentState) => {
      state.isLoading = true;
    },
    setAutoResponse: (state: IncidentState, action: PayloadAction<IncidentAutoResponse>) => {
      state.autoResponse = action.payload;
      state.isLoading = false;
    },
    closeStart: (state: IncidentState) => {
      state.closeLoading = true;
    },
    closeSuccess: (state: IncidentState, action: PayloadAction<IncidentClosePayload>) => {
      const { resolution, comment } = action.payload;
      state.closeLoading = false;
      if (state.details) {
        state.details.status = IncidentStatus.Closed;
        state.details.resolution = resolution;
        state.details.statusDescription = comment;
        state.details.updateTime = Date.now();
      }
    },
    closeError: (state: IncidentState, action: PayloadAction<string>) => {
      state.closeLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setPageSize,
  setFilters,
  setColumns,
  setSearch,
  listStart,
  listSuccess,
  listCount,
  listSort,
  detailsStart,
  detailsSuccess,
  detailsSocket,
  createStart,
  createSuccess,
  createSocket,
  updateSocket,
  deleteSocket,
  setUnreadCount,
  incrementLoadingCount,
  markAsRead,
  setLastVisited,
  startAutoResponse,
  setAutoResponse,
  closeStart,
  closeSuccess,
  closeError,
  error,
} = incidentListSlice.actions;

export default incidentListSlice.reducer;
