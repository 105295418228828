import { postRequest } from 'utils/http';
import {
  InviteInfo,
  UserListSagaPayload,
  UpdateActivePayload,
  UpdateInvitedPayload,
  ActivatePayload,
  DeletePayload,
} from './types';

const url = 'user';

export function getUserList(payload: UserListSagaPayload) {
  return postRequest<UserListSagaPayload>(`${url}/list`, {
    paging: payload.paging,
  });
}

export function activateUser(payload: ActivatePayload) {
  return postRequest<ActivatePayload>(`${url}/user-activation`, { ...payload });
}

export function updateActiveUser(payload: UpdateActivePayload) {
  return postRequest<UpdateActivePayload>(`${url}/update-active-user`, { ...payload });
}

export function updateInvitedUser(payload: UpdateInvitedPayload) {
  return postRequest<UpdateInvitedPayload>(`${url}/update-invited-user`, { ...payload });
}

export function deleteUser(payload: DeletePayload) {
  return postRequest<DeletePayload>(`${url}/delete-user`, { ...payload });
}

export function createInvite(payload: InviteInfo) {
  return postRequest<InviteInfo>(`${url}/create-invite`, { ...payload });
}

export function deleteInvite(payload: InviteInfo) {
  return postRequest<InviteInfo>(`${url}/delete-invite`, { ...payload });
}
