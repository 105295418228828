import { takeLatest, put, call } from 'redux-saga/effects';
import { addNotification } from 'services/system/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { save } from 'utils/storage';
import { changeClientId, loginRequest, refreshRequest } from 'services/auth/slice';
import { activationExist } from 'services/activation/slice';
import { setPendingActivation } from 'services/user/slice';
import { getT } from 'utils/i18n';
import { EMAIL_ALREADY_USED, ACCESS_DENIED } from 'global/errors';
import { setChannelsSettings, resetTelegramActivation } from 'services/notifications/api';
import {
  userListRequest,
  activateUserRequest,
  updateActiveUserRequest,
  updateInvitedUserRequest,
  deleteUserRequest,
  createInviteRequest,
  deleteInviteRequest,
  listStart,
  listSuccess,
  closeSideDialog,
  error,
  submittingStart,
  submittingEnd,
} from './slice';

import {
  getUserList,
  createInvite,
  updateActiveUser,
  updateInvitedUser,
  deleteUser,
  activateUser,
  deleteInvite,
} from './api';
import { DeletePayload, InviteInfo, UpdateActivePayload, UpdateInvitedPayload, UserListSagaPayload } from './types';

function* fetchUserList(action: PayloadAction<UserListSagaPayload>): any {
  yield put(listStart());
  try {
    const { users } = yield call(getUserList, action.payload);
    yield put(listSuccess(users));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* sendUpdateActiveUser(action: PayloadAction<UpdateActivePayload>): any {
  yield put(submittingStart());
  try {
    const { channels, unsubscribeFromTelegram, telegramStateToken, wasChanges, ...restPayload } = action.payload;

    if (unsubscribeFromTelegram && telegramStateToken) {
      yield call(resetTelegramActivation, {
        renew: false,
        configStateToken: telegramStateToken,
        userId: restPayload.userId,
      });
    }
    if (wasChanges) {
      const { notificationConfigStateToken } = yield call(updateActiveUser, restPayload);
      yield call(setChannelsSettings, {
        channels,
        configStateToken: notificationConfigStateToken,
        userId: restPayload.userId,
      });
    }
    yield put(userListRequest({}));
    yield put(closeSideDialog());
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));

    const trMessage = message === ACCESS_DENIED ? getT('errors:Access denied') : message;
    yield put(addNotification({ message: trMessage, options: { variant: 'error' } }));
  }
  yield put(submittingEnd());
}

function* sendUpdateInvitedUser(action: PayloadAction<UpdateInvitedPayload>): any {
  yield put(submittingStart());
  try {
    yield call(updateInvitedUser, action.payload);
    yield put(userListRequest({}));
    yield put(closeSideDialog());
    yield put(addNotification({
      message: getT('notifications:Invite has been updated'),
      options: { variant: 'success' },
    }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
  yield put(submittingEnd());
}

function* sendDeleteUser(action: PayloadAction<DeletePayload>): any {
  try {
    yield call(deleteUser, action.payload);
    yield put(userListRequest({}));
    yield put(
      addNotification({
        message: getT('notifications:The user has been deleted'),
        options: { variant: 'success' },
      }),
    );
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));

    const trMessage = message === ACCESS_DENIED ? getT('errors:Access denied') : message;
    yield put(addNotification({ message: trMessage, options: { variant: 'error' } }));
  }
}

function* fetchInvite(action: PayloadAction<InviteInfo>): any {
  try {
    yield call(createInvite, action.payload);
    yield put(userListRequest({}));
    yield put(closeSideDialog());
    yield put(addNotification({
      message: getT('notifications:Invite has been sent to the specified email address'),
      options: { variant: 'success' },
    }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));

    const trMessage = message === EMAIL_ALREADY_USED ? getT('errors:Email already Used') : message;
    yield put(addNotification({ message: trMessage, options: { variant: 'error' } }));
  }
}

function* sendDeleteInvite(action: PayloadAction<InviteInfo>): any {
  try {
    yield call(deleteInvite, action.payload);
    yield put(
      addNotification({
        message: getT('notifications:Invite has been canceled'),
        options: { variant: 'success' },
      }),
    );
    yield put(userListRequest({}));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* sendActivateUser(action: PayloadAction<{ inviteId: string; isAuthenticated: boolean }>) {
  const { inviteId, isAuthenticated } = action.payload;

  yield put(submittingStart());
  try {
    if (!isAuthenticated) {
      yield put(loginRequest());
    } else {
      const { clientId } = yield call(activateUser, { inviteId });
      save('organizations', [clientId]);
      save('clientId', clientId);
      yield put(changeClientId({ clientId, roleName: '' }));

      yield put(activationExist());
      yield put(setPendingActivation({ inProgress: false, activated: true }));
      yield put(refreshRequest());
    }
  } catch (err) {
    const message = err.message || err;
    yield put(setPendingActivation({ inProgress: false, activated: false, error: err }));
    yield put(error(message));
  }
  yield put(submittingEnd());
}

export const userSaga = [
  takeLatest(userListRequest.type, fetchUserList),
  takeLatest(updateActiveUserRequest.type, sendUpdateActiveUser),
  takeLatest(updateInvitedUserRequest.type, sendUpdateInvitedUser),
  takeLatest(deleteUserRequest.type, sendDeleteUser),
  takeLatest(deleteInviteRequest.type, sendDeleteInvite),
  takeLatest(createInviteRequest.type, fetchInvite),
  takeLatest(activateUserRequest.type, sendActivateUser),
];
