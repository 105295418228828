import React, { useMemo } from 'react';
import { useSelector } from 'store/hook';
import {
  IncidentHistoryItem,
  IncidentHistoryState,
  IncidentHistoryFilteringEntityType as IHFEntityType,
} from 'services/incidentHistory/types';
import { HistoryInner } from './HistoryInner';
import { HistoryProps } from './types';

const filterHistoryEntityTypes = (data: IncidentHistoryItem[], filteringEntities: IHFEntityType | undefined) => {
  if (filteringEntities === IHFEntityType.All) {
    return data;
  }

  return data.filter(item => {
    switch (filteringEntities) {
      case IHFEntityType.Communication:
        if (item.entityType === 'attachment' || item.entityType === 'comment') {
          return true;
        }
        break;
      case IHFEntityType.Incidents:
        if (item.entityType === 'incident') {
          return true;
        }
        break;
      case IHFEntityType.Responses:
        if (item.entityType === 'response' || item.entityType === 'edrResponse') {
          return true;
        }
        break;
      default:
        //do nothing;
    }
    return false;
  });
};

const searchHistory = (data: IncidentHistoryItem[], search: string | undefined) => {
  if (!search || !data) {
    return data;
  }

  return data.filter(item => {
    if (
      (item.after && Object.values(item.after).some(
        (key) => key.value && String(key.value).indexOf(search) !== -1,
      ))
      || (item.before && Object.values(item.before).some(
        (key) => key.value && String(key.value).indexOf(search) !== -1,
      ))
    ) {
      return true;
    }

    return false;
  });
};

const filterHistory = (data: IncidentHistoryItem[], filters: any) => {
  if (!filters || !data) {
    return data;
  }

  const { communication, incident, response } = filters;
  if (!communication.length && !incident.length && !response.length) {
    return data;
  }

  const result = data.filter((item) => {
    if (!item.after) {
      return false;
    }

    if (
      (item.entityType === 'attachment' && communication.includes('attachment'))
      || (item.entityType === 'comment' && communication.includes('comment'))
      || (item.entityType === 'response' && Object.keys(item.after).some((key) => response.includes(key)))
      || (item.entityType === 'incident' && Object.keys(item.after).some((key) => incident.includes(key)))
    ) {
      return true;
    }

    return false;
  });

  return result;
};

export const History: React.FC<HistoryProps> = props => {
  const {
    columns,
    filters,
    search,
    isLoading,
    filteringEntities,
  } = useSelector((state: { incidentHistory: IncidentHistoryState }) => state.incidentHistory);

  const { history } = props;

  const memoFilteredList = useMemo(() => {
    const typeFilteredResult = filterHistoryEntityTypes(history, filteringEntities);
    const filterResult = filterHistory(typeFilteredResult, filters);
    return searchHistory(filterResult, search);
  }, [history, filters, filteringEntities, search]);

  // prevent items from being rendered before getting information about filters
  const list = (!filters && !search) ? [] : memoFilteredList;

  return (
    <HistoryInner
      history={list}
      columnsSettings={columns}
      isLoading={isLoading}
    />
  );
};
