import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import i18n from 'i18next';
import { makeStyles } from '@material-ui/core/styles';
import { KLSelect } from 'components/KLSelect';
import { useTranslation } from 'react-i18next';
import { getLang, getLocale } from 'utils/i18n';
import styles from './styles';

interface FooterSectionProps {
  outerClassname?: string;
  innerClassname?: string;
}

const useStyles = makeStyles(styles);

export const FooterSection: React.FC<FooterSectionProps> = (props) => {
  const classes = useStyles();
  const { outerClassname, innerClassname } = props;
  const { t } = useTranslation(['LoginPage', 'languages']);
  const lang = getLang().substr(0, 2);

  return (
    <Grid container className={classNames(classes.root, outerClassname)}>
      <Grid container className={innerClassname}>
        <Grid item xs={12} sm={10}>
          <ul>
            <li>{t('2020 AO Kaspersky Lab')}</li>
            <li><a href="https://www.kaspersky.com/web-privacy-policy">{t('Privacy Policy')}</a></li>
            <li><a href="https://www.kaspersky.com/anti-corruption-policy">{t('Anti - Corruption Policy')}</a></li>
            <li>
              <a href="https://www.kaspersky.com/end-user-license-agreement">
                {t('License Agreement B2C')}
              </a>
            </li>
            <li><a href="https://www.kaspersky.com/business/eula">{t('License Agreement B2B')}</a></li>
          </ul>
          <ul>
            <li><a href={t('Help_URL', { locale: getLocale() })}>{t('Help')}</a></li>
            <li><a href="http://www.kaspersky.com/about/contact">{t('Contact Kaspersky')}</a></li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={2} className={classes.selectorContainer}>
          <KLSelect
            items={[{ label: t('languages:Russian'), value: 'ru' }, { label: t('languages:English'), value: 'en' }]}
            value={lang}
            onChange={(event) => {
              const selectedLang = event.target.value as string;
              i18n.changeLanguage(selectedLang);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
