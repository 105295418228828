import React from 'react';
import {
  SETTINGS_PAGE, STATISTICS_PAGE, INCIDENT_LIST_PAGE, AUTH_LOGIN_PAGE, ACTIVATION_PAGE,
} from 'global/routes';
import { Redirect } from 'react-router-dom';
import { usePermissions } from 'components/Permissions/hooks';
import { Loading } from 'components/Loading';
import { useSelector } from 'store/hook';

export const DefaultRedirectPage = () => {
  const {
    auth: { isAuthenticated },
    activation: { isLicenseExists },
  } = useSelector(state => state);

  const [
    monitoringPerm,
    organizationSettingsPerm,
    portalSettingsPerm,
    incidentsListPerm,
  ] = usePermissions([
    'view_organization_monitoring',
    'view_organization_settings',
    'view_portal_settings',
    'view_incidents_list',
  ]);
  if (!isAuthenticated) {
    return <Redirect to={AUTH_LOGIN_PAGE} />;
  }
  if (monitoringPerm) {
    return <Redirect to={STATISTICS_PAGE} />;
  }
  if (incidentsListPerm) {
    return <Redirect to={INCIDENT_LIST_PAGE} />;
  }
  if (organizationSettingsPerm || portalSettingsPerm) {
    return <Redirect to={SETTINGS_PAGE} />;
  }
  if (!isLicenseExists) {
    return <Redirect to={ACTIVATION_PAGE} />;
  }

  return <Loading />;
};
