import React, { useMemo, useState, useEffect } from 'react';
import { markdown2html } from 'services/system/api';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'store/hook';
import { Title } from 'components/Title';
import { Loading } from 'components/Loading';
import { useDispatch } from 'react-redux';
import { set } from 'services/markdownCache/slice';
import { CustomImage } from '../CustomImage';
import { CustomMarkdownProps } from './types';

export const CustomMarkdown = ({
  description,
  classes,
  title,
  withCache,
}: CustomMarkdownProps) => {
  const { clientId, secret } = useSelector(store => store.auth);
  const markdownCache = useSelector(store => (withCache ? store.markdownCache[withCache] : null));
  const dispatch = useDispatch();

  const [desc, setDesc] = useState({
    loading: true,
    data: '',
  });

  useEffect(() => {
    // set data as plain text
    if (description.match(/^[\d\w'"!:., ]+$/g)) {
      setDesc({
        loading: false,
        data: description,
      });
      return;
    }

    // set data from cache
    if (withCache && markdownCache) {
      setDesc({
        loading: false,
        data: markdownCache.value,
      });
      return;
    }
    // set data from http markdown service
    markdown2html(description).then(({ data }) => {
      if (withCache && data) {
        dispatch(set({ key: withCache, value: data }));
      }

      setDesc({
        loading: false,
        data,
      });
    });

  }, [description, clientId, secret]);

  const DescriptionMarkdown = useMemo(() => (
    <div className={`${classes?.description} markdown`}>
      {
        ReactHtmlParser(desc.data, {
          transform: (node) => {
            if (node.type === 'tag' && node.name === 'img') {
              return (
                <CustomImage
                  src={node.attribs?.src}
                  alt={node.attribs?.alt}
                  clientId={clientId}
                  secret={secret}
                />
              );
            }

            return undefined;
          },
        })
      }
    </div>
  ), [desc.data, clientId, secret, classes]);

  return desc.loading ? (<Loading />) : (
    <>
      {title ? <Title component="h2" classes={{ root: classes?.descriptionTitle }}>{title}</Title> : null}
      {DescriptionMarkdown}
    </>
  );
};
