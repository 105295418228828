import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  label: {
    color: '#666',
    fontSize: '0.8rem',
  },
  value: {
    color: '#000',
    fontSize: '0.8rem',
    whiteSpace: 'pre-wrap',
  },
});
