// code partially from https://github.com/mfrachet/use-socketio/blob/master/src/io/hooks.ts
import { useContext, useEffect, useRef, useState } from 'react';
import { SocketIOContext } from './SocketIOContext';

export const useSocket = (
  eventKey?: string,
  callback?: (...args: any) => void,
) => {
  const socket = useContext(SocketIOContext);
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  const socketHandlerRef = useRef((...args: any) => {
    if (callbackRef.current) {
      callbackRef.current.apply(null, args);
    }
  });

  const subscribe = () => {
    if (socket && eventKey) {
      socket.on(eventKey, socketHandlerRef.current);
    }
  };

  const unsubscribe = () => {
    if (socket && eventKey) {
      socket.removeListener(eventKey, socketHandlerRef.current);
    }
  };

  useEffect(() => {
    subscribe();

    return unsubscribe;
  }, [eventKey, socket]); // eslint-disable-line

  return { socket, unsubscribe, subscribe };
};

export const useLastMessage = (eventKey: string) => {
  const socket = useContext(SocketIOContext);
  const [data, setData] = useState();

  const subscribe = () => {
    if (socket && eventKey) {
      socket.on(eventKey, setData);
    }
  };

  const unsubscribe = () => {
    if (socket && eventKey) {
      socket.removeListener(eventKey, setData);
    }
  };

  useEffect(() => {
    subscribe();

    return unsubscribe;
  }, [eventKey, socket]); // eslint-disable-line

  return { data, socket, unsubscribe, subscribe };
};
