import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationsState, SetOrganizationNamePayload, Organization } from './types';

export const name = 'organizations';

export const listRequest = createAction(`${name}/listRequest`);
export const setRequest = createAction<SetOrganizationNamePayload>(`${name}/setRequest`);

const initialState: OrganizationsState = {
  list: [],
  isLoading: false,
  error: null,
};

const organizationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = null;
    },
    success(state, action: PayloadAction<Organization[]>) {
      state.isLoading = false;
      state.list = action.payload;
    },
    setSuccess(state, action: PayloadAction<SetOrganizationNamePayload>) {
      const { clientId, socClientId } = action.payload;
      state.isLoading = false;
      state.list = state.list.map((organization: Organization) => ((clientId === organization.clientId)
        ? { ...organization, socClientId }
        : organization));
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  error,
  setSuccess,
  start,
  success,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
