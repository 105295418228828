import React, { SVGProps } from 'react';
import { ReactComponent as Icon } from './icon.svg';
import { ReactComponent as IconOutlined } from './iconOutlined.svg';

type ErrorIconProps = {
  outlined?: boolean;
};

export const ErrorIcon = React.forwardRef<SVGSVGElement, SVGProps<SVGSVGElement> & ErrorIconProps>((props, ref) => {
  const { outlined = false, ...rest } = props;

  return (outlined ? <IconOutlined {...rest} ref={ref} /> : <Icon {...rest} ref={ref} />);
});
