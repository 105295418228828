import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  table: {
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    fontFamily: 'MuseoSansCyrl',
    position: 'relative',
    '& .ReactVirtualized__Table__headerRow': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderTop: '1px solid rgb(196, 196, 196)',
      transform: 'translate3d(0px, 0px, 0px)',
      position: 'relative',
      zIndex: 10,
      marginTop: '-1px',
    },
    '& .ReactVirtualized__Table__row': {
      cursor: 'pointer',
      transition: 'background .3s ease',
      outline: 'none',
      alignItems: 'baseline',
    },

    '& .ReactVirtualized__Table__row:nth-child(odd)': {
      background: '#F6F6F6',
    },
    '& .ReactVirtualized__Table__row:nth-child(even)': {
      background: '#FFF',
    },
    '& .ReactVirtualized__Table__row:hover': {
      background: '#DBF1EE',
    },
    '& .ReactVirtualized__Table__rowColumn': {
      padding: '0 16px',
      fontFamily: 'MuseoSansCyrl',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      margin: 0,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-word',
    },
    '& .ReactVirtualized__Table__rowColumn:first-of-type': {
      marginLeft: '38px',
    },
  },
  headerCell: {
    padding: '12px 16px',
    textAlign: 'left',
    textTransform: 'initial',

    fontFamily: 'MuseoSansCyrl',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    margin: 0,
    '&:first-of-type': {
      marginLeft: '38px',
    },
  },
  empty: {
    backgroundColor: '#f6f6f6',
    textAlign: 'center',
    fontSize: '14px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 49,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'MuseoSansCyrl',
  },
});
