import { createAction, createSlice, PayloadAction, PrepareAction } from '@reduxjs/toolkit';
import {
  ClientInformerState,
  IncidentEmail,
  ReportSettingsRecord,
  ReportSettingsPayload,
  ClientNotification,
  NotificationPayload,
  NotificationMarkAsReadPayload, DeleteReportSchedulePayload,
} from './types';

const name = 'clientInformer';
const prepareRequest: PrepareAction<any> = (payload: any) => ({ payload });
const prepareIncidentEmailRequest: PrepareAction<any> = (payload: IncidentEmail) => ({ payload });

export const getReportSettingsRequest = createAction(`${name}/get`);
export const setReportSettingsRequest = createAction<ReportSettingsPayload & { resolve(): void}>(`${name}/set`);
export const deleteScheduleRequest = createAction<
  DeleteReportSchedulePayload & { message: string }
>(`${name}/delete-schedule`);
export const sendAssetsByEmailRequest = createAction(`${name}/send-assets-by-email`);
export const sendCreateReportEmailRequest = createAction(`${name}/create-report-email`, prepareRequest);
export const sendCreateIncidentEmailRequest = createAction(`${name}/create-incident-email`,
  prepareIncidentEmailRequest);
export const getNotificationsRequest = createAction<NotificationPayload>(`${name}/get-notifications`);
export const markAsReadRequest = createAction<NotificationMarkAsReadPayload>(`${name}/mark-as-read`);

const initialState: ClientInformerState = {
  settingsToSet: {
    enabled: false,
    emails: [],
    period: [],
    time: '',
    description: '',
    format: 'pdf',
    schedule: '',
    source: 'jira',
    type: 'weekly',
  },
  settingsReceived: [],
  notifications: [],
  isLoading: false,
  error: null,
};

const clientInformerSlice = createSlice({
  name,
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
    },
    success(state) {
      state.isLoading = false;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    settingsFetched(state, action: PayloadAction<ReportSettingsRecord[]>) {
      state.settingsReceived = action.payload;
    },
    saveSettings(state, action: PayloadAction<ReportSettingsRecord>) {
      state.settingsReceived = state.settingsReceived.map(
        item => (
          action.payload.clientReportSchedule.type === item.clientReportSchedule.type
            ? action.payload
            : item
        ),
      );
    },
    saveNotifications(state, action: PayloadAction<ClientNotification[]>) {
      state.notifications = action.payload;
    },
    deleteScheduleSuccess(state, action: PayloadAction<DeleteReportSchedulePayload>) {
      state.settingsReceived = state.settingsReceived.filter(
        item => (
          action.payload.type !== item.clientReportSchedule.type
        ),
      );
    },
  },
});

export const {
  start,
  settingsFetched,
  success,
  error,
  saveSettings,
  saveNotifications,
  deleteScheduleSuccess,
} = clientInformerSlice.actions;

export default clientInformerSlice.reducer;
