import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3, 6),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  about: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  logoMDR: {
    flexShrink: 0,
    marginRight: theme.spacing(3),
  },
  name: {
    marginBottom: theme.spacing(2),
  },
  version: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(5),
  },
  help: {
    marginBottom: theme.spacing(8),
  },
  logoKL: {
    width: 100,
  },
  copyright: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: '#999999',
  },
});
