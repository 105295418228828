import React, { ChangeEventHandler, useMemo, useCallback } from 'react';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { Column } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';
import { KLCheckBox } from 'components/KLCheckBox';
import { TableColumn } from './column';
import styles from './styles';

export type TableColumnsFormProps<T extends object> = {
  columns: Column<T>[];
  setColumns: (columns: Column<T>[]) => void;
  locator?: string;
};

const useStyles = makeStyles(styles);

export function TableColumnsForm<T extends object>(props: TableColumnsFormProps<T>) {
  const { columns, setColumns, locator } = props;
  const classes = useStyles();
  const { t } = useTranslation('columns');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, checked } = event.target;
    const columnIndex = columns.findIndex(({ field }) => field === name);
    setColumns(update(columns, { [columnIndex]: { hidden: { $set: !checked } } }));
  };

  const handleAllChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;
    setColumns(columns.map(column => ({ ...column, hidden: !checked })));
  };

  const isIndeterminate = useMemo(() => columns.some(({ hidden }) => !!hidden), [columns]);
  const isChecked = useMemo(() => columns.every(({ hidden }) => !hidden), [columns]);
  const isUnchecked = useMemo(() => columns.every(({ hidden }) => !!hidden), [columns]);

  const moveColumn = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragColumn = columns[dragIndex];
      setColumns(
        update(columns, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragColumn],
          ],
        }),
      );
    },
    [columns, setColumns],
  );

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <FormControlLabel
          control={(
            <KLCheckBox
              color="primary"
              name="all"
              indeterminate={isIndeterminate && !isUnchecked}
              checked={isChecked}
              onChange={handleAllChange}
            />
          )}
          label={t('All')}
        />
      </div>
      {columns.map((column, idx) => (
        column.field && (
          <TableColumn
            locator={locator}
            key={column.field as string}
            id={column.field}
            index={idx}
            name={column.field as string}
            title={column.title as string}
            checked={!column.hidden}
            onChange={handleChange}
            moveColumn={moveColumn}
          />
        )
      ))}
    </div>
  );
}
