import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MessageLayer } from 'components/MessageLayer';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';

export const EmailActivationError = () => {
  const { t } = useTranslation('ActivationPage');

  return (
    <MessageLayer
      title={t('Please, check your email address')}
      icon={<MailOutlineOutlinedIcon style={{ color: 'red' }} />}
    >
      <Typography variant="h3" component="h3">
        {t('Confirmed email address is required to proceed')}
      </Typography>
    </MessageLayer>
  );
};
