import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemsContext } from '../DonutChart';
import colorMatching from '../../utils/colorMatching';
import nameMatching from '../../utils/nameMatching';

const colors = ['#EC64A1', '#F3A536', '#51AEE2'];

export const VerticalLegend = () => {
  const { t } = useTranslation(['StatisticsPage']);

  return (
    <ItemsContext.Consumer>
      {({ onMouseEnter, onMouseLeave, data, options, classes }) => (
        <>
          {
            data.map((item: any, i: number) => (
              <div
                className={classes.listItem}
                key={item.name}
                data-id={i}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >

                <div
                  data-id={i}
                  className={classes.marker}
                  style={{ backgroundColor: colorMatching[item.name] || colors[i] }}
                />
                <div data-id={i} className={classes.names}>{t(nameMatching[item.name] || item.name)}</div>
                <div data-id={i} className={classes.values}>{item.value}</div>
              </div>
            ))
          }

          {options.limit ? (
            <div className={classes.listItem}>
              <span>{`${t('License limit exceeded by')} ${options.limit}`}</span>
            </div>
          ) : null}
        </>

      )}
    </ItemsContext.Consumer>
  );
};
