import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  position: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
    borderRadius: '50%',
    border: '0.5px solid #009C7F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 16,
    height: 16,
    paddingTop: 1,
    marginRight: theme.spacing(2),
    marginTop: 2,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  link: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
