import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { activateUserRequest } from 'services/user/slice';
import { useSelector } from 'store/hook';
import { MAIN_PAGE, FORBIDDEN_ERROR_PAGE } from 'global/routes';
import { Loading } from 'components/Loading';
import { EmailActivationError } from 'components/EmailActivationError';

export const UserActivationPage: React.FC<any> = (props: any) => {
  const { location } = props;
  const urlParams = location.search;
  const inviteIdQueryParam = urlParams.match(/invite-id=[\w-]+/, 'i');
  const inviteId = inviteIdQueryParam && inviteIdQueryParam[0].split('=')[1];
  const { inProgress, activated, error } = useSelector(state => state.users.pendingActivation);
  const { isAuthenticated, emailConfirmed } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inviteId) {
      dispatch(activateUserRequest({ inviteId, isAuthenticated }));
    }
  }, [inviteId, dispatch, emailConfirmed]);

  if (activated || !inviteId) {
    return <Redirect to={MAIN_PAGE} />;
  }

  if (inProgress) {
    return <Loading />;
  }

  if (error === 'unverifiedUserEmail') {
    return <EmailActivationError />;
  }

  return (
    <Redirect
      to={{
        pathname: FORBIDDEN_ERROR_PAGE,
        state: { error },
      }}
    />
  );
};
