import { Theme, createStyles } from '@material-ui/core/styles';
import { drawerWidth, collapsedDrawerSpacing } from 'pages/MainPage/components/Sidebar/styles';

export default (theme: Theme) => createStyles({
  container: {
    padding: theme.spacing(2, 4),
  },
  drawer: {
    width: 0,
    height: 0,
  },
  paper: {
    marginLeft: (isSideBarOpened) => (isSideBarOpened ? drawerWidth : theme.spacing(collapsedDrawerSpacing)),
  },
  paperSmall: {
    marginLeft: theme.spacing(collapsedDrawerSpacing),
  },
});
