import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KLCheckBox } from 'components/KLCheckBox';
import styles from './styles';

const useStyles = makeStyles(styles);

export const CheckBoxField = (props: any) => {
  const classes = useStyles();
  const { children, onChange, checked, disabled } = props;
  return (
    <Box className={classes.container}>
      <Box>
        <KLCheckBox
          color="primary"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  );
};
