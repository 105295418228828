import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { addNotification } from 'services/system/slice';
import {
  listRequest,
  listStart,
  listSuccess,
  listCount,
  createRequest,
  createStart,
  createSuccess,
  updateRequest,
  updateStart,
  updateSuccess,
  deleteRequest,
  deleteStart,
  deleteSuccess,
  markAsReadRequest,
  error,
} from './slice';
import * as api from './api';
import {
  CommentCreateRequestPayload,
  CommentUpdateRequestPayload,
  CommentDeleteRequestPayload,
  CommentMarkAsReadPayload,
} from './types';

function* fetchCommentList(action: PayloadAction<string>) {
  yield put(listStart());

  try {
    const items = yield call(api.getCommentList, action.payload);
    yield put(listSuccess(items));
    yield put(listCount(items.length));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* createComment(action: PayloadAction<CommentCreateRequestPayload>) {
  yield put(createStart());

  try {
    const item = yield call(api.createComment, action.payload.incidentId, action.payload.text);
    yield put(createSuccess(item));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* updateComment(action: PayloadAction<CommentUpdateRequestPayload>) {
  yield put(updateStart());

  try {
    const item = yield call(
      api.updateComment,
      action.payload.commentId,
      action.payload.text,
    );
    yield put(updateSuccess({ ...item, idField: 'commentId' }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* deleteComment(action: PayloadAction<CommentDeleteRequestPayload>) {
  try {
    yield put(deleteStart());
    yield call(api.deleteComment, action.payload.commentId);
    yield put(deleteSuccess({ idField: 'commentId', id: action.payload.commentId }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* markAsReadComment(action: PayloadAction<CommentMarkAsReadPayload>): any {
  try {
    yield call(api.markAsReadComment, action.payload);
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const commentSaga = [
  takeLatest(listRequest.type, fetchCommentList),
  takeLatest(createRequest.type, createComment),
  takeLatest(updateRequest.type, updateComment),
  takeLatest(deleteRequest.type, deleteComment),
  takeLatest(markAsReadRequest.type, markAsReadComment),
];
