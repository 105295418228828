import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& > .MuiCollapse-hidden': {
      marginBottom: 0,
    },
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1300,
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
    marginLeft: theme.spacing(1),
  },
});
