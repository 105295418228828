import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { SUPERVISOR } from 'services/auth/constants';
import { ForbiddenError } from 'components/ForbiddenError';
import { EmailActivationError } from 'components/EmailActivationError';
import { clear } from 'services/activation/slice';
import { LicenseAgreementStep } from './components/LicenseAgreementStep';
import { EnterCodeStep } from './components/EnterCodeStep';
import { ActivationError } from './components/ActivationError';
import { ActivationSuccess } from './components/ActivationSuccess';

export const ActivationPage = () => {
  const {
    error,
    licenseAgreementStep,
    licenseSuccessStep,
    isLicenseExists,
  } = useSelector(state => state.activation);
  const { emailConfirmed, roleName } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(clear());
  }, []);

  if (!emailConfirmed) {
    return <EmailActivationError />;
  }

  if (roleName !== '' && roleName !== SUPERVISOR) {
    return <ForbiddenError />;
  }

  if (error) {
    return <ActivationError />;
  }

  if (isLicenseExists && licenseSuccessStep) {
    return <ActivationSuccess />;
  }

  return (licenseAgreementStep) ? (
    <LicenseAgreementStep />
  ) : (
    <EnterCodeStep />
  );
};
