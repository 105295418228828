import React, { ChangeEvent, useState, useEffect } from 'react';
import { Box, Table, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { KLButton } from 'components/KLButton';
import { KLTextField } from 'components/KLTextField';
import { setRequest } from 'services/organizations/slice';
import { addNotification } from 'services/system/slice';
import { OrganisationTableRow } from '../OrganisationTableRow';
import { OrganizationEntryProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const OrganizationEntry = (props: OrganizationEntryProps) => {
  const { t } = useTranslation('organizations');
  const dispatch = useDispatch();
  const classes = useStyles();
  const { organization: { clientId, socClientId, ...organizationData }, isLoading, error, getFields } = props;
  const [value, setValue] = useState(socClientId);

  const isValid = !!(value === socClientId || value.match(/^[a-z0-9]{1,20}_?[a-z0-9]{0,20}$/));

  const submitHandler = () => {
    dispatch(setRequest({ clientId, socClientId: value }));
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (!error) return;
    dispatch(addNotification({
      message: t('Error updating organisation name'),
      options: { variant: 'error' },
    }));
  }, [error, dispatch, t]);

  const socClientIdField = (
    <>
      <KLTextField
        className={classes.input}
        variant="outlined"
        label={t('Human-friendly client name')}
        onChange={changeHandler}
        value={value}
        error={!isValid}
        helperText={isValid ? null : t('Invalid name')}
        disabled={!!socClientId}
      />
      {(value !== socClientId && !socClientId && isValid) ? (
        <KLButton
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={submitHandler}
          isLoading={isLoading}
        >
          {t('Save')}
        </KLButton>
      ) : null}
    </>
  );

  return (
    <Box className={classes.root}>
      <Typography className={classes.label} variant="h3" component="h3">{clientId}</Typography>
      <Table className={classes.table}>
        <TableBody>
          <OrganisationTableRow label={t('SOC client ID')} value={socClientIdField} />
          {getFields(organizationData)}
        </TableBody>
      </Table>
      <hr className={classes.line} />
    </Box>
  );
};
