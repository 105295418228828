import { postRequest } from '../../utils/http';
import { IncidentHistorySagaItem } from './types';

const url = 'incidents';

export function getIncidentHistory(id: string) {
  return postRequest<IncidentHistorySagaItem[]>(`${url}/history`, {
    incidentId: id,
  });
}
