import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  title: {
    marginBottom: theme.spacing(2),
  },
  text: {
    border: '1px solid #B3B3B3',
    boxShadow: 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05)',
    borderRadius: 3,
    padding: theme.spacing(2),
    overflow: 'auto',
    height: '100%',
  },
  markdown: {
    '& img': {
      maxWidth: '100vh',
      margin: theme.spacing(0.5, 0, 1),
    },
    '& p': {
      margin: theme.spacing(0.5, 0, 1),
    },
    '& h1': {
      margin: theme.spacing(3, 0, 2),
    },
    '& h2': {
      margin: theme.spacing(2, 0, 1),
    },
    '& h3': {
      margin: theme.spacing(2, 0, 1),
    },
    '& table': {
      margin: theme.spacing(0.5, 0, 1),
      borderTop: '1px solid #000',
      borderLeft: '1px solid #000',
    },
    '& td': {
      borderRight: '1px solid #000',
      borderBottom: '1px solid #000',
      padding: theme.spacing(1),
    },
    '& th': {
      borderRight: '1px solid #000',
      borderBottom: '1px solid #000',
      padding: theme.spacing(1),
    },
  },
  btn: {
    fontWeight: 300,
    padding: 0,
    color: '#00A88E',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
