import React, { SVGProps } from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as Unchecked } from './unchecked.svg';
import { ReactComponent as Indeterminate } from './indeterminate.svg';

const useStyles = makeStyles(() => createStyles({
  disabled: {
    '& .MuiIconButton-label': {
      position: 'relative',
    },
    '& .MuiIconButton-label::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: '#F6F6F6',
      borderRadius: 3,
      width: 14,
      height: 14,
      top: 1,
      left: 1,
    },
    '&.Mui-checked .MuiIconButton-label::before': {
      top: 0,
      left: 0,
      width: 16,
      height: 16,
      opacity: 0.7,
      borderRadius: 0,
    },
  },
}));

const CheckedIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <Checked {...props} />
);
const CheckedIconRef: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> = React.forwardRef(
  (props, ref) => (
    <CheckedIcon {...props} ref={ref} />
  ),
);

const UncheckedIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <Unchecked {...props} />
);
const UncheckedIconRef: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> = React.forwardRef(
  (props, ref) => (
    <UncheckedIcon {...props} ref={ref} />
  ),
);

const IndeterminateIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <Indeterminate {...props} />
);
const IndeterminateIconRef: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> = React.forwardRef(
  (props, ref) => (
    <IndeterminateIcon {...props} ref={ref} />
  ),
);

export const KLCheckBox: React.FC<CheckboxProps> = props => {
  const classes = useStyles();

  return (
    <Checkbox
      {...props}
      classes={{ disabled: classes.disabled }}
      icon={<UncheckedIconRef />}
      checkedIcon={<CheckedIconRef />}
      indeterminateIcon={<IndeterminateIconRef />}
    />
  );
};
