import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { Locale } from 'date-fns';
import { ru as ruLocale, enUS as enLocale } from 'date-fns/locale';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { checkLogin } from 'services/auth/slice';
import {
  MAIN_PAGE, AUTO_POST_LOGIN_PAGE, AUTH_ERROR_PAGE, AUTH_LOGIN_PAGE, ORIGIN_MISMATCH_PAGE,
} from 'global/routes';
import { PrivateRoute } from 'components/PrivateRoute';
import { ConfirmationProvider } from 'components/Confirmation';
import { Permissions } from 'components/Permissions';
import { MainPage } from 'pages/MainPage';
import { PostLoginPage } from 'pages/PostLoginPage';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon';
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { AuthErrorPage } from 'pages/AuthErrorPage';
import { LoginPage } from 'pages/LoginPage';
import { DefaultRedirectPage } from 'pages/DefaultRedirectPage';
import { getLang } from 'utils/i18n';
import { OriginMismatchPage } from 'pages/OriginMismatchPage';
import styles from './App.styles';

const useStyles = makeStyles(styles);

const App: React.FC = () => {
  const { isAuthenticated } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const localeMap: { [key: string]: Locale } = {
    en: enLocale,
    ru: ruLocale,
    /* eslint-disable @typescript-eslint/camelcase */
    'ru-RU': ruLocale,
    'en-US': enLocale,
    /* eslint-enable */
  };

  if (!isAuthenticated) {
    dispatch(checkLogin());
  }

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils} locale={localeMap[getLang()]}>
      <ConfirmationProvider>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          classes={{
            variantInfo: classes.info,
            variantWarning: classes.warning,
            variantError: classes.error,
            variantSuccess: classes.success,
          }}
          iconVariant={{
            info: <InfoIcon className={classes.icon} fill={theme.palette.info.main} />,
            warning: <WarningIcon className={classes.icon} />,
            error: <ErrorIcon className={classes.icon} />,
            success: <SuccessIcon className={classes.icon} />,
          }}
        >
          <Permissions />
          <Switch>
            <Route exact path="/" component={DefaultRedirectPage} />
            <Route path={AUTH_LOGIN_PAGE} exact component={LoginPage} />
            <Route path={AUTH_ERROR_PAGE} exact component={AuthErrorPage} />
            <Route path={ORIGIN_MISMATCH_PAGE} exact component={OriginMismatchPage} />
            <Route path={AUTO_POST_LOGIN_PAGE} exact component={PostLoginPage} />
            <PrivateRoute path={`${MAIN_PAGE}:id`} component={MainPage} />
          </Switch>
        </SnackbarProvider>
      </ConfirmationProvider>
    </LocalizationProvider>
  );
};

export default App;
