import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { Loading } from 'components/Loading';
import styles from './styles';

type AgreementProps = {
  title: string;
  text: string;
};

const useStyles = makeStyles(styles);

export const Agreement = (props: AgreementProps) => {
  const { title, text } = props;
  const classes = useStyles();

  return (
    <>
      {title && (
        <Box>
          <Typography className={classes.title} variant="h1" component="h1">
            {title}
          </Typography>
        </Box>
      )}
      <Box className={classes.text}>
        { text ? (
          <ReactMarkdown
            className={classes.markdown}
            source={text}
            skipHtml={false}
            escapeHtml={false}
          />
        ) : <Loading />}
      </Box>
    </>
  );
};
