import { postRequest } from 'utils/http';
import { TelemetryStatsRequest } from './types';

const url = 'statistics';

export function getStats() {
  return postRequest(`${url}/stats`);
}

export function getTelemetryStats({ dayRange, tenantIds }: TelemetryStatsRequest) {
  return postRequest(`${url}/stats/telemetry`, { dayRange, tenantIds });
}

export function getCheck() {
  return postRequest(`${url}/check`);
}
