import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { HELP_URL } from 'global/environments';
import { getLocale } from 'utils/i18n';
import styles from './styles';

const useStyles = makeStyles(styles);

// TODO: Lame realisation, needs to be redone to accept dinamic changes
type GuideEntryProps = {
  text: string;
  linkName: string;
  link: string;
  position?: number;
  ns?: string;
  content?: React.ReactNode;
  linkName2?: string;
  link2?: string;
};

export const GuideEntry: React.FC<GuideEntryProps> = (props) => {
  const classes = useStyles();
  const { text, linkName, link, position, content, ns = '', linkName2, link2 } = props;
  const { t } = useTranslation(ns);
  const tLink = t(link, { locale: getLocale(), helpUrl: HELP_URL });

  const values: Record<string, string> = { placeholder: t(linkName) };

  if (linkName2) {
    values.placeholder2 = t(linkName2);
  }

  const components: any = [
    (<a href={tLink} target="_blank" rel="noopener noreferrer" className={classes.link}>placeholder</a>),
  ];

  if (link2) {
    const tLink2 = t(link2, { locale: getLocale(), helpUrl: HELP_URL });
    components.push((
      <a href={tLink2} target="_blank" rel="noopener noreferrer" className={classes.link}>placeholder2</a>
    ));
  }

  return (
    <Box className={classes.container}>
      {position && <Typography component="span" className={classes.position}>{position}</Typography>}
      <Box>
        <Typography component="span" className={classes.text}>
          <Trans
            ns={ns}
            defaults={text}
            values={values}
            components={components}
          />
        </Typography>
        <Box>{content}</Box>
      </Box>
    </Box>
  );
};
