import { Theme, createStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../Sidebar/styles';

type CompanyNameStyleProps = {
  isSideBarOpened: boolean;
  company: boolean;
}

export default (theme: Theme) => createStyles({
  root: {
    display: ({ isSideBarOpened }: CompanyNameStyleProps) => (isSideBarOpened ? 'flex' : 'none'),
    color: '#fff',
    whiteSpace: 'normal',
  },
  title: {
    fontSize: 11,
    paddingTop: ({ company }: CompanyNameStyleProps) => (company ? theme.spacing(2) : theme.spacing(4)),
    overflow: 'hidden',
    maxWidth: 185,
  },
  companyTitle: {
    fontSize: 14,
    paddingTop: theme.spacing(1),
  },
  logo: {
    padding: theme.spacing(2),
    height: 94,
    maxWidth: drawerWidth,
    width: 'auto',
    alignSelf: 'flex-start',
  },
});
