import React, { SVGProps } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StandardProps } from '@material-ui/core';
import { ReactComponent as Icon } from './icon.svg';
import { ReactComponent as IconWithLine } from './iconWithLine.svg';

export type ArrowIconProps = {
  direction?: 'top' | 'right' | 'bottom' | 'left';
  fill?: string;
  withLine?: boolean;
};

const rotateMapping: {
  [key: string]: number;
} = {
  top: -90,
  right: 0,
  bottom: 90,
  left: 180,
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fill: ({ fill }: ArrowIconProps) => fill ? fill : theme.palette.primary.main,
    transform: ({ direction }: ArrowIconProps) => `rotate(${rotateMapping[direction as string]}deg)`,
  },
}));

export const ArrowIcon: React.FC<StandardProps<ArrowIconProps, 'root'> & SVGProps<SVGSVGElement>> = props => {
  const { direction, fill, withLine = false, classes, ...restProps } = props;
  const styles = useStyles(props);

  return withLine ? (
    <IconWithLine className={styles.root} {...restProps} />
  ) : (
    <Icon className={styles.root} {...restProps} />
  );
};

ArrowIcon.defaultProps = {
  direction: 'right',
  fill: '#000000',
  withLine: false,
};
