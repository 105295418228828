import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Image } from './repair.svg';
import styles from './styles';

const useStyles = makeStyles(styles);

export const UnderConstruction = () => {
  const classes = useStyles();
  const { t } = useTranslation(['StatisticsPage']);

  return (
    <Grid item xs={12}>
      <Box className={classes.root}>
        <Image className={classes.image} />
        <Typography className={classes.heading} component={'h1'} variant={'h2'}>
          {t('Section is under construction')}
        </Typography>
        <Typography>{t('Soon, summary information and graphs will be back')}</Typography>
      </Box>
    </Grid>
  );
};
