import React from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'store/hook';
import { AUTH_LOGIN_PAGE, ACTIVATION_PAGE, USER_ACTIVATION_PAGE, FORBIDDEN_ERROR_PAGE } from 'global/routes';
import { PRELOGIN_LOCATION } from 'global/storage';
import { save } from 'utils/storage';
import { usePermissionStates, DENIED, ALLOWED } from 'components/Permissions/hooks';
import { Loading } from 'components/Loading';
import { setPendingActivation } from 'services/user/slice';
import { useDispatch } from 'react-redux';
import { PrivateRouteProps } from './types';

export const PrivateRoute: React.FC<RouteProps & PrivateRouteProps> = (props) => {
  const { component: Component, permission, ...rest } = props;

  const {
    auth: { isAuthenticated, clientId, isLoading },
    activation: { isLicenseExists },
    users: { pendingActivation },
  } = useSelector(state => state);

  const perms = typeof permission === 'string' ? [permission] : (permission || []);
  const permissionsStates = usePermissionStates(perms);

  const location = useLocation();
  const dispatch = useDispatch();
  let activationInProgress = pendingActivation.inProgress;
  if (!activationInProgress && !clientId && !pendingActivation.error && location.pathname === USER_ACTIVATION_PAGE) {
    dispatch(setPendingActivation({ inProgress: true, activated: false }));
    activationInProgress = true;
    save(PRELOGIN_LOCATION, location, true);
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated && !activationInProgress) {
    save(PRELOGIN_LOCATION, location, true);
    return <Redirect to={AUTH_LOGIN_PAGE} />;
  }

  if (!activationInProgress && !pendingActivation.error && !isLicenseExists
    && location.pathname !== ACTIVATION_PAGE && location.pathname !== USER_ACTIVATION_PAGE) {
    return <Redirect to={ACTIVATION_PAGE} />;
  }

  if (perms.length) {
    if (permissionsStates.every(perm => perm === DENIED)) {
      return <Redirect to={FORBIDDEN_ERROR_PAGE} />;
    }

    if (!permissionsStates.some(perm => perm === ALLOWED)) {
      return <Loading />;
    }
  }

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={
        props => (isAuthenticated || activationInProgress ? (
          <Component {...props} />
        ) : null)
      }
    />
  );
};
