import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';
import { Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import {
  getRequest,
  setRequest,
  getTelegramRequest,
  resetTelegramRequest,
  getChannelsRequest,
} from 'services/notifications/slice';
import { getGeneralConfigRequest } from 'services/settings/slice';
import { NotificationConfig } from 'services/notifications/types';
import { BottomDrawer } from 'components/BottomDrawer';
import { KLButton } from 'components/KLButton';
import { DateTime } from 'components/DateTime';
import { usePermissions } from 'components/Permissions/hooks';
import { TELEGRAM_BOT_NAME } from 'global/environments';
import styles from './styles';
import { NotificationSettingsRow } from './notificationSettingsRow';

type NotificationSettingsProps = {
  setWasChanges: (wasChanges: boolean) => void;
};

interface NotificationItem {
  title: string;
  name: string;
  childProps?: {[key: string]: any};
}

interface NotificationPermission {
  [key: string]: boolean;
}

export interface NotificationChannel {
  name: string;
  disabled: boolean;
}

const useStyles = makeStyles(styles);

export const NotificationSettings: React.FC<NotificationSettingsProps> = props => {
  const { setWasChanges } = props;
  const email = useSelector((state) => state.auth.displayName);
  const {
    config,
    configStateToken,
    telegramStateToken,
    telegramActivationToken,
    telegramActivationExpirationTime,
    telegramActivated,
    isActivating,
    isLoading,
    channels,
  } = useSelector((state) => state.notifications);
  const {
    enableExtendedNotifications,
  } = useSelector((state) => state.settings);
  const userId = useSelector((state) => state.auth.userId);
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { t } = useTranslation(['SettingsPage', 'common']);
  const [notifications, setNotifications] = useState<NotificationConfig>(config);
  const [
    incidentsNotificationPerm,
    commentsNotificationPerm,
    attachmentsNotificationPerm,
    responsesNotificationPerm,
    licenseNotificationPerm,
    editUserNotificationsSettingsPerm,
  ] = usePermissions([
    'view_incident',
    'view_incident_comment',
    'view_incident_attachment',
    'view_incident_response',
    'view_organization_license',
    'edit_per_organization_user_notifications_settings',
  ]);

  const notificationChannels: NotificationChannel[] = useMemo(() => [
    {
      name: 'email',
      disabled: !channels.email,
    },
    {
      name: 'telegram',
      disabled: !telegramActivated && channels.telegram,
    },
  ], [channels, telegramActivated]);

  const extendedNotificationsProps = {
    title: t('NotificationSettings.Extended notifications'),
    name: 'extendedNotifications',
    notificationChannels: [{ name: 'email', disabled: !enableExtendedNotifications }],
  };

  const notificationItems: NotificationItem[] = [
    { title: t('NotificationSettings.Incidents'), name: 'incidents', childProps: extendedNotificationsProps },
    { title: t('NotificationSettings.Comments'), name: 'comments' },
    { title: t('NotificationSettings.Responses'), name: 'responses' },
    { title: t('NotificationSettings.License'), name: 'license' },
  ];
  const notificationPermissions: NotificationPermission = {
    incidents: incidentsNotificationPerm,
    comments: commentsNotificationPerm || attachmentsNotificationPerm,
    responses: responsesNotificationPerm,
    license: licenseNotificationPerm,
    extendedNotifications: incidentsNotificationPerm,
  };

  useEffect(() => {
    dispatch(getRequest(userId));
    dispatch(getTelegramRequest(userId));
    dispatch(getChannelsRequest(userId));
    dispatch(getGeneralConfigRequest(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    setNotifications(config);
  }, [config]);

  const wasChanges = useMemo(() => {
    const filteredConfig = Object.keys(config)
      .filter((name) => notificationPermissions[name])
      .reduce<NotificationConfig>((acc, name) => ({
        ...acc, [name]: config[name],
      }), {});
    const filteredNotifications = Object.keys(notifications)
      .filter((name) => notificationPermissions[name])
      .reduce<NotificationConfig>((acc, name) => ({
        ...acc, [name]: notifications[name],
      }), {});
    return !isEqual(filteredConfig, filteredNotifications);
  }, [config, notifications, notificationPermissions]);

  useEffect(() => {
    setWasChanges(wasChanges);
  }, [wasChanges, setWasChanges]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name: inputName, checked } = event.target;
    const [name, channel] = inputName.split('_');
    const newNotifications = { ...notifications };
    // Unchecking an incident automatically removes extended
    if (name === 'incidents' && !checked && enableExtendedNotifications) {
      newNotifications.extendedNotifications = { ...newNotifications.extendedNotifications, [channel]: false };
    } else if (name === 'extendedNotifications' && checked && enableExtendedNotifications) {
      // Checking extended automatically checks incident
      newNotifications.incidents = { ...newNotifications.incidents, [channel]: true };
    }
    setNotifications({ ...newNotifications, [name]: { ...newNotifications[name], [channel]: checked } });
  };

  const handleSaveChanges = () => {
    dispatch(setRequest({
      config: notifications,
      message: t('NotificationSettings.Notification settings were saved successfully'),
      configStateToken,
      userId,
    }));
  };

  const handleCancelChanges = () => {
    setNotifications(config);
  };

  const handleAllChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name: inputName, checked } = event.target;
    const channel = inputName.split('_')[1];
    const newNotifications = Object.keys(notifications)
      .filter((name) => notificationPermissions[name])
      .reduce<NotificationConfig>((config, name) => ({
        ...config,
        [name]: checked && name === 'extendedNotifications'
          ? notifications[name]
          : { ...notifications[name], [channel]: checked },
      }), {});
    setNotifications(newNotifications);
  };

  const isIndeterminate = useCallback((channel: string) => (
    Object.keys(notifications)
      .filter((name) => notificationPermissions[name] && name !== 'extendedNotifications')
      .some((name) => !notifications[name][channel])
  ), [notifications, notificationPermissions]);

  const isChecked = useCallback((channel: string) => (
    Object.keys(notifications)
      .filter((name) => notificationPermissions[name] && name !== 'extendedNotifications')
      .every((name) => notifications[name][channel])
  ), [notifications, notificationPermissions]);

  const isUnchecked = useCallback((channel: string) => (
    Object.keys(notifications)
      .filter((name) => notificationPermissions[name] && name !== 'extendedNotifications')
      .every((name) => !notifications[name][channel])
  ), [notifications, notificationPermissions]);

  const isTelegramTokenExpired = useMemo(
    () => (telegramActivationExpirationTime || 0) < Date.now(),
    [telegramActivationExpirationTime],
  );

  return (
    <div className={classes.root}>
      { channels.email ? (
        <Box className={classes.group}>
          <Typography component="div" className={classes.label}>{t('NotificationSettings.Email')}</Typography>
          <Typography component="div" className={classes.email}>{email}</Typography>
        </Box>
      ) : null}

      { channels.telegram ? (
        <Box className={classes.group}>
          <Typography component="div" className={classes.label}>{t('NotificationSettings.Telegram bot')}</Typography>
          {!telegramActivated ? (
            <>
              {!isTelegramTokenExpired && (
                <div className={classes.telegram}>
                  <Link
                    href={`https://t.me/${TELEGRAM_BOT_NAME}?start=${telegramActivationToken}`}
                    target="_blank"
                    className={classes.telegram}
                  >
                    {`t.me/${TELEGRAM_BOT_NAME}?start=${telegramActivationToken}`}
                  </Link>
                  <span className={classes.telegram}>
                    {`(${t('NotificationSettings.Link is active until')} `}
                    <DateTime timestamp={telegramActivationExpirationTime as number} withTime />
                    {')'}
                  </span>
                </div>
              )}
              {isTelegramTokenExpired && (
                <KLButton
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    dispatch(resetTelegramRequest({
                      renew: true,
                      configStateToken: telegramStateToken,
                      userId,
                    }));
                  }}
                  isLoading={isActivating}
                  disabled={!editUserNotificationsSettingsPerm}
                >
                  {t('NotificationSettings.Subscribe')}
                </KLButton>
              )}
            </>
          ) : (
            <>
              <Link
                className={classes.telegram}
                href={`https://t.me/${TELEGRAM_BOT_NAME}`}
                target="_blank"
              >
                {`t.me/${TELEGRAM_BOT_NAME}`}
              </Link>
              <KLButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  dispatch(resetTelegramRequest({
                    renew: false,
                    configStateToken: telegramStateToken,
                    userId,
                  }));
                }}
                isLoading={isActivating}
                disabled={!editUserNotificationsSettingsPerm}
              >
                {t('NotificationSettings.Unsubscribe')}
              </KLButton>
            </>
          )}
        </Box>
      ) : null}

      {
        Object.values(channels).some((channel) => channel) ? (
          <table className={classes.settings}>
            <thead>
              <tr>
                <th />
                {
                  channels.email ? (
                    <th className={classes.columnHeader}>{t('NotificationSettings.Email')}</th>
                  ) : null
                }

                {
                  channels.telegram ? (
                    <th className={classes.columnHeader}>{t('NotificationSettings.Telegram')}</th>
                  ) : null
                }
              </tr>
            </thead>
            <tbody>
              {notificationItems.some(({ name }) => notificationPermissions[name]) && (
                <NotificationSettingsRow
                  title={t('NotificationSettings.All')}
                  name={'all'}
                  idx={-1}
                  channels={channels}
                  notificationChannels={notificationChannels}
                  notifications={notifications}
                  handleChange={handleAllChange}
                  isLoading={isLoading}
                  indeterminate={(channel) => isIndeterminate(channel) && !isUnchecked(channel)}
                  editUserNotificationsSettingsPerm={editUserNotificationsSettingsPerm}
                  checked={(notifications, name, channel) => isChecked(channel)}
                />
              )}
              {notificationItems
                .filter(({ name }) => notificationPermissions[name])
                .map(({ title, name, childProps }, idx) => (
                  <NotificationSettingsRow
                    title={title}
                    name={name}
                    idx={idx}
                    key={name}
                    channels={channels}
                    notificationChannels={notificationChannels}
                    notifications={notifications}
                    handleChange={handleChange}
                    isLoading={isLoading}
                    editUserNotificationsSettingsPerm={editUserNotificationsSettingsPerm}
                    checked={(notifications, name, channel) => (
                      notifications[name] ? notifications[name][channel] : false
                    )}
                    childProps={childProps}
                  />
                ))}
            </tbody>
          </table>

        ) : (
          <Box className={classes.group}>
            <Typography component="div" className={classes.label}>{t('You can’t get any notification')}</Typography>
          </Box>
        )
      }

      <BottomDrawer open={wasChanges}>
        <KLButton
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          className={classes.saveButton}
          isLoading={isLoading}
          disabled={!editUserNotificationsSettingsPerm}
        >
          {t('NotificationSettings.Save')}
        </KLButton>
        <KLButton
          variant="text"
          color="primary"
          onClick={handleCancelChanges}
          className={classes.cancelButton}
          disabled={isLoading}
        >
          {t('NotificationSettings.Cancel')}
        </KLButton>
      </BottomDrawer>
    </div>
  );
};
