import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CMSetPayload, CMState } from './types';

const name = 'markdownCache';
const cacheLifetime = 600000; // 10 mins

const initialState: CMState = {};

const markdownCacheSlice = createSlice({
  name,
  initialState,
  reducers: {
    set(state, { payload: { key, value } }: PayloadAction<CMSetPayload>) {
      const currentDate = Date.now();
      state[key] = {
        value,
        expires: currentDate + cacheLifetime,
      };

      // clear old cache
      Object.entries(state).forEach(([index, data]) => {
        if (currentDate > data.expires) {
          delete state[index];
        }
      });
    },
    remove(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
  },
});

export const {
  set,
  remove,
} = markdownCacheSlice.actions;

export default markdownCacheSlice.reducer;
