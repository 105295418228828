import { Theme, createStyles } from '@material-ui/core/styles';
import { KLInputProps } from './index';

export default (theme: Theme) => createStyles({
  root: (props: KLInputProps) => ({
    'label + &': {
      marginTop: theme.spacing(props.noMarginTop ? 0 : 2.5),
    },
  }),
  input: {
    borderRadius: 3,
    position: 'relative',
    backgroundColor: (props: any) => (props.disabled ? '#E4E4E4' : theme.palette.common.white),
    border: '1px solid #B3B3B3',
    boxShadow: 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05);',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    padding: '5px 8px',
    transition: theme.transitions.create(['border-color']),
    height: 'auto',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    '.Mui-error &': {
      borderColor: theme.palette.error.main,
    },
    '&.Mui-disabled': {
      backgroundColor: 'hsl(0,0%,95%);',
    },
  },
});
