import React, { useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { ConfirmationContext } from './ConfirmationContext';
import { Confirmation } from './Confirmation';
import { ConfirmationProviderProps, ConfirmationOptions } from './types';

export const ConfirmationProvider: React.FC<ConfirmationProviderProps> = props => {
  const { dialogProps, children } = props;
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const defaultOptions: ConfirmationOptions = {
    content: '',
    isLoading: false,
    isDisabled: false,
    confirmText: t('Yes'),
    dismissText: t('No'),
    dialogProps: {},
  };
  const [options, setOptions] = useState<ConfirmationOptions>(defaultOptions);

  const openConfirmation = useCallback((initialOptions: ConfirmationOptions) => {
    const newOptions = { ...defaultOptions, ...initialOptions };
    if (!isEqual(options, newOptions)) {
      setOptions(newOptions);
    }
    setOpen(true);
  }, [defaultOptions, options]);

  const closeConfirmation = useCallback(() => {
    setOpen(false);
  }, []);

  const setConfirmationOptions = useCallback((options: ConfirmationOptions) => {
    setOptions((currentOptions) => ({ ...currentOptions, ...options }));
  }, []);

  const handleClose = () => {
    if (options.onDismiss) {
      options.onDismiss();
    }
    closeConfirmation();
  };

  return (
    <ConfirmationContext.Provider value={{ openConfirmation, closeConfirmation, setConfirmationOptions }}>
      {children}
      <Confirmation
        {...options}
        open={open}
        dialogProps={dialogProps}
        onDismiss={handleClose}
      />
    </ConfirmationContext.Provider>
  );
};
