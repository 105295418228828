import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    width: '80%',
    flexShrink: 0,
    marginBottom: theme.spacing(3),
    '&:first-child': {
      marginTop: theme.spacing(3),
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  rootLeft: {
    alignSelf: 'flex-start',
  },
  rootRight: {
    alignSelf: 'flex-end',
  },
  comment: {
    padding: theme.spacing(1.5),
    backgroundColor: '#F6F6F6',
    borderRadius: 2,
    position: 'relative',
    transition: 'background-color 1s ease',
  },
  commentUnread: {
    backgroundColor: '#FAF6D9',
  },
  commentWithAttachment: {
    marginBottom: theme.spacing(0.5),
  },
  commentInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  commentInfoWithAttachment: {
    marginBottom: 0,
  },
  commentText: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    marginBottom: theme.spacing(1),
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  commentAuthor: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: '#000000',
    opacity: 0.5,
  },
  commentAuthorIcon: {
    marginRight: theme.spacing(1),
    flexShrink: 0,
    fill: '#000000',
  },
  commentAuthorName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  commentTime: {
    color: '#979797',
    flexShrink: 0,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  commentActions: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: '100%',
    top: 0,
  },
  commentActionButton: {
    padding: theme.spacing(1),
  },
  commentActionIcon: {
    fill: '#979797',
  },
  commentEditable: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: '#979797',
    textAlign: 'right',
  },
  commentAttachment: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },
  commentAttachmentIcon: {
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  commentAttachmentLink: {
    fontWeight: 300,
    overflow: 'hidden',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
  },
});
