import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  form: {
    padding: theme.spacing(0, 4),
    height: '100%',
  },
  container: {
    height: '100%',
  },
  label: {
    marginBottom: theme.spacing(1),
    color: '#58595B',
    fontSize: theme.typography.pxToRem(14),
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
  description: {
    borderRadius: 3,
    border: '1px solid #B3B3B3',
    boxShadow: 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05);',
    padding: '5px 8px',
    resize: 'vertical',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    '&:focus': {
      border: '1px solid #00A88E',
      outline: 'none',
    },
  },
  errorMessage: {
    display: 'none',
    '&.Mui-error': {
      display: 'block',
    },
  },
  buttons: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button + button': {
      marginLeft: theme.spacing(1),
    },
  },
});
