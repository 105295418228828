import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  btn: {
    minWidth: 108,
    marginLeft: theme.spacing(1),
  },
  firstBtn: {
    minWidth: 108,
    marginLeft: theme.spacing(3),
  },
});
