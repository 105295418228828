import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { PermissionsListSagaPayload, PermissionDict, PermissionState } from './types';

export const name = 'permissions';

export const listRequest = createAction<PermissionsListSagaPayload>(`${name}/listRequest`);

const initialState: PermissionState = {
  dict: {},
  isLoading: false,
  originMismatch: false,
  expectedOrigin: null,
  error: null,
};

const permissionsSlice = createSlice({
  name,
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = null;
    },
    success(state, action: PayloadAction<PermissionDict>) {
      state.isLoading = false;
      state.dict = action.payload;
    },
    originMismatch(state, { payload: expectedOrigin }: PayloadAction<string>) {
      state.isLoading = false;
      state.originMismatch = true;
      state.expectedOrigin = expectedOrigin;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  start,
  success,
  error,
  originMismatch,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
