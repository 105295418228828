import React from 'react';
import { useHistory } from 'react-router-dom';
import { KLButton } from 'components/KLButton';

// todo: replace any type
export const LinkButton = (props: any) => {
  const { to, href, children, ...restProps } = props;
  const history = useHistory();

  const clickHandle = () => {
    if (to) {
      history.replace(to);
    } else {
      window.location.replace(href);
    }
  };

  return (
    <KLButton
      onClick={clickHandle}
      {...restProps}
    >
      {children}
    </KLButton>
  );
};
