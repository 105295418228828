export type TProtobufDate = {
  seconds: string;
  nanos: number;
}

export const fixTimezoneOffset = (date: number, add = true) => {
  const offset = (new Date()).getTimezoneOffset() * 60 * 1000 * (add ? 1 : -1);
  const result = date + offset;
  return result;
};

export const protoToTimestamp = (protobufDate: TProtobufDate): number => {
  if (
    !protobufDate
    || typeof protobufDate !== 'object'
    || !Object.prototype.hasOwnProperty.call(protobufDate, 'seconds')
    || !Object.prototype.hasOwnProperty.call(protobufDate, 'nanos')
  ) {
    return 0;
  }
  return (Number(protobufDate.seconds) * 1000) + Math.round(protobufDate.nanos / 1000000);
};

export const daysToMillis = (days: number): number => days * 24 * 60 * 60 * 1000;
