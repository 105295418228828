import React from 'react';

type LimitedTextProps = {
  text: string;
  maxSize: number;
};

export const LimitedText: React.FC<LimitedTextProps> = props => {
  const { text, maxSize } = props;

  return (
    <>{text.length > maxSize ? `${text.substr(0, maxSize)}...` : text}</>
  );
};
