import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    '& .feature-list': {
      '& h2': {
        fontSize: 24,
      },
      '& ul': {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(1.5),
        listStyle: 'none',
        '& li': {
          margin: `${theme.spacing(1)}px 0`,
          '&::before': {
            content: '"\u2022"',
            color: '#D8D8D8',
            display: 'inline-flex',
            width: '1em',
            marginLeft: '-1em',
          },
        },
      },
    },
    '& .screenshot': {
      '& > figure': {
        width: '100%',
        padding: `0 ${theme.spacing(2)}px`,
        '& > img': {
          margin: '0 auto',
          display: 'block',
          maxWidth: '100%',
          height: 'auto',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .screenshot': {
        marginTop: theme.spacing(3),
      },
    },
  },

});
