import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { Title } from 'components/Title';
import styles from './styles';

const useStyles = makeStyles(styles);

interface FeatureSectionProps {
  outerClassname?: string;
  innerClassname?: string;
  contentKeys: {
    title: string;
    list: string[];
    image: {
      src: any;
      alt: string;
    };
  };
}

export const FeatureSection: React.FC<FeatureSectionProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('LoginPage');
  const { outerClassname, innerClassname, contentKeys } = props;

  return (
    <Grid container justify="center" className={outerClassname}>
      <Grid container className={classNames(classes.root, innerClassname)}>
        <Grid item sm={12} md={6} className="feature-list">
          <Title component="h2">{t(contentKeys.title)}</Title>
          <ul>
            { contentKeys.list.map((item: string) => <li key={item}>{t(item)}</li>)}
          </ul>
        </Grid>
        <Grid item sm={12} md={6} className="screenshot">
          <figure>
            <img src={contentKeys.image.src} alt={contentKeys.image.alt} />
          </figure>
        </Grid>
      </Grid>
    </Grid>
  );
};
