import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0, 6, 3),
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 'calc(100vh - 129px)', // 80 + 48 + 1
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxHeight: 'none',
    },
  },
  comments: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '55%',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
      marginBottom: theme.spacing(3),
      width: '100%',
    },
  },
  commentsList: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(2),
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      overflowY: 'auto',
    },
  },
  commentsForm: {
    flex: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  commentsFormSubmitButton: {
    marginRight: theme.spacing(1.5),
  },
  commentsFormWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  commentsFormEditor: {
    marginBottom: theme.spacing(1),
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  commentsFormAttachmentList: {
    order: -2,
    flexBasis: '100%',
    width: '100%',
  },
  commentsFormControls: {
    order: -1,
    marginRight: 'auto',
  },
  commentsFormToolbar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  commentsFormToolbarInline: {
    display: 'flex',
  },
  commentsFormToolbarOption: {
    marginLeft: 4,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    filter: 'invert(0.5)',
    fontSize: 0,
    cursor: 'pointer',
    '&:hover': {
      filter: 'none',
    },
    '&[aria-selected="true"]': {
      filter: 'none',
      backgroundColor: '#C4C4C4',
      borderRadius: 2,
    },
  },
  attachments: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  attachmentsTitle: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: 'inherit',
    },
  },
});
