import { postRequest } from 'utils/http';
import { name } from './slice';
import { SetOrganizationNamePayload } from './types';

export function list() {
  return postRequest(`${name}/list`);
}

export function set({ clientId, socClientId }: SetOrganizationNamePayload) {
  return postRequest(`${name}/set`, {
    clientId,
    socClientId,
  });
}
