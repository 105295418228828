import React, { MouseEventHandler, RefObject } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useSelector } from 'store/hook';
import { DateTime } from 'components/DateTime';
import { AuthorIcon } from 'assets/icons/AuthorIcon';
import { EditIcon } from 'assets/icons/EditIcon';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { FileIcon } from 'assets/icons/FileIcon';
import { IncidentAttachment } from 'services/attachments/types';
import { isComment } from 'pages/IncidentDetailsPage/components/Communication';
import { MessageItemType } from 'pages/IncidentDetailsPage/components/Communication/types';
import { usePermissions } from 'components/Permissions/hooks';

import { CustomMarkdown } from '../../../CustomMarkdown';

import { CommunicationImage } from '../../../CommunicationImage';
import styles from './styles';

type MessageItemProps = {
  item: MessageItemType;
  itemRef: RefObject<HTMLDivElement> | null;
  userId: string;
  editableTime: number;
  itemUrl: string;
  onEdit: MouseEventHandler;
  onDelete: MouseEventHandler;
};

const useStyles = makeStyles(styles);

export const MessageItem: React.FC<MessageItemProps> = props => {
  const { clientId, secret } = useSelector(store => store.auth);
  const { item, itemRef, userId, editableTime, itemUrl, onEdit, onDelete } = props;
  const classes = useStyles();
  const { t } = useTranslation('Communication');
  const [editCommentPerm, editAttachPerm] = usePermissions([
    'edit_incident_comment',
    'edit_incident_attachment',
  ]);
  const { isActiveOrganization } = useSelector(state => state.auth);

  const isEditable = (item.authorId === userId)
    && (editableTime > 0)
    && ((isComment(item) ? editCommentPerm : editAttachPerm) && isActiveOrganization);

  const Comment = (
    <Box
      className={
        classNames(
          classes.comment,
          !isComment(item) && classes.commentWithAttachment,
          !item.wasRead && classes.commentUnread,
        )
      }
    >
      <Box className={classes.commentInfo}>
        <Box className={classes.commentAuthor} component="span">
          <AuthorIcon className={classes.commentAuthorIcon} />
          <span className={classes.commentAuthorName}>
            {item.authorName === 'Security Team' || item.authorName === 'portal' ? t(item.authorName) : item.authorName}
          </span>
        </Box>
        <Box className={classes.commentTime} component="span">
          <DateTime timestamp={item.creationTime} type="relative" />
        </Box>
      </Box>
      <Box className={classes.commentText}>
        <CustomMarkdown description={isComment(item) ? item.text : item.caption} classes={classes} />
      </Box>
      {isEditable && (
        <Box className={classes.commentEditable}>
          {t('Editable for')}
          {` ${editableTime} `}
          {t('min')}
        </Box>
      )}
      {isEditable && (
        <Box className={classes.commentActions}>
          <Tooltip title={t('Edit') as string}>
            <IconButton
              className={classes.commentActionButton}
              onClick={onEdit}
              disableRipple
            >
              <EditIcon className={classes.commentActionIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('Delete') as string}>
            <IconButton
              className={classes.commentActionButton}
              onClick={onDelete}
              disableRipple
            >
              <DeleteIcon className={classes.commentActionIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );

  const Attachment = (
    <Box className={classNames(classes.comment, !item.wasRead && classes.commentUnread)}>
      <CommunicationImage
        item={item as IncidentAttachment}
        clientId={clientId}
        secret={secret}
      />
      <Box className={classNames(classes.commentInfo, classes.commentInfoWithAttachment)}>
        <Box className={classes.commentAttachment} component="span">
          <FileIcon className={classes.commentAttachmentIcon} />
          <Link
            className={classes.commentAttachmentLink}
            component={RouterLink}
            to={itemUrl}
            replace
          >
            {decodeURI((item as IncidentAttachment).fileName)}
          </Link>
        </Box>
        <Box className={classes.commentTime} component="span">
          <DateTime timestamp={item.creationTime} type="relative" />
        </Box>
      </Box>
      {isEditable && !(item as IncidentAttachment).caption && (
        <Box className={classes.commentEditable}>
          {t('Editable for')}
          {` ${editableTime} `}
          {t('min')}
        </Box>
      )}
      {isEditable && !(item as IncidentAttachment).caption && (
        <Box className={classes.commentActions}>
          <Tooltip title={t('Edit') as string}>
            <IconButton
              className={classes.commentActionButton}
              onClick={onEdit}
              disableRipple
            >
              <EditIcon className={classes.commentActionIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('Delete') as string}>
            <IconButton
              className={classes.commentActionButton}
              onClick={onDelete}
              disableRipple
            >
              <DeleteIcon className={classes.commentActionIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );

  return (
    <div
      ref={itemRef}
      className={
        classNames(
          classes.root,
          item.origin === 'Service' ? classes.rootLeft : classes.rootRight,
        )
      }
    >
      {isComment(item) && Comment}
      {!isComment(item) && item.caption && Comment}
      {!isComment(item) && Attachment}
    </div>
  );
};
