import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  messageBox: {
    position: 'relative',
    maxWidth: 600,
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
    top: -120,
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: 96,
  },
  marginTop: {
    marginTop: 20,
  },
});
