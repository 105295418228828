import React from 'react';
import { LineChartProps } from './types';

const LineChartMark = ({ x, y, name, color, onMouseEnter, onMouseLeave }: LineChartProps) => (
  <g>
    <circle
      cx={x}
      cy={y}
      r="3"
      name={name}
      fill={color}
      stroke={color}
      cursor="pointer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />

    <circle
      cx={x}
      cy={y}
      r="1"
      fill="white"
      stroke="white"
      pointerEvents="none"
    />
  </g>
);

export default LineChartMark;
