import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  image: {
    marginBottom: theme.spacing(5),
  },
  heading: {
    marginBottom: theme.spacing(3),
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  text: {
    marginBottom: theme.spacing(5),
    padding: '0 30%',
    textAlign: 'center',
  },
  confirmBtn: {
    marginBottom: theme.spacing(2),
  },
  downloadBtn: {
    margin: theme.spacing(1, 0, 2),
    alignSelf: 'flex-start',
  },
  labelRoot: {
    marginBottom: theme.spacing(3),
    alignItems: 'flex-start',
  },
  labelLabel: {
    marginTop: 0,
  },
  link: {
    fontSize: 14,
    color: '#00A88E',
    '&:hover': {
      textDecoration: 'none',
    },
    display: 'inline-block',
    margin: theme.spacing(2, 0, 2, 2),
  },
  btn: {
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
  controls: {},
});
