import React from 'react';
import { Typography } from '@material-ui/core';

type LabelProps = {
  className?: string;
  text?: string;
};

export const Label: React.FC<LabelProps> = props => {
  const { className, text } = props;

  return (
    <Typography component="label" className={className}>{text}</Typography>
  );
};
