import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSocket } from 'components/SocketIO';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getT } from 'utils/i18n';
import { Table, TableColumn } from 'components/Table';
import { KLChip, KLChipProps } from 'components/KLChip';
import { KLButton } from 'components/KLButton';
import { DateTime } from 'components/DateTime';
import { FilterField, FilterSelectedField } from 'components/Filter';
import { SideDialog } from 'components/SideDialog';
import {
  columnsGetRequest,
  columnsSetRequest,
  createRequest,
  createSocket,
  deleteSocket,
  listRequest,
  setColumns,
  setFilters,
  setPage,
  setPageSize,
  setSearch,
  updateSocket,
  detailsSuccess,
} from 'services/incidents/slice';
import {
  IncidentColumns,
  IncidentFilters,
  IncidentNewItem,
  IncidentNotification,
  IncidentDetails,
  UserOrigin,
} from 'services/incidents/types';
import { AssetState, setSuggestion, suggestionRequest } from 'services/assets/slice';
import {
  setSuggestion as setTenantSuggestion,
  suggestionRequest as suggestionTenantRequest,
  TenantState,
} from 'services/tenants/slice';
import { AssetListFilters, AssetSuggestion } from 'services/assets/types';
import { createRequest as attachmentCreateRequest } from 'services/attachments/slice';
import { useSelector } from 'store/hook';
import { INCIDENT_LIST_PAGE } from 'global/routes';
import { INCIDENTS_LIST_FILTER_HELP } from 'utils/help';
import { INCIDENT_CREATE_SOCKET, INCIDENT_DELETE_SOCKET, INCIDENT_UPDATE_SOCKET } from 'global/sockets';
import { AddIcon } from 'assets/icons/AddIcon';
import * as filtersConfig from 'configs/filters.json';
import { usePermissions } from 'components/Permissions/hooks';
import { KLMultiSelectType } from 'components/KLMultiSelect';
import { TenantSuggestion } from '../../services/tenants/types';
import { NewIncidentForm } from './components/NewIncidentForm';
import styles from './styles';

export const filterFields: FilterField<IncidentFilters>[] = [
  {
    type: 'date',
    title: 'Created',
    filter: 'creationTime',
    field: 'creationTime',
  },
  {
    type: 'date',
    title: 'Updated',
    filter: 'updateTime',
    field: 'updateTime',
  },
  {
    type: 'checked',
    title: 'Priority',
    filter: 'priorities',
    field: 'priority',
    items: filtersConfig.incidents.priority,
    index: true,
  },
  {
    type: 'checked',
    title: 'Status',
    filter: 'statuses',
    field: 'status',
    items: filtersConfig.incidents.status,
  },
  {
    type: 'checked',
    title: 'Resolution',
    filter: 'resolutions',
    field: 'resolution',
    items: filtersConfig.incidents.resolution,
    index: true,
  },
  {
    type: 'selected',
    selectType: KLMultiSelectType.async,
    title: 'Assets',
    filter: 'affectedHosts',
    field: 'affectedHosts',
    request: suggestionRequest,
    setRequest: setSuggestion,
    valueToOption: value => ({ label: value.substring(0, value.indexOf(':')), value }),
    placeholder: 'filters:Start entering name',
    transNamespace: 'filters',
  },
  {
    type: 'selected',
    selectType: KLMultiSelectType.async,
    title: 'Tenant',
    filter: 'tenantsNames',
    field: 'tenantName',
    request: suggestionTenantRequest,
    setRequest: setTenantSuggestion,
    placeholder: 'filters:Start entering name',
    noValue: 'Without tenants',
    transNamespace: 'filters',
  },
  {
    type: 'selected',
    title: 'Tactics',
    filter: 'mitreTactics',
    field: 'mitreTactics',
    options: Object.values(filtersConfig.incidents.mitreTactics),
    menuPlacement: 'top',
    transNamespace: 'mitreTactics',
  },
  {
    type: 'selected',
    title: 'Response statuses',
    filter: 'responseStatuses',
    options: Object.keys(filtersConfig.incidents.responseStatuses).map(key => ({
      label: (filtersConfig.incidents.responseStatuses as { [key: string]: string })[key],
      value: key,
    })),
    valueToOption: value => ({
      label: getT(
        `IncidentResponses:${(filtersConfig.incidents.responseStatuses as { [key: string]: string })[value]}`,
      ),
      value,
    }),
    transNamespace: 'IncidentResponses',
  },
];

const useStyles = makeStyles(styles);

export const IncidentListPage: React.FC = () => {
  const {
    list: items,
    details,
    filters,
    isLoading,
    isCreating,
    count,
    columns: columnsSettings,
    error,
    lastVisited,
    page,
    pageSize,
    search,
  } = useSelector(state => state.incidents);
  const {
    isCreating: isAttachmentCreating,
    isCreated: isAttachmentCreated,
  } = useSelector(state => state.attachments);
  const {
    suggestion: assetSuggestion,
    suggestionLoading: assetSuggestionLoading,
  } = useSelector((state: { assets: AssetState }) => state.assets);
  const {
    suggestion: tenantSuggestion,
    suggestionLoading: tenantSuggestionLoading,
    hasAccessToRoot,
  } = useSelector((state: { tenants: TenantState }) => state.tenants);
  const { isActiveOrganization } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation(['IncidentListPage', 'filters', 'NewIncident']);
  const theme = useTheme();
  const classes = useStyles();
  const [openNewIncident, setOpenNewIncident] = useState(false);
  const [
    filterPermission,
    searchPermission,
    createIncidentPermission,
  ] = usePermissions([
    'filter_incidents_list',
    'search_incidents_list',
    'create_incident',
  ]);
  const [files, setFiles] = useState<File[]>([]);

  const filtersSet = Boolean((filters
    && Object.values(filters).filter(filtersKey => filtersKey && (filtersKey as string[]).length).length)
    || (search && search.length)
    || (page || 1) - 1);
  const listRequestPayload = { paging: { page: (page || 1) - 1, pageSize }, filter: filters, searchPhrase: search };

  useSocket(INCIDENT_CREATE_SOCKET, (data: IncidentNotification) => {
    // Socket incident details origin is different from rdp incident details origin
    // Update time is required field due to default sorting
    const { origin, updateTime, ...restIncident } = data.incident;
    const newOrigin = typeof origin === 'string' ? UserOrigin[origin] : origin;
    const newUpdateTime = updateTime || restIncident.creationTime;

    if (filtersSet) {
      dispatch(listRequest(listRequestPayload));
    } else {
      dispatch(createSocket({ ...restIncident, origin: newOrigin, updateTime: newUpdateTime }));
    }
  });

  useSocket(INCIDENT_UPDATE_SOCKET, (data: IncidentNotification) => {
    if (filtersSet) {
      dispatch(listRequest(listRequestPayload));
    } else {
      dispatch(updateSocket({ ...data.incident, idField: 'incidentId' }));
    }
  });

  useSocket(INCIDENT_DELETE_SOCKET, (data: IncidentNotification) => {
    dispatch(deleteSocket({ idField: 'incidentId', id: data.incident.incidentId }));
  });

  const defaultNewIncidentState: IncidentNewItem = {
    summary: '',
    clientDescription: '',
    affectedHosts: [],
    priority: 'LOW',
  };

  const makeAssetSuggestion = ({ hostName, assetId }: AssetSuggestion) => ({
    label: hostName,
    value: `${hostName}:${assetId}`,
  });
  const makeTenantSuggestion = ({ tenantName }: TenantSuggestion) => tenantName;

  const handleSubmitNewIncident = (state: IncidentNewItem) => {
    const { tenantId, ...restState } = state;

    dispatch(createRequest({
      ...restState,
      tenantId: tenantId?.substring(tenantId?.indexOf(':') + 1),
      message: t('Incident was successfully created'),
      filtersSet,
    }));
    if (filtersSet) {
      dispatch(listRequest(listRequestPayload));
    }
  };

  useEffect(() => {
    if (!isCreating && !error && details && openNewIncident) {
      if (files.length) {
        dispatch(attachmentCreateRequest({
          incidentId: details.incidentId,
          caption: '',
          file: files[0],
        }));
        setFiles([]);
      }
      setOpenNewIncident(false);
    }
  }, [isCreating, error, details, files, openNewIncident, dispatch]);

  useEffect(() => {
    setOpenNewIncident(false);
  }, [isAttachmentCreated]);

  useEffect(() => {
    dispatch(columnsGetRequest());
  }, [dispatch]);

  useEffect(() => {
    if (typeof columnsSettings === 'undefined') return;
    dispatch(columnsSetRequest(columnsSettings));
  }, [columnsSettings, dispatch]);

  const customFilterFields = useMemo(() => {
    const assetField = filterFields.find(
      field => field.filter === 'affectedHosts',
    ) as FilterSelectedField<IncidentFilters>;

    if (assetField) {
      assetField.options = assetSuggestion?.map(makeAssetSuggestion);
      assetField.isRequesting = assetSuggestionLoading;
    }

    const tenantField = filterFields.find(
      field => field.filter === 'tenantsNames',
    ) as FilterSelectedField<AssetListFilters>;

    if (tenantField) {
      tenantField.options = tenantSuggestion?.map(makeTenantSuggestion);
      tenantField.isRequesting = tenantSuggestionLoading;
    }

    return filterFields;
  }, [assetSuggestion, assetSuggestionLoading, tenantSuggestion, tenantSuggestionLoading]);

  const priorityMapping: {
    [key: string]: KLChipProps;
  } = useMemo(() => ({
    HIGH: { bgColor: '#FF4455', fontColor: theme.palette.common.white, bold: true },
    NORMAL: { borderColor: '#646464', outlined: true, bold: true },
    LOW: { borderColor: '#BEBEBE', outlined: true, bold: true },
  }), [theme]);

  const columns: TableColumn<IncidentDetails>[] = [
    {
      searchable: false,
      sorting: false,
      width: `${theme.spacing(4)}px`,
    },
    {
      title: t('ID'),
      field: 'creationTime',
      sorting: false,
      cellStyle: {
        textTransform: 'uppercase',
      },
      hidden: false,
      locator: 'id/created',
      render: ({ caseId, creationTime }) => (
        <>
          <div>{caseId}</div>
          <div><DateTime timestamp={creationTime} formatString="dd MMM yyyy" /></div>
        </>
      ),
    },
    {
      title: t('Priority'),
      field: 'priority',
      searchable: false,
      sorting: false,
      hidden: true,
      locator: 'priority',
      render: rowData => <KLChip label={t(rowData.priority)} {...priorityMapping[rowData.priority]} />,
    },
    {
      title: t('Status'),
      field: 'status',
      searchable: false,
      sorting: false,
      cellStyle: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
      },
      hidden: false,
      locator: 'status',
      render: ({ status }) => (
        <>
          <KLChip label={t(status)} borderColor="#FFAA59" outlined uppercase />
        </>
      ),
    },
    {
      title: t('Resolution'),
      searchable: false,
      field: 'resolution',
      sorting: false,
      hidden: true,
      locator: 'resolution',
      render: ({ resolution }) => (resolution ? t(resolution) : ''),
    },
    {
      title: t('Summary'),
      field: 'summary',
      sorting: false,
      hidden: false,
      searchable: true,
      locator: 'summary',
      cellStyle: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
      },
    },
    {
      title: t('Assets'),
      field: 'affectedHosts',
      sorting: false,
      hidden: true,
      searchable: true,
      locator: 'assets',
      render: rowData => (
        `${
          rowData.affectedHosts
            ?.slice(0, 2)
            .map(asset => asset.split(':')[0])
            .join(', ')
          || t('No')
        }${rowData.affectedHosts?.length > 2 ? `, ${rowData.affectedHosts.length - 2} ${t('more')}...` : ''}`
      ),
      cellStyle: (data, rowData) => ({
        color: rowData.affectedHosts?.length ? 'inherit' : '#979797',
      }),
    },
    {
      title: t('Tactics'),
      field: 'mitreTactics',
      sorting: false,
      hidden: true,
      locator: 'tactics',
      render: rowData => (
        `${
          rowData.mitreTactics
            ?.slice(0, 2)
            .join(', ')
          || t('No')
        }${rowData.mitreTactics?.length > 2 ? `, ${rowData.mitreTactics.length - 2} ${t('more')}...` : ''}`
      ),
      cellStyle: (data, rowData) => ({
        color: rowData.mitreTactics?.length ? 'inherit' : '#979797',
      }),
    },
    {
      title: t('Tenant'),
      field: 'tenantName',
      sorting: false,
      locator: 'tenant',
    },
    {
      title: t('Updated'),
      type: 'datetime',
      defaultSort: 'desc',
      searchable: false,
      field: 'updateTime',
      hidden: false,
      locator: 'updated',
      render: rowData => <DateTime timestamp={rowData.updateTime} withTime />,
      customSort: (a, b) => a.updateTime - b.updateTime,
    },
  ];

  const extraActions: React.ReactNode[] = [];

  if (createIncidentPermission && isActiveOrganization) {
    extraActions.push(
      <KLButton
        color="primary"
        variant="contained"
        startIcon={<AddIcon fill="#FFFFFF" />}
        onClick={() => {
          dispatch(detailsSuccess(null));
          setOpenNewIncident(true);
        }}
        id="incidents_button_add"
      >
        {t('Add')}
      </KLButton>,
    );
  }

  return (
    <Grid item xs={12}>
      <Table<IncidentDetails, IncidentFilters, IncidentColumns>
        action={listRequest}
        setPageToStore={setPage}
        setPageSizeToStore={setPageSize}
        filterHelp={INCIDENTS_LIST_FILTER_HELP}
        count={count}
        route={INCIDENT_LIST_PAGE}
        uniqueId="incidentId"
        routeId="incidentId"
        columns={columns}
        extraActions={extraActions}
        data={items}
        filterFields={customFilterFields}
        transNamespace="IncidentListPage"
        setFilters={setFilters}
        setColumns={setColumns}
        setSearch={setSearch}
        columnsSettings={columnsSettings}
        title={t('Incidents')}
        isLoading={isLoading}
        lastVisited={lastVisited}
        options={{
          search: searchPermission,
          filtering: filterPermission,
          columnsButton: true,
        }}
        locator="incidents"
        searchFields={{
          caseId: 'IncidentListPage:ID',
          summary: 'Summary:Summary',
          description: 'Summary:Description',
          statusDescription: 'Summary:Status description',
          affectedHosts: 'Summary:Affected assets',
          hostBasedIocs: 'Summary:Asset-based IOCs',
          networkBasedIocs: 'Summary:Network-based IOCs',
        }}
      />
      <SideDialog<IncidentNewItem>
        classes={{ container: classes.newIncidentFormContainer }}
        state={defaultNewIncidentState}
        open={openNewIncident}
        title={t('NewIncident:New incident')}
        onClose={() => {
          setFiles([]);
          setOpenNewIncident(false);
        }}
        onCancel={(state, setState) => {
          setState(defaultNewIncidentState);
          setFiles([]);
          setOpenNewIncident(false);
        }}
        cancelText={t('NewIncident:Cancel')}
        onSubmit={handleSubmitNewIncident}
        submitText={t('NewIncident:Send')}
        loading={isCreating || isAttachmentCreating}
        disabled={(state) => (!(state.summary
          && state.clientDescription
          && state.affectedHosts.length)
          || (!state.tenantId?.length && !hasAccessToRoot)
        )}
        preventClose
        render={(state, setState) => (
          <NewIncidentForm
            state={state}
            setState={setState}
            disabled={isCreating || isAttachmentCreating}
            files={files}
            setFiles={setFiles}
          />
        )}
      />
    </Grid>
  );
};
