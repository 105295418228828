import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  content: {
    maxWidth: 700,
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  topTip: {
    marginBottom: theme.spacing(3),
  },
  bottomTip: {
    marginTop: theme.spacing(3),
    fontSize: '0.8rem',
  },
  checkbox: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  kata: {
    marginBottom: theme.spacing(3),
  },
  btn: {
    minWidth: 190,
  },
  input: {
    minWidth: 376,
  },
});
