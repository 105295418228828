import React from 'react';
import classNames from 'classnames';
import { AppBar, Toolbar, IconButton, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuIcon } from 'assets/icons/MenuIcon';
import { GuideIcon } from 'assets/icons/GuideIcon';
import { HelpButton } from 'components/HelpButton';
import styles from './styles';

type HeaderProps = {
  handleDrawerToggle: (ev: React.MouseEvent) => void;
  appBarOpen: boolean;
};

const useStyles = makeStyles(styles);

export const Header: React.FC<HeaderProps> = ({ handleDrawerToggle, appBarOpen }) => {
  const classes = useStyles(appBarOpen);

  return (
    <AppBar
      position="relative"
      elevation={0}
      className={classNames(classes.appBar, appBarOpen && classes.appBarShift)}
    >
      <Toolbar className={classNames(classes.toolbar, appBarOpen && classes.toolbarShift)}>
        <IconButton
          color="inherit"
          aria-label="open sidebar"
          onClick={handleDrawerToggle}
          className={classNames(classes.menuButton, appBarOpen && classes.menuButtonShift)}
          disableRipple
        >
          <MenuIcon />
        </IconButton>
        <Box flexGrow={1} />
        <HelpButton icon={<GuideIcon />} className={classes.guideButton} />
      </Toolbar>
    </AppBar>
  );
};
