import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0, 4),
  },
  formControl: {
    marginBottom: theme.spacing(2.5),
    '& label': {
      marginBottom: theme.spacing(1),
      lineHeight: theme.typography.pxToRem(20),
      color: '#000000',
    },
    '& label.Mui-focused': {
      color: '#000000',
    },
  },
});
