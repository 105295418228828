import React from 'react';

import { ForbiddenError } from 'components/ForbiddenError';
import { EmailActivationError } from 'components/EmailActivationError';
import { InviteExpiredError } from 'components/InviteExpiredError';
import { InviteAlreadyActivatedError } from 'components/InviteAlreadyActivatedError';
import { InviteEmailMismatchError } from 'components/InviteEmailMismatchError';

export const ForbiddenErrorPage: React.FC = (props: any) => {
  const { location } = props;
  const error = location?.state?.error;

  switch (error) {
    case 'inviteEmailMismatch':
      return <InviteEmailMismatchError />;
    case 'inviteAlreadyActivated':
      return <InviteAlreadyActivatedError />;
    case 'inviteExpired':
      return <InviteExpiredError />;
    case 'unverifiedUserEmail':
      return <EmailActivationError />;
    default:
      return <ForbiddenError />;
  }
};
