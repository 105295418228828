export const extractTimeFromCron = (cronString: string) => {
  let timeArray = cronString.split(' ');
  timeArray.length = 2;
  timeArray = timeArray.reverse();
  return timeArray.join(':');
};

export const extractWeekdayFromCron = (cronString: string) => cronString.split(' ').pop() as string;

export const WEEKDAYS = {
  '*': 'everyday',
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};
