import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { DateTime } from 'components/DateTime';
import { LinkButton } from 'components/LinkButton';
import { Loading } from 'components/Loading';
import { SuccessCircleIcon } from 'assets/icons/SuccessCircleIcon';
import { DataLine } from 'pages/LicenseStatePage/components/DataLine';
import { GUIDE_PAGE } from 'global/routes';
import styles from './styles';

const useStyles = makeStyles(styles);

export const ActivationSuccess = () => {
  const { t } = useTranslation('LicenseStatePage');
  const classes = useStyles();

  const {
    licenseType,
    activationDate,
    expirationDate,
    assetsCount,
    daysLeftToUse,
    solution,
  } = useSelector(state => state.activation);

  return (
    <Box className={classes.container}>
      <SuccessCircleIcon className={classes.icon} />

      <Typography className={classes.title} variant="h1" component="h1">
        {t('Application activated successfully')}
      </Typography>

      <Box className={classes.data}>
        {!licenseType ? (
          <Loading />
        ) : (
          <>
            <DataLine title={t('License type')} value={licenseType ? t(`licenseTypes:${licenseType}`) : null} />
            <DataLine title={t('Solution')} value={solution ? t(solution) : null} />
            <DataLine
              title={t('Activation date')}
              value={activationDate ? <DateTime timestamp={activationDate} /> : null}
            />
            <DataLine
              title={t('Expiration date')}
              value={expirationDate ? <DateTime timestamp={expirationDate} /> : null}
            />
            <DataLine title={t('Days left to use')} value={daysLeftToUse} />
            <DataLine title={t('Number of assets')} value={assetsCount} />
          </>
        )}
      </Box>

      <LinkButton
        className={classes.btn}
        size="large"
        variant="outlined"
        color="primary"
        to={GUIDE_PAGE}
      >
        {t('Ok')}
      </LinkButton>
    </Box>
  );
};
