import { createStyles, Theme } from '@material-ui/core/styles';
import { backgroundColors } from './themes/default';

export default (theme: Theme) => createStyles({
  info: {
    backgroundColor: backgroundColors?.info?.main,
    color: theme.palette.common.black,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.info.main}`,
  },
  warning: {
    backgroundColor: backgroundColors?.warning?.main,
    color: theme.palette.common.black,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.warning.main}`,
  },
  error: {
    backgroundColor: backgroundColors?.error?.main,
    color: theme.palette.common.black,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.error.main}`,
  },
  success: {
    backgroundColor: backgroundColors?.primary?.main,
    color: theme.palette.common.black,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});
