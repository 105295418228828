import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { useSnackbar, SnackbarKey } from 'notistack';
import { IconButton } from '@material-ui/core';
import { removeNotification, closeNotification } from 'services/system/slice';
import { CloseIcon } from 'assets/icons/CloseIcon';

let displayed: SnackbarKey[] = [];

export const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.system.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        action: (key) => (
          <>
            {options.action && (typeof options.action === 'function' ? options.action(key) : options.action)}
            <IconButton color="secondary" disableRipple size="small" onClick={() => dispatch(closeNotification(key))}>
              <CloseIcon size={12} />
            </IconButton>
          </>
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeNotification(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });

  }, [notifications, enqueueSnackbar, closeSnackbar, dispatch]);

  return null;
};
