import { createAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from 'store/slice';
import {
  IncidentAttachment,
  IncidentAttachmentCreateRequestPayload,
  IncidentAttachmentUpdateRequestPayload,
  IncidentAttachmentDeleteRequestPayload,
  IncidentAttachmentFileRequestPayload,
  IncidentAttachmentMarkAsReadPayload,
} from './types';

const name = 'attachments';

export const listRequest = createAction<string>(`${name}/list`);
export const createRequest = createAction<IncidentAttachmentCreateRequestPayload>(`${name}/create`);
export const updateRequest = createAction<IncidentAttachmentUpdateRequestPayload>(`${name}/update`);
export const deleteRequest = createAction<IncidentAttachmentDeleteRequestPayload>(`${name}/delete`);
export const fileRequest = createAction<IncidentAttachmentFileRequestPayload>(`${name}/file`);
export const markAsReadRequest = createAction<IncidentAttachmentMarkAsReadPayload>(`${name}/mark-as-read`);

const initialState: EntityState<IncidentAttachment, never> = {
  list: [],
  isLoading: false,
  count: 0,
  error: null,
};

const attachmentSlice = createEntitySlice({
  name,
  initialState,
  reducers: {},
});

export const {
  listStart,
  listSuccess,
  createStart,
  createSuccess,
  createSocket,
  updateStart,
  updateSuccess,
  updateSocket,
  deleteStart,
  deleteSuccess,
  deleteSocket,
  markAsRead,
  error,
} = attachmentSlice.actions;

export default attachmentSlice.reducer;
