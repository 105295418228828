import { postRequest, putRequest, deleteRequest } from 'utils/http';
import { CommentItem, CommentMarkAsReadPayload } from './types';

const url = 'comments';

export function getCommentList(incidentId: string) {
  return postRequest<CommentItem[]>(`${url}/list`, {
    incidentId,
  });
}

export function createComment(incidentId: string, text: string) {
  return postRequest<CommentItem>(`${url}/create`, {
    incidentId,
    text,
  });
}

export function updateComment(commentId: string, text: string) {
  return putRequest<CommentItem>(`${url}/update`, {
    text,
    commentId,
  });
}

export function deleteComment(commentId: string) {
  return deleteRequest<CommentItem>(`${url}/delete`, {
    data: {
      commentId,
    },
  });
}

export function markAsReadComment(payload: CommentMarkAsReadPayload) {
  return postRequest<never>(`${url}/mark-as-read`, {
    ...payload,
  });
}
