import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Box, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toggleSideBar } from 'services/system/slice';
import { useSelector } from 'store/hook';
import { usePermissions } from 'components/Permissions/hooks';
import { MainMenu } from '../MainMenu';
import { Header } from '../Header';
import { UserMenu } from '../UserMenu';
import { LicenseState } from '../LicenseState';
import { CompanyName } from '../CompanyName';
import styles from './styles';

const useStyles = makeStyles(styles);

type SidebarProps = {
  disabled: boolean;
};

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { disabled } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSideBarOpened } = useSelector(state => state.system);

  const [viewLicensePerm] = usePermissions(['view_organization_license']);

  const handleDrawerToggle = () => {
    dispatch(toggleSideBar());
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(classes.drawerPaper, !isSideBarOpened && classes.drawerPaperClose),
      }}
      open={isSideBarOpened}
    >
      <Header handleDrawerToggle={handleDrawerToggle} appBarOpen={isSideBarOpened} />
      <CompanyName isSideBarOpened={isSideBarOpened} />
      {!disabled && (
        <MainMenu
          listProps={{
            className: classes.mainMenu,
            disablePadding: true,
          }}
          itemProps={{
            className: classes.mainMenuItem,
          }}
          itemIconProps={{
            className: classes.mainMenuIcon,
          }}
          itemTextProps={{
            className: classes.mainMenuText,
            disableTypography: true,
          }}
          dense={!isSideBarOpened}
        />
      )}
      <Box className={classes.spacer} />
      {viewLicensePerm ? (<LicenseState appBarOpen={isSideBarOpened} disabled={disabled} />) : null}
      <UserMenu appBarOpen={isSideBarOpened} disabled={disabled} />
    </Drawer>
  );
};
