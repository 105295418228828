export const INCIDENT_COMMENT_CREATE_SOCKET = 'incidentComment:new';
export const INCIDENT_COMMENT_UPDATE_SOCKET = 'incidentComment:update';
export const INCIDENT_COMMENT_DELETE_SOCKET = 'incidentComment:delete';

export const INCIDENT_RESPONSE_CREATE_SOCKET = 'incidentResponse:new';
export const INCIDENT_RESPONSE_UPDATE_SOCKET = 'incidentResponse:update';

export const INCIDENT_CREATE_SOCKET = 'incident:new';
export const INCIDENT_UPDATE_SOCKET = 'incident:update';
export const INCIDENT_DELETE_SOCKET = 'incident:delete';

export const INCIDENT_ATTACHMENT_CREATE_SOCKET = 'incidentAttachment:new';
export const INCIDENT_ATTACHMENT_UPDATE_SOCKET = 'incidentAttachment:update';
export const INCIDENT_ATTACHMENT_DELETE_SOCKET = 'incidentAttachment:delete';

export const LICENSE_UPDATE_SOCKET = 'license:update';
export const LICENSE_ABOUT_EXPIRE_SOCKET = 'license:about_expire'; //  todo ??
export const LICENSE_EXPIRED_SOCKET = 'license:expired'; //  todo ??

export const USER_ROLE_UPDATE_SOCKET = 'userRole:update';
