export enum RobotSessionStatus {
    'Pending',
    'Active',
    'Idle',
    'Expired'
}

export interface RobotSessionItem {
    creationTime: number;
    expirationTime: number;
    sessionId: string;
    sessionName: string;
    stateToken: string;
    status: number;
    tenants: Tenant[];
    roleId: number;
}

export interface RobotSessionResponse {
    session: RobotSessionItem;
    refreshToken: string;
}

export interface RobotUpdateSessionResponse {
    session: RobotSessionItem;
}

interface Tenant {
    tenantId: string;
    tenantName: string;
}

export interface TenantRequest {
    tenantId: string;
}
