import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0, 4),
  },
  textField: {
    width: '100%',
  },
  btn: {
    minWidth: 92,
  },
  btnMarginLeft: {
    minWidth: 92,
    marginLeft: theme.spacing(1),
  },
  comment: {
    marginBottom: theme.spacing(0),
  },
  text: {
    overflow: 'auto',
    paddingBottom: 10,
  },
  empty: {
    color: 'rgb(153, 153, 153)',
    paddingTop: '10px',
  },
  details: {
    marginBottom: theme.spacing(4),
  },
  downloadLinkWrap: {
    textAlign: 'right',
    marginBottom: theme.spacing(1),
  },
  downloadLink: {
    fontWeight: 600,
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
  },
});
