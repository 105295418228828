import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Map } from 'immutable';
import Draft, {
  EditorState,
  convertFromRaw,
  convertToRaw,
  RichUtils,
  Modifier,
  SelectionState,
  DraftHandleValue,
  getDefaultKeyBinding,
} from 'draft-js';
import { Editor, EditorProps, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import filesize from 'filesize';
import { getLang } from 'utils/i18n';
import { Link } from './components/Link';
import { FileUpload } from './components/FileUpload';
import { AttachmentList } from './components/AttachmentList';
import { Controls } from './components/Controls';
import { Label } from './components/Label';
import { CodeBlock } from './components/CodeBlock';
import { Inline } from './components/Inline';
import { Counter } from './components/Counter';
import styles from './styles';
import attachIcon from './icons/attach.svg';
import boldIcon from './icons/bold.svg';
import italicIcon from './icons/italic.svg';
import codeIcon from './icons/code.svg';
import linkIcon from './icons/link.svg';
import 'draft-js/dist/Draft.css';
import './editor.css';

export type KLEditorProps = {
  uploaded?: boolean;
  multiple?: boolean;
  controls?: (text: string, files: File[], maxTextSize: number) => React.ReactNode;
  label?: string;
  toolbarPosition?: 'top' | 'bottom';
  maxTextSize?: number;
  initialText?: string | null;
  text?: string | null;
  setText?: (text: string | null) => void;
  files?: File[];
  setFiles?: (files: File[]) => void;
  maxFileSize?: number;
  minRows?: number;
  maxRows?: number;
  fontSize?: number;
  lineHeight?: number;
  submitCallback?: (text: string, files: File[]) => void;
  sendNotification?: (message: string, type: 'error' | 'warning' | 'success') => void;
  counter?: boolean;
};
type DraftEditorProps = Omit<EditorProps, 'editorRef' | 'editorState' | 'onEditorStateChange' | 'toolbarCustomButtons'>;

const useStyles = makeStyles(styles);

export const KLEditor = React.forwardRef<any, KLEditorProps & DraftEditorProps>((props, extRef) => {
  const {
    uploaded,
    multiple = false,
    controls,
    label,
    toolbarPosition = 'bottom',
    initialText = '',
    text = '',
    setText,
    maxTextSize = 5000,
    counter = false,
    files = [],
    setFiles,
    maxFileSize = 10 * 1024 * 1024, // 10 mb
    minRows = 4,
    maxRows = 7,
    readOnly,
    fontSize = 16,
    lineHeight = 24,
    submitCallback,
    sendNotification,
    ...restProps
  } = props;
  const editorRef = useRef<any>(null);
  const theme = useTheme();
  const classes = useStyles({ toolbarPosition, minRows, maxRows, readOnly, fontSize, lineHeight, ...restProps });
  const { t } = useTranslation('Communication');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const isSubmittingRef = useRef(false);

  const handleAddFiles = (selectedFiles: FileList) => {
    if (setFiles) {
      const filteredFiles = Array.from(selectedFiles).filter(({ size }) => size <= maxFileSize);
      if (sendNotification) {
        for (let i = 0; i < selectedFiles.length - filteredFiles.length; i++) {
          sendNotification(
            t('The file cannot be larger than', { maxFileSize: filesize(maxFileSize, {
              locale: getLang(),
              symbols: { B: 'Б' },
            }) }),
            'error',
          );
        }
      }
      setFiles([...(multiple ? files : []), ...filteredFiles]);
    }
  };

  const handleDeleteFile = (idx: number) => {
    if (setFiles) {
      setFiles(files.filter((file, key) => key !== idx));
    }
  };

  const toolbarCustomButtons: EditorProps['toolbarCustomButtons'] = [
    <Inline
      className={classes.toolbarInline}
      options={[
        {
          style: 'bold',
          icon: boldIcon,
          className: classes.toolbarOption,
          title: t('Bold'),
        },
        {
          style: 'italic',
          icon: italicIcon,
          className: classes.toolbarOption,
          title: t('Italic'),
        },
      ]}
    />,
    <CodeBlock
      icon={codeIcon}
      className={classes.toolbarOption}
      title={t('Code')}
    />,
  ];
  if (toolbarPosition === 'bottom') {
    if (counter) {
      toolbarCustomButtons.push(
        <Counter
          maxTextSize={maxTextSize}
          textSize={text ? text.length : 0}
        />,
      );
    }
  }
  if (uploaded) {
    toolbarCustomButtons.push(
      <FileUpload
        className={classes.toolbarOption}
        icon={attachIcon}
        title={t('Attach file')}
        addFiles={handleAddFiles}
        files={files}
        multiple={multiple}
      />,
    );
    if (toolbarPosition === 'bottom') {
      toolbarCustomButtons.push(
        <AttachmentList
          className={classes.attachments}
          files={files}
          deleteFile={handleDeleteFile}
        />,
      );
    }
  }
  if (controls && toolbarPosition === 'bottom') {
    toolbarCustomButtons.push(
      <Controls
        className={classes.controls}
        controls={controls(text || '', files, maxTextSize)}
      />,
    );
  }
  if (label && toolbarPosition === 'top') {
    toolbarCustomButtons.push(
      <Label
        className={classes.label}
        text={label}
      />,
    );
  }

  useEffect(() => {
    if (text === null) {
      setEditorState(EditorState.createEmpty());
      isSubmittingRef.current = false;
    }
  }, [text]);

  useEffect(() => {
    if (!initialText) return;
    const content = convertFromRaw(markdownToDraft(initialText));
    const newEditorState = EditorState.createWithContent(content);
    setEditorState(EditorState.moveFocusToEnd(newEditorState));
  }, [initialText]);

  const extendedBlockRenderMap = useMemo(() => {
    const blockRenderMap = Map({
      'code-block': {
        element: 'pre',
        wrapper: null,
      },
    });
    return Draft.DefaultDraftBlockRenderMap.merge(blockRenderMap);
  }, []);

  return (
    <>
      {label && toolbarPosition === 'bottom' && (
        <Label
          className={classes.label}
          text={label}
        />
      )}
      <Editor
        editorRef={(ref) => {
          editorRef.current = ref;
          if (extRef && 'current' in extRef) {
            extRef.current = ref;
          }
        }}
        editorState={editorState}
        onEditorStateChange={(newEditorState) => {
          const contentState = newEditorState.getCurrentContent();
          const selectionState = newEditorState.getSelection();
          const text = contentState.getBlockForKey(selectionState.getFocusKey()).getText();

          // Fix firefox bug when extra new line added
          if (selectionState.getAnchorKey() === selectionState.getFocusKey()
            && selectionState.getAnchorOffset() === selectionState.getFocusOffset()
            && selectionState.getFocusOffset() - text.length === -1
            && text[text.length - 1] === '\n'
          ) return;

          if (counter || contentState.getPlainText().length <= maxTextSize) {
            setEditorState(newEditorState);
            if (setText) {
              setText(draftToMarkdown(convertToRaw(contentState), { escapeMarkdownCharacters: false }));
            }
          } else {
            setEditorState(EditorState.push(newEditorState, editorState.getCurrentContent(), 'delete-character'));
          }
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        blockRenderMap={extendedBlockRenderMap}
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        handleReturn={(event: React.KeyboardEvent<{}>, editorState: EditorState) => {
          if (event.ctrlKey && event.keyCode === 13) {
            if (submitCallback && text && !isSubmittingRef.current) {
              isSubmittingRef.current = true;
              submitCallback(text, files);
              return 'handled';
            }
          }

          const blockType = RichUtils.getCurrentBlockType(editorState);

          if (blockType === 'code-block') {
            const content = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            const text = content.getBlockForKey(selection.getFocusKey()).getText();
            const block = content.getBlockForKey(selection.getFocusKey());

            if (!selection.isCollapsed()) {
              return 'handled';
            }

            if (selection.getAnchorOffset() === selection.getFocusOffset()
              && text.length === selection.getFocusOffset()
              && text[text.length - 1] === '\n'
            ) {
              const clearText = block.getText().replace(/[\n]+$/, '');
              const clearSelection = new SelectionState({
                anchorKey: selection.getAnchorKey(),
                anchorOffset: 0,
                focusKey: selection.getFocusKey(),
                focusOffset: text.length,
              });
              const clearContent = Modifier.replaceText(editorState.getCurrentContent(), clearSelection, clearText);
              const clearState = EditorState.push(editorState, clearContent, 'delete-character');
              const splitContent = Modifier.splitBlock(clearContent, selection);
              const splitState = EditorState.push(clearState, splitContent, 'split-block');
              const afterContent = Modifier.setBlockType(splitContent, splitContent.getSelectionAfter(), 'unstyled');
              const afterState = EditorState.push(splitState, afterContent, 'change-block-type');
              setEditorState(afterState);
              return 'handled';
            }

            setEditorState(RichUtils.insertSoftNewline(editorState));
            return 'handled';
          }
          return 'not_handled';
        }}
        handlePastedText={(text, html, editorState, onChange) => {
          let strings: string[] = [];
          if (RichUtils.getCurrentBlockType(editorState) === 'code-block') {
            strings.push(text);
          } else {
            strings = text.split(/\r\n|\r|\n/g);
          }
          let newEditorState = editorState;
          let content = editorState.getCurrentContent();

          content = Modifier.replaceText(content, editorState.getSelection(), '');
          newEditorState = EditorState.push(editorState, content, 'insert-characters');

          strings.forEach((str, idx) => {
            content = Modifier.insertText(content, newEditorState.getSelection(), str);
            newEditorState = EditorState.push(newEditorState, content, 'insert-characters');
            if (idx < strings.length - 1) {
              content = Modifier.splitBlock(content, newEditorState.getSelection());
              newEditorState = EditorState.push(newEditorState, content, 'split-block');
            }
          });

          onChange(newEditorState);
          return true;
        }}
        handleKeyCommand={(command: string): DraftHandleValue => {
          if (RichUtils.getCurrentBlockType(editorState) === 'code-block') {
            if (command === 'bold' || command === 'italic') {
              return 'handled';
            }
          }
          const newEditorState = RichUtils.handleKeyCommand(editorState, command);
          if (newEditorState) {
            setEditorState(newEditorState);
            return 'handled';
          }
          return 'not-handled';
        }}
        keyBindingFn={(event: SyntheticKeyboardEvent) => getDefaultKeyBinding(event)}
        readOnly={readOnly}
        wrapperClassName={classes.wrapper}
        toolbarClassName={classes.toolbar}
        editorClassName={classNames(classes.editor, readOnly && classes.editorDisabled)}
        customStyleMap={{
          CODE: {
            border: '1px dashed #D8D8D8',
            padding: theme.spacing(0.5, 1),
            backgroundColor: '#ECF2FA',
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(20),
            display: 'inline-block',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
          },
        }}
        toolbar={{
          options: ['link'],
          link: {
            options: ['link'],
            className: classes.toolbarInline,
            component: (props: any) => <Link {...props} editorRef={editorRef} />,
            showOpenOptionOnHover: false,
            defaultTargetOption: '_blank',
            link: {
              icon: linkIcon,
              className: classes.toolbarOption,
            },
          },
        }}
        toolbarCustomButtons={toolbarCustomButtons}
        localization={{
          locale: getLang(),
          translations: {
            'generic.add': t('Add'),
            'generic.cancel': t('Cancel'),
            'components.controls.inline.bold': t('Bold'),
            'components.controls.inline.italic': t('Italic'),
            'components.controls.inline.monospace': t('Code'),
            'components.controls.blocktype.code': t('Code'),
            'components.controls.link.link': t('Link'),
            'components.controls.link.linkTitle': t('Link Title'),
            'components.controls.link.linkTarget': t('Link Target'),
          },
        }}
        {...restProps}
      />
      {uploaded && toolbarPosition === 'top' && (
        <AttachmentList
          className={classes.attachments}
          files={files}
          deleteFile={handleDeleteFile}
        />
      )}
      {controls && toolbarPosition === 'top' && (
        <Controls
          className={classes.controls}
          controls={controls(text || '', files, maxTextSize)}
        />
      )}
    </>
  );
});
