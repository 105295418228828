import { useSelector } from 'store/hook';

export const LOADING = 'loading';
export const DENIED = 'denied';
export const ALLOWED = 'allowed';

export const usePermissions = (permissions: (string | undefined)[]) => {
  const { dict } = useSelector(state => state.permissions);

  return permissions.map(permission => {
    if (!permission) {
      return false;
    }

    return dict[permission] || false;
  });
};

export const usePermissionStates = (permissions: (string | undefined)[]) => {
  const { dict } = useSelector(state => state.permissions);
  return permissions.map(permission => {
    if (!permission) {
      return null;
    }
    if (typeof dict[permission] === 'undefined') {
      return LOADING;
    }
    return dict[permission] ? ALLOWED : DENIED;
  });
};
