import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  tooltip: {
    color: 'white',
    backgroundColor: '#1D1D1B',
  },
  status: {
    fontWeight: 700,
  },
  statusDescription: {
    fontWeight: 400,
  },
  span: {
    verticalAlign: 'sub',
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
});
