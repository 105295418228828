import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import {
  AgreementTitles,
} from 'services/activation/types';
import { KLButton } from 'components/KLButton';
import { getLang, getLocale } from 'utils/i18n';
import { downloadAgreement } from 'utils/common';
import styles from './styles';
import { isSa } from 'utils/help';

const useStyles = makeStyles(styles);

export const HelpfulDocs = () => {
  const classes = useStyles();
  // todo: need to change scope for translates?
  // todo: some features duplicated with javascript/console/src/pages/LicenseStatePage/components/HelpfulDocs
  const { t } = useTranslation(['LicenseStatePage']);
  const lang = getLang();

  return (
    <Box className={classes.container}>
      <Grid container spacing={2} className={classes.linksContainer}>
        <Grid item xs={12}>
          <a 
            className={classes.link} 
            href={t('legalDocs.privacyPolicyForWebLink', { locale: getLocale() })} 
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('legalDocs.Kaspersky Lab Privacy Policy for websites')}
          </a>
        </Grid>
        <Grid item xs={6}>
          <KLButton
            className={classes.btn}
            color="primary"
            variant="text"
            onClick={downloadAgreement(lang, AgreementTitles.Agreement)}
          >
            {t('legalDocs.Download MDR')}
          </KLButton>
        </Grid>
        {!isSa() &&
          <Grid item xs={6}>
            <KLButton
              className={classes.btn}
              color="primary"
              variant="text"
              onClick={downloadAgreement(lang, AgreementTitles.DPA)}
            >
              {t('legalDocs.Download DPA')}
            </KLButton>
          </Grid>
        }
      </Grid>
    </Box>
  );
};
