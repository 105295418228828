import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KLButton } from 'components/KLButton';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { ConfirmationProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const Confirmation: React.FC<ConfirmationProps> = props => {
  const {
    open,
    title,
    content,
    onConfirm,
    onDismiss,
    confirmText,
    dismissText,
    isLoading,
    isDisabled,
    dialogProps,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      {...dialogProps}
      open={open}
    >
      <IconButton className={classes.closeIcon} onClick={onDismiss} size="small">
        <CloseIcon />
      </IconButton>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {title && <Typography component="h2" variant="h2" classes={{ root: classes.dialogTitle }}>{title}</Typography>}
        {typeof content === 'string' ? (
          <DialogContentText classes={{ root: classes.dialogContentText }}>{content}</DialogContentText>
        ) : content}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        {onConfirm && (
          <KLButton
            color="primary"
            variant="outlined"
            classes={{ root: classes.dialogButton }}
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={onConfirm}
          >
            {confirmText}
          </KLButton>
        )}
        {onDismiss && (
          <KLButton
            color="primary"
            variant="contained"
            classes={{ root: classes.dialogButton }}
            onClick={onDismiss}
          >
            {dismissText}
          </KLButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
