import React from 'react';
import { MessageLayer } from 'components/MessageLayer';
import { useSelector } from 'store/hook';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { ErrorPageOriginMismatch } from 'assets/icons/ErrorPageOriginMismatch';
import { Sidebar } from 'pages/MainPage/components/Sidebar';
import styles from './styles';

const useStyles = makeStyles(styles);

export const OriginMismatchPage: React.FC = () => {
  const classes = useStyles();
  const { expectedOrigin } = useSelector(state => state.permissions);

  return (
    <div className={classes.container}>
      <Sidebar disabled />
      <MessageLayer
        title={(
          <Trans
            defaults="OriginMismatchPage:Origin mismatch error"
            values={{ expectedPortal: expectedOrigin }}
            components={[
              (
                <a href={`https://${expectedOrigin}`} rel="noopener noreferrer">
                  placeholder
                </a>
              ),
            ]}
          />
        )}
        icon={<ErrorPageOriginMismatch className={classes.icon} />}
      />
    </div>
  );
};
