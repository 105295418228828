import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PageLayer } from 'components/PageLayer';
import { KLButton } from 'components/KLButton';
import { useSelector } from 'store/hook';
import {
  agreementListForActivationRequest,
  confirmRequest,
  decline,
} from 'services/activation/slice';
import { getLang } from 'utils/i18n';
import { HelpfulDocs } from 'components/HelpfulDocs';
import { Agreement } from 'components/Agreement';
import {
  AgreementLanguage,
  AgreementMIME,
  AgreementTitles,
} from 'services/activation/types';
import { Loading } from 'components/Loading';
import styles from './styles';
import { AgreementCheckBoxField } from './components/AgreementCheckBoxField';
import { isSa } from 'utils/help';

const useStyles = makeStyles(styles);

export const LicenseAgreementStep = () => {
  const { t } = useTranslation('ActivationPage');
  const dispatch = useDispatch();
  const classes = useStyles();
  const [agreementToShow, setAgreementToShow] = useState<AgreementTitles>(AgreementTitles.Agreement);
  const [isFirstChecked, setFirstChecked] = useState(false);
  const [isSecondChecked, setSecondChecked] = useState(false);
  const [isFirstAccepted, setFirstAccepted] = useState(false);
  const [isSecondAccepted, setSecondAccepted] = useState(false);
  const { agreementsForActivation, isLoading } = useSelector(state => state.activation);
  const lang = getLang();

  useEffect(() => {
    dispatch(agreementListForActivationRequest({
      language: AgreementLanguage[lang],
      mime: AgreementMIME.HTML,
      titles: Object.values(AgreementTitles),
    }));
  }, [dispatch, lang]);

  useEffect(() => {
    if (agreementsForActivation) {
      setFirstAccepted(
        Boolean(!agreementsForActivation[AgreementTitles.Agreement]),
      );
      setSecondAccepted(
        Boolean(!agreementsForActivation[AgreementTitles.DPA]),
      );
    }
  }, [agreementsForActivation]);

  const onChangeFirstHandler = () => setFirstChecked(!isFirstChecked);
  const onChangeSecondHandler = () => setSecondChecked(!isSecondChecked);
  const declineHandler = () => dispatch(decline());

  if (!agreementsForActivation) {
    return <Loading />;
  }

  const confirmHandler = () => {
    if (isSa()) {
      dispatch(confirmRequest([agreementsForActivation[AgreementTitles.Agreement].meta.agreementId]));
    } else {
      dispatch(confirmRequest(Object.values(agreementsForActivation).map(item => item.meta.agreementId)));
    }
  };

  return (
    <PageLayer flex>
      <Agreement
        title={agreementsForActivation[agreementToShow].meta.title}
        text={agreementsForActivation[agreementToShow].body}
      />
      <HelpfulDocs />
      <Box>
        {!isFirstAccepted && (
          <AgreementCheckBoxField
            agreementToShow={agreementToShow}
            setAgreementToShow={setAgreementToShow}
            agreementTitle={AgreementTitles.Agreement}
            onChange={onChangeFirstHandler}
            checked={isFirstChecked}
            isLoading={isLoading}
            text={t('I confirm that I have fully read')}
          />
        )}
        {!isSecondAccepted && !isSa() && (
          <AgreementCheckBoxField
            agreementToShow={agreementToShow}
            setAgreementToShow={setAgreementToShow}
            agreementTitle={AgreementTitles.DPA}
            onChange={onChangeSecondHandler}
            checked={isSecondChecked}
            isLoading={isLoading}
            text={t('I am aware and agree that')}
          />
        )}
      </Box>
      <Box className={classes.controls}>
        <KLButton
          variant="outlined"
          color="primary"
          className={classes.btn}
          onClick={declineHandler}
        >
          {t('Decline')}
        </KLButton>
        <KLButton
          disabled={!((isFirstAccepted || isFirstChecked) && ((isSecondAccepted || isSecondChecked) || isSa()))}
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={confirmHandler}
          isLoading={isLoading}
        >
          {t('Confirm and activate')}
        </KLButton>
        {isLoading ? (
          <Typography className={classes.tip} component="span">
            {t('The activation process may take up to a few minutes')}
          </Typography>
        ) : null}
      </Box>
    </PageLayer>
  );
};
