import {
  ABOUT_PAGE,
  ACTIVATION_PAGE,
  ASSET_LIST_PAGE,
  INCIDENT_DETAILS_PAGE,
  INCIDENT_LIST_PAGE,
  SETTINGS_PAGE,
  STATISTICS_PAGE,
  LICENSE_STATE_PAGE,
} from 'global/routes';
import { HELP_URL } from 'global/environments';
import { addNotification } from 'services/system/slice';
import { TFunction } from 'i18next';

export const ASSET_DETAILS_HELP = 'ASSET_DETAILS_HELP';
export const INVITE_USER_HELP = 'INVITE_USER_HELP';
export const ASSET_LIST_FILTER_HELP = 'ASSET_LIST_FILTER_HELP';
export const INCIDENTS_LIST_FILTER_HELP = 'FILTER';

const getLocale = (lang: string) => {
  if (lang === 'ru') {
    return 'ru-RU';
  }
  return 'en-US';
};

export const isSa = (): boolean => window.location.hostname.startsWith('sa');

const openTab = (url: string) => {
  const helpWindow = window.open(url, '_blank');
  if (helpWindow) {
    helpWindow.focus();
  }
};

const helpPageURLMapping: any = {
  [ABOUT_PAGE]: 'WC_ABOUT',
  [ACTIVATION_PAGE]: 'ACTIVATION_PAGE',
  [STATISTICS_PAGE]: 'STATISTICS_PAGE',
  [INCIDENT_LIST_PAGE]: 'INCIDENTS_ON_PORTAL',
  [INCIDENT_DETAILS_PAGE]: 'INCIDENT_ID',
  [ASSET_LIST_PAGE]: 'ASSET_LIST_PAGE',
  [SETTINGS_PAGE]: 'WC_SETTINGS',
  [LICENSE_STATE_PAGE]: 'LICENSE',
  [`${SETTINGS_PAGE}#user-list`]: 'WC_SETTINGS',
  [`${SETTINGS_PAGE}#notification-settings`]: 'SETTINGS_PAGE_NOTIFICATION_SETTINGS',
  [`${SETTINGS_PAGE}#incidents`]: 'SETTINGS_INCIDENTS',
  [`${SETTINGS_PAGE}#report-settings`]: 'SETTINGS_PAGE_REPORT_SETTINGS',
  [`${SETTINGS_PAGE}#api-keys`]: 'SETTINGS_PAGE_API_KEYS',
  [`${SETTINGS_PAGE}#tenants`]: 'SETTINGS_TENANTS',
  [`${SETTINGS_PAGE}#general-settings`]: 'settings_general',
};
const defaultHelpPageId = 'DEFAULT_HELP';

export const openHelpById = (id: string | number, lang: string) => {
  openTab(`${HELP_URL}/?hl=${getLocale(lang)}&version=1.0&link=${id}&pid=mdr`);
};

export const openHelpByUrl = (url: string, lang: string) => {
  const id = helpPageURLMapping[url.includes('/incidents/') ? '/incidents/:id' : url] || defaultHelpPageId;
  openHelpById(id, lang);
};

export const copyText = (dispatch: any, t: TFunction, text: string) => {
  if (!navigator.clipboard) {
    dispatch(addNotification({ message: "Your browser doesn't support copying", options: { variant: 'error' } }));
    return;
  }

  navigator.clipboard.writeText(text).then(() => {
    dispatch(addNotification({
      message: t('Copied to clipboard!'),
      options: { variant: 'success' },
    }));
  }, (err) => {
    dispatch(addNotification({ message: `${t('Could not copy text')}: ${err}`, options: { variant: 'error' } }));
  });
};
