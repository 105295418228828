import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    maxHeight: 140,
    overflowY: 'scroll',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  fileIcon: {
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    marginRight: theme.spacing(1),
    color: theme.palette.info.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  fileDeleteIcon: {
    flexShrink: 0,
    cursor: 'pointer',
  },
});
