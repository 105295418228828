import { Theme, createStyles } from '@material-ui/core/styles';

const textStyleMixin = {
  color: '#B3B3B3',
  textDecoration: 'none',
};

export default (theme: Theme) => createStyles({
  icon: {
    marginRight: (appBarOpen: boolean) => (appBarOpen ? theme.spacing(1) : 0),
    flexShrink: 0,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    height: 40,
    width: '100%',
    fontWeight: 'bolder',
    whiteSpace: 'normal',
    ...textStyleMixin,
  },
  active: {
    '&:visited': {
      ...textStyleMixin,
    },
    '&:hover': {
      ...textStyleMixin,
      backgroundColor: '#2C2C2C',
    },
    '&:visited:hover': {
      ...textStyleMixin,
    },
  },
});
