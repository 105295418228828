import { postRequest } from 'utils/http';
import { IncidentDetails } from 'services/incidents/types';
import {
  AssetDetails,
  AssetIncidentListPayload,
  AssetListItem,
  AssetListSagaPayload,
  AssetSuggestion,
} from './types';

const url = 'assets';

export function postAssetList(payload: AssetListSagaPayload) {
  return postRequest<AssetListItem[]>(`${url}/list`, {
    paging: payload.paging,
    filter: payload.filter,
    searchPhrase: payload.searchPhrase,
  });
}

export function postAssetCount(payload: AssetListSagaPayload) {
  return postRequest<number>(`${url}/count`, {
    filter: payload.filter,
    searchPhrase: payload.searchPhrase,
  });
}

export function postAssetDetails(assetId: string) {
  return postRequest<AssetDetails>(`${url}/details`, { assetId });
}

export function postAssetIncidentsList(assetId: string) {
  const params: AssetIncidentListPayload = {
    paging: {
      page: 0,
      pageSize: 1000,
    },
    filter: {
      affectedHosts: [assetId],
    },
  };

  return postRequest<IncidentDetails[]>('incidents/list', params);
}

export function postAssetSuggestion(searchPhrase: string, tenantsNames: string[]) {
  return postRequest<AssetSuggestion[]>(
    `${url}/suggestion`,
    { searchPhrase, tenantsNames },
  );
}
