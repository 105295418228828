import { createAction, createSlice, PayloadAction, PrepareAction } from '@reduxjs/toolkit';
import {
  UserListItem,
  UserListSagaPayload,
} from './types';

const name = 'users';

const prepareListRequest: PrepareAction<UserListSagaPayload> = (payload: UserListSagaPayload) => ({ payload });
const prepareRequest: PrepareAction<any> = (payload: any) => ({ payload });

export const userListRequest = createAction(`${name}/list`, prepareListRequest);
export const activateUserRequest = createAction(`${name}/user-activation`, prepareRequest);
export const updateActiveUserRequest = createAction(`${name}/update-active-user`, prepareRequest);
export const updateInvitedUserRequest = createAction(`${name}/update-invited-user`, prepareRequest);
export const deleteUserRequest = createAction(`${name}/delete-user`, prepareRequest);
export const createInviteRequest = createAction(`${name}/create-invite`, prepareRequest);
export const deleteInviteRequest = createAction(`${name}/delete-invite`, prepareRequest);

//TODO: create correct combined type
const initialState: {
  list: UserListItem[];
  isLoading: boolean;
  error: any;
  pendingActivation: {
    inProgress: boolean;
    activated: boolean;
    error?: string;
  };
  sideDialogData: any;
  isSubmitting: boolean;
} = {
  list: [],
  isLoading: false,
  error: null,
  pendingActivation: {
    inProgress: false,
    activated: false,
    error: '',
  },
  sideDialogData: null,
  isSubmitting: false,
};

const userListSlice = createSlice({
  name,
  initialState,
  reducers: {
    openSideDialog(state: any, action: any) {
      state.sideDialogData = action.payload;
    },
    closeSideDialog(state: any) {
      state.sideDialogData = null;
      state.generatedInviteInfo = null;
    },
    listStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    listSuccess(state: any, action: any) {
      if (action.payload) state.list = action.payload;
      state.isLoading = false;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPendingActivation(state, action: PayloadAction<{ inProgress: boolean; activated: boolean; error?: string}>) {
      state.pendingActivation.inProgress = action.payload.inProgress;
      state.pendingActivation.activated = action.payload.activated;
      if (action.payload.error) state.pendingActivation.error = action.payload.error;
    },
    submittingStart(state: any) {
      state.isSubmitting = true;
    },
    submittingEnd(state: any) {
      state.isSubmitting = false;
    },
  },
});

export const {
  listStart,
  listSuccess,
  error,
  openSideDialog,
  closeSideDialog,
  setPendingActivation,
  submittingStart,
  submittingEnd,
} = userListSlice.actions;

export default userListSlice.reducer;
