import { postRequest, downloadFile } from 'utils/http';
import {
  LicenseInfo,
  PostConfirmApiPayload,
  LicenseText,
  LicenseDocType,
  AgreementOffer,
  Agreement,
  AgreementPayload,
  AgreementDecidePayload,
  AgreementType,
} from './types';

const url = 'activation';

export function postValidate(activationCode: string, idToken: string) {
  return postRequest<boolean>(`${url}/validate`, {
    activationCode,
    idToken,
  });
}

export function postConfirm({ activationCode, region, kata, idToken }: PostConfirmApiPayload) {
  return postRequest<string>(`${url}/confirm`, {
    activationCode,
    region,
    kataId: kata,
    idToken,
  }, {
    timeout: 120000, // 2 min
  });
}

export function getInfo() {
  return postRequest<LicenseInfo>(`${url}/info`);
}

export function downloadMDRConfig(licenseId: string, purpose?: number) {
  return downloadFile(`${url}/download-mdr-config`, { licenseId, purpose });
}

export function downloadLicenseDoc(type: LicenseDocType, lang: string) {
  return downloadFile(`${url}/download-license-doc`, { type, lang });
}

export function updateKata(licenseId: string, kataId: string) {
  return postRequest<LicenseInfo>(`${url}/setKata`, { licenseId, kataId });
}

export function getLicense(region: string, lang: string) {
  return postRequest<LicenseText>(`${url}/get-license`, { region, lang });
}

export function getAgreements(type: AgreementType) {
  return postRequest<AgreementOffer[]>(`${url}/get-agreements`, { type });
}

export function getAgreement({ language, title, lastAcceptedBy, mime }: AgreementPayload) {
  return postRequest<Agreement>(
    `${url}/get-agreement`,
    { language, title, mime, lastAcceptedBy },
  );
}

export function decideAgreement(payload: AgreementDecidePayload) {
  return postRequest<AgreementOffer>(`${url}/decide-agreement`, { ...payload });
}
