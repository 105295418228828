import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { KLButton } from 'components/KLButton';
import { getLang } from 'utils/i18n';
import {
  AgreementTitles,
} from 'services/activation/types';
import { downloadAgreement } from 'utils/common';
import { CheckBoxField } from '../../../CheckBoxField';
import styles from './styles';

const useStyles = makeStyles(styles);

export const AgreementCheckBoxField = (props: any) => {
  const { t } = useTranslation('ActivationPage');
  const { agreementTitle, onChange, checked, isLoading, agreementToShow, setAgreementToShow, text } = props;
  const classes = useStyles();
  const lang = getLang();

  const onChangeAgreementHandler = (AgreementTitle: AgreementTitles) => () => {
    if (AgreementTitle !== agreementToShow) {
      setAgreementToShow(AgreementTitle);
    }
  };
  return (
    <CheckBoxField onChange={onChange} checked={checked} disabled={isLoading}>
      {text}
      <KLButton
        className={classes.textBtn}
        color="primary"
        variant="text"
        onClick={onChangeAgreementHandler(agreementTitle)}
      >
        {t(agreementTitle)}
      </KLButton> (
      <KLButton
        className={classes.textBtn}
        color="primary"
        variant="text"
        onClick={downloadAgreement(lang, agreementTitle)}
      >
        {t('Download')}
      </KLButton>).
    </CheckBoxField>
  );
};
