import { Theme, createStyles, fade } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    height: 40,
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    justifyContent: 'flex-start',
    color: theme.palette.common.white,
    textTransform: 'none',
    height: '100%',
    minWidth: 'auto',
  },
  buttonText: {
    padding: theme.spacing(0, 2),
  },
  buttonStartIcon: {
    marginLeft: 0,
  },
  buttonEndIcon: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  buttonEndIconOpen: {
    transform: 'rotate(180deg)',
  },
  userName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(1),
  },
  menu: {
    backgroundColor: theme.palette.common.black,
    borderRadius: 0,
    color: theme.palette.common.white,
    minWidth: 272,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    fontSize: theme.typography.fontSize,
    padding: theme.spacing(1, 2, 1, 3),
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: fade(theme.palette.common.white, 0.1),
    },
  },
  menuItemAlone: {
    padding: theme.spacing(2, 2, 2, 3),
  },
  menuItemDense: {
    padding: theme.spacing(3, 2, 1, 3),
    fontSize: theme.typography.fontSize - 2,
    color: '#666666',
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  menuItemDenseInactive: {
    cursor: 'default',
    padding: theme.spacing(1, 2, 1, 3),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  activeMenuItem: {
    fontWeight: 'bolder',
  },
  arrowIcon: {
    fill: '#979797',
  },
});
