import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from 'store/slice';
import { RobotSessionItem } from './types';

const name = 'keys';

export const listRequest = createAction<any>(`${name}/list`);
export const startSessionRequest = createAction<any, any>(`${name}/start`);
export const terminateSessionRequest = createAction<any, any>(`${name}/terminate`);

export type APIkeysState = EntityState<RobotSessionItem, RobotSessionItem> & {
  drawer?: RobotSessionItem | null;
  isOpen?: boolean;
};

const initialState: APIkeysState = {
  list: [],
  isLoading: false,
  count: 0,
  error: null,
  drawer: null,
};

const keysSlice = createEntitySlice({
  name,
  initialState,
  reducers: {
    setDrawerData(state: APIkeysState, action: PayloadAction<RobotSessionItem | null>) {
      state.drawer = action.payload;
      state.isOpen = true;
    },
    closeDrawer(state: APIkeysState) {
      state.drawer = null;
      state.isOpen = false;
    },
  },
});

export const {
  listStart,
  listSuccess,
  error,
  setDrawerData,
  closeDrawer,
} = keysSlice.actions;

export default keysSlice.reducer;
