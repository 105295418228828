import React, { useState, useEffect, MouseEvent, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';
import filesize from 'filesize';
import { useQueryParam, StringParam } from 'use-query-params';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  Typography,
  Link,
} from '@material-ui/core';
import { useSelector } from 'store/hook';
import { EntityType } from 'store/slice';
import {
  createRequest as commentCreateRequest,
  updateRequest as commentUpdateRequest,
  deleteRequest as commentDeleteRequest,
  markAsReadRequest as commentMarkAsReadRequest,
  markAsRead as commentMarkAsRead,
} from 'services/comments/slice';
import { CommentItem } from 'services/comments/types';
import {
  createRequest as attachmentCreateRequest,
  updateRequest as attachmentUpdateRequest,
  deleteRequest as attachmentDeleteRequest,
  markAsReadRequest as attachmentMarkAsReadRequest,
  markAsRead as attachmentMarkAsRead,
  fileRequest,
} from 'services/attachments/slice';
import { IncidentAttachment } from 'services/attachments/types';
import { addNotification } from 'services/system/slice';
import { getLang } from 'utils/i18n';
import { Loading } from 'components/Loading';
import { DateTime } from 'components/DateTime';
import { Table } from 'components/Table';
import { KLButton } from 'components/KLButton';
import { useConfirmation } from 'components/Confirmation';
import { KLEditor } from 'components/KLEditor';
import { CommunicationProps, MessageItemsEditableTime, MessageItemsRefs, MessageItemType } from './types';
import { MessageItem } from './components/MessageItem';
import styles from './styles';

const useStyles = makeStyles(styles);

export function isComment(item: MessageItemType): item is CommentItem {
  return 'commentId' in item;
}

export const Communication: React.FC<CommunicationProps> = props => {
  const {
    incidentId,
    comments,
    attachments,
    isLoading,
    isCreating,
    isCreated,
    isUpdating,
    isUpdated,
    isDeleting,
    isDeleted,
    hasError,
    readonly = false,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['Communication', 'common']);
  const [message, setMessage] = useState<string | null>('');
  const [initialMessage, setInitialMessage] = useState<string | null>('');
  const [isEditing, setIsEditing] = useState(false);
  const [messageItemsEditableTime, setmessageItemsEditableTime] = useState<MessageItemsEditableTime>({});
  const [messageItemsRefs, setMessageItemsRefs] = useState<MessageItemsRefs>({});
  const [isRequesting, setIsRequesting] = useState(false);
  const { openConfirmation, closeConfirmation, setConfirmationOptions } = useConfirmation();
  const visibilityIntervalRef = useRef<number | null>(null);
  const unreadTimersRef = useRef<number[]>([]);
  const formEl = useRef<HTMLFormElement>(null);
  const editingItem = useRef<MessageItemType | null>(null);
  const deletingItem = useRef<MessageItemType | null>(null);
  const editorRef = useRef<any>(null);
  const userId = useSelector(state => state.auth.userId);
  const {
    solution,
  } = useSelector(state => state.activation);
  const [messageId, setMessageId] = useQueryParam('messageId', StringParam);
  const [attachmentId, setAttachmentId] = useQueryParam('attachmentId', StringParam);
  const [items, setItems] = useState<Array<MessageItemType>>([]);
  const [files, setFiles] = useState<File[]>([]);
  const location = useLocation();

  const handleSubmit = (text: string, files: File[]) => {
    setIsRequesting(true);
    setMessageId(undefined);

    if (isEditing && editingItem.current) {
      if (isComment(editingItem.current)) {
        dispatch(commentUpdateRequest({
          commentId: editingItem.current.commentId,
          text,
        }));
      } else {
        dispatch(attachmentUpdateRequest({
          attachmentId: editingItem.current.attachmentId,
          caption: text,
        }));
      }
    } else if (files.length) {
      dispatch(attachmentCreateRequest({
        incidentId,
        caption: text,
        file: files[0],
      }));
    } else {
      dispatch(commentCreateRequest({
        incidentId,
        text,
      }));
    }
  };

  const handleSendClick = (text: string, files: File[]) => (event: MouseEvent) => {
    event.preventDefault();
    handleSubmit(text, files);
  };

  const handleCancelClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    if (editorRef.current) {
      editorRef.current.blur();
    }
    editingItem.current = null;
    setIsEditing(false);
    setMessage(null);
    setInitialMessage(null);
    setFiles([]);
  }, []);

  const handleEditClick = (item: MessageItemType) => (event: MouseEvent) => {
    event.preventDefault();
    setIsEditing(true);
    editingItem.current = item;
    const text = isComment(item) ? item.text : item.caption;
    setMessage(text);
    setInitialMessage(text);
    if (formEl.current) {
      formEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleDialogDelete = useCallback(() => {
    if (deletingItem.current) {
      if (isComment(deletingItem.current)) {
        dispatch(commentDeleteRequest({
          commentId: deletingItem.current.commentId,
        }));
      } else {
        dispatch(attachmentDeleteRequest({
          attachmentId: deletingItem.current.attachmentId,
        }));
      }
      setIsRequesting(true);
    }
  }, [dispatch]);

  const handleDeleteClick = (item: MessageItemType) => (event: MouseEvent) => {
    event.preventDefault();
    deletingItem.current = item;
    openConfirmation({
      title: t('Confirm comment deletion'),
      content: t('Are you sure that you want to delete this comment?'),
      onConfirm: handleDialogDelete,
      confirmText: t('common:Yes'),
      dismissText: t('common:No'),
    });
  };

  const getEditableTime = useCallback(() => {
    const maxEditingTime = 10; // 10 min
    const now = Date.now();

    return items.reduce<MessageItemsEditableTime>((acc, item) => {
      const time = maxEditingTime - Math.floor((now - item.creationTime) / (1000 * 60));

      return {
        ...acc,
        [isComment(item) ? item.commentId : item.attachmentId]: time,
      };
    }, {});
  }, [items]);

  useEffect(() => {
    setItems(
      [
        ...comments,
        ...attachments,
      ].sort((a, b) => a.creationTime - b.creationTime),
    );
  }, [comments, attachments]);

  useEffect(() => {
    const refs = items.reduce<MessageItemsRefs>((acc, item) => ({
      ...acc,
      [isComment(item) ? item.commentId : item.attachmentId]: React.createRef<HTMLDivElement>(),
    }), {});

    setMessageItemsRefs(refs);
    setmessageItemsEditableTime(getEditableTime());

    visibilityIntervalRef.current = window.setInterval(() => {
      setmessageItemsEditableTime(getEditableTime());
    }, 10000);

    return () => {
      if (visibilityIntervalRef.current) {
        clearInterval(visibilityIntervalRef.current);
      }
    };
  }, [items, getEditableTime]);

  useEffect(() => {
    let cid = messageId;

    if (!cid && items && items.length) {
      const item = items[items.length - 1];
      cid = isComment(item) ? item.commentId : item.attachmentId;
    }
    if (!cid) return;

    const itemRef = messageItemsRefs[cid];

    if (!itemRef || !itemRef.current) return;

    itemRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [messageId, messageItemsRefs, files, items]);

  useEffect(() => {
    if (hasError || !isCreated || !isRequesting || isUpdating || isDeleting) return;

    const isAttachment = files.length > 0;
    setMessage(null);
    setInitialMessage(null);
    setFiles([]);
    setIsRequesting(false);
    if (editorRef.current) {
      setTimeout(() => {
        editorRef.current.blur();
      }, 0);
    }
    dispatch(addNotification({
      message: t(`${isAttachment ? 'Attachment' : 'Comment'} was successfully created`),
      options: { variant: 'success' },
    }));
  }, [hasError, isCreated, isRequesting, isUpdating, isDeleting, files, dispatch, t]);

  useEffect(() => {
    if (hasError || !isUpdated || !editingItem.current || !isRequesting || isCreating || isDeleting) return;

    const isAttachment = !isComment(editingItem.current);
    setMessageId(isComment(editingItem.current) ? editingItem.current.commentId : editingItem.current.attachmentId);
    setMessage(null);
    setInitialMessage(null);
    setIsEditing(false);
    setIsRequesting(false);
    editingItem.current = null;
    if (editorRef.current) {
      setTimeout(() => {
        editorRef.current.blur();
      }, 0);
    }
    dispatch(addNotification({
      message: t(`${isAttachment ? 'Attachment' : 'Comment'} was successfully updated`),
      options: { variant: 'success' },
    }));
  }, [hasError, isUpdated, isRequesting, isCreating, isDeleting, setMessageId, dispatch, t]);

  useEffect(() => {
    if (hasError || !isDeleted || !deletingItem.current || !isRequesting || isCreating || isUpdating) return;

    const isAttachment = !isComment(deletingItem.current);
    closeConfirmation();
    if (deletingItem.current === editingItem.current) {
      setMessage(null);
      setInitialMessage(null);
      setIsEditing(false);
      editingItem.current = null;
    }
    deletingItem.current = null;
    setIsRequesting(false);
    setMessageId(undefined);
    dispatch(addNotification({
      message: t(`${isAttachment ? 'Attachment' : 'Comment'} was successfully deleted`),
      options: { variant: 'success' },
    }));
  }, [hasError, isDeleted, isRequesting, isCreating, isUpdating, closeConfirmation, setMessageId, dispatch, t]);

  useEffect(() => {
    setConfirmationOptions({
      isLoading: isDeleting,
    });
  }, [isDeleting, setConfirmationOptions]);

  useEffect(() => {
    if (!attachmentId) return;
    const attachment = attachments.find(attach => attach.attachmentId === attachmentId);
    if (!attachment) return;
    dispatch(fileRequest({ attachmentId, fileName: attachment.fileName }));
    setAttachmentId(undefined, 'replaceIn');
  }, [attachmentId, setAttachmentId, attachments, dispatch]);

  useEffect(() => {
    const commentIds = items
      .filter(item => !item.wasRead && isComment(item))
      .map(item => (item as CommentItem).commentId);
    const attachmentIds = items
      .filter(item => !item.wasRead && !isComment(item))
      .map(item => (item as IncidentAttachment).attachmentId);
    const unreadTimers = unreadTimersRef.current;

    if (commentIds.length) {
      dispatch(commentMarkAsReadRequest({ entityType: EntityType.INCIDENT_COMMENT, entityIds: commentIds }));
      dispatch(commentMarkAsRead({ idField: 'commentId', entityIds: commentIds }));
    }
    if (attachmentIds.length) {
      dispatch(attachmentMarkAsReadRequest({ entityType: EntityType.INCIDENT_ATTACHMENT, entityIds: attachmentIds }));
      dispatch(attachmentMarkAsRead({ idField: 'attachmentId', entityIds: attachmentIds }));
    }

    return () => {
      unreadTimers.forEach(timer => {
        window.clearTimeout(timer);
      });
    };
  }, [items, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.comments}>
        {items.length > 0 && (
          <Box className={classes.commentsList}>
            {items.map(item => (
              <MessageItem
                key={isComment(item) ? item.commentId : item.attachmentId}
                item={item}
                itemRef={messageItemsRefs[isComment(item) ? item.commentId : item.attachmentId]}
                userId={userId}
                editableTime={messageItemsEditableTime[isComment(item) ? item.commentId : item.attachmentId]}
                itemUrl={!isComment(item)
                  ? `${location.pathname}?attachmentId=${item.attachmentId}${location.hash}`
                  : ''}
                onEdit={handleEditClick(item)}
                onDelete={handleDeleteClick(item)}
              />
            ))}
          </Box>
        )}
        {!readonly && (
          <form ref={formEl} className={classes.commentsForm} noValidate encType="multipart/form-data">
            <FormControl fullWidth>
              {(solution === 'Optimum')
              && <Box className={classes.commentsForm} component="span">{t('OptimumChatInfoMessage')}</Box>}
              <KLEditor
                ref={editorRef}
                initialText={initialMessage}
                text={message}
                setText={setMessage}
                files={files}
                setFiles={setFiles}
                counter
                placeholder={t('Comment text')}
                readOnly={isCreating || isUpdating || readonly}
                submitCallback={handleSubmit}
                sendNotification={(message, type) => {
                  dispatch(addNotification({
                    message,
                    options: {
                      variant: type,
                    },
                  }));
                }}
                controls={(text, files, maxTextSize) => (
                  <>
                    <KLButton
                      className={classes.commentsFormSubmitButton}
                      variant="outlined"
                      color="primary"
                      onClick={handleSendClick(text, files)}
                      isLoading={isCreating || isUpdating}
                      disabled={
                        (
                          (
                            message === initialMessage || !message || message.length > maxTextSize
                          ) && files.length < 1
                        ) || readonly
                      }
                    >
                      {t('Send')}
                    </KLButton>
                    {isEditing && (
                      <KLButton
                        className={classes.commentsFormSubmitButton}
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancelClick}
                      >
                        {t('Cancel')}
                      </KLButton>
                    )}
                  </>
                )}
                uploaded={!isEditing}
              />
            </FormControl>
          </form>
        )}
      </Box>
      <Box className={classes.attachments}>
        <Typography component="h2" className={classes.attachmentsTitle}>{t('All attachments')}</Typography>
        <Table<IncidentAttachment>
          columns={[
            {
              title: t('Filename'),
              field: 'name',
              disableClick: true,
              sorting: false,
              cellStyle: {
                maxWidth: 300,
                whiteSpace: 'pre',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              render: rowData => (
                <Link
                  component={RouterLink}
                  to={`${location.pathname}?attachmentId=${rowData.attachmentId}${location.hash}`}
                  replace
                >
                  {decodeURI(rowData.fileName)}
                </Link>
              ),
            },
            {
              title: t('File size'),
              field: 'size',
              sorting: false,
              render: rowData => filesize(rowData.fileSize, {
                locale: getLang(),
                symbols: { B: getLang() === 'ru' ? 'Б' : 'B' },
              }),
              disableClick: true,
            },
            {
              title: t('Uploaded'),
              field: 'creationTime',
              type: 'datetime',
              defaultSort: 'desc',
              render: rowData => <DateTime timestamp={rowData.creationTime} withTime />,
              customSort: (a, b) => a.creationTime - b.creationTime,
              disableClick: true,
            },
          ]}
          data={attachments}
          count={attachments.length}
          options={{
            search: false,
            paging: false,
            showTitle: false,
            toolbar: false,
          }}
        />
      </Box>
      {!isRequesting && (
        <NavigationPrompt
          when={message !== initialMessage || files.length > 0}
          disableNative
          afterConfirm={() => {
            closeConfirmation();
          }}
        >
          {({ onConfirm, onCancel }) => {
            openConfirmation({
              title: t('common:Leave this page'),
              content: t('common:Unsaved changes'),
              onConfirm,
              confirmText: t('common:Yes'),
              onDismiss: onCancel,
              dismissText: t('common:No'),
            });
            return false;
          }}
        </NavigationPrompt>
      )}
    </Box>
  );
};
