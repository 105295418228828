import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    position: 'relative',
  },
  appBarSpacer: { ...theme.mixins.toolbar },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: 0,
    margin: 0,
    height: '100%',
  },
  fixedHeight: {
    height: 240,
  },
});
