import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  AgreementTitles,
} from 'services/activation/types';
import { KLButton } from 'components/KLButton';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { getLang, getLocale } from 'utils/i18n';

import { downloadAgreement } from 'utils/common';
import { useSelector } from 'store/hook';
import styles from './styles';
import { isSa } from 'utils/help';

const useStyles = makeStyles(styles);

export const LegalDocs = () => {
  const classes = useStyles();
  const { t } = useTranslation(['LicenseStatePage']);
  const lang = getLang();
  const clientId = useSelector(({ auth }) => auth.clientId);
  return (
    <Box className={classes.container}>
      <Box className={classes.row}>
        <a
          className={classes.link}
          href={t('legalDocs.privacyPolicyForWebLink', { locale: getLocale() })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('legalDocs.Kaspersky Lab Privacy Policy for websites')}
        </a>
      </Box>
      <Box className={classes.row}>
        <KLButton
          className={classes.btn}
          color="primary"
          variant="text"
          startIcon={<DownloadIcon />}
          onClick={downloadAgreement(lang, AgreementTitles.Agreement, clientId)}
        >
          {t('legalDocs.Download MDR')}
        </KLButton>
      </Box>
      {!isSa() &&
        <Box className={classes.row}>
          <KLButton
            className={classes.btn}
            color="primary"
            variant="text"
            startIcon={<DownloadIcon />}
            onClick={downloadAgreement(lang, AgreementTitles.DPA, clientId)}
          >
            {t('legalDocs.Download DPA')}
          </KLButton>
        </Box>
      }
    </Box>
  );
};
