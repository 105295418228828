import { takeLatest, put, call } from 'redux-saga/effects';
import { addNotification } from 'services/system/slice';
import { getT } from 'utils/i18n';

import { PayloadAction } from '@reduxjs/toolkit';
import {
  getReportSettings,
  setReportSettings,
  sendAssetsByEmail,
  createIncidentEmail,
  createReportEmail,
  getNotifications,
  postMarkAsRead, deleteSchedule,
} from './api';
import {
  getReportSettingsRequest,
  setReportSettingsRequest,
  sendAssetsByEmailRequest,
  sendCreateIncidentEmailRequest,
  start,
  success,
  error,
  saveSettings,
  sendCreateReportEmailRequest,
  getNotificationsRequest,
  saveNotifications,
  markAsReadRequest,
  settingsFetched, deleteScheduleRequest, deleteScheduleSuccess,
} from './slice';
import {
  IncidentEmail,
  ReportSettingsPayload,
  NotificationPayload,
  NotificationMarkAsReadPayload,
  DeleteReportSchedulePayload,
} from './types';

function* fetchReportSettings() {
  try {
    const { schedules } = yield call(getReportSettings);
    yield put(settingsFetched(schedules));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* sendSetReportSettings(action: PayloadAction<ReportSettingsPayload & {resolve: () => void}>): any {
  yield put(start());
  try {
    const { client, settings, message } = action.payload;
    yield call(setReportSettings, { ...settings, client });
    yield put(success());
    yield put(saveSettings({
      clientReportSchedule: settings,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }));
    yield call(action.payload.resolve);
    yield put(addNotification({ message, options: { variant: 'success' } }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* deleteScheduleRequestSaga(
  { payload: { message, ...deleteSchedulePayload } }: PayloadAction<DeleteReportSchedulePayload & {message: string}>,
): any {
  yield put(start());
  try {
    yield call(deleteSchedule, deleteSchedulePayload);
    yield put(success());
    yield put(deleteScheduleSuccess(deleteSchedulePayload));
    yield put(addNotification({ message, options: { variant: 'success' } }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* sendAssetsByEmailSaga(): any {
  try {
    yield call(sendAssetsByEmail);
    yield put(addNotification({
      message: getT('notifications:A CSV file with asset list has been sent to your email address'),
      options: { variant: 'success' },
    }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* createIncidentEmailSaga(action: PayloadAction<IncidentEmail>): any {
  try {
    yield call(createIncidentEmail, action.payload);
    yield put(addNotification({
      message: getT('notifications:A PDF file with incident information has been sent to your email address'),
      options: { variant: 'success' },
    }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* createReportEmailSaga(action: PayloadAction<any>): any {
  try {
    yield call(createReportEmail, action.payload);
    yield put(addNotification({
      message: getT('notifications:The report has been sent to your email address'),
      options: { variant: 'success' },
    }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchNotifications(action: PayloadAction<NotificationPayload>) {
  try {
    const notifications = yield call(getNotifications, action.payload);
    yield put(saveNotifications(notifications));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchNotificationMarkAsRead(action: PayloadAction<NotificationMarkAsReadPayload>): any {
  try {
    yield call(postMarkAsRead, action.payload);
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const clientInformerSaga = [
  takeLatest(getReportSettingsRequest.type, fetchReportSettings),
  takeLatest(setReportSettingsRequest.type, sendSetReportSettings),
  takeLatest(sendAssetsByEmailRequest.type, sendAssetsByEmailSaga),
  takeLatest(deleteScheduleRequest.type, deleteScheduleRequestSaga),
  takeLatest(sendCreateIncidentEmailRequest.type, createIncidentEmailSaga),
  takeLatest(sendCreateReportEmailRequest.type, createReportEmailSaga),
  takeLatest(getNotificationsRequest.type, fetchNotifications),
  takeLatest(markAsReadRequest.type, fetchNotificationMarkAsRead),
];
