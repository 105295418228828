import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KLCheckBox } from 'components/KLCheckBox';
import { NotificationConfig } from 'services/notifications/types';
import styles from './styles';
import { NotificationChannel } from './index';

interface NotificationSettingsRowProps {
  title: string;
  name: string;
  idx: number;
  channels: {
    email: boolean;
    telegram?: boolean;
  };
  checked: (notifications: NotificationConfig, name: string, channel: string) => boolean;
  indeterminate?: (channel: string) => boolean;
  notificationChannels: NotificationChannel[];
  notifications: NotificationConfig;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  editUserNotificationsSettingsPerm: boolean;
  childProps?: { [key: string]: any };
  isChild?: boolean;
}

const useStyles = makeStyles(styles);

export const NotificationSettingsRow = (props: NotificationSettingsRowProps) => {
  const {
    title,
    name,
    idx,
    channels,
    checked,
    indeterminate,
    notificationChannels,
    notifications,
    handleChange,
    isLoading,
    editUserNotificationsSettingsPerm,
    childProps,
    isChild,
  } = props;
  const classes = useStyles(props);
  return (
    <>
      <tr key={idx}>
        <td className={isChild ? classes.childRow : ''}>{title}</td>
        {notificationChannels.map(({ name: channel, disabled }, idx) => (
          channels[channel as 'telegram' | 'email'] ? (
            <td key={idx}>
              <KLCheckBox
                color="primary"
                name={`${name}_${channel}`}
                checked={checked(notifications, name, channel)}
                indeterminate={indeterminate ? indeterminate(channel) : undefined}
                onChange={handleChange}
                disabled={isLoading || disabled || !editUserNotificationsSettingsPerm}
              />
            </td>
          ) : null
        ))}
      </tr>
      {childProps
      && Boolean(childProps.notificationChannels
        .filter((notificationChannel: { [key: string]: any }) => (
          channels[notificationChannel.name as 'telegram' | 'email']
        )).length)
      && (
        //                                                     Чтобы у детей не появлялись дети
        <NotificationSettingsRow {...{ ...props, ...childProps, childProps: undefined, isChild: true }} />
      )}
    </>
  );
};
