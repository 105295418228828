import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { MitreDescrLinkProps, MitreType } from './types';
import { tacticsMapping, techniquesMapping } from './mappings';

const useStyles = makeStyles(styles);

const rootUri = 'https://attack.mitre.org';
export const { TACTICS, TECHNIQUES } = MitreType;

export const MitreDescrLink = (props: MitreDescrLinkProps) => {
  const classes = useStyles();
  const { name, type } = props;

  let linkId: string | null = null;
  let subId: string | null = null;
  linkId = name.includes(':') ? name.split(':')[0] : null;
  [linkId, subId] = linkId && linkId.includes('.') ? linkId.split('.') : [linkId, null];
  if (!linkId) {
    const mapping = type === TACTICS ? tacticsMapping : techniquesMapping;
    const mappingItem = Object.keys(mapping).find(key => name.toLocaleLowerCase().includes(key.toLocaleLowerCase()));
    linkId = mappingItem ? mapping[mappingItem] : null;
  }
  if (!linkId) {
    return (
      <Typography className={classes.noLink} component="span">
        {name}
      </Typography>
    );
  }

  return (
    <Link
      target="_blank"
      className={classes.root}
      color="primary"
      href={`${rootUri}/${type}/${linkId}${subId ? `/${subId}` : ''}`}
    >
      {name}
    </Link>
  );
};
