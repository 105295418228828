import { createStyles } from '@material-ui/core/styles';
import { DonutChartProps } from './types';

export default () => createStyles({
  DonutChart: {
    position: 'relative',
    marginTop: '10px',
    display: 'flex',
    '& path': {
      transition: 'stroke-width .1s ease',
      cursor: 'pointer',
    },
    flexWrap: 'wrap',
  },
  svgWrapper: {
    minWidth: '150px',
  },
  list: {
    display: 'flex',
    msFlex: 1,
    flexDirection: 'column',
    marginTop: '10px',
    marginLeft: '30px',
    flexGrow: 1,
  },
  listItem: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '8px',
    fontWeight: 300,
    fontSize: '14px',
    maxWidth: '100%',
    '& span': {
      color: '#FF4455',
    },
    '& a': {
      color: '#00A88E',
    },
  },
  marker: {
    width: '8px',
    height: '8px',
    marginRight: '8px',
    borderRadius: '50%',
    minWidth: '8px',
  },
  names: {
    flexGrow: 1,
  },
  values: {
    minWidth: '25px',
    textAlign: 'right',
  },
  text: {
    textAnchor: 'middle',
    // dominantBaseline: 'middle',
    fontSize: '24px',
    fontWeight: 'bold',
    fill: (props: Partial<DonutChartProps>) => (props.options?.highlight ? '#FF4455' : '#000000'),
  },
  popup: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'block',
    background: '#1D1D1B',
    boxShadow: '0px 4px 4px #C2C2C2',
    color: 'white',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '10px',
    width: '140px',
    transform: 'translate(0, 100%)',
    pointerEvents: 'none',
    maxHeight: '100px',
    zIndex: 100,
  },
});
