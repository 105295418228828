import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ASSET_DETAILS_HELP } from 'utils/help';
import { KLDrawer } from 'components/KLDrawer';
import { KLTabs } from 'components/KLTabs';
import {
  detailsRequest,
  incidentsRequest,
  setLastVisited,
} from 'services/assets/slice';
import { detailsRequest as responseDetailsRequest } from 'services/incidentResponses/slice';
import { AssetListItem } from 'services/assets/types';
import { useSelector } from 'store/hook';
import { MessageLayer } from 'components/MessageLayer';
import { Loading } from 'components/Loading';
import { Properties } from '../Properties';
import { AssetIncidents } from '../AssetIncidents';
import { Parameters } from '../Parameters';
import styles from './styles';
import { DetailsDialogProps } from './types';

const useStyles = makeStyles(styles);

export const DetailsDialog = (props: DetailsDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('AssetListPage');
  const { assetId, open, onClose, onMovePrevNextClick, onMovePrevNextPageChange } = props;
  const {
    details,
    incidents,
    isLoading,
    error,
    list,
    page,
    pageSize,
    pageStep,
    count,
    lastVisited,
  } = useSelector(state => state.assets);
  const { details: responseDetails } = useSelector(state => state.incidentResponses);

  const closeHandler = () => {
    onClose();
  };

  useEffect(() => {
    if (assetId && open) {
      dispatch(detailsRequest(assetId));
      dispatch(incidentsRequest(assetId));
      dispatch(setLastVisited(assetId));
    }
  }, [assetId, dispatch, open]);

  useEffect(() => {
    if (!details || !details?.isolationTaskId) {
      return;
    }

    dispatch(responseDetailsRequest(details.isolationTaskId));
  }, [details, dispatch]);

  let DrawerContent = null;

  if (!isLoading && details && incidents) {
    DrawerContent = (
      <KLTabs
        tabs={[
          {
            label: t('Properties'),
            component: <Properties details={details} />,
            path: '#properties',
            isDefault: true,
          },
          {
            label: t('Incidents'),
            component: <AssetIncidents incidents={incidents} />,
            path: '#incidents',
          },
          {
            label: t('Parameters'),
            component: <Parameters parameters={responseDetails?.parameters} />,
            path: '#parameters',
            hidden: !details.isolationTaskId,
          },
        ]}
        classes={{
          root: classes.tabRoot,
          tab: classes.tab,
          indicator: classes.tabIndicator,
        }}
      />
    );
  } else if (isLoading) {
    DrawerContent = (<Loading />);
  } else if (error === 'notFound') {
    DrawerContent = (
      <MessageLayer
        title={t('Asset not found')}
        icon={<CancelOutlinedIcon style={{ color: 'red' }} />}
      />
    );
  }

  return (
    <KLDrawer<AssetListItem>
      open={open}
      onClose={closeHandler}
      helpId={ASSET_DETAILS_HELP}
      noPadding
      fullContentSpace
      MovePrevNextProps={
        onMovePrevNextClick 
          ? {
            list,
            page,
            pageSize,
            pageStep,
            count,
            entityId: lastVisited || assetId,
            entityIdField: 'assetId',
            onClick: onMovePrevNextClick,
            onPageChange: onMovePrevNextPageChange,
          }
          : undefined
    }
    >
      { DrawerContent }
    </KLDrawer>
  );
};
