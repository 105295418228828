import React, { useState, ReactNode, useEffect } from 'react';
import { StandardProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { KLDrawer } from 'components/KLDrawer';
import { KLButton } from 'components/KLButton';

type SideDialogProps<T> = {
  state: T;
  open: boolean;
  title: string;
  onClose: () => void;
  onCancel?: (state: T, setState: (state: T) => void) => void;
  cancelText?: string;
  onSubmit?: (state: T, setState: (state: T) => void) => void;
  submitText?: string;
  fullContentSpace?: boolean;
  loading?: boolean;
  disabled?: (state: T) => boolean;
  render: (state: T, setState: (state: T) => void) => ReactNode;
  helpId?: string | number;
  preventClose?: boolean;
  locator?: string;
};

type SideDialogClassKey = 'container' | 'content';

const useStyles = makeStyles(() => createStyles({
  container: {},
  content: {},
}));

export function SideDialog<T>(props: StandardProps<SideDialogProps<T>, SideDialogClassKey>) {
  const {
    state: defaultState,
    render,
    open,
    title,
    onClose,
    onCancel,
    cancelText,
    onSubmit,
    submitText,
    loading = false,
    disabled,
    helpId,
    preventClose = false,
    locator,
  } = props;
  const classes = useStyles(props);
  const [state, setState] = useState<T>(defaultState);

  useEffect(() => {
    if (!open) {
      setState(defaultState);
    }
  }, [open, defaultState]);

  const controls: React.ReactNode[] = [];
  if (onSubmit) {
    controls.push(
      <KLButton
        key="submit"
        variant="contained"
        color="primary"
        onClick={() => onSubmit(state, setState)}
        isLoading={loading}
        disabled={disabled ? disabled(state) || loading : false}
        id={locator ? `${locator}_button_submit` : undefined}
      >
        {submitText}
      </KLButton>,
    );
  }
  if (onCancel) {
    controls.push(
      <KLButton
        key="cancel"
        variant="outlined"
        color="primary"
        onClick={() => onCancel(state, setState)}
        disabled={loading}
        id={locator ? `${locator}_button_cancel` : undefined}
      >
        {cancelText}
      </KLButton>,
    );
  }

  return (
    <KLDrawer
      helpId={helpId}
      onClose={onClose}
      open={open}
      title={title}
      controls={controls.length > 0 ? controls : undefined}
      classes={{ container: classes.container, content: classes.content }}
      noPadding
      fullContentSpace
      preventClose={preventClose}
    >
      {render(state, setState)}
    </KLDrawer>
  );
}
