import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  textBtn: {
    fontWeight: 300,
    padding: 0,
    color: '#00A88E',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
