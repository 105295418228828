import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  textField: {
    width: '100%',
  },
  btn: {
    minWidth: 92,
  },
  btnMarginLeft: {
    minWidth: 92,
    marginLeft: theme.spacing(1),
  },
});
