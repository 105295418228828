import React, { useEffect } from 'react';
import { MessageLayer } from 'components/MessageLayer';
import { useSelector } from 'store/hook';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Redirect } from 'react-router-dom';
import { MAIN_PAGE } from 'global/routes';
import { LinkButton } from 'components/LinkButton';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loginCancel } from 'services/auth/slice';
import styles from './styles';

const useStyles = makeStyles(styles);

export const AuthErrorPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('AuthErrorPage');
  const classes = useStyles();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    dispatch(loginCancel()); // clear auth error
  }, [dispatch]);

  if (isAuthenticated) {
    return <Redirect to={MAIN_PAGE} />;
  }

  const controls = (
    <LinkButton
      variant="outlined"
      color="primary"
      to={MAIN_PAGE}
    >
      {t('Try again')}
    </LinkButton>
  );

  return (
    <MessageLayer
      title={t('Authorization error')}
      icon={<CancelOutlinedIcon className={classes.icon} />}
      controls={controls}
    />
  );
};
