import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    marginTop: theme.spacing(2),
  },
  link: {
    fontSize: 14,
    color: '#00A88E',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  btn: {
    fontWeight: 300,
    padding: 0,
    color: '#00A88E',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linksContainer: {
    display: 'flex',
  },
});
