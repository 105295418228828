import { Theme, createStyles } from '@material-ui/core/styles';
import { KLDrawerProps } from './types';

type Props = KLDrawerProps<any> & { scrollPos: number; maxScroll: number };

export default (theme: Theme) => createStyles({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: 440,
    maxWidth: '100vw',
    position: 'relative',
    maxHeight: '100%',
    overflowX: 'hidden',
    padding: (props: Props) => (props.noPadding ? 0 : theme.spacing(4)),
  },
  controls: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    fontSize: 0,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  control: {
    padding: theme.spacing(1),
  },
  title: {
    flexShrink: 0,
    zIndex: 1,
    padding: (props) => (props.noPadding
      ? theme.spacing(props.title ? 4 : 3)
      : theme.spacing(0, 0, props.title ? 4 : 3)),
    boxShadow: (props: Props) => (props.scrollPos > 0 ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'),
  },
  content: {
    marginBottom: (props: Props) => (props.controls ? theme.spacing(8) : 0),
    overflowY: (props: Props) => (props.fullContentSpace ? 'auto' : 'scroll'),
    height: '100%',
  },
  extControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexShrink: 0,
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    padding: (props: Props) => (props.noControlsPadding ? theme.spacing(2, 0) : theme.spacing(2, 4)),
    height: theme.spacing(8),
    backgroundColor: theme.palette.common.white,
    boxShadow: (props: Props) => (props.scrollPos < props.maxScroll ? '0px -4px 4px rgba(0, 0, 0, 0.25)' : 'none'),
    zIndex: 1,
    '& button + button': {
      marginLeft: theme.spacing(1),
    },
  },
});
