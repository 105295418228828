import React, { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { SOCKET_IO } from 'global/environments';
import { useSelector } from 'store/hook';
import { SocketIOContext } from './SocketIOContext';

export const SocketIOProvider = ({ children }: any) => {
  const { secret, clientId } = useSelector(state => state.auth);
  const [socket, setSocket] = useState<Socket | null>(null);
  useEffect(() => {
    if (socket) {
      socket.disconnect();
    }

    if (!secret || !clientId || !SOCKET_IO) {
      return;
    }

    const newSocket = io(SOCKET_IO as string, {
      transports: ['websocket'],
      query: {
        Authorization: `Bearer ${secret}`,
        'X-ClientID': clientId,
        'X-Client-ID': clientId,
      },
    });

    newSocket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        newSocket.connect();
      }
    });
    setSocket(newSocket);
  }, [secret, clientId]);

  return (
    <SocketIOContext.Provider value={socket}>
      {children}
    </SocketIOContext.Provider>
  );
};
