import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from '../OrganisationTableRow/styles';

const useStyles = makeStyles(styles);

export const OrganisationTableRow = (props: any) => {
  const { label, value } = props;
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classNames(classes.label, classes.root)}>{label}</TableCell>
      <TableCell className={classNames(classes.value, classes.root)}>{value}</TableCell>
    </TableRow>
  );
};
