import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Tabs, Tab, StandardProps, Typography, Box } from '@material-ui/core';
import styles from './styles';

import { ClassKeys, KLTabsProps, TabPanelProps } from './types';

function TabPanel(props: StandardProps<TabPanelProps, 'root'>) {
  const { children, value, index } = props;
  const useStyles = makeStyles(() => createStyles({
    root: {},
  }));
  const classes = useStyles(props);

  return (
    <Typography
      classes={{ root: classes.root }}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles(styles);

export const KLTabs: React.FC<StandardProps<KLTabsProps, ClassKeys>> = props => {
  const { tabs, withRouter } = props;
  let defaultTabIndex = tabs.findIndex((tab => !tab.hidden));
  tabs.forEach((item, idx) => {
    if (item.isDefault && !item.hidden) {
      defaultTabIndex = idx;
    }
  });
  const defaultTab = withRouter ? tabs[defaultTabIndex].path : defaultTabIndex;
  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState<any>(withRouter ? false : defaultTab);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: string | number) => {
    if (withRouter) {
      history.replace(value.toString());
    } else {
      setActiveTab(value);
    }
  };

  useEffect(() => {
    if (!withRouter) return;

    const isCorrectPath = (path: string) => tabs.map(tab => tab.path).includes(path);
    let activeTab: any = defaultTab;

    if (isCorrectPath(location.hash)) {
      activeTab = location.hash;
    } else if (location.hash) {
      activeTab = false;
    }
    setActiveTab(activeTab);
  }, [withRouter, location, defaultTab, tabs]);

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        classes={{ root: classes.root, indicator: classes.indicator }}
        variant="scrollable"
        scrollButtons="on"
      >
        {tabs
          .filter(({ hidden }) => !hidden)
          .map(({ label, path, disabled, id }, idx) => (
            <Tab
              key={label}
              label={label}
              value={withRouter ? path : idx}
              classes={{
                root: classes.tab,
                textColorInherit: classes.tabTextColorInherit,
                selected: classes.tabSelected,
                disabled: classes.tabDisabled,
              }}
              disableRipple
              disabled={disabled}
              id={id}
            />
          ))}
      </Tabs>
      {!withRouter && tabs.filter(({ hidden }) => !hidden).map(({ component }, idx) => (
        <TabPanel key={idx} value={activeTab} index={idx} classes={{ root: classes.panel }}>{component}</TabPanel>
      ))}
      {withRouter && (
        <>
          {tabs
            .filter(({ hidden }) => !hidden)
            .map(({ component, path }, idx) => (
              <Route
                key={idx}
                render={({ location }) => (
                  ((location.hash === path) || (!location.hash && defaultTab === path)) && component
                )}
              />
            ))}
        </>
      )}
    </>
  );
};

export * from './types';
