import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';
import { LegendItemProps } from './types';

const useStyles = makeStyles(styles);

const LegendItem = ({ active, color, children, title, onClick }: LegendItemProps) => {
  const classes = useStyles({ active, color });

  return <div title={title} className={classes.container} onClick={onClick}>{children}</div>;
};

export default LegendItem;
