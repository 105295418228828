import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FileIcon } from 'assets/icons/FileIcon';
import { CloseIcon } from 'assets/icons/CloseIcon';
import styles from './styles';

type AttachmentListProps = {
  className?: string;
  files: File[];
  deleteFile?: (idx: number) => void;
};

const useStyles = makeStyles(styles);

export const AttachmentList: React.FC<AttachmentListProps> = props => {
  const { className, files, deleteFile } = props;
  const classes = useStyles();

  const handleDeleteFile = (idx: number) => () => {
    if (deleteFile) {
      deleteFile(idx);
    }
  };

  return (
    <ul className={classNames(className, classes.root)}>
      {files.map((file, idx) => (
        <li key={idx} className={classes.item}>
          <FileIcon className={classes.fileIcon} />
          <span className={classes.fileName}>{file.name}</span>
          <CloseIcon className={classes.fileDeleteIcon} onClick={handleDeleteFile(idx)} />
        </li>
      ))}
    </ul>
  );
};
