import React, { SVGProps } from 'react';
import classNames from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StandardProps } from '@material-ui/core';
import { ReactComponent as Icon } from './icon.svg';

type CloseIconProps = {
  stroke?: string;
  strokeWidth?: number;
  size?: number;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    stroke: ({ stroke }: CloseIconProps) => stroke,
    strokeWidth: ({ strokeWidth }: CloseIconProps) => strokeWidth,
    width: ({ size }: CloseIconProps) => size,
    height: ({ size }: CloseIconProps) => size,
  },
}));

export const CloseIcon: React.FC<StandardProps<CloseIconProps, 'root'> & SVGProps<SVGSVGElement>> = props => {
  const { stroke, strokeWidth, size, classes, className, ...restProps } = props;
  const styles = useStyles(props);

  return (
    <Icon {...restProps} className={classNames(className, styles.root)} />
  );
};

CloseIcon.defaultProps = {
  stroke: '#58595B',
  strokeWidth: 2,
  size: 14,
};
