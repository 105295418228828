import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    padding: theme.spacing(8),
  },
  title: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    marginBottom: theme.spacing(3),
  },
  btn: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  downloadLink: {
    padding: 0,
  },
  confirmBtn: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginBottom: theme.spacing(3),
  },
  tooltip: {
    color: '#1D1D1B',
    backgroundColor: 'white',
    border: '1px solid #1D1D1B',
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
});
