import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import FlatPagination from 'material-ui-flat-pagination';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KLSelect } from 'components/KLSelect';
import { PrevIcon } from 'assets/icons/PrevIcon';
import { NextIcon } from 'assets/icons/NextIcon';
import styles from './styles';

type PaginationProps = {
  count: number;
  page: number;
  pageSize: number;
  loadable?: boolean;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  onChangePage: (event: React.MouseEvent<HTMLElement>, page: number) => void;
  onChangeRowsPerPage: React.EventHandler<any>;
};

const useStyles = makeStyles(styles);

export const Pagination: React.FC<PropsWithChildren<PaginationProps>> = React.memo(props => {
  const {
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPageOptions,
    rowsPerPage,
    count,
    page,
    pageSize,
    loadable = false,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation(['pagination', 'common']);

  return (
    <>
      {loadable ? (
        rowsPerPage < count && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            component="td"
            className={classes.loadableContainer}
          >
            <Button
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() => {
                onChangeRowsPerPage({ target: { value: rowsPerPage + Math.min(5, count - rowsPerPage) } });
              }}
            >
              {t('common:Load more')}
            </Button>
          </Box>
        )
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          component="td"
          className={classes.container}
        >
          {count > 0 && (
            <FlatPagination
              limit={pageSize}
              offset={(page - 1) * pageSize}
              total={count}
              onClick={(ev, offset, page) => {
                onChangePage(ev, page);
              }}
              previousPageLabel={<><PrevIcon className={classes.previousIcon} />{t('Previous')}</>}
              nextPageLabel={<>{t('Next')}<NextIcon className={classes.nextIcon} /></>}
              classes={{
                root: classes.root,
                text: classes.text,
                disabled: classes.disabled,
                rootCurrent: classes.rootCurrent,
                rootEnd: classes.rootEnd,
                rootEllipsis: classes.rootEllipsis,
              }}
            />
          )}
          {count > 0 && (
            <Box mr={3}>
              <KLSelect
                items={rowsPerPageOptions.map(item => ({ label: String(item), value: item }))}
                renderValue={(value) => `${value} ${t('entries per page')}`}
                value={pageSize}
                onChange={(ev) => {
                  onChangeRowsPerPage(ev);
                }}
                dense
              />
            </Box>
          )}
          <Box className={classes.entries} component="span">
            {t('Entries')}:
            {count > 0 && (
              <>
                <Box component="span" fontWeight="fontWeightBold">
                  {` ${(page - 1) * pageSize + 1}-${Math.min((page - 1) * pageSize + pageSize, count)} / ${count} `}
                </Box>
                {t('total')}
              </>
            )}
            {count === 0 && <Box component="span" fontWeight="fontWeightBold">{` ${t('no entries')}`}</Box>}
          </Box>
        </Box>
      )}
    </>
  );
});
