import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  selectorContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  root: {
    marginBottom: theme.spacing(3),
    '& ul': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: 0,
      padding: 0,
      fontSize: 12,
      listStyleType: 'none',
      color: theme.palette.text.secondary,
      '& li': {
        margin: `0 ${theme.spacing(1)}px`,
        position: 'relative',
        '&:first-child': {
          listStyleType: 'none',
          marginLeft: 0,
          '&::before': {
            content: '""',
          },
        },
        '& > a': {
          color: theme.palette.text.secondary,
          textDecoration: 'none',
          '&:hover': {
            color: theme.palette.primary.dark,
          },
        },
        '&::before': {
          content: '"\u2022"',
          display: 'inline-flex',
          position: 'absolute',
          left: '-10px',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiInputBase-root': {
        width: '100% !important',
        margin: `${theme.spacing(2)}px 0`,
      },
    },
  },
});
