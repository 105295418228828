import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { MAIN_PAGE } from 'global/routes';
import {
  AuthState, AuthPayload, Organization, ChangeClientIdPayload,
} from './types';

export const name = 'auth';

export const loginRequest = createAction(`${name}/loginRequest`);
export const userCreateRequest = createAction(`${name}/userCreateRequest`);
export const checkLogin = createAction(`${name}/checkLogin`);
export const logoutRequest = createAction(`${name}/logoutRequest`);
export const uisLogoutRequest = createAction(`${name}/uisLogoutRequest`);
export const postLoginRequest = createAction<string>(`${name}/postLoginRequest`);
export const refreshRequest = createAction(`${name}/refreshRequest`);
export const startRefreshCheck = createAction(`${name}/startRefreshCheck`);
export const setClientIdRequest = createAction<string>(`${name}/setClientId`);

const initialState: AuthState = {
  isAuthenticated: false,
  redirectPathOnAuthentication: MAIN_PAGE,
  isLoading: true,
  error: null,
  displayName: '',
  userId: '',
  clientId: null, // presence of clientId is also a sign of product activation
  organizations: [],
  refreshTime: null,
  isRefreshChecking: false,
  emailConfirmed: false,
  roleName: '',
  isActiveOrganization: true,
  secret: '',
  idToken: '',
  region: 'ru',
  isSocAnalyst: false,
};

const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    loginStart(state) {
      state.isLoading = true;
    },
    loginSuccess(state, action: PayloadAction<AuthPayload>) {
      const {
        displayName,
        userId, secret,
        clientId,
        organizations,
        refreshTime,
        emailConfirmed,
        idToken,
        roleName,
        isActiveOrganization,
      } = action.payload;

      state.isLoading = false;
      state.isAuthenticated = true;
      state.displayName = displayName;
      state.userId = userId;
      state.clientId = clientId;
      state.organizations = organizations;
      state.refreshTime = refreshTime;
      state.emailConfirmed = emailConfirmed;
      state.roleName = roleName;
      state.isActiveOrganization = isActiveOrganization;
      state.secret = secret;
      state.idToken = idToken;
      state.isSocAnalyst = roleName === 'SOC Analyst';
    },
    loginError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    loginCancel(state) {
      state.isLoading = false;
      state.error = null;
    },
    logoutSuccess(state) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.displayName = '';
      state.userId = '';
      state.secret = '';
      state.clientId = '';
      state.organizations = [];
      state.idToken = '';
      state.roleName = '';
      state.isSocAnalyst = false;
    },
    changeClientId(state, action: PayloadAction<ChangeClientIdPayload>) {
      const { clientId, roleName } = action.payload;
      state.clientId = clientId;
      state.roleName = roleName;
    },
    setOrganizations(state, action: PayloadAction<Organization[]>) {
      state.organizations = action.payload;
    },
    setRegion(state, action: PayloadAction<string>) {
      state.region = action.payload;
    },
    setRefreshChecking(state, action: PayloadAction<boolean>) {
      state.isRefreshChecking = action.payload;
    },
  },
});

export const {
  loginCancel,
  loginStart,
  loginSuccess,
  loginError,
  logoutSuccess,
  changeClientId,
  setOrganizations,
  setRegion,
  setRefreshChecking,
} = authSlice.actions;

export default authSlice.reducer;
