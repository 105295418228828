import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { check } from 'services/auth/saga';
import {
  start,
  error,
  success,
  setSuccess,
  listRequest,
  setRequest,
} from './slice';
import { list, set } from './api';
import { SetOrganizationNamePayload } from './types';

function* listRequestSaga() {
  yield put(start());
  try {
    const response = yield call(list);
    yield put(success(response));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
  }
}

function* setRequestSaga(action: PayloadAction<SetOrganizationNamePayload>) {
  yield put(start());
  try {
    yield call(set, action.payload);
    yield put(setSuccess(action.payload));
    yield call(check, true); // get actual user info
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
  }
}

export const organizationsSaga = [
  takeLatest(listRequest.type, listRequestSaga),
  takeLatest(setRequest.type, setRequestSaga),
];

