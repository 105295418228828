import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScriptState } from './types';

const name = 'incidentResponseScript';
export const scriptRequest = createAction<string>(`${name}/script`);

const initialState: ScriptState = {
  script: null,
  scriptIsLoading: false,
};
const scriptSlice = createSlice(
  {
    name: 'responseScriptSlice',
    initialState,
    reducers: {
      scriptStart(state: any) {
        state.script = null;
      },
      scriptSuccess(state: any, { payload }: PayloadAction<any>) {
        state.script = payload;
      },
    },
  },
);

export const {
  scriptStart, 
  scriptSuccess,
} = scriptSlice.actions;

export default scriptSlice.reducer;
