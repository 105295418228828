import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { KLButton } from 'components/KLButton';
import styles from './styles';

const useStyles = makeStyles(styles);

interface NoteSectionProps {
  onSignInClick: () => void;
  outerClassname?: string;
  innerClassname?: string;
}

export const NoteSection: React.FC<NoteSectionProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('LoginPage');
  const { outerClassname, innerClassname, onSignInClick } = props;

  return (
    <Grid container className={classNames(classes.root, outerClassname)}>
      <Grid container className={innerClassname}>
        <Grid item xs={12} sm={9}>
          <p>{t('As corporate processes')}</p>
        </Grid>
        <Grid item xs={12} sm={3} className="note-button-container">
          <KLButton
            variant="contained"
            className="red-bg no-border-radius"
            onClick={onSignInClick}
          >
            {t('Create account')}
          </KLButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

