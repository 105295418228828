import { postRequest } from 'utils/http';
import {
  ReportSettings,
  IncidentEmail,
  NotificationPayload,
  NotificationMarkAsReadPayload,
  DeleteReportSchedulePayload,
} from './types';

const url = 'client-informer';

export function getReportSettings() {
  return postRequest(`${url}/get`);
}

export function setReportSettings(payload: ReportSettings | { client: string | null }) {
  return postRequest(`${url}/set`, { ...payload });
}

export function deleteSchedule(payload: DeleteReportSchedulePayload) {
  return postRequest(`${url}/delete-schedule`, { ...payload });
}

export function sendAssetsByEmail() {
  return postRequest(`${url}/send-assets-by-email`);
}

export function createIncidentEmail(payload: IncidentEmail) {
  return postRequest(`${url}/create-incident-email`, { ...payload });
}

export function createReportEmail(payload: any) {
  return postRequest(`${url}/create-report-email`, { ...payload });
}

export function getNotifications(payload: NotificationPayload) {
  return postRequest(`${url}/get-notifications`, { ...payload });
}

export function postMarkAsRead(payload: NotificationMarkAsReadPayload) {
  return postRequest<never>(`${url}/mark-as-read`, {
    ...payload,
  });
}
