import { createStyles } from '@material-ui/core/styles';
import { LegendItemProps } from './types';

export default () => createStyles({
  container: {
    display: 'inline-block',
    padding: '1px 5px',
    borderRadius: '2px',
    fontSize: '12px',
    lineHeight: '14px',
    color: (props: Partial<LegendItemProps>) => (props.active ? 'white' : props.color),
    backgroundColor: (props: Partial<LegendItemProps>) => (props.active ? props.color : 'transparent'),
    border: (props: Partial<LegendItemProps>) => `1px solid ${props.color}`,
    marginRight: '7px',
    cursor: 'pointer',
    transition: '.3s ease',
    userSelect: 'none',
    '&:last-of-type': {
      marginRight: '0',
    },
  },
});
