import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { MDRLogo } from 'assets/icons/MDRLogo';
import styles from './styles';

const useStyles = makeStyles(styles);

export const CompanyName = (props: any) => {
  const { company } = useSelector(state => state.activation);
  const { isSideBarOpened } = props;
  const classes = useStyles({ isSideBarOpened, company: !!company });

  return (
    <Box className={classes.root}>
      <MDRLogo
        className={classes.logo}
        type="horizontal"
        color="white"
      />
      <Box className={classes.title}>
        <Box>Kaspersky</Box>
        <Box>Managed Detection and Response</Box>
        <Box className={classes.companyTitle}>
          {company}
        </Box>
      </Box>
    </Box>
  );
};
