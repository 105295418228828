import React, { SVGProps } from 'react';
import { Radio, RadioProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as Unchecked } from './unchecked.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

const CheckedIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <Checked {...props} />
);
const CheckedIconRef: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> = React.forwardRef(
  (props, ref) => (
    <CheckedIcon {...props} ref={ref} />
  ),
);

const UncheckedIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <Unchecked {...props} />
);
const UncheckedIconRef: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> = React.forwardRef(
  (props, ref) => (
    <UncheckedIcon {...props} ref={ref} />
  ),
);

export const KLRadio: React.FC<RadioProps> = props => {
  const classes = useStyles();

  return (
    <Radio
      classes={{
        root: classes.root,
      }}
      disableRipple
      icon={<UncheckedIconRef />}
      checkedIcon={<CheckedIconRef />}
      {...props}
    />
  );
};
