import React from 'react';
import { Action } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, IconButton } from '@material-ui/core';
import { Title } from 'components/Title';
import styles from './styles';

export type ToolbarProps<T extends object> = {
  title: string;
  actions: (Action<T> & {locator?: string} | ((rowData: T) => Action<T>))[];
  extraActions: React.ReactNode[];
  className?: string;
};

const useStyles = makeStyles(styles);

export function Toolbar<T extends object>(props: ToolbarProps<T>) {
  const {
    title,
    actions = [],
    extraActions = [],
    className,
  } = props;
  const classes = useStyles();
  const toolbarActions = actions.filter(action => (
    typeof action !== 'function' && action.position === 'toolbar' && !action.hidden
  )) as (Action<T> & {locator?: string})[];

  return (
    <div className={classes.root}>
      <Title classes={{ root: className || classes.title }} component="h2">{title}</Title>
      {(toolbarActions.length !== 0 || extraActions.length !== 0) && <div className={classes.spacer} />}
      {toolbarActions.map(({ icon, tooltip, onClick, isFreeAction, locator }, idx) => (
        <React.Fragment key={idx}>
          {!isFreeAction ? (
            <Tooltip title={tooltip || ''} arrow>
              <IconButton
                id={locator}
                onClick={onClick as () => void}
              >
                {typeof icon === 'string' ? (
                  <img src={icon} alt="" />
                ) : (
                  <>
                    { icon({}) }
                  </>
                )}
              </IconButton>
            </Tooltip>
          ) : (
            <>
              { typeof icon !== 'string' && icon({}) }
            </>
          )}
        </React.Fragment>
      ))}
      {extraActions.length > 0 && (
        <>
          <div className={classes.extraActionsSpacer} />
          {extraActions.map((action, idx) => (
            <React.Fragment key={idx}>{action}</React.Fragment>
          ))}
        </>
      )}
    </div>
  );
}
