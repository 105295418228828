import { Theme, createStyles } from '@material-ui/core/styles';
import { drawerWidth, bgColor } from '../Sidebar/styles';

export default (theme: Theme) => createStyles({
  toolbar: {
    padding: theme.spacing(0, 2),
    flexWrap: 'wrap',
    minHeight: 'auto',
    justifyContent: 'center',
  },
  toolbarShift: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  appBar: {
    backgroundColor: bgColor,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: drawerWidth,
    marginBottom: 0,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    padding: theme.spacing(2, 0),
  },
  menuButtonShift: {},
  searchButton: {
    padding: theme.spacing(2, 0),
  },
  guideButton: {
    padding: theme.spacing(2, 0),
    marginRight: (appBarOpen: boolean) => (appBarOpen ? theme.spacing(0) : 0),
  },
  title: {
    flexGrow: 1,
  },
});
