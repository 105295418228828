import React, { useEffect } from 'react';
import { useSelector } from 'store/hook';
import { useDispatch } from 'react-redux';
import { listRequest } from 'services/permissions/slice';
import { Redirect } from 'react-router-dom';
import { ORIGIN_MISMATCH_PAGE } from 'global/routes';

export const Permissions = () => {
  const dispatch = useDispatch();
  const { clientId, userId, roleName, isActiveOrganization } = useSelector(state => state.auth);
  const { originMismatch } = useSelector(state => state.permissions);
  useEffect(() => {
    if (!userId || !clientId) {
      return;
    }
    dispatch(listRequest({ clientId, userId }));
  }, [clientId, userId, roleName, isActiveOrganization]); // eslint-disable-line
  
  if (originMismatch) {
    return <Redirect to={ORIGIN_MISMATCH_PAGE} push={false} />;
  }

  return null;
};
