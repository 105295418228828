import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& p': {
      margin: `${theme.spacing(2)}px auto`,
    },
  },
}));

export const DeleteScheduleConfirmation: React.FC<{name: string}> = ({ name }) => {
  const classes = useStyles();
  const { t } = useTranslation('SettingsPage');

  return (
    <div className={classes.root}>
      <p>{t('ReportSettings.Are you sure that you want to delete the following schedule?')}</p>
      <p>{`${t('ReportSettings.Schedule name')}: ${name}`}</p>
    </div>
  );
};
