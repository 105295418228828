import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

export type CounterProps = {
  className?: string;
  maxTextSize: number;
  textSize: number;
};

const useStyles = makeStyles(styles);

export const Counter: React.FC<CounterProps> = props => {
  const { className, maxTextSize, textSize } = props;
  const classes = useStyles({ maxTextSize, textSize });
  return (
    <span className={classNames(className, classes.counter)}>
      {textSize}/{maxTextSize}
    </span>
  );
};
