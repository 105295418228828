import React, { ChangeEvent, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Grid,
  FormHelperText,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { KLInput } from 'components/KLInput';
import { KLButton } from 'components/KLButton';
import { KLDatePicker, KLDateType, KLDate, KLDateValue } from 'components/KLDatePicker';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { DateTime } from 'components/DateTime';
import { usePermissions } from 'components/Permissions/hooks';
import { fixTimezoneOffset } from 'utils/date';
import {
  createRequest,
  configRequest,
  detailsSuccess,
} from 'services/tenants/slice';
import { GuideEntry } from '../../../GuidePage/components/GuideEntry';

import styles from './styles';

const useStyles = makeStyles(styles);

function getDefaultEndDate(maxEndDate?: number, licenseEndDate?: number) {
  if (licenseEndDate) {
    return licenseEndDate;
  } if (maxEndDate) {
    return maxEndDate;
  }
  return null;
}

export const TenantForm = () => {
  const { details } = useSelector(state => state.tenants);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['SettingsPage', 'GuidePage']);
  const [editOrganizationUsersPerm] = usePermissions([
    'edit_organization_users',
  ]);

  const {
    isActive,
    name,
    description,
    assetsCount,
    licenseStartDate,
    tenantId,
    blobId,
    licenseEndDate,
  } = details || {};

  const {
    expirationDate,
  } = useSelector(state => state.activation);

  const [active, setActive] = useState(isActive ?? true);
  const [tenantName, setTenantName] = useState(name || '');
  const [desc, setDesc] = useState(description || '');
  const [assets, setAssets] = useState(assetsCount || 0);
  const [start] = useState<KLDateValue>(licenseStartDate || Date.now());
  const [end, setEnd] = useState(getDefaultEndDate(details?.rules?.maxEndDate, licenseEndDate));
  const [errors, setErrors] = useState<boolean | string>(false);

  const handleSubmit = () => {
    dispatch(createRequest({
      isActive: active,
      tenantName,
      tenantDescription: desc,
      assetsCount: assets,
      licenseStartDate: start,
      licenseEndDate: end,
      tenantId: details?.tenantId,
    }));
  };

  const canSubmit = Boolean(tenantName && assets && start && end && !errors);

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={2} alignItems="stretch" className={classes.formWrapper}>
        <Grid item>
          <FormControlLabel
            control={(
              <Switch
                checked={active}
                onChange={() => {
                  setActive(!active);
                }}
                name="acive"
                disabled={!editOrganizationUsersPerm}
              />
            )}
            label={t('Active')}
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth className={classes.formBlock}>
            <FormLabel className={classes.label}>{t('Tenants.Tenant Name')} *</FormLabel>
            <KLInput
              id="tenants_add_name"
              noMarginTop
              value={tenantName}
              onChange={(event: ChangeEvent) => {
                const name = (event.target as HTMLInputElement).value;
                setTenantName(name);
              }}
              disabled={!editOrganizationUsersPerm || !!name}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel className={classes.label}>{t('Description')}</FormLabel>
            <textarea
              className={classes.textarea}
              name="description"
              value={desc}
              disabled={!editOrganizationUsersPerm}
              onChange={(event: ChangeEvent) => {
                const name = (event.target as HTMLInputElement).value;
                setDesc(name);
              }}
              id="tenants_add_description"
            />
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl fullWidth className={classes.formBlock} error={!!errors}>
            <FormLabel className={classes.label}>{t('Tenants.Number of assets')}</FormLabel>
            <KLInput
              noMarginTop
              value={assets}
              onChange={(event: ChangeEvent) => {
                const value = (event.target as HTMLInputElement).value || '0';
                const reg = /^\d+$/;
                let error = '';

                if (!reg.test(value)) {
                  return;
                }

                if (+value > (details?.rules?.availableAssetsCount || 0)) {
                  error = t('Tenants.Exceeded available assets');
                }

                setErrors(error);
                setAssets(+value);
              }}
              disabled={!editOrganizationUsersPerm}
              id="tenants_add_assets-number"
            />
            <FormHelperText>
              <span id="tenants_add_label_max-assets">
                {`${details?.rules?.availableAssetsCount || 0} ${t('Tenants.max')}`}
              </span>
              {`${errors || ''}`}
            </FormHelperText>
          </FormControl>
        </Grid>

        {
          details?.rules?.minStartDate && details?.rules?.maxEndDate ? (
            <Grid item>
              <Typography variant="body1">
                <strong>{t('Tenants.Lifetime of this tenant')}</strong>
              </Typography>
              <div id="tenants_add_label_max-lifetime">
                {t('Tenants.Lifetime of this tenant limit')}
                {' '}
                <DateTime timestamp={expirationDate || details?.rules?.maxEndDate + 100086400} />
              </div>
            </Grid>
          ) : null
        }

        <Grid item>
          <FormControl fullWidth className={classes.formBlock}>
            <FormLabel className={classes.label}>{t('Tenants.MDR config lifetime')}</FormLabel>
            <KLDatePicker
              useTextInput
              fixedStartDate
              disabled={!editOrganizationUsersPerm}
              type={KLDateType.custom}
              start={fixTimezoneOffset(start || Date.now())}
              end={end ? fixTimezoneOffset(end) : null}
              onChange={(date?: KLDate) => {
                if (date) {
                  const { end } = date;
                  setEnd(end);
                }
              }}
              minDate={fixTimezoneOffset(Date.now())}
              maxDate={fixTimezoneOffset(details?.rules?.maxEndDate || Date.now())}
              id="tenants_add_lifetime"
            />
          </FormControl>
        </Grid>

        {
          details?.blobId && editOrganizationUsersPerm ? (
            <Grid item>
              <div className={classes.buttonsNotification}>
                <WarningIcon className={classes.notificationHeader} />
                <div className={classes.notificationText}>
                  <GuideEntry
                    text={'Tenants.notificationWarning'}
                    link={'Tenants.notificationWarningLink'}
                    linkName={'Tenants.notificationWarningLinkName'}
                    ns={'SettingsPage'}
                  />
                </div>
              </div>
              <div className={classes.buttons}>
                <DownloadIcon />
                <KLButton
                  type="submit" color="primary"
                  variant="text"
                  disabled={!canSubmit}
                  onClick={() => dispatch(configRequest({
                    tenantId,
                    blobId,
                    purpose: 0,
                  }))}
                  id="tenants_edit_download-kea"
                >
                  {'MDR configuration file.zip'}
                </KLButton>
                <Typography component="span">{` (${t('GuidePage:blob included')})`}</Typography>
              </div>
              <div className={classes.buttons}>
                <DownloadIcon />
                <KLButton
                  type="submit" color="primary"
                  variant="text"
                  disabled={!canSubmit}
                  onClick={() => dispatch(configRequest({
                    tenantId,
                    blobId,
                    purpose: 1,
                  }))}
                  id="tenants_edit_download-kes"
                >
                  {'MDR configuration file.zip'}
                </KLButton>
                <Typography component="span">{` (${t('GuidePage:bat included')})`}</Typography>
              </div>
            </Grid>
          ) : null
        }

        <div className={classes.footer}>
          {editOrganizationUsersPerm ? (
            <KLButton
              type="submit" color="primary"
              variant="contained"
              disabled={!canSubmit}
              onClick={handleSubmit}
              id="tenants_button_submit"
            >{t('Save')}
            </KLButton>
          ) : null}
          <KLButton
            color="primary"
            variant="outlined"
            onClick={() => dispatch(detailsSuccess(null))}
            id="tenants_button_cancel"
          >{t('Cancel')}
          </KLButton>
        </div>
      </Grid>
    </div>
  );
};
