import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StandardProps, TextField, TextFieldProps, OutlinedTextFieldProps } from '@material-ui/core';
import styles from './styles';
import { ClassKeys } from './types';

const useStyles = makeStyles(styles);

export const KLTextField: React.FC<StandardProps<TextFieldProps & OutlinedTextFieldProps, ClassKeys>> = (props) => {
  const classes = useStyles(); // eslint-disable-line
  const { InputProps, ...restProps } = props;
  return (
    <TextField
      variant="outlined"
      size="small"
      InputProps={{
        classes: {
          root: classes.input,
          notchedOutline: classes.notchedOutline,
        },
        ...InputProps,
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
          contained: classes.contained,
        },
      }}
      InputLabelProps={{
        classes: {
          shrink: classes.shrink,
          marginDense: classes.marginDense,
        },
      }}
      {...restProps}
    />
  );
};
