export const ACTIVATION_PAGE = '/activation';
export const AUTO_POST_LOGIN_PAGE = '/auth/post-login/:code';
export const AUTH_ERROR_PAGE = '/auth/error';
export const ORIGIN_MISMATCH_PAGE = '/origin-mismatch';
export const AUTH_LOGIN_PAGE = '/auth/login';
export const MAIN_PAGE = '/';
export const INCIDENT_LIST_PAGE = '/incidents';
export const INCIDENT_DETAILS_PAGE = '/incidents/:id';
export const ASSET_LIST_PAGE = '/assets';
export const SETTINGS_PAGE = '/settings';
export const USER_ACTIVATION_PAGE = '/user-activation';
export const LICENSE_STATE_PAGE = '/license';
export const GUIDE_PAGE = '/guide';
export const FORBIDDEN_ERROR_PAGE = '/forbidden';
export const STATISTICS_PAGE = '/monitoring';
export const ABOUT_PAGE = '/about';
