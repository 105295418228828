import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { addNotification } from 'services/system/slice';
import {
  getRequest,
  setRequest,
  getTelegramRequest,
  resetTelegramRequest,
  start,
  getSuccess,
  setSuccess,
  getTelegramSuccess,
  startResetTelegram,
  resetTelegramSuccess,
  error,
  startGetChannels,
  getChannelsRequest,
  getChannelsSuccess,
} from './slice';
import {
  NotificationGetResponse,
  NotificationSetPayload,
  NotificationTelegramResponse,
  NotificationTelegramResetPayload,
  NotificationTelegramResetResponse,
} from './types';
import * as api from './api';

function* getNotifications(action: PayloadAction<string>) {
  yield put(start());
  try {
    const config: NotificationGetResponse = yield call(api.getNotifications, action.payload);
    yield put(getSuccess(config));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* setNotifications(action: PayloadAction<NotificationSetPayload>) {
  yield put(start());
  try {
    const { message, ...restPayload } = action.payload;
    const configStateToken: string = yield call(api.setNotifications, restPayload);
    yield put(setSuccess({ config: action.payload.config, configStateToken }));
    yield put(addNotification({ message, options: { variant: 'success' } }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* getTelegramConfig(action: PayloadAction<string>) {
  try {
    const telegramConfig: NotificationTelegramResponse = yield call(api.getTelegramConfig, action.payload);
    yield put(getTelegramSuccess(telegramConfig));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* resetTelegramActivation(action: PayloadAction<NotificationTelegramResetPayload>) {
  yield put(startResetTelegram());
  try {
    const resetResponse: NotificationTelegramResetResponse = yield call(api.resetTelegramActivation, action.payload);
    yield put(resetTelegramSuccess(resetResponse));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchChannelSettings(action: PayloadAction<any>) {
  try {
    yield put(startGetChannels());
    const data = yield call(api.getChannelsSettings, action.payload);
    yield put(getChannelsSuccess(data));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const notificationsSaga = [
  takeLatest(getRequest.type, getNotifications),
  takeLatest(setRequest.type, setNotifications),
  takeLatest(getTelegramRequest.type, getTelegramConfig),
  takeLatest(resetTelegramRequest.type, resetTelegramActivation),
  takeLatest(getChannelsRequest.type, fetchChannelSettings),
];
