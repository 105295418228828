import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { downloadMDRConfigRequest } from 'services/activation/slice';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { usePermissions } from 'components/Permissions/hooks';
import { ForbiddenError } from 'components/ForbiddenError';
import { KLButton } from 'components/KLButton';
import { GuideEntry } from './components/GuideEntry';
import styles from './styles';

const useStyles = makeStyles(styles);

export const GuidePage = () => {
  const { t } = useTranslation('GuidePage');
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editLicensePerm] = usePermissions(['edit_organization_license']);

  const downloadMDRHandler = () => {
    dispatch(downloadMDRConfigRequest(0));
  };

  const downloadKESMDRHandler = () => {
    dispatch(downloadMDRConfigRequest(1));
  };

  if (!editLicensePerm) {
    return <ForbiddenError />;
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h1" component="h1">
        {t('Start User Guide')}
      </Typography>

      <Box className={classes.steps}>
        <Typography className={classes.subtitle} variant="h3" component="h2">
          {t('Deployment for KES 11_6')}
        </Typography>
        <Typography className={classes.subtitle} variant="h4" component="h3">
          <GuideEntry
            text={'Deployment attention text'}
            link={'Deployment attention link'}
            linkName={'Deployment attention linkName'}
            ns={'GuidePage'}
          />
        </Typography>
        <Typography className={classes.subtitle} variant="h4" component="h3">
          <GuideEntry
            text={'Full list of programs text'}
            link={'Full list of programs link'}
            linkName={'Full list of programs linkName'}
            ns={'GuidePage'}
          />
        </Typography>
        <GuideEntry
          position={1}
          text={'startGuideKES11_6.1 text'}
          link={'startGuideKES11_6.1 link'}
          linkName={'startGuideKES11_6.1 linkName'}
          ns={'GuidePage'}
        />
        <GuideEntry
          position={2}
          text={'startGuideKES11_6.2 text'}
          link={'startGuideKES11_6.2 link'}
          linkName={'startGuideKES11_6.2 linkName'}
          ns={'GuidePage'}
        />
        <GuideEntry
          position={3}
          text={'startGuideKES11_6.3 text'}
          link={'startGuideKES11_6.3 link'}
          linkName={'startGuideKES11_6.3 linkName'}
          link2={'startGuideKES11_6.3 link2'}
          linkName2={'startGuideKES11_6.3 linkName2'}
          ns={'GuidePage'}
        />

        <GuideEntry
          position={4}
          text={'startGuideKES11_6.4 text'}
          link={'startGuideKES11_6.4 link'}
          linkName={'startGuideKES11_6.4 linkName'}
          ns={'GuidePage'}
          content={(
            <>
              <Box>
                <KLButton
                  color="primary"
                  variant="text"
                  startIcon={<DownloadIcon />}
                  onClick={downloadMDRHandler}
                >
                  {'MDR configuration file.zip'}
                </KLButton>
                <Typography component="span">{` (${t('blob included')})`}</Typography>
              </Box>
              <Box>
                <KLButton color="primary"
                  variant="text"
                  startIcon={<DownloadIcon />}
                  onClick={downloadKESMDRHandler}
                >
                  {'MDR configuration file.zip'}
                </KLButton>
                <Typography component="span">{` (${t('bat included')})`}</Typography>
              </Box>
            </>
          )}
        />
        <GuideEntry
          position={5}
          text={'startGuideKES11_6.5 text'}
          link={'startGuideKES11_6.5 link'}
          linkName={'startGuideKES11_6.5 linkName'}
          ns={'GuidePage'}
        />

        <GuideEntry
          position={6}
          text={'startGuideKES11_6.6 text'}
          link={'startGuideKES11_6.6 link'}
          linkName={'startGuideKES11_6.6 linkName'}
          ns={'GuidePage'}
        />
        <GuideEntry
          position={7}
          text={'startGuideKES11_6.7 text'}
          link={'startGuideKES11_6.7 link'}
          linkName={'startGuideKES11_6.7 linkName'}
          ns={'GuidePage'}
        />
        <GuideEntry
          position={8}
          text={'startGuideKES11_6.8 text'}
          link={'startGuideKES11_6.8 link'}
          linkName={'startGuideKES11_6.8 linkName'}
          ns={'GuidePage'}
        />
        <GuideEntry
          position={9}
          text={'startGuideKES11_6.9 text'}
          link={''}
          linkName={''}
          ns={'GuidePage'}
        />
        <GuideEntry
          position={10}
          text={'startGuideKES11_6.10 text'}
          link={''}
          linkName={''}
          ns={'GuidePage'}
        />
      </Box>

      <Box className={classes.help}>
        <Typography className={classes.subtitle} variant="h3" component="h2">
          {t('Helpful sources')}
        </Typography>

        <GuideEntry
          text={'helpfulSources.api text'}
          link={'helpfulSources.api link'}
          linkName={'helpfulSources.api linkName'}
          ns={'GuidePage'}
        />

        <GuideEntry
          text={'helpfulSources.help text'}
          link={'helpfulSources.help link'}
          linkName={'helpfulSources.help linkName'}
          ns={'GuidePage'}
        />
        <GuideEntry
          text={'helpfulSources.support text'}
          link={'helpfulSources.support link'}
          linkName={'helpfulSources.support linkName'}
          ns={'GuidePage'}
        />
      </Box>
    </Box>
  );
};
