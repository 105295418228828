import { Theme, createStyles } from '@material-ui/core/styles';
import { backgroundColors } from '../../../../themes/default';

export default (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    padding: theme.spacing(1, 3),
    backgroundColor: backgroundColors?.warning?.main,
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1300,
  },
  text: {
    color: theme.palette.common.black,
    fontWeight: 600,
    lineHeight: 2,
  },
  btn: {
    marginLeft: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  leftSide: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
});
