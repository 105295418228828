import React from 'react';
import { useTranslation } from 'react-i18next';
import { KLButton } from 'components/KLButton';
import { KLDrawer } from 'components/KLDrawer';
import { KLEditor } from 'components/KLEditor';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { ResponsesDeclineDialogProps } from './types';

const useStyles = makeStyles(styles);

export const ResponsesDeclineDialog = (props: ResponsesDeclineDialogProps) => {
  const { onCancel, open, onSubmit } = props;
  const classes = useStyles();

  const { t } = useTranslation('IncidentResponses');
  const [commentText, setCommentText] = React.useState('');

  const submit = () => {
    onSubmit(commentText);
    setCommentText('');
  };

  const onCancelHandler = () => {
    onCancel();
    setCommentText('');
  };

  const controls = (
    <>
      <KLButton
        variant="contained"
        color="primary"
        onClick={submit}
        className={classes.btn}
        disabled={commentText.trim().length === 0}
      >
        {t('Send')}
      </KLButton>
      <KLButton
        variant="outlined"
        color="primary"
        onClick={onCancelHandler}
        className={classes.btnMarginLeft}
      >
        {t('Cancel')}
      </KLButton>
    </>
  );

  return (
    <KLDrawer
      open={open}
      onClose={onCancelHandler}
      title={t('Give the reason for response decline')}
      controls={controls}
    >
      <KLEditor
        text={commentText}
        setText={(text) => setCommentText(text || '')}
        minRows={4}
        maxRows={4}
        placeholder={t('Comment text')}
        toolbarHidden
      />
    </KLDrawer>
  );
};
