import React from 'react';
import { HelpIcon } from 'assets/icons/HelpIcon';
import { IconButton } from '@material-ui/core';
import { openHelpById, openHelpByUrl } from 'utils/help';
import { getLang } from 'utils/i18n';
import { useLocation } from 'react-router-dom';

export const HelpButton = (props: any) => {
  const { id, icon, className } = props;
  const lang = getLang();
  const location = useLocation();

  const openHelpHadler = () => {
    if (id) {
      openHelpById(id, lang);
    } else {
      const { pathname, hash } = location;
      openHelpByUrl(`${pathname}${hash}`, lang);
    }
  };

  return (
    <IconButton
      color="inherit"
      onClick={openHelpHadler}
      className={className}
      size={'small'}
    >
      {icon || <HelpIcon />}
    </IconButton>
  );
};
