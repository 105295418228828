import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4, 6),
  },
  table: {
    minWidth: 600,
    width: '40%',
  },
});
