import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1, 0),
    position: 'relative',
  },
  rangeButtonGroup: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(0.5)}px`,
  },
  rangeButton: {
    cursor: 'pointer',
    display: 'block',
    background: 'transparent',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      background: `${theme.palette.primary.light}`,
      color: 'white',
    },
    '&:active': {
      background: `${theme.palette.primary.dark}`,
      color: 'white',
    },
  },
  rangeButtonSelected: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  table: {
    marginTop: theme.spacing(3),
    borderCollapse: 'collapse',
    tableLayout: 'auto',
    width: '100%',
  },
  tableCell: {
    borderTop: '1px dashed #ECF0F4',
  },
  tableCellEvents: {
    fontSize: '32px',
    fontWeight: 900,
  },
  tableCellSuspiciousEvents: {
    fontSize: '30px',
    fontWeight: 900,
    color: 'rgba(243, 174, 104, 1)',
  },
  tableCellAlerts: {
    fontSize: '28px',
    fontWeight: 900,
    color: 'rgb(71, 103, 171)',
  },
  tableCellIncidents: {
    fontSize: '24px',
    color: 'rgb(255, 68, 85)',
    fontWeight: 900,
  },
  utcNotice: {
    flexBasis: '100%',
    color: '#545454',
    fontSize: '.7rem',
  },
  dataSinceNotice: {
    marginTop: theme.spacing(1),
    color: '#545454',
    fontSize: '.7rem',
  },
  noDataMessage: {
    marginTop: theme.spacing(1),
    color: '#C4C4C4',
    fontSize: '.7rem',
  },
  incidentsLink: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  loadingWrap: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(255,255,255,.3)',
  },
  searchContainer: {
    color: '#58595B',
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput: {
    height: theme.spacing(4),
    borderRadius: '3px',
    border: '1px solid #B3B3B3',
    paddingLeft: '8px',
    '&:focus': {
      outline: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subheader: {
    fontSize: '20px',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  menu: {
    width: '388px',
    padding: theme.spacing(2),
    '&>.MuiList-padding': {
      padding: '0px',
    },
    '&>.MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  scrollContainer: {
    maxHeight: '220px',
    overflowY: 'auto',
  },
  menuItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&>.MuiMenuItem-root': {
      paddingLeft: '0px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  noOptionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#B3B3B3',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tenantFilterFooter: {
    '&>*': {
      marginRight: '10px',
    },
  },
});
