import { getAgreement } from '../services/activation/api';
import { AgreementLanguage, AgreementMIME, AgreementTitles } from '../services/activation/types';

export const getValueOrDefault = (obj: any, key: string, defaultValue: any) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  (obj && obj[key] ? obj[key] : defaultValue);

type lang = 'ru' | 'en';

export const downloadAgreement = (
  lang: lang, title?: AgreementTitles | string, lastAcceptedBy?: string | null,
) => async () => {
  if (!title) {
    return;
  }

  const { body } = await getAgreement({
    language: AgreementLanguage[lang],
    mime: AgreementMIME.PDF,
    title,
    lastAcceptedBy,
  });

  const link = document.createElement('a');
  link.href = `data:application/pdf;base64,${body}`;
  link.setAttribute('download', `${title}.pdf`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
