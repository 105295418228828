import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { MAIN_PAGE, AUTH_LOGIN_PAGE } from 'global/routes';
import { PRELOGIN_LOCATION } from 'global/storage';
import { useSelector } from 'store/hook';
import { useDispatch } from 'react-redux';
import { loginRequest, userCreateRequest } from 'services/auth/slice';
import { load, save } from 'utils/storage';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import incidentsImg from 'assets/images/incidents.png';
import monitoringImg from 'assets/images/monitoring-summary.png';

import { LogoHeader } from './components/LogoHeader';
import { ProductOverviewSection } from './components/ProductOverviewSection';
import { FeatureSection } from './components/FeatureSection';
import { NoteSection } from './components/NoteSection';
import { FooterSection } from './components/FooterSection';
import styles from './styles';

const useStyles = makeStyles(styles);

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const preLoginLocation = load(PRELOGIN_LOCATION, true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isAuthenticated, error } = useSelector(state => state.auth);

  if (error) {
    return <Redirect to={AUTH_LOGIN_PAGE} />;
  }

  if (isAuthenticated) {
    if (preLoginLocation) {
      save(PRELOGIN_LOCATION, null, true);
      history.push(preLoginLocation);
      return null;
    }
    return <Redirect to={MAIN_PAGE} />;
  }

  const onSignInClick = () => {
    dispatch(loginRequest());
  };

  const onUserCreateClick = () => {
    dispatch(userCreateRequest());
  };

  const featureSectionProps = [{
    outerClassname: classes.outerContainer,
    innerClassname: classes.innerContainer,
    contentKeys: {
      title: '24x7 monitoring',
      list: [
        'Fast efficient detection',
        'No time-wasting false positives',
        'Unique insights into your attackers',
        'Real-time visibility',
      ],
      image: { src: monitoringImg, alt: 'Monitoring' },
    },
  }, {
    outerClassname: classNames(classes.outerContainer, classes.greyBackground),
    innerClassname: classNames(classes.innerContainer, classes.reverse),
    contentKeys: {
      title: 'Cut through the noise',
      list: [
        'The reassurance of knowing',
        'Reduced overall security costs',
        'Maximized value',
        'All the major advantages',
      ],
      image: { src: incidentsImg, alt: 'Incidents' },
    },
  }];

  return (
    <div>
      <LogoHeader
        onSignInClick={onSignInClick}
        outerClassname={classes.outerContainer}
        innerClassname={classes.miniInnerContainer}
      />
      <ProductOverviewSection
        outerClassname={classes.outerContainer}
        innerClassname={classes.innerContainer}
        onUserCreateClick={onUserCreateClick}
        onSignInClick={onSignInClick}
      />
      {featureSectionProps.map((sectionProps) => (
        <FeatureSection key={sectionProps.contentKeys.title} {...sectionProps} />
      ))}
      <NoteSection
        onSignInClick={onUserCreateClick}
        outerClassname={classes.outerContainer}
        innerClassname={classes.innerContainer}
      />
      <FooterSection
        outerClassname={classes.outerContainer}
        innerClassname={classes.innerContainer}
      />
    </div>
  );
};
