import React from 'react';
import { useTranslation } from 'react-i18next';
import { KLButton } from 'components/KLButton';
import { BottomDrawer } from 'components/BottomDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { ResponseActionsProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const ResponseActions = (props: ResponseActionsProps) => {
  const { selectedCount, onDecline, onConfirm, isLoading } = props;
  const { t } = useTranslation('IncidentResponses');
  const classes = useStyles();

  return (
    <BottomDrawer
      open={selectedCount > 0 && !isLoading}
    >
      {t('Selected')}: {selectedCount}
      <KLButton
        variant="contained"
        color="primary"
        onClick={onConfirm}
        className={classes.firstBtn}
      >
        {t('Confirm')}
      </KLButton>
      <KLButton
        variant="outlined"
        color="primary"
        onClick={onDecline}
        className={classes.btn}
      >
        {t('Decline')}
      </KLButton>
    </BottomDrawer>
  );
};
