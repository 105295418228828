import React, { useRef } from 'react';

type FileUploadProps = {
  className?: string;
  icon?: string;
  title?: string;
  addFiles?: (files: FileList) => void;
  files: File[];
  multiple?: boolean;
};

export const FileUpload: React.FC<FileUploadProps> = props => {
  const { className, icon, title, addFiles, multiple = false, files: filesProp } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!inputRef || !inputRef.current) return;

    inputRef.current.click();
  };

  const handleChange = (files: FileList | null) => {
    if (files && files.length && addFiles) {
      addFiles(files);
    }
  };

  const handleClickInput = (target: any) => { // todo any
    // clear input for fix bug #4315926
    if (!filesProp.length && target.value) {
      target.value = null;
    }
  };

  // TODO: Comment this due to safari bug and only one file per message limit
  // useEffect(() => {
  //   if (inputRef.current) {
  //     const dt = new DataTransfer();
  //     for (const file of files) {
  //       dt.items.add(file);
  //     }
  //     inputRef.current.files = dt.files;
  //   }
  // }, [files]);

  return (
    <div
      aria-label="rdw-file-upload"
      className={className}
      onClick={handleClick}
      title={title}
    >
      <img src={icon} alt="" />
      <input
        ref={inputRef}
        type="file"
        onChange={(ev) => handleChange(ev.target.files)}
        onClick={(ev) => handleClickInput(ev.target)}
        style={{ display: 'none' }}
        multiple={multiple}
      />
    </div>
  );
};
