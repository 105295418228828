import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { addNotification } from 'services/system/slice';
import {
  error,
  getGeneralConfigRequest,
  getGeneralSuccess,
  setGeneralConfigRequest,
  setGeneralSuccess,
  start,
} from './slice';
import {
  getGeneralConfig,
  setGeneralConfig,
} from './api';
import {
  GeneralConfigGetResponse,
  GeneralConfigSetPayload,
  GeneralConfigSetResponse,
} from './types';

function* getGeneral(action: PayloadAction<string>) {
  yield put(start());
  try {
    const generalConfig: GeneralConfigGetResponse = yield call(getGeneralConfig, action.payload);
    yield put(getGeneralSuccess(generalConfig));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* setGeneral(action: PayloadAction<GeneralConfigSetPayload>) {
  yield put(start());
  try {
    const { message, ...restPayload } = action.payload;
    const updatedGeneralConfig: GeneralConfigSetResponse = yield call(setGeneralConfig, restPayload);
    yield put(setGeneralSuccess(updatedGeneralConfig));
    yield put(addNotification({ message, options: { variant: 'success' } }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const settingsSaga = [
  takeLatest(getGeneralConfigRequest.type, getGeneral),
  takeLatest(setGeneralConfigRequest.type, setGeneral),
];
