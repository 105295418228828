import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { KLLogo } from 'assets/icons/KLLogo';
import { KLButton } from 'components/KLButton';
import styles from './styles';

const useStyles = makeStyles(styles);

interface LogoHeaderProps {
  onSignInClick: () => void;
  outerClassname?: string;
  innerClassname?: string;
}

export const LogoHeader: React.FC<LogoHeaderProps> = ({ onSignInClick, outerClassname, innerClassname }) => {
  const classes = useStyles();
  const { t } = useTranslation('LoginPage');

  return (
    <Grid container className={outerClassname}>
      <Grid container className={innerClassname}>
        <Grid item xs={12} sm={6} md={6} className={classes.logoBlock}>
          <a href="https://www.kaspersky.com"><KLLogo /></a>
        </Grid>
        <Grid item sm={6} md={6} justify="flex-end" className={classes.buttonBlock}>
          <KLButton
            variant="outlined"
            color="primary"
            onClick={onSignInClick}
          >
            {t('Sign In')}
          </KLButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
