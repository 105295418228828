import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  deleteIcon: {
    fill: '#000000',
    opacity: 0,
    '.MuiTableRow-root:hover &': {
      opacity: 1,
    },
  },
});
