import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  image: {
    marginBottom: theme.spacing(5),
  },
  heading: {
    marginBottom: theme.spacing(3),
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
});
