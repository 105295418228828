import React, { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { useDebouncedCallback } from 'use-lodash-debounce';
import { KLButton } from 'components/KLButton';
import { KLSelect } from 'components/KLSelect';
import { KLTextField } from 'components/KLTextField';
import {
  SUPPORT_URL_EN,
  SUPPORT_URL_RU,
  PRIVATE_KSN_URL_EN,
  PRIVATE_KSN_URL_RU,
  IS_HIDE_LATAM,
  IS_HIDE_REST_WORLD_REGION,
} from 'global/environments';
import { getLang } from 'utils/i18n';
import { getValueOrDefault } from 'utils/common';
import {
  validateRequest,
  setRegion,
  setActivationCode,
  toNextStep,
  confirmRequest,
} from 'services/activation/slice';
import { AgreementOffer, AgreementTitles } from 'services/activation/types';
import { isSa } from 'utils/help';
import { CheckBoxField } from '../CheckBoxField';
import styles from './styles';

const useStyles = makeStyles(styles);
const EUROPE = 'europe';
const CANADA = 'canada';
const RUSSIA = 'russia';
const LATAM = 'latam';
const KSA = 'ksa';
const REST = 'rest';

const debounceCallback = (func: any, value: any) => func(value);
const debounceTime = 300;

function getHelperText(t: (s: string) => ReactNode, nonValidError: boolean, expectedOrigin: string | undefined) {
  if (nonValidError) {
    return t('Invalid code');
  }
  if (expectedOrigin !== undefined) {
    return (
      <Trans
        defaults="ActivationPage:Wrong server"
        values={{ correctServer: expectedOrigin || 'mdr.kaspersky.com' }}
        components={[
          (
            <a
              href={`https://${expectedOrigin}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              placeholder
            </a>
          ),
        ]}
      />
    );
  }
  return null;
}

function getRegionOptionsAndDefaultValue(
  licenseRegions: string[],
  t: (s: string) => string,
): [{ value: string; label: string }[], string] {
  const regions = isSa()
    ? [
      {
        value: KSA,
        label: `${t(KSA)} — ${t('telemetry')}${t('ksa')}`,
      },
    ]
    : [
      {
        value: EUROPE,
        label: `${t(EUROPE)} — ${t('telemetry')}${t('neu')}`,
      },
      {
        value: CANADA,
        label: `${t(CANADA)} — ${t('telemetry')}${t('neu')}`,
      },
      {
        value: RUSSIA,
        label: `${t(RUSSIA)} — ${t('telemetry')}${t('rus')}`,
      },
    ];

  if (!IS_HIDE_REST_WORLD_REGION && !isSa()) {
    regions.push({
      value: REST,
      label: `${t(REST)} — ${t('telemetry')}${t('rus')}`,
    });
  }

  if (!IS_HIDE_LATAM && !isSa()) {
    regions.push({
      value: LATAM,
      label: `${t(LATAM)} — ${t('telemetry')}${t('br')}`,
    });
  }

  const result = regions.filter(
    ({ value }) => licenseRegions.includes(value),
  );

  if (result.length > 1) {
    result.push({
      value: '',
      label: t('Select your country'),
    });
  }
  return [result, result.length === 1 ? result[0].value : ''];
}

export const EnterCodeStep = () => {
  const { t } = useTranslation('ActivationPage');
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    expectedOrigin,
    agreements,
    activatedRegion,
    isLoading,
    region,
    validCode,
    regions: licenseRegions,
  } = useSelector(state => state.activation);
  const canActivateInTheSameRegion = licenseRegions.includes(activatedRegion);
  const [isFirstChecked, setFirstChecked] = useState(false);
  const onChangeFirstHandler = () => setFirstChecked(!isFirstChecked);

  const submitHandler = () => {
    dispatch(toNextStep(code));
  };

  const submitHandlerActivatedRegion = () => {
    dispatch(setRegion(region || activatedRegion));
    if (
      agreements && agreements.find(
        (agr: AgreementOffer) => Object.values(AgreementTitles).includes(agr.meta.title as AgreementTitles),
      )
    ) {
      dispatch(toNextStep(code));
    } else {
      dispatch(setActivationCode(code));
      dispatch(confirmRequest());
    }
  };
  const debouncedValidate = useDebouncedCallback(debounceCallback, debounceTime);

  const changeCodeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = e;
    setCode(value);
    if (value) {
      debouncedValidate(dispatch, validateRequest(value));
    }
  };

  const onChangeRegionHandler = (event: any) => {
    const country = (event.target as HTMLInputElement).value;
    dispatch(setRegion(country));
  };

  const nonValidError = (!!code && validCode === false);

  const [regionOptions, defaultRegion] = useMemo(
    () => getRegionOptionsAndDefaultValue(licenseRegions, t),
    [licenseRegions],
  );

  useEffect(() => {
    dispatch(setRegion(defaultRegion));
  }, [regionOptions]);

  const regionToTelemetry = {
    [EUROPE]: t('neu'),
    [CANADA]: t('neu'),
    [LATAM]: t('br'),
    [KSA]: t('ksa'),
    [RUSSIA]: t('rus'),
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography className={classes.title} variant="h1" component="h1">{t('Welcome!')}</Typography>
        <Typography className={classes.topTip} component="p">{t('Before you start using the service')}</Typography>
        <Box>
          <Box>
            <KLTextField
              data-testid="ActivationCodeField"
              className={classes.input}
              variant="outlined"
              label={t('Activation code')}
              onChange={changeCodeHandler}
              value={code}
              error={nonValidError || expectedOrigin !== undefined}
              helperText={getHelperText(t, nonValidError, expectedOrigin)}
            />
          </Box>
          {(!activatedRegion) ? (
            <>

              {
                validCode && regionOptions.length > 0 && (
                  <Box className={classes.checkbox}>
                    {t('Resident of')} {' '}
                    <KLSelect
                      displayEmpty
                      value={region}
                      items={regionOptions}
                      onChange={onChangeRegionHandler}
                    />
                  </Box>
                )
              }
              {
                validCode && region !== '' && (
                  <CheckBoxField onChange={onChangeFirstHandler} checked={isFirstChecked} disabled={isLoading}>
                    {t('I am aware region',
                      { region: getValueOrDefault(regionToTelemetry, region, t('rus')) })}
                  </CheckBoxField>
                )
              }
              <Box>
                <div style={{ height: '20px' }} />
                <Trans
                  defaults="ActivationPage:When you re-activate the service"
                  values={{ configurePrivateKSN: t('configure Private KSN') }}
                  components={[
                    (
                      <a
                        href={getLang() === 'ru' ? PRIVATE_KSN_URL_RU : PRIVATE_KSN_URL_EN}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        univers
                      </a>
                    ),
                  ]}
                />
              </Box>
              <Box>
                <div style={{ height: '20px' }} />
                <KLButton
                  className={classes.btn}
                  variant="contained"
                  color="primary"
                  onClick={submitHandler}
                  disabled={!validCode || !region || !isFirstChecked || expectedOrigin != null}
                  isLoading={isLoading}
                >
                  {t('Continue')}
                </KLButton>
              </Box>
            </>
          ) : (
            <Box>
              {
                (!canActivateInTheSameRegion && validCode) && (
                  <>
                    <Box>
                      <div style={{ height: '20px' }} />
                      <Trans
                        defaults="ActivationPage:Changed region"
                        values={{ placeholder: t('Changed region link text') }}
                        components={[
                          (
                            <a
                              href={t('Changed region link')}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              univers
                            </a>
                          ),
                        ]}
                      />
                    </Box>
                    {
                      regionOptions.length > 0 
                        ? (
                          <Box className={classes.checkbox}>
                            {t('Resident of')} {' '}
                            <KLSelect
                              displayEmpty
                              value={region}
                              items={regionOptions}
                              onChange={onChangeRegionHandler}
                            />
                          </Box>
                        )
                        : undefined
                    }
                  </>
                )
              }
              <div style={{ height: '20px' }} />
              <KLButton
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={submitHandlerActivatedRegion}
                disabled={!validCode}
                isLoading={isLoading}
              >
                {t('Continue')}
              </KLButton>
            </Box>
          )}
        </Box>
        <Typography className={classes.bottomTip} component="p">
          <Trans
            defaults="ActivationPage:The activation code can"
            values={{ customerSupport: t('Customer support') }}
            components={[
              (
                <a
                  href={getLang() === 'ru' ? SUPPORT_URL_RU : SUPPORT_URL_EN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  univers
                </a>
              ),
            ]}
          />
        </Typography>
      </Box>
    </Box>
  );
};
