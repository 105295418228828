import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enData, ruData } from 'utils/extImports';
import { IS_DEV_ENV, SKIP_TRANSLATION_DEBUG } from 'global/environments';

const resources = {
  en: enData,
  ru: ruData,
};

export default i18n
  .use(ICU)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: IS_DEV_ENV && !SKIP_TRANSLATION_DEBUG,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const changeLanguage = (lng: string) => () => i18n.changeLanguage(lng);
export const getLang = () => ((i18n.language === 'ru' || i18n.language === 'ru-RU') ? 'ru' : 'en');
export const getT = (key: string, options?: any) => i18n.t(key, options);
export const getLocale = (): 'ru-RU' | 'en-US' => {
  if (i18n.language === 'ru' || i18n.language === 'ru-RU') {
    return 'ru-RU';
  }
  return 'en-US';
};
