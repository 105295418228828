import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { permissions } from 'utils/extImports';
import template from 'string-placeholder';
import { ORIGIN_MISMATCH } from 'global/errors';
import { get } from './api';
import { PermissionsListSagaPayload, PermissionAPIPayloadItem } from './types';

import {
  start,
  success,
  error,
  listRequest,
  originMismatch,
} from './slice';

const buildRequestData = ({ userId, clientId }: PermissionsListSagaPayload) => {
  const keys = Object.keys(permissions);
  const payload = Object.values(permissions as PermissionAPIPayloadItem[])
    .reduce((acc: PermissionAPIPayloadItem[], { action, target }) => (
      [
        ...acc,
        {
          action,
          target: template(target, {
            org_id: clientId, user_id: userId, // eslint-disable-line @typescript-eslint/camelcase
          }, {
            before: '{',
            after: '}',
          }),
        },
      ]
    ), []);

  return { keys, payload };
};

function* requestList(action: PayloadAction<PermissionsListSagaPayload>) {
  yield put(start());
  const { keys, payload } = buildRequestData(action.payload);
  try {
    const response = yield call(get, payload);
    const permissions = keys.reduce((acc, name, index) => ({
      ...acc,
      [name]: response[index] as boolean,
    }), {});
    yield put(success(permissions));
  } catch (err) {
    if (err.message === ORIGIN_MISMATCH) {
      yield put(originMismatch(err.data.expectedOrigin));
    } else {
      const message = err.message || err;
      yield put(error(message));
    }
  }
}

export const permissionsSaga = [
  takeLatest(listRequest.type, requestList),
];
