export const storageName = 'MDR_CONSOLE_SETTINGS';

const getStorage = (useSessionStorage: boolean) => {
  const storage = useSessionStorage ? window.sessionStorage : window.localStorage;
  return storage || {
    getItem: () => undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeItem: () => {},
  };
};

const load = (stateName: string | null, useSessionStorage: boolean | Storage = false) => {
  let store;

  if (typeof useSessionStorage === 'object') {
    store = useSessionStorage;
  } else {
    store = getStorage(useSessionStorage);
  }

  try {
    const res = JSON.parse(store.getItem(storageName) || '') || {};
    return stateName ? res[stateName] || null : res;
  } catch (e) {
    return null;
  }
};

const save = (key: string, value: any, useSessionStorage = false) => {
  if (!key || typeof key !== 'string' || value === undefined) {
    return false;
  }

  const store = getStorage(useSessionStorage);

  try {
    const data = load(null, store) || {};
    data[key] = value;
    store.setItem(storageName, JSON.stringify(data));
    return true;
  } catch (e) {
    return false;
  }
};

const remove = (key: string, useSessionStorage = false) => {
  if (!key || typeof key !== 'string') {
    return false;
  }

  const store = getStorage(useSessionStorage);

  try {
    const data = load(null, store);
    delete data[key];
    store.setItem(storageName, JSON.stringify(data));
    return true;
  } catch (e) {
    return false;
  }
};

export { save, load, remove };
