import { postRequest } from 'utils/http';
import {
  NotificationGetResponse,
  NotificationSetPayload,
  NotificationTelegramResponse,
  NotificationTelegramResetPayload,
  NotificationTelegramResetResponse,
} from './types';

const url = 'notifications';

export function getChannelsSettings(payload: any) {
  return postRequest(`${url}/getChannels`, { userId: payload });
}

export function setChannelsSettings(payload: any) {
  return postRequest(`${url}/setChannels`, { ...payload });
}

export function getNotifications(userId: string) {
  return postRequest<NotificationGetResponse>(`${url}/get`, {
    userId,
  });
}

export function setNotifications(payload: NotificationSetPayload) {
  return postRequest<string>(`${url}/set`, {
    ...payload,
  });
}

export function getTelegramConfig(userId: string) {
  return postRequest<NotificationTelegramResponse>(`${url}/get-telegram`, {
    userId,
  });
}

export function resetTelegramActivation(payload: NotificationTelegramResetPayload) {
  return postRequest<NotificationTelegramResetResponse>(`${url}/reset-telegram`, {
    ...payload,
  });
}
