import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  root: {
    borderBottom: 'none',
    paddingTop: 10,
    paddingBottom: 10,
  },
  label: {
    minWidth: 300,
    width: '40%',
    paddingLeft: 0,
  },
  value: {
    minWidth: 300,
    width: '60%',
  },
});
