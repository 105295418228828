export enum MitreType {
  TACTICS = 'tactics',
  TECHNIQUES = 'techniques'
}

export type MitreDescrLinkProps = {
  name: string;
  type: MitreType;
}

export type Mapping = {
  [key: string]: string;
}
