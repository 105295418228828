import React from 'react';
import { Drawer, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import styles from './styles';
import { BottomDrawerProps } from './types';

const useStyles = makeStyles(styles);

export const BottomDrawer = (props: BottomDrawerProps) => {
  const { isSideBarOpened } = useSelector(state => state.system);
  const classes = useStyles(isSideBarOpened);

  const {
    children,
    open,
  } = props;

  return (
    <Drawer
      anchor="bottom"
      open={open}
      ModalProps={{
        hideBackdrop: true,
        disableAutoFocus: true,
        disableEnforceFocus: true,
      }}
      classes={{
        modal: classes.drawer,
        paper: classes.paper,
      }}
    >
      <Box className={classes.container}>
        {children}
      </Box>
    </Drawer>
  );
};
