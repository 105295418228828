import { Theme, createStyles } from '@material-ui/core/styles';
import { ListItemLinkProps } from './index';

export default (theme: Theme) => createStyles({
  badgeRoot: {
    display: 'flex',
  },
  badge: ({ countColor, countBackgroundColor, dense }: ListItemLinkProps) => ({
    top: '50%',
    right: theme.spacing(1),
    transform: dense ? 'translateY(-16px)' : 'translateY(-50%)',
    borderRadius: dense ? '50%' : 2,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
    color: countColor,
    backgroundColor: countBackgroundColor,
  }),
  text: ({ count }: ListItemLinkProps) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: count && count > 0 ? theme.spacing(4) : 0,
  }),
});
