import { useSelector } from 'store/hook';
import { ShowOnPermissionProps, Permission } from './types';

export const ShowOnPermission = (props: ShowOnPermissionProps) => {
  const { children, permissions, fallback = null, every = false, emptyIsTrue = true } = props;
  const { dict } = useSelector(state => state.permissions);

  const checkPermission = (permissionName: Permission) => {
    if (typeof permissionName !== 'string' || !permissionName) {
      return emptyIsTrue;
    }
    return dict[permissionName];
  };

  const hasPesmission = every
    ? permissions.every(checkPermission)
    : permissions.some(checkPermission);

  return hasPesmission ? children : fallback;
};
