import React from 'react';
import { MessageLayer } from 'components/MessageLayer';
import { ErrorPageNotFound } from 'assets/icons/ErrorPageNotFound';
import { MAIN_PAGE } from 'global/routes';
import { LinkButton } from 'components/LinkButton';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const useStyles = makeStyles(styles);

export const NotFoundErrorPage: React.FC = () => {
  const { t } = useTranslation('404ErrorPage');
  const classes = useStyles();

  const controls = (
    <LinkButton
      variant="outlined"
      color="primary"
      to={MAIN_PAGE}
    >
      {t('Go to the main page')}
    </LinkButton>
  );

  return (
    <MessageLayer
      title={t('404 page not found')}
      icon={<ErrorPageNotFound className={classes.icon} />}
      controls={controls}
    />
  );
};
