import { Mapping } from './types';

export const tacticsMapping: Mapping = {
  'Command and control': 'TA0011',
  'Credential access': 'TA0006',
  'Defense evasion': 'TA0005',
  'Initial access': 'TA0001',
  'Lateral movement': 'TA0008',
  'Privilege escalation': 'TA0004',
  Collection: 'TA0009',
  Discovery: 'TA0007',
  Execution: 'TA0002',
  Exfiltration: 'TA0010',
  Impact: 'TA0040',
  Persistence: 'TA0003',
};

export const techniquesMapping: Mapping = {
  'Access Token Manipulation': 'T1134',
  'Accessibility Features': 'T1015',
  'Account Discovery': 'T1087',
  'Account Manipulation': 'T1098',
  'AppCert DLLs': 'T1182',
  'AppInit DLLs': 'T1103',
  'Application Shimming': 'T1138',
  'Audio Capture': 'T1123',
  'Authentication Package': 'T1131',
  'Automated Collection': 'T1119',
  'Automated Exfiltration': 'T1020',
  'Binary Padding': 'T1009',
  'BITS Jobs': 'T1197',
  'Browser Extensions': 'T1176',
  'Bypass User Account Control': 'T1088',
  'Change Default File Association': 'T1042',
  'Clear Command History': 'T1146',
  'Clipboard Data': 'T1115',
  'Code Signing': 'T1116',
  'Command-Line Interface': 'T1059',
  'Commonly Used Port': 'T1043',
  'Compile After Delivery': 'T1500',
  'Compiled HTML File': 'T1223',
  'Component Object Model Hijacking': 'T1122',
  'Component Object Model and Distributed COM': 'T1175',
  'Connection Proxy': 'T1090',
  'Control Panel Items': 'T1196',
  'Create Account': 'T1136',
  'Credential Dumping': 'T1003',
  'Credentials in Files': 'T1081',
  'Credentials in Registry': 'T1214',
  'Custom Command and Control Protocol': 'T1094',
  'DLL Search Order Hijacking': 'T1038',
  'Data Compressed': 'T1002',
  'Data Destruction': 'T1485',
  'Data Encoding': 'T1132',
  'Data Encrypted for Impact': 'T1486',
  'Data Encrypted': 'T1022',
  'Data from Local System': 'T1005',
  'Data Obfuscation': 'T1001',
  'Deobfuscate/Decode Files or Information': 'T1140',
  'Direct Volume Access': 'T1006',
  'Disabling Security Tools': 'T1089',
  'Disk Content Wipe': 'T1488',
  'Disk Structure Wipe': 'T1487',
  'Domain Trust Discovery': 'T1482',
  'Drive-by Compromise': 'T1189',
  'Dynamic Data Exchange': 'T1173',
  'Email Collection': 'T1114',
  'Execution through API': 'T1106',
  'Execution through Module Load': 'T1129',
  'Exfiltration Over Alternative Protocol': 'T1048',
  'Exfiltration Over Physical Medium': 'T1052',
  'Exploitation for Client Execution': 'T1203',
  'Exploitation for Credential Access': 'T1212',
  'Exploitation for Privilege Escalation': 'T1068',
  'Exploitation of Remote Services': 'T1210',
  'Fallback Channels': 'T1008',
  'File Deletion': 'T1107',
  'File System Permissions Weakness': 'T1044',
  'File and Directory Discovery': 'T1083',
  'File and Directory Permissions Modification': 'T1222',
  'Hidden Files and Directories': 'T1158',
  'Hidden Users': 'T1147',
  'Image File Execution Options Injection': 'T1183',
  'Indicator Removal on Host': 'T1070',
  'Indirect Command Execution': 'T1202',
  'Input Capture': 'T1056',
  'Install Root Certificate': 'T1130',
  'LSASS Driver': 'T1177',
  'Logon Scripts': 'T1037',
  'Modify Existing Service': 'T1031',
  'Modify Registry': 'T1112',
  'Multi-Stage Channels': 'T1104',
  'Multi-hop Proxy': 'T1188',
  'NTFS File Attributes': 'T1096',
  'Netsh Helper DLL': 'T1128',
  'Network Share Connection Removal': 'T1126',
  'Network Share Discovery': 'T1135',
  'Network Sniffing': 'T1040',
  'New Service': 'T1050',
  'Obfuscated Files or Information': 'T1027',
  'Office Application Startup': 'T1137',
  'Pass the Hash': 'T1075',
  'Pass the Ticket': 'T1097',
  'Password Filter DLL': 'T1174',
  'Password Policy Discovery': 'T1201',
  'Path Interception': 'T1034',
  'Peripheral Device Discovery': 'T1120',
  'Permission Groups Discovery': 'T1069',
  'Port Monitors': 'T1013',
  'PowerShell Profile': 'T1504',
  'Process Discovery': 'T1057',
  'Process Doppelgänging': 'T1186',
  'Process Injection': 'T1055',
  'Query Registry': 'T1012',
  'Registry Run Keys / Startup Folder': 'T1060',
  'Regsvcs/Regasm': 'T1121',
  'Remote Access Tools': 'T1219',
  'Remote Desktop Protocol': 'T1076',
  'Remote File Copy': 'T1105',
  'Remote Services': 'T1021',
  'Remote System Discovery': 'T1018',
  'Replication Through Removable Media': 'T1091',
  'Resource Hijacking': 'T1496',
  'Runtime Data Manipulation': 'T1494',
  'SID-History Injection': 'T1178',
  'SIP and Trust Provider Hijacking': 'T1198',
  'Scheduled Task': 'T1053',
  'Screen Capture': 'T1113',
  'Security Software Discovery': 'T1063',
  'Security Support Provider': 'T1101',
  'Server Software Component': 'T1505',
  'Service Execution': 'T1035',
  'Service Registry Permissions Weakness': 'T1058',
  'Shortcut Modification': 'T1023',
  'Signed Binary Proxy Execution': 'T1218',
  'Signed Script Proxy Execution': 'T1216',
  'Software Packing': 'T1045',
  'Spearphishing Attachment': 'T1193',
  'Spearphishing Link': 'T1192',
  'Standard Application Layer Protocol': 'T1071',
  'Standard Non-Application Layer Protocol': 'T1095',
  'Startup Items': 'T1165',
  'Stored Data Manipulation': 'T1492',
  'Supply Chain Compromise': 'T1195',
  'System Information Discovery': 'T1082',
  'System Network Configuration Discovery': 'T1016',
  'System Network Connections Discovery': 'T1049',
  'System Owner/User Discovery': 'T1033',
  'System Service Discovery': 'T1007',
  'System Time Discovery': 'T1124',
  'Template Injection': 'T1221',
  'Time Providers': 'T1209',
  'Transmitted Data Manipulation': 'T1493',
  'Trusted Developer Utilities': 'T1127',
  'Uncommonly Used Port': 'T1065',
  'User Execution': 'T1204',
  'Valid Accounts': 'T1078',
  'Virtualization/Sandbox Evasion': 'T1497',
  'Web Service': 'T1102',
  'Web Shell': 'T1100',
  'Windows Admin Shares': 'T1077',
  'Windows Management Instrumentation Event Subscription': 'T1084',
  'Windows Management Instrumentation': 'T1047',
  'Windows Remote Management': 'T1028',
  'Winlogon Helper DLL': 'T1004',
  'XSL Script Processing': 'T1220',
  Bootkit: 'T1067',
  CMSTP: 'T1191',
  DCShadow: 'T1207',
  InstallUtil: 'T1118',
  Kerberoasting: 'T1208',
  Masquerading: 'T1036',
  Mshta: 'T1170',
  PowerShell: 'T1086',
  Regsvr32: 'T1117',
  Rootkit: 'T1014',
  Rundll32: 'T1085',
  Screensaver: 'T1180',
  Scripting: 'T1064',
  Timestomp: 'T1099',
};
