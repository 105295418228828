import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import {
  agreementRequest,
  agreementDecideRequest,
  agreementDecideSuccess,
  agreementStart,
} from 'services/activation/slice';
import { AgreementLanguage, AgreementMIME, AgreementOffer } from 'services/activation/types';
import { Agreement } from 'components/Agreement';
import { KLButton } from 'components/KLButton';
import { usePermissions } from 'components/Permissions/hooks';
import { PageLayer } from 'components/PageLayer';
import { KLCheckBox } from 'components/KLCheckBox';
import { getLang } from 'utils/i18n';
import { downloadAgreement } from 'utils/common';
import { ReactComponent as Image } from './icon.svg';
import styles from './styles';

type AgreementDialogProps = {
  agreements: AgreementOffer[];
};

const useStyles = makeStyles(styles);

export const AgreementDialog = (props: AgreementDialogProps) => {
  const { agreements } = props;
  const classes = useStyles();
  const { t } = useTranslation(['Agreement', 'ActivationPage', 'LicenseStatePage']);
  const dispatch = useDispatch();
  const { agreement, isLoading, showAgreement } = useSelector(state => state.activation);
  const [editLicensePerm] = usePermissions(['edit_organization_license']);
  const [isAgreeWithAgreement, setIsAgreeWithAgreement] = useState(false);
  const lang = getLang();

  useEffect(() => {
    if (!agreements?.length || !showAgreement) {
      return;
    }

    const agreement = agreements[0];
    dispatch(agreementRequest({
      language: AgreementLanguage[lang],
      mime: AgreementMIME.HTML,
      title: agreement.meta.title,
    }));
  }, [agreements, showAgreement, lang]);

  const handleContinue = () => {
    dispatch(agreementStart());
  };

  const handleConfirm = () => {
    if (!agreement) {
      return;
    }

    dispatch(agreementDecideRequest({
      agreementId: agreement.meta.agreementId,
      accepted: true,
    }));
  };

  const handleDecline = () => {
    dispatch(agreementDecideSuccess(null));
    setIsAgreeWithAgreement(false);
  };

  return (
    <>
      {showAgreement ? (
        <PageLayer flex>
          <Agreement
            title={agreement?.meta.title ? t(`ActivationPage:${agreement?.meta.title}`) : ''}
            text={agreement?.body || ''}
          />
          <KLButton
            className={classes.downloadBtn}
            color="primary"
            variant="text"
            onClick={downloadAgreement(lang, agreement?.meta.title)}
            disabled={!agreement}
          >
            {t('Download agreement')}
          </KLButton>
          <FormControlLabel
            classes={{ root: classes.labelRoot, label: classes.labelLabel }}
            control={(
              <KLCheckBox
                color="primary"
                name="agreeWithAgreement"
                checked={isAgreeWithAgreement}
                onChange={() => setIsAgreeWithAgreement(prevState => !prevState)}
                disabled={!agreement}
              />
            )}
            label={t('I confirm that I have fully read')}
          />
          <Box className={classes.controls}>
            <KLButton
              variant="outlined"
              color="primary"
              className={classes.btn}
              onClick={handleDecline}
              disabled={isLoading}
            >
              {t('ActivationPage:Decline')}
            </KLButton>
            <KLButton
              disabled={!(isAgreeWithAgreement && agreement)}
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              {t('ActivationPage:Confirm')}
            </KLButton>
          </Box>
        </PageLayer>
      ) : (
        <Grid item xs={12}>
          <Box className={classes.root}>
            <Image className={classes.image} width={170} height={200} />
            <Typography className={classes.heading} component={'h1'} variant={'h2'}>
              {t('You need to confirm agreement')}
            </Typography>
            <Typography className={classes.text}>{t('Please, confirm agreement for further work')}</Typography>
            <Typography className={classes.text}>
              {t('Unconfirmed agreements', { count: agreements?.length || 0 })}
            </Typography>
            <KLButton
              className={classes.confirmBtn}
              color="primary"
              variant="contained"
              onClick={handleContinue}
              disabled={!editLicensePerm}
            >
              {t('Confirm')}
            </KLButton>
            {!editLicensePerm && <Typography>{t('Only administrator can confirm')}</Typography>}
          </Box>
        </Grid>
      )}
    </>
  );
};
