import React, { ReactNode } from 'react';
import { FormGroup } from '@material-ui/core';

type ControlsProps = {
  className?: string;
  controls: ReactNode;
};

export const Controls: React.FC<ControlsProps> = props => {
  const { className, controls } = props;

  return (
    <FormGroup className={className} row>
      {controls}
    </FormGroup>
  );
};
