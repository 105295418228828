import { KLDate } from 'components/KLDatePicker';
import { IncidentFilters } from 'services/incidents/types';
import { EntityColumn } from 'store/slice';
import { TProtobufDate } from '../../utils/date';

export type AssetListPayload = {
  paging: {
    page: number;
    pageSize: number;
  };
  filter: AssetListFilters;
  searchPhrase: string;
};

export type AssetIncidentListPayload = {
  paging: {
    page: number;
    pageSize: number;
  };
  filter: IncidentFilters;
}

export type AssetListSagaPayload = {
  paging: {
    page: number;
    pageSize: number;
  };
  filter: AssetListSagaFilters;
  searchPhrase: string;
};

export enum AssetStatus {
  Ok,
  Warning,
  Critical,
  Blocked,
  Offline,
  Absent
}

export type AssetStatusReasons =
  | 'COMPONENT_TURNED_OFF'
  | 'OPTION_TURNED_OFF'
  | 'COMPONENT_NOT_INSTALLED'
  | 'COMPONENT_MALFUNCTION'
  | 'NO_TELEMETRY_MORE_THAN_X_DAYS'
  | 'AV_BASES_OUTDATED'
  | 'TELEMETRY_LOSS'
  | 'KPSN_EXPIRATION'

export interface AssetStatusReason {
  code: AssetStatusReasons;
  args: any;
}

export interface AssetListItem {
  assetId: string;
  hostName: string;
  networkInterfaces: AssetNetworkInterface[];
  domain: string;
  firstSeen: number;
  lastSeen: number;
  osVersion: string;
  installedProductInfo: string;
  tenantName: string;
  status: AssetStatus;
  isolated: boolean;
  isolationTaskId: string;
  protectionIssues: {
    'reasons': AssetIssueReason[];
    'additionalProblems': AssetIssueReason[];
    'options': [];
    'status': AssetStatus;
    'lastSeen': {
      'seconds': string;
      'nanos': number;
    };
    'hours': 0;
  };
  'protectionIssuesIntegral': {
    'reasons': AssetIssueReason[];
    'additionalProblems': AssetIssueReason[];
    'options': [];
    'status': AssetStatus;
    'lastSeen': number | null;
    'hours': number;
  };
}

export interface AssetNetworkInterface {
  defg: string;
  dnsd: string;
  dsc: string;
  ip: string;
  ipcm: string;
  mac: string;
}

interface Product {
  name: string;
  lastSeen: number;
  version: string;
}

export type AssetIssueReason = {
  code: string;
  helpLink: string;
  args: {
    component: string;
    product: string;
    days: number;
    percent: number;
    showDays: number;
    level: string;
    expirationDate: TProtobufDate | string;
  };
}
export interface AssetDetails {
  networkInterfaces: AssetNetworkInterface[];
  productMap: Product[];
  assetId: string;
  hostName: string;
  domain: string;
  osVersion: string;
  installedProductInfo: string;
  tenantName: string;
  machinesidKsc: string;
  isolationTaskId: string;
  status: AssetStatus;
  protectionIssues: {
    'reasons': AssetIssueReason[];
    'additionalProblems': AssetIssueReason[];
    'options': [];
    'status': AssetStatus;
    'lastSeen': {
      'seconds': string;
      'nanos': number;
    };
    'hours': 0;
  };
  'protectionIssuesIntegral': {
    'reasons': AssetIssueReason[];
    'additionalProblems': AssetIssueReason[];
    'options': [];
    'status': AssetStatus;
    'lastSeen': number | null;
    'hours': number;
  };
  'firstSeen': number;
  'lastSeen': number;
  'isolated': boolean;
}
export interface AssetListFilters {
  [key: string]: any;
  firstSeen?: KLDate;
  lastSeen?: KLDate;
  hostNames?: string[];
  tenantsNames?: string[];
}

export interface AssetListSagaFilters {
  [key: string]: any;
  minFirstSeen?: number;
  maxFirstSeen?: number;
  minLastSeen?: number;
  maxLastSeen?: number;
  hostNames?: string[];
  relatedIncidentsIds?: string[];
  tenantsNames?: string[];
}

export interface AssetSuggestion {
  assetId: string;
  hostName: string;
}

export interface AssetListColumns {
  [key: string]: any;
  hostName?: EntityColumn;
  networkInterfaces?: EntityColumn;
  domain?: EntityColumn;
  firstSeen?: EntityColumn;
  lastSeen?: EntityColumn;
  osVersion?: EntityColumn;
  installedProductInfo?: EntityColumn;
}

export type AssetListColumnsPayload = {
  [key: string]: EntityColumn;
} | null;

export interface AssetSuggestionPayload {
  searchPhrase: string;
  tenantsNames: string[];
}
