import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  steps: {
    marginBottom: theme.spacing(5),
  },
  help: {},
  link: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
