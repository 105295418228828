import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  LineChart: {
    position: 'relative',
    marginTop: '10px',
    '& svg': {
      fill: 'none',
      display: 'block',
      strokeWidth: '2px',
    },
  },
  legendBlock: {
    padding: '20px',
    textAlign: 'left',
  },
  popup: {
    display: 'block',
    background: '#1D1D1B',
    boxShadow: '0px 4px 4px #C2C2C2',
    color: 'white',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '10px',
    width: '120px',
    transform: 'translate(-50%, 0)',
    pointerEvents: 'none',
    borderRadius: '0',
    maxHeight: '100px', 
    zIndex: 100,
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  line: {
    transition: 'transform .5s cubic-bezier(.8,-.5,.2,1.4)',
  },
  ticks: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '11px',
    fill: '#8492A4',
  },
});
