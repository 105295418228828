import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

export const PageLayer = (props: any) => {
  const { children, flex } = props;
  const classes = useStyles(flex);

  return (
    <Box className={classes.container}>
      {children}
    </Box>
  );
};
