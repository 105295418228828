import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: theme.spacing(0, 2),
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    padding: (short: boolean) => theme.spacing(0, short ? 0 : 0.5),
  },
  linkDisabled: {
    color: theme.palette.action.disabled,
  },
  icon: {
    fill: theme.palette.primary.main,
    width: 13,
    height: 13,
  },
  iconPrev: {
    marginRight: (short: boolean) => theme.spacing(short ? 0 : 1),
  },
  iconNext: {
    marginLeft: (short: boolean) => theme.spacing(short ? 0 : 1),
  },
  iconDisabled: {
    fill: theme.palette.action.disabled,
  },
});
