import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps, StandardProps, Button, CircularProgress } from '@material-ui/core';
import styles from './styles';

type ClassKeys = 'root';

type KLButtonProps = {
  isLoading?: boolean;
};

const useStyles = makeStyles(styles);

export const KLButton: React.FC<StandardProps<KLButtonProps & ButtonProps, ClassKeys>> = props => {
  const buttonEl = useRef<HTMLButtonElement>(null);
  const classes = useStyles(props);
  const [width, setWidth] = useState(0);
  const { isLoading, disabled, children, size, className, ...restProps } = props;
  let progressSize = 16;

  if (size === 'small') {
    progressSize = 14;
  }
  if (size === 'large') {
    progressSize = 16;
  }

  useEffect(() => {
    if (buttonEl.current) {
      setWidth(buttonEl.current.offsetWidth);
    }
  }, []);

  return (
    <Button
      {...restProps}
      ref={buttonEl}
      style={{ width: `${isLoading ? `${width}px` : 'auto'}` }}
      disabled={disabled || isLoading}
      size={size}
      className={classNames(className, isLoading && 'MuiButton-loading')}
      classes={{ root: classes.root }}
    >
      {
        isLoading
          ? <CircularProgress size={progressSize} color="inherit" />
          : children
      }
    </Button>
  );
};
