import React from 'react';
import { MessageLayer } from 'components/MessageLayer';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const useStyles = makeStyles(styles);

export const ForbiddenError: React.FC = () => {
  const { t } = useTranslation('errors');
  const classes = useStyles();

  return (
    <MessageLayer
      title={t('Access denied')}
      icon={<CancelOutlinedIcon className={classes.icon} />}
    />
  );
};
