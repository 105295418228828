import { createStyles, Theme } from '@material-ui/core/styles';
import { backgroundColors } from 'themes/default';

export default (theme: Theme) => createStyles({
  root: {
    marginRight: theme.spacing(2.75),
  },
  container: {
    padding: theme.spacing(2, 6),
  },
  text: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    '& svg': {
      fill: theme.palette.primary.main,
    },
    lineHeight: theme.typography.pxToRem(32),
    padding: theme.spacing(0, 1.5),
    maxHeight: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: backgroundColors?.primary?.main,
    },
  },
  disabled: {
    color: '#cccccc',
    '& svg': {
      fill: '#cccccc',
    },
  },
  rootCurrent: {
    border: '1px solid #58595B',
    color: '#58595B',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  rootEnd: {
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
    '&:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  rootEllipsis: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  entries: {
    lineHeight: theme.typography.pxToRem(32),
  },
  previousIcon: {
    marginRight: theme.spacing(0.75),
  },
  nextIcon: {
    marginLeft: theme.spacing(0.75),
  },
  loadableContainer: {
    paddingTop: theme.spacing(3),
  },
});
