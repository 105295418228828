import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  info: {
    padding: theme.spacing(1, 0),
    borderBottom: '1px dashed #C4C4C4',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  author: {
    display: 'flex',
    alignItems: 'center',
  },
  authorIcon: {
    marginRight: theme.spacing(1),
  },
  compact: {
    fontSize: theme.typography.pxToRem(14),
  },
  dateTime: {
    color: '#979797',
    fontSize: theme.typography.pxToRem(14),
  },
});
