import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RobotSessionItem } from './types';

import {
  listRequest,
  listStart,
  listSuccess,

  closeDrawer,
  terminateSessionRequest,

  error,
} from './slice';

import {
  getList,
  terminateSession,
} from './api';

function* fetchKeysList() {
  yield put(listStart());
  try {
    const sessions: any = yield call(getList);
    yield put(listSuccess(sessions));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
  }
}

function* fetchSessionTermination(action: PayloadAction<RobotSessionItem>) {
  try {
    yield call(terminateSession, action.payload);
    yield put(closeDrawer());
    yield call(fetchKeysList);

  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
  }
}

export const APIKeysSaga = [
  takeLatest(listRequest.type, fetchKeysList),

  takeLatest(terminateSessionRequest.type, fetchSessionTermination),
];
