import { MouseEvent, useRef, useCallback } from 'react';

export const useHoverHooks = (enterCb: (target: HTMLElement, e?: any) => void, leaveCb: () => void) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onMouseEnter = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const { clientX, clientY } = e;
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      enterCb(target, { clientX, clientY });
    }, 300);
  }, [enterCb]);

  const onMouseLeave = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    leaveCb();
  }, [leaveCb]);

  return {
    onMouseEnter,
    onMouseLeave,
  };
};
