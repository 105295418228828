import React from 'react';
import { format, formatDistanceToNow, formatRelative, Locale } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { getLang } from 'utils/i18n';

export type DateTimeProps = {
  timestamp: number;
  type?: 'standard' | 'distance' | 'relative';
  formatString?: string;
  withTime?: boolean;
  withTimeSeconds?: boolean;
};

const formatRelativeEnLocale: { [key: string]: string } = {
  lastWeek: 'eeee, p',
  yesterday: "'Yesterday', p",
  today: "'Today', p",
  tomorrow: "'Tomorrow', p",
  nextWeek: "'Next' eeee, p",
  other: 'P',
};
const enLocale = {
  ...enUS,
  formatRelative: (token: string) => formatRelativeEnLocale[token],
};

const formatRelativeRuLocale: { [key: string]: string } = {
  lastWeek: 'eeee, p',
  yesterday: "'Вчера', p",
  today: "'Сегодня', p",
  tomorrow: "'Завтра', p",
  nextWeek: "'След.' eeee, p",
  other: 'P',
};
const ruLocale = {
  ...ru,
  formatRelative: (token: string) => formatRelativeRuLocale[token],
};

const localeMap: { [key: string]: Locale } = {
  en: enLocale,
  ru: ruLocale,
};

export const DateTime: React.FC<DateTimeProps> = props => {
  const { timestamp, formatString, type = 'standard', withTime = true, withTimeSeconds = false } = props;
  const { t } = useTranslation('dates');
  const isUnixTime = String(timestamp).length === 10;
  const ts = isUnixTime ? timestamp * 1000 : timestamp;

  let formatTimeString = '';
  if (withTime || withTimeSeconds) {
    formatTimeString = withTime ? ` ${t('formats.time')}` : ` ${t('formats.timeSeconds')}`;
  }
  const tFormatString = `${t('formats.fullDate')}${formatTimeString}`;
  const formatDistOptions = { locale: localeMap[getLang()] };

  return (
    <>
      {type === 'standard' && format(new Date(ts), formatString || tFormatString, formatDistOptions)}
      {type === 'distance' && formatDistanceToNow(ts, formatDistOptions)}
      {type === 'relative' && formatRelative(ts, Date.now(), formatDistOptions)}
    </>
  );
};

DateTime.defaultProps = {
  timestamp: Date.now(),
  type: 'standard',
  withTime: false,
  formatString: '',
};
