import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  image: {
    maxWidth: '100%',
    display: 'block',
    marginBottom: '12px',
    marginTop: '12px',
  },
});
