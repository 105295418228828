import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GeneralConfigGetResponse,
  GeneralConfigSetResponse,
  GeneralConfigSetPayload,
  GeneralConfig, EntityState,
} from './types';

const name = 'settings';

export const getGeneralConfigRequest = createAction<string>(`${name}/getGeneral`);
export const setGeneralConfigRequest = createAction<GeneralConfigSetPayload>(`${name}/setGeneral`);

const initialState: EntityState & GeneralConfig = {
  isLoading: false,
  error: null,
  daysForStatusOffline: 7,
  hideAbsentAssets: false,
  enableExtendedNotifications: false,
};

const assetListSlice = createSlice({
  name,
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = null;
    },
    getGeneralSuccess(state, action: PayloadAction<GeneralConfigGetResponse>) {
      state.daysForStatusOffline = action.payload.daysForStatusOffline;
      state.hideAbsentAssets = action.payload.hideAbsentAssets;
      state.enableExtendedNotifications = action.payload.enableExtendedNotifications;
      state.isLoading = false;
    },
    setGeneralSuccess(state, action: PayloadAction<GeneralConfigSetResponse>) {
      state.daysForStatusOffline = action.payload.config.daysForStatusOffline;
      state.hideAbsentAssets = action.payload.config.hideAbsentAssets;
      state.enableExtendedNotifications = action.payload.config.enableExtendedNotifications;
      state.isLoading = false;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  start,
  getGeneralSuccess,
  setGeneralSuccess,
  error,
} = assetListSlice.actions;

export default assetListSlice.reducer;
