import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { listRequest } from 'services/organizations/slice';
import { useSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { Organization } from 'services/organizations/types';
import { OrganizationEntry } from '../OrganizationEntry';
import { OrganisationTableRow } from '../OrganisationTableRow';
import styles from './styles';

const useStyles = makeStyles(styles);

export const Organizations = () => {
  const { t } = useTranslation('organizations');

  const { list, isLoading, error } = useSelector(state => state.organizations);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(listRequest());
  }, [dispatch]);

  const translateMapping: any = {
    region: t('Region'),
    activationEmail: t('Email'),
    customerName: t('Name'),
    customerCompany: t('Company'),
    customerCountry: t('Country'),
    customerCity: t('City'),
    customerAddress: t('Address'),
    customerPhone: t('Phone'),
    customerFax: t('Fax'),
    customerWww: t('Website'),
  };

  const getFields = (organizationData: Organization) => Object.entries(organizationData)
    .map(([key, value]) => (
      value ? <OrganisationTableRow key={key} label={translateMapping[key]} value={value} /> : null
    ));

  return (
    <Box className={classes.root}>
      {list.map((organization) => (
        <OrganizationEntry
          key={organization.clientId}
          organization={organization}
          isLoading={isLoading}
          error={error}
          getFields={getFields}
          translateMapping={translateMapping}
        />
      ))}
    </Box>
  );
};
