import React from 'react';
import { InputBase, InputBaseProps, StandardProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

type ClassKeys = 'root' | 'input';
export type KLInputProps = {
  noMarginTop?: boolean;
  maxLength?: number;
};

const useStyles = makeStyles(styles);

export const KLInput = React.forwardRef<HTMLInputElement, StandardProps<KLInputProps & InputBaseProps, ClassKeys>>(
  (props, ref) => {
    const { noMarginTop, maxLength = 1000, ...restProps } = props;
    const classes = useStyles({ noMarginTop, ...restProps });

    return (
      <InputBase
        {...restProps}
        ref={ref}
        classes={{
          root: classes.root,
          input: classes.input,
        }}
        inputProps={{
          maxLength,
        }}
      />
    );
  },
);
