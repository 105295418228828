import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  controls: {
    marginTop: theme.spacing(2),
  },
  btn: {
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
  tip: {
    fontSize: 12,
  },
});
