import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  title: {
    minWidth: 300,
    marginRight: theme.spacing(2),
  },
});
