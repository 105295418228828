import { combineReducers } from 'redux';
import activationReducer from 'services/activation/slice';
import assetReducer from 'services/assets/slice';
import attachmentReducer from 'services/attachments/slice';
import authReducer from 'services/auth/slice';
import clientInformerReducer from 'services/clientInformer/slice';
import commentReducer from 'services/comments/slice';
import incidentHistoryReducer from 'services/incidentHistory/slice';
import incidentReducer from 'services/incidents/slice';
import incidentResponsesReducer from 'services/incidentResponses/slice';
import incidentResponseScriptReducer from 'services/incidentResponses/scriptSlice';
import keysReducer from 'services/APIkeys/slice';
import markdownCacheReducer from 'services/markdownCache/slice';
import notificationsReducer from 'services/notifications/slice';
import organizationsReducer from 'services/organizations/slice';
import permissionsReducer from 'services/permissions/slice';
import settingsReducer from 'services/settings/slice';
import statisticsReducer from 'services/statistics/slice';
import systemReducer from 'services/system/slice';
import tenantsReducer from 'services/tenants/slice';
import userReducer from 'services/user/slice';

const rootReducer = combineReducers({
  APIkeys: keysReducer,
  activation: activationReducer,
  assets: assetReducer,
  attachments: attachmentReducer,
  auth: authReducer,
  clientInformer: clientInformerReducer,
  comments: commentReducer,
  incidentHistory: incidentHistoryReducer,
  incidentResponses: incidentResponsesReducer,
  incidentResponseScript: incidentResponseScriptReducer,
  incidents: incidentReducer,
  markdownCache: markdownCacheReducer,
  notifications: notificationsReducer,
  organizations: organizationsReducer,
  permissions: permissionsReducer,
  settings: settingsReducer,
  statistics: statisticsReducer,
  system: systemReducer,
  tenants: tenantsReducer,
  users: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
