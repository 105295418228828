import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  root: {
    display: 'block',
    '&:link': {
      color: '#1E856F',
    },
    '&:visited': {
      color: '#1E856F',
    },
    '&:visited:hover': {
      color: '#1E856F',
    },
  },
  noLink: {
    display: 'block',
    fontSize: 14,
  },
});
