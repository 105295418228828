import { KLDate } from 'components/KLDatePicker';
import { EntityType, EntityColumn } from 'store/slice';

export type IncidentListPayload = {
  paging: {
    page: number;
    pageSize: number;
  };
  filter: IncidentFilters;
  searchPhrase: string;
};

export type IncidentListSagaPayload = {
  paging: {
    page: number;
    pageSize: number;
  };
  filter: IncidentSagaFilters;
  searchPhrase: string;
};

export type IncidentMarkAsReadPayload = {
  entityIds: string[];
  entityType: EntityType;
}

export interface IncidentNewItem {
  summary: string;
  clientDescription: string;
  affectedHosts: string[];
  priority: string;
  tenantId?: string;
  title?: string;
}

export enum UserOrigin {
  SERVICE,
  CLIENT,
}

export interface IncidentDetails {
  incidentId: string;
  caseId: number;
  summary: string;
  priority: string;
  status: IncidentStatus;
  resolution: IncidentResolution;
  affectedHosts: string[];
  hostBasedIocs: string[];
  networkBasedIocs: string[];
  detectionTechnology: string;
  creationTime: number;
  updateTime: number;
  attackStage: string;
  mitreTactics: string[];
  mitreTechniques: string[];
  description: string;
  wasRead: boolean;
  statusDescription: string;
  clientDescription: string;
  authorId: string;
  tenantName: string;
  origin: UserOrigin | keyof typeof UserOrigin;
}

export interface IncidentAutoResponse {
  autoResponse: boolean;
  tenantlyMode?: boolean;
  tenantsAutoResponse?: Array<{ tenantId: string; tenantName: string; autoResponse: boolean }>;
}

export interface IncidentAutoResponsePayload {
  autoResponse: boolean;
  tenantlyMode?: boolean;
  tenantsAutoResponse?: Dict<boolean>;
  message?: string;
}

export interface IncidentNotification {
  incident: IncidentDetails;
}

export interface IncidentDeleteNotification {
  incidentId: string;
}

export interface IncidentFilters {
  [key: string]: any;
  creationTime?: KLDate;
  updateTime?: KLDate;
  statuses?: IncidentStatus[];
  priorities?: string[];
  resolutions?: IncidentResolution[];
  affectedHosts?: string[];
  mitreTactics?: string[];
  tenantsNames?: string[];
}

export interface IncidentSagaFilters {
  [key: string]: any;
  minCreationTime?: number;
  maxCreationTime?: number;
  minUpdateTime?: number;
  maxUpdateTime?: number;
  statuses?: IncidentStatus[];
  priorities?: string[];
  resolutions?: IncidentResolution[];
  affectedHosts?: string[];
  mitreTactics?: string[];
  tenantsNames?: string[];
}

export interface IncidentCreatePayload {
  summary: string;
  clientDescription: string;
  affectedHosts: string[];
  priority: string;
  tenantId?: string;
  message?: string;
  filtersSet?: boolean;
}

export interface IncidentColumns {
  [key: string]: any;
  summary?: EntityColumn;
  priority?: EntityColumn;
  status?: EntityColumn;
  resolution?: EntityColumn;
  affectedHosts?: EntityColumn;
  mitreTactics?: EntityColumn;
  creationTime?: EntityColumn;
  updateTime?: EntityColumn;
}

export type IncidentColumnsPayload = {
  [key: string]: EntityColumn;
} | null;

export interface IncidentClosePayload {
  incidentId: string;
  comment: string;
  resolution: IncidentResolution;
  message?: string;
}

export enum IncidentResolution {
  TruePositive = 'TruePositive',
  FalsePositive = 'FalsePositive',
  Other = 'Other',
}

export enum IncidentStatus {
  Open = 'Open',
  OnHold = 'OnHold',
  Resolved = 'Resolved',
  Closed = 'Closed',
}
