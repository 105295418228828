import { takeLatest, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/reducer';
import { addNotification } from 'services/system/slice';
import {
  listRequest,
  listStart,
  listSuccess,
  error,
  updateRequest,
  markAsReadRequest,
  detailsRequest,
  detailsStart,
  detailsSuccess,
} from './slice';
import * as api from './api';
import { ResponseItem, UpdateRequestSagaPayload, ResponseMarkAsReadPayload } from './types';
import { scriptRequest, scriptStart, scriptSuccess } from './scriptSlice';

const sortItems = (items: ResponseItem[]) => items
  .slice()
  .sort(({ updateTime: a }, { updateTime: b }) => (b || Number.MAX_SAFE_INTEGER) - (a || Number.MAX_SAFE_INTEGER));

function* getResponsesList(action: PayloadAction<string>): any {
  yield put(listStart());
  try {
    const items = yield call(api.getList, action.payload);
    // add fields for checkboxes
    const formattedItems = items.map((item: ResponseItem) => ({ ...item, selected: false }));

    yield put(listSuccess(sortItems(formattedItems)));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* updateResponsesList(action: PayloadAction<UpdateRequestSagaPayload>): any {
  const { newStatus, comment = '', responsesIds, successMessage, errorMessage } = action.payload;
  yield put(listStart());
  try {
    const {
      incidentResponses: { list },
      auth: { displayName },
    }: RootState = yield select(state => state);

    const { ids }: { ids: string[] } = yield call(api.update, {
      responsesIds, comment, status: newStatus,
    });
    const successIds = ids.reduce<{[key: string]: string}>((acc, cur) => ({ ...acc, [cur]: cur }), {});

    const updatedItems = list
      .map((item: ResponseItem, i: number) => {
        const { status, responseId } = item;

        if (successIds[responseId] && status === 'Waiting') {
          return {
            ...item,
            status: newStatus,
            comment,
            selected: false,
            changedBy: displayName,
            updateTime: Date.now() + i, // add +i for correct sorting by updateTime
          };
        }
        return item;
      });

    yield put(listSuccess(sortItems(updatedItems)));

    if (ids.length > 0 && successMessage) {
      yield put(addNotification({ message: `${successMessage(ids.length)}`, options: { variant: 'success' } }));
    }

    const responsesCount = Object.keys(responsesIds).length;
    if (ids.length !== responsesCount && errorMessage) {
      yield put(addNotification({
        message: `${errorMessage(responsesCount - ids.length)}`,
        options: { variant: 'warning' },
      }));
    }
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* markAsReadResponsesList(action: PayloadAction<ResponseMarkAsReadPayload>): any {
  try {
    yield call(api.postMarkAsRead, action.payload);
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* getResponseDetails(action: PayloadAction<string>) {
  yield put(detailsStart());

  try {
    const details = yield call(api.getDetails, action.payload);
    yield put(detailsSuccess(details));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}
function* getResponseScript(action: PayloadAction<string>) {
  yield put(scriptStart());

  try {
    const script = yield call(api.getScript, action.payload);
    yield put(scriptSuccess(script as any));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const incidentResponses = [
  takeLatest(listRequest.type, getResponsesList),
  takeLatest(updateRequest.type, updateResponsesList),
  takeLatest(markAsReadRequest.type, markAsReadResponsesList),
  takeLatest(detailsRequest.type, getResponseDetails),
  takeLatest(scriptRequest.type, getResponseScript),
];
