import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0, 4, 4),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '26px',
  },
  formBlock: {
    marginBottom: '20px',
  },
  body: {
    flexGrow: 1,
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    color: '#58595B',
    fontSize: theme.typography.pxToRem(14),
  },
  copyIconButton: {
    padding: '0px',
  },
  labelBottom: {
    marginTop: theme.spacing(1),
    color: '#58595B',
    fontSize: theme.typography.pxToRem(14),
  },
  action: {
    marginTop: '20px',
  },
  textarea: {
    minHeight: '106px',
    resize: 'none',
    outline: 'none',
    width: '100%',
  },
  disclaimer: {
    marginTop: '8px',
    marginBottom: '20px',
  },
  textareaWrapper: {
    position: 'relative',
  },
  refresh: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  cell: {
    padding: '0 20px 20px 0',
    '&:first-of-type': {
      color: '#58595B',
    },
  },
  footer: {
    marginTop: 'auto',
    textAlign: 'right',
    paddingTop: theme.spacing(4),
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
  save: {
    marginRight: theme.spacing(1),
  },
});
