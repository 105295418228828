import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postLoginRequest } from 'services/auth/slice';
import { useSelector } from 'store/hook';
import { Loading } from 'components/Loading';
import { AUTH_ERROR_PAGE, AUTH_LOGIN_PAGE } from 'global/routes';

export const PostLoginPage: React.FC = () => {
  const { code } = useParams<Record<string, string>>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      dispatch(postLoginRequest(code));
    }
  }, [code, dispatch]);

  const { isAuthenticated, error } = useSelector(state => state.auth);

  // restart application for getting tokens by websocket provider
  if (isAuthenticated) {
    return <Redirect to={AUTH_LOGIN_PAGE} />;
  }

  if (error) {
    return <Redirect to={AUTH_ERROR_PAGE} />;
  }

  return <Loading />;
};
