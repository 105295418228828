import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // [theme.breakpoints.down('sm')]: {
    //   height: 'auto',
    // },
  },
  cardHeader: {
    padding: theme.spacing(3, 6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardHeaderActions: {
    display: 'flex',
    alignItems: 'center',
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  tab: {
    padding: theme.spacing(1.75, 0),
    '&.Mui-selected': {
      marginRight: 22,
    },
  },
});
