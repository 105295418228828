import React, { MouseEvent, useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { KLButton } from 'components/KLButton';
import { KLDrawer } from 'components/KLDrawer';
import { KLEditor } from 'components/KLEditor';
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'components/Permissions/hooks';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { scriptRequest } from 'services/incidentResponses/scriptSlice';
import { saveAs } from 'file-saver';
import { CommentHeader } from '../CommentHeader';
import { formatParameters } from '../Responses';
import { DownloadIcon } from '../../../../assets/icons/DownloadIcon';
import styles from './styles';
import { ResponsesDescDialogProps } from './types';

const useStyles = makeStyles(styles);

export const ResponsesDescDialog = (props: ResponsesDescDialogProps) => {
  const {
    data,
    open,
    onDecline,
    onConfirm,
    onClose,
  } = props;
  const classes = useStyles();

  const { t } = useTranslation('IncidentResponses');
  const [commentText, setCommentText] = React.useState('');
  const [editResponsePerm] = usePermissions(['edit_incident_response']);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.responseId && data.type === 'RunShell') {
      dispatch(scriptRequest(data.responseId));
    }
  }, [data?.responseId]);
  const script = useSelector(({ incidentResponseScript }) => incidentResponseScript.script);

  const onDownloadClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (script?.content) {
        saveAs(
          new Blob([script.content], {
            type: 'text/plain;charset=utf-8;',
          }),
          script.fileName,
        );
      }
    },
    [script],
  );

  if (!data) {
    return null;
  }

  const {
    responseId,
    status,
    description,
    comment,
    updateTime,
    changedBy,
    creationTime,
    parameters,
    type,
  } = data;

  const confirmClickHandler = () => {
    onConfirm(responseId, commentText);
    setCommentText('');
  };
  const declineClickHandler = () => {
    onDecline(responseId, commentText);
    setCommentText('');
  };

  const closeHandler = () => {
    onClose();
    setCommentText('');
  };

  const isEditable = status === 'Waiting' && editResponsePerm;

  const tChangedBy = changedBy === 'autoresponder'
    ? t('Autoresponder')
    : changedBy;

  const tComment = comment === 'Confirmed by autoresponder'
    ? t('Confirmed by autoresponder')
    : comment;

  const controls = (
    <>
      <KLButton
        variant="contained"
        color="primary"
        onClick={confirmClickHandler}
        className={classes.btn}
      >
        {t('Send and confirm')}
      </KLButton>
      <KLButton
        variant="outlined"
        color="primary"
        onClick={declineClickHandler}
        className={classes.btnMarginLeft}
        disabled={commentText.trim().length === 0}
      >
        {t('Send and decline')}
      </KLButton>
    </>
  );

  const closeControl = (
    <KLButton
      variant="outlined"
      color="primary"
      onClick={closeHandler}
      className={classes.btn}
    >
      {t('Close')}
    </KLButton>
  );

  return (
    <KLDrawer
      open={open}
      onClose={closeHandler}
      title={t('Response description')}
      noPadding
      fullContentSpace
      controls={isEditable ? controls : closeControl}
    >
      <Box className={classes.root}>
        {parameters && parameters !== '{}' && (
          <Box className={classes.details}>
            <ReactJson
              name={t('Parameters') as string}
              src={formatParameters(JSON.parse(parameters))}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
              style={{
                wordBreak: 'break-all',
              }}
              theme={{
                base00: 'rgba(0, 0, 0, 0)',
                base01: 'rgb(245, 245, 245)',
                base02: 'rgb(235, 235, 235)',
                base03: '#93a1a1',
                base04: 'rgba(0, 0, 0, 0.3)',
                base05: '#586e75',
                base06: '#073642',
                base07: '#666666',
                base08: '#d33682',
                base09: '#000000',
                base0A: '#dc322f',
                base0B: '#859900',
                base0C: '#6c71c4',
                base0D: '#586e75',
                base0E: '#2aa198',
                base0F: '#000000',
              }}
            />
          </Box>
        )}
        {
          type === 'RunShell' && script
            ? (
              <>
                <textarea style={{ resize: 'none', width: '100%', minHeight: '200px' }} value={script.content} />
                <div className={classes.downloadLinkWrap}>
                  <a
                    className={classes.downloadLink}
                    onClick={onDownloadClick}
                  >
                    <DownloadIcon className={classes.downloadIcon} />
                    Download file
                  </a>
                </div>
              </>
            )
            : undefined
        }
        {description ? (
          <Box className={classes.comment}>
            <CommentHeader authorName={t('Security team')} date={creationTime} compact />
            <Box className={classes.text}>
              {description}
            </Box>
          </Box>
        ) : (
          <Box className={classes.comment}>
            <Box className={classes.empty}>
              { t('No comments') }
            </Box>
          </Box>
        )}
        {isEditable ? (
          <Box className={classes.comment}>
            <CommentHeader authorName={t('You')} compact />
            <KLEditor
              text={commentText}
              setText={(text) => setCommentText(text || '')}
              minRows={4}
              maxRows={4}
              placeholder={t('Comment text')}
              toolbarHidden
            />
          </Box>
        ) : null }
        {comment ? (
          <Box className={classes.comment}>
            <CommentHeader authorName={tChangedBy} date={updateTime} compact />
            <Box className={classes.text}>
              {tComment}
            </Box>
          </Box>
        ) : null}
      </Box>
    </KLDrawer>
  );
};
