import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link, LinkProps } from '@material-ui/core';

export const KLLink = (props: LinkProps & RouterLinkProps) => {
  const { children, ...restProps } = props;

  return (
    <Link
      component={RouterLink}
      {...restProps}
    >
      {children}
    </Link>
  );
};
