export const ACCESS_DENIED = 'accessDenied';
export const ALREADY_CHANGED = 'AlreadyChanged';
export const EMAIL_ALREADY_USED = 'emailAlreadyUsed';
export const INCIDENT_REVOKED = 'incidentRevoked';
export const INVITE_ALREADY_ACTIVATED = 'inviteAlreadyActivated';
export const INVITE_EMAIL_MISMATCH = 'inviteEmailMismatch';
export const INVITE_EXPIRED = 'inviteExpired';
export const NOT_FOUND = 'notFound';
export const TENANT_EXISTS_ALREADY = 'tenantExistsAlready';
export const UNAUTHORIZED = 'unauthorized';
export const UNVERIFIED_USER_EMAIL = 'unverifiedUserEmail';
export const LICENSE_ALREADY_ACTIVATED = 'licenseAlreadyActivated';
export const ORIGIN_MISMATCH = 'originMismatch';

export const DUPLICATE_SCHEDULE = 'duplicateSchedule';
export const MAX_SCHEDULES = 'maxSchedules';
