import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { SUPPORT_URL_RU, SUPPORT_URL_EN } from 'global/environments';
import { getLang } from 'utils/i18n';
import { KLButton } from 'components/KLButton';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorCircleIcon } from 'assets/icons/ErrorCircleIcon';
import { clear } from 'services/activation/slice';
import styles from './styles';

const useStyles = makeStyles(styles);

export const ActivationError = () => {
  const { t } = useTranslation('ActivationPage');
  const dispatch = useDispatch();
  const classes = useStyles();


  const closeHandler = () => {
    dispatch(clear());
  };

  return (
    <Box className={classes.container}>
      <ErrorCircleIcon className={classes.icon} />
      <Typography className={classes.title} variant="h1" component="h1">
        {t('An error occurred during activation')}
      </Typography>
      <Typography className={classes.subtitle} variant="h3" component="h3">
        <Trans
          defaults="ActivationPage:Please contact us through"
          values={{ customerSupport: t('Customer support') }}
          components={[
            (
              <a
                href={getLang() === 'ru' ? SUPPORT_URL_RU : SUPPORT_URL_EN}
                target="_blank"
                rel="noopener noreferrer"
              >
                placeholder
              </a>
            ),
          ]}
        />
      </Typography>
      <KLButton
        variant="outlined"
        size="large"
        color="primary"
        onClick={closeHandler}
        className={classes.btn}
      >
        {t('Back')}
      </KLButton>
    </Box>
  );
};
