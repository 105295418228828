import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    '&.red-bg': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.error.main,
    },
    '&.no-border-radius': {
      borderRadius: 0,
    },
    '&.dark-green': {
      color: theme.palette.primary.dark,
    },
  },

});
