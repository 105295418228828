import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Table } from 'components/Table';
import { useTheme } from '@material-ui/core/styles';
import { INCIDENT_LIST_PAGE } from 'global/routes';
import { IncidentDetails } from 'services/incidents/types';
import { AssetIncidentsProps } from './types';

export const AssetIncidents = (props: AssetIncidentsProps) => {
  const { t } = useTranslation('IncidentListPage');
  const { incidents } = props;
  const theme = useTheme();

  return (
    <Box>
      <Table<IncidentDetails>
        columns={[
          {
            title: t('ID'),
            field: 'caseId',
            cellStyle: {
              color: '#00A88E',
              textDecoration: 'underline',
              paddingLeft: theme.spacing(4),
            },
            headerStyle: { paddingLeft: theme.spacing(4) },
          },
          {
            title: t('Status'),
            render: ({ status }) => t(status),
          },
        ]}
        data={incidents}
        count={incidents.length}
        route={INCIDENT_LIST_PAGE}
        routeId="incidentId"
        options={{
          search: false,
          showTitle: false,
          toolbar: false,
          sorting: false,
          paging: false,
          headerStyle: {
            fontWeight: 700,
            borderBottom: 'none',
            padding: theme.spacing(1, 2),
            lineHeight: theme.typography.pxToRem(32),
            whiteSpace: 'nowrap',
          },
        }}
      />
    </Box>
  );
};
