import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
  },
  label: {
    padding: theme.spacing(0.5, 0, 0.5, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
  drag: {
    padding: theme.spacing(0, 1),
    cursor: 'move',
  },
});
