import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    marginLeft: 4,
    paddingLeft: 7,
    border: '1px solid #B3B3B3',
    borderRadius: 3,
    width: 200,
    outline: 'none',
    '& svg': {
      flexShrink: 0,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    height: 'auto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    padding: '5px 0',
  },
  hint: {
    marginTop: 1,
    padding: theme.spacing(1),
    width: 200,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 10,
  },
  fields: {
    paddingLeft: theme.spacing(2),
  },
});
