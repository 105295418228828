import { Theme, createStyles, darken } from '@material-ui/core/styles';

export const drawerWidth = 280;
export const collapsedDrawerSpacing = 6;
export const bgColor = '#1D1D1B';

export default (theme: Theme) => createStyles({
  drawerPaper: {
    display: 'flex',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    border: 'none',
    backgroundColor: bgColor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(collapsedDrawerSpacing),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  mainMenu: {
    padding: theme.spacing(2, 0),
  },
  mainMenuItem: {
    padding: theme.spacing(0, 2),
    height: 44,
    position: 'relative',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#595959',
    },
    '&.Mui-selected::before, &::before': {
      position: 'absolute',
      content: '""',
      display: 'block',
      top: 0,
      left: 0,
      width: 4,
      height: 44,
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-selected:hover::before': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:hover': {
      backgroundColor: darken('#595959', 0.5),
    },
    '&::before': {
      display: 'none',
    },
    '&:hover::before': {
      backgroundColor: darken(theme.palette.primary.light, 0.5),
      display: 'block',
    },
  },
  mainMenuIcon: {
    minWidth: theme.spacing(4),
  },
  mainMenuText: {
    fontSize: theme.typography.fontSize,
    fontWeight: 300,
    color: theme.palette.common.white,
  },
  spacer: {
    flexGrow: 1,
  },
});
