import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { autoResponseGetRequest, autoResponseSetRequest, IncidentState } from 'services/incidents/slice';
import { BottomDrawer } from 'components/BottomDrawer';
import { KLButton } from 'components/KLButton';
import { KLCheckBox } from 'components/KLCheckBox';
import styles from './styles';
import AutoResponseRadio from './components/AutoResponseRadio';

type IncidentSettingsProps = {
  setWasChanges: (wasChanges: boolean) => void;
};

const useStyles = makeStyles(styles);

export const IncidentSettings: React.FC<IncidentSettingsProps> = props => {
  const { setWasChanges } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const {
    autoResponse: defaultAutoResponse,
    isLoading,
  } = useSelector((state: { incidents: IncidentState }) => state.incidents);
  const { t } = useTranslation('SettingsPage');

  const [autoResponse, setAutoResponse] = useState(defaultAutoResponse?.autoResponse);
  const [tenantlyMode, setTenantlyMode] = useState(defaultAutoResponse?.tenantlyMode);
  const [tenantsAutoResponse, setTenantsAutoReponse] = useState(defaultAutoResponse?.tenantsAutoResponse);

  const [selectedTenantIds, setSelectedTenantIds] = useState<Dict<boolean>>({});
  const [initialTenantSelections, setInitialTenantSelections] = useState<Dict<boolean>>({});
  const [isSelectionChanged, setIsSelectionChanged] = useState(false);

  const currentRadioValue = useMemo(() => {
    if (autoResponse) return 'enabled';
    return tenantlyMode ? 'tenant auto-response' : 'disabled';
  }, [autoResponse, tenantlyMode]);

  useEffect(() => {
    dispatch(autoResponseGetRequest());
  }, []);

  useEffect(() => {
    setWasChanges(isSelectionChanged);
  }, [setWasChanges, isSelectionChanged]);

  const allSelected = useMemo(
    () => tenantsAutoResponse?.every(tenant => selectedTenantIds[tenant.tenantId]), 
    [tenantsAutoResponse, selectedTenantIds],
  );

  useEffect(() => {
    if (tenantsAutoResponse) {
      const tenantSelectionFlags = tenantsAutoResponse?.reduce((acc: Dict<boolean>, tenant) => {
        acc[tenant.tenantId] = tenant.autoResponse;
        return acc;
      }, {});
      setSelectedTenantIds(tenantSelectionFlags);
      setInitialTenantSelections(tenantSelectionFlags);
    }
  }, [tenantsAutoResponse]);

  useEffect(() => {
    const allKeys = new Set([...Object.keys(selectedTenantIds), ...Object.keys(initialTenantSelections)]);
    const isChanged = Array.from(allKeys)
      .some(key => selectedTenantIds[key] !== (initialTenantSelections[key] || false));
    
    if (
      (defaultAutoResponse?.autoResponse !== autoResponse || defaultAutoResponse?.tenantlyMode !== tenantlyMode) 
      || isChanged
    ) {
      setIsSelectionChanged(true);
    } else {
      setIsSelectionChanged(false);
    }
  }, [autoResponse, selectedTenantIds, initialTenantSelections, tenantlyMode]);

  useEffect(() => {
    setAutoResponse(defaultAutoResponse?.autoResponse);
    setTenantlyMode(defaultAutoResponse?.tenantlyMode);
    setTenantsAutoReponse(defaultAutoResponse?.tenantsAutoResponse);
  }, [defaultAutoResponse]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setAutoResponse(value !== 'disabled' && value !== 'tenant auto-response');
    setTenantlyMode(value === 'tenant auto-response');
  };

  const handleCheckboxChange = (tenantId: string, isChecked: boolean) => {
    setSelectedTenantIds(prev => ({ ...prev, [tenantId]: isChecked }));
  };
  
  const handleSelectAllChange = (isChecked: boolean) => {
    const allSelection = tenantsAutoResponse?.reduce((acc: Dict<boolean>, tenant) => {
      acc[tenant.tenantId] = isChecked;
      return acc;
    }, {}) || {};
    setSelectedTenantIds(allSelection);
  };

  const handleSaveChanges = () => {
    dispatch(autoResponseSetRequest({
      autoResponse: !!autoResponse,
      tenantlyMode: !!tenantlyMode,
      message: t('incidents.Incident auto response setting was saved successfully'),
      tenantsAutoResponse: tenantlyMode ? selectedTenantIds : {},
    }));
    setInitialTenantSelections(selectedTenantIds);
  };

  const handleCancelChanges = () => {
    setAutoResponse(defaultAutoResponse?.autoResponse);
    setTenantlyMode(defaultAutoResponse?.tenantlyMode);
    setSelectedTenantIds(initialTenantSelections);
  };
  
  return (
    <div className={classes.root}>
      <h1>{t('incidents.Auto-accept for responses')}</h1>
      <RadioGroup
        className={classes.radioGroup}
        value={currentRadioValue}
        onChange={handleChange}
      >
        <AutoResponseRadio
          value="disabled"
          labelKey="incidents.Disabled"
          noteKeys={['incidents.You accept all responses manually']}
          classes={classes}
        />
        <AutoResponseRadio
          value="enabled"
          labelKey="incidents.Enabled for all tenants"
          noteKeys={['incidents.All responses for all assets in all tenants are accepted automatically']}
          classes={classes}
        />
        <AutoResponseRadio
          value="tenant auto-response"
          labelKey="incidents.Enabled for the tenants selected below"
          noteKeys={[
            'incidents.After creating a new tenant, you have to manually enable automatic confirmation here',
            'incidents.Please select the tenants where the responses should be accepted automatically',
          ]}
          classes={classes}
        />
      </RadioGroup>
      <div className={classes.tenantsContainer}>
        <FormControlLabel
          classes={{
            root: classes.control,
            label: classes.label,
            disabled: classes.disabled,
          }}
          control={(
            <KLCheckBox
              className={classes.tenantCheckbox}
              color="primary"
              checked={Boolean(allSelected)}
              disabled={!tenantlyMode}
              onChange={e => handleSelectAllChange(e.target.checked)}
            />
            )}
          label={t('incidents.All')}
        />
        {tenantsAutoResponse?.map(tenant => (
          <FormControlLabel
            classes={{
              root: classes.control,
              label: classes.label,
              disabled: classes.disabled,
            }}
            control={(
              <KLCheckBox
                className={classes.tenantCheckbox}
                color="primary"
                checked={selectedTenantIds[tenant.tenantId] === true}
                disabled={!tenantlyMode}
                onChange={e => handleCheckboxChange(tenant.tenantId, e.target.checked)}
              />
            )}
            label={tenant.tenantName}
          />
        ))}
      </div>
      <BottomDrawer open={isSelectionChanged}>
        <KLButton
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          className={classes.saveButton}
          isLoading={isLoading}
        >
          {t('Save')}
        </KLButton>
        <KLButton
          variant="text"
          color="primary"
          onClick={handleCancelChanges}
          className={classes.cancelButton}
          disabled={isLoading}
        >
          {t('Cancel')}
        </KLButton>
      </BottomDrawer>
    </div>
  );
};
