import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  dialogTitle: {
    marginBottom: theme.spacing(2),
  },
  dialogContent: {
    padding: `${theme.spacing(4)}px !important`,
  },
  dialogContentText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: '#000000',
    marginBottom: 0,
  },
  dialogActions: {
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 4, 3),
  },
  dialogButton: {
    minWidth: 120,
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(1),
  },
});
