import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  root: {
  },
  input: {
    '& input': {
      padding: 7,
    },
  },
  notchedOutline: {
    top: 0,
    '& legend': {
      display: 'none',
    },
  },
  shrink: {
    '&.MuiInputLabel-marginDense.MuiInputLabel-outlined': {
      transform: 'translate(0px, -14px) scale(0.75)',
    },
  },
  marginDense: {
    '&.MuiInputLabel-outlined': {
      transform: 'translate(7px, 10px) scale(0.9)',
    },
    '&.Mui-error': {
      color: '#979797',
    },
  },
  contained: {
    marginLeft: 0,
  },
  helperText: {},
});
