import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

export const DataLine = (props: any) => {
  const { title, value } = props;
  const classes = useStyles();

  return (
    <>
      {(value || value === 0) ? (
        <Box className={classes.container}>
          <Box className={classes.title}>
            {title}
          </Box>
          <Box>
            {value}
          </Box>
        </Box>
      ) : null}
    </>
  );
};
