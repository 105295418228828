import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store/hook';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { KLButton } from 'components/KLButton';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { skipUpdateNotice } from 'services/activation/slice';
import { save } from 'utils/storage';
import { LICENSE_STATE_PAGE } from 'global/routes';
import styles from './styles';

const useStyles = makeStyles(styles);
const DAYS_LEFT_FOR_NOTIFICATION = 30;

export const UpdateLicenseNotification: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation('licenseState');
  const { isLicenseExists, daysLeftToUse, isSkippedUpdateNotice } = useSelector(state => state.activation);
  const dispatch = useDispatch();

  if (
    isSkippedUpdateNotice
    || !isLicenseExists
    || daysLeftToUse === null
    || daysLeftToUse >= DAYS_LEFT_FOR_NOTIFICATION
  ) {
    return null;
  }

  const showInfoClickHandler = () => {
    dispatch(skipUpdateNotice());
    history.push({ pathname: LICENSE_STATE_PAGE });
    save('isSkippedUpdateNotice', true);
  };

  const closeClickHandler = (ev: any) => {
    ev.stopPropagation();
    dispatch(skipUpdateNotice());
    save('isSkippedUpdateNotice', true);
  };
  const message = (daysLeftToUse === 0)
    ? t('License configuration file expired The application works in read-only mode')
    : t('Less than 30 days left before license configuration file expires');

  return (
    <Box className={classes.container} onClick={showInfoClickHandler}>
      <Box className={classes.leftSide}>
        <WarningIcon className={classes.icon} />
        <Typography component="span" className={classes.text}>
          {message}
        </Typography>
      </Box>
      <Box>
        <KLButton
          variant="outlined"
          color="secondary"
          onClick={closeClickHandler}
          className={classes.btn}
        >
          {t('Close')}
        </KLButton>
      </Box>
    </Box>
  );
};
