import { takeLatest, put, call, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { addNotification } from 'services/system/slice';
import { load, save } from 'utils/storage';
import { COLUMNS } from 'global/storage';
import {
  listRequest,
  listStart,
  listSuccess,
  listCount,
  detailsRequest,
  detailsStart,
  detailsSuccess,
  incidentsRequest,
  incidentsStart,
  incidentsSuccess,
  suggestionRequest,
  startSuggestion,
  setSuggestion,
  columnsGetRequest,
  columnsSetRequest,
  setColumns,
  error,
} from './slice';
import {
  postAssetList,
  postAssetCount,
  postAssetDetails,
  postAssetIncidentsList, postAssetSuggestion,
} from './api';
import {
  AssetListSagaPayload,
  AssetListColumnsPayload,
  AssetSuggestionPayload,
  AssetStatus,
} from './types';

function* fetchAssetList(action: PayloadAction<AssetListSagaPayload>): any {
  yield put(listStart());

  try {
    const [items, count] = yield all([
      call(postAssetList, action.payload),
      call(postAssetCount, action.payload),
    ]);
    yield put(listSuccess(items));
    yield put(listCount(count));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchAssetDetails(action: PayloadAction<string>) {
  yield put(detailsStart());

  try {
    const response = yield call(postAssetDetails, action.payload);
    yield put(detailsSuccess(response));
    // yield put(detailsSuccess({ ...response, status: AssetStatus.Offline }));
    // yield put(detailsSuccess({ ...response, status: AssetStatus.Absent }));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));

    if (message !== 'notFound') {
      yield put(addNotification({ message, options: { variant: 'error' } }));
    }
  }
}

function* fetchAssetIncidents(action: PayloadAction<string>) {
  yield put(incidentsStart());

  try {
    const response = yield call(postAssetIncidentsList, action.payload);
    yield put(incidentsSuccess(response));
  } catch (err) {
    const message = err.message || err;
    yield put(incidentsSuccess([]));
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* fetchAssetSuggestion(action: PayloadAction<AssetSuggestionPayload | string>) {
  yield put(startSuggestion());

  try {
    let searchPhrase: string;
    let tenantsNames: string[] = [];
    if (typeof action.payload === 'string') {
      searchPhrase = action.payload;
    } else {
      searchPhrase = action.payload.searchPhrase;
      tenantsNames = action.payload.tenantsNames;
    }
    const suggestion = yield call(postAssetSuggestion, searchPhrase, tenantsNames);
    yield put(setSuggestion(suggestion));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* getAssetColumns(): any {
  // TODO: Remove this after backend columns config saving will be done
  const columns = yield load(COLUMNS);
  yield put(setColumns(columns?.assets || null));
}

function* setAssetColumns(action: PayloadAction<AssetListColumnsPayload>): any {
  // TODO: Remove this after backend columns config saving will be done
  const columns = yield load(COLUMNS);
  yield save(COLUMNS, { ...columns, assets: action.payload });
}

export const assetSaga = [
  takeLatest(listRequest.type, fetchAssetList),
  takeLatest(detailsRequest.type, fetchAssetDetails),
  takeLatest(incidentsRequest.type, fetchAssetIncidents),
  takeLatest(suggestionRequest.type, fetchAssetSuggestion),
  takeLatest(columnsGetRequest.type, getAssetColumns),
  takeLatest(columnsSetRequest.type, setAssetColumns),
];
