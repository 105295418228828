import React, { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import styles from './styles';

const useStyles = makeStyles(styles);

export type SpoilerProps = PropsWithChildren<{
  label: ReactNode;
}>

export function Spoiler({ label, children }: SpoilerProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = useCallback(() => setOpen(o => !o), []);
  return (
    <div className={classNames(classes.spoiler)}>
      <div onClick={toggleOpen} className={classes.spoilerHeader}>
        <span className={classes.spoilerLabel}>{label}</span>&nbsp;
        <ArrowIcon width={12} height={12} direction={open ? 'top' : 'bottom'} />
      </div>
      <div className={classNames(classes.spoilerBody, { [classes.spoilerBodyOpen]: open })}>
        {children}
      </div>
    </div>
  );
}
