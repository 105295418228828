import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    '& .note-button-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      '& .note-button-container': {
        marginTop: theme.spacing(2),
        '& button': {
          width: '100% !important',
        },
      },
    },
  },
});
