import { Theme, createStyles } from '@material-ui/core/styles';
import { EditorProps } from 'react-draft-wysiwyg';
import { KLEditorProps } from './index';

export default (theme: Theme) => createStyles({
  root: {},
  wrapper: ({ toolbarPosition }: KLEditorProps) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: toolbarPosition === 'top' ? 'column' : 'column-reverse',
  }),
  toolbar: ({ toolbarPosition }: KLEditorProps) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: toolbarPosition === 'top' ? 2 : 0,
  }),
  toolbarInline: {
    display: 'flex',
    order: -1,
  },
  toolbarOption: ({ toolbarPosition, readOnly }: KLEditorProps & EditorProps) => ({
    marginLeft: 4,
    marginBottom: toolbarPosition === 'top' ? 2 : 0,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    filter: 'invert(0.5)',
    fontSize: 0,
    cursor: 'pointer',
    pointerEvents: readOnly ? 'none' : 'initial',
    '&:hover': {
      filter: 'none',
    },
    '&[aria-selected="true"]': {
      filter: 'none',
      backgroundColor: '#C4C4C4',
      borderRadius: 2,
    },
    '&[aria-disabled="true"]': {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  }),
  controls: ({ toolbarPosition }: KLEditorProps) => ({
    order: toolbarPosition === 'top' ? 2 : -2,
    marginRight: 'auto',
  }),
  attachments: ({ toolbarPosition, files }: KLEditorProps) => ({
    order: toolbarPosition === 'top' ? 1 : -3,
    flexBasis: '100%',
    width: '100%',
    marginBottom: files?.length ? theme.spacing(1) : 0,
  }),
  label: ({ toolbarPosition }: KLEditorProps) => ({
    order: toolbarPosition === 'top' ? -2 : 0,
    marginRight: 'auto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    alignSelf: 'flex-end',
  }),
  editor: ({ minRows, maxRows, fontSize = 16, lineHeight = 24 }: KLEditorProps) => ({
    padding: '6px 8px',
    border: '1px solid #B3B3B3',
    borderRadius: 3,
    boxShadow: 'inset 0 3px 4px rgba(29, 29, 29, 0.05), inset 0 2px 4px rgba(29, 29, 29, 0.05)',
    fontSize: theme.typography.pxToRem(fontSize),
    lineHeight: theme.typography.pxToRem(lineHeight),
    minHeight: `calc(${lineHeight}px * ${minRows})`,
    maxHeight: `calc(${lineHeight}px * ${maxRows})`,
    boxSizing: 'content-box',
    marginBottom: theme.spacing(1),
    '& a': {
      color: theme.palette.primary.main,
    },
    '& pre': {
      border: '1px dashed #D8D8D8',
      padding: theme.spacing(0.5, 1),
      backgroundColor: '#ECF2FA',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
    // '& pre > pre': {
    //   border: 'none',
    //   padding: 0,
    // },
  }),
  editorDisabled: {
    backgroundColor: 'hsl(0,0%,95%)',
  },
});
