import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getAttachmentFile } from 'services/attachments/api';
import styles from './styles';

const useStyles = makeStyles(styles);

export const CustomImage = (props: Partial<HTMLImageElement> & { clientId: string | null; secret: string }) => {
  const { clientId, secret, src, alt } = props;
  const classes = useStyles();
  const [updatedSrc, setSrc] = useState<string>('');

  useEffect(() => {
    const parsedUrl = /attachment\/(.*)\/download/.exec(src || '');

    if (clientId && parsedUrl && parsedUrl[1]) {
      getAttachmentFile(clientId, secret, parsedUrl[1]).then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          setSrc(base64data);
        };
      });
    }

  }, [clientId, secret, src]);

  return <img className={classes.image} src={updatedSrc} alt={alt} />;
};
