import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarKey, SnackbarMessage, OptionsObject } from 'notistack';

const name = 'system';

type Notification = {
  key: SnackbarKey;
  message: SnackbarMessage;
  options?: OptionsObject;
  dismissed?: boolean;
};

export type NotificationPayload = {
  message: SnackbarMessage;
  options?: OptionsObject;
};

type SystemState = {
  notifications: Notification[];
  isSideBarOpened: boolean;
};

const initialState: SystemState = {
  notifications: [],
  isSideBarOpened: true,
};

const systemSlice = createSlice({
  name,
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<NotificationPayload>) {
      const key = action.payload.options && action.payload.options.key;
      state.notifications.push({
        ...action.payload,
        key: key || new Date().getTime() + Math.random(),
      });
    },
    closeNotification(state, action: PayloadAction<SnackbarKey | undefined>) {
      const dismissAll = !action.payload;
      state.notifications.forEach(notification => {
        notification.dismissed = dismissAll || notification.key === action.payload;
      });
    },
    removeNotification(state, action: PayloadAction<SnackbarKey>) {
      const idx = state.notifications.findIndex(notification => notification.key === action.payload);
      state.notifications.splice(idx, 1);
    },
    toggleSideBar(state) {
      state.isSideBarOpened = !state.isSideBarOpened;
    },
  },
});

export const {
  addNotification,
  closeNotification,
  removeNotification,
  toggleSideBar,
} = systemSlice.actions;
export default systemSlice.reducer;
