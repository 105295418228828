import { createStyles, Theme } from '@material-ui/core/styles';
import landingHeaderImg from 'assets/images/landing-header.png';

export default (theme: Theme) => createStyles({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    backgroundImage: `url(${landingHeaderImg})`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    justifyContent: 'center',
    '& .product-overview': {
      marginLeft: theme.spacing(4),
      '& > h3': {
        color: '#FFFFFF',
        fontSize: 22,
        marginBottom: theme.spacing(2.5),
        maxWidth: theme.spacing(32),
      },
      '& > p': {
        margin: `${theme.spacing(2.5)}px 0 ${theme.spacing(5)}px 0`,
      },
    },
    '& .button-grid': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up('xs')]: {
      '& .button-grid > div': {
        paddingLeft: '32px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .product-overview': {
        marginLeft: theme.spacing(2),
      },
      '& .button-grid > div': {
        paddingLeft: '0',
        '& button': {
          width: '100% !important',
        },
      },
    },
  },
  loginButton: {
    marginTop: 8,
    color: '#fff',
    borderColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});
