import { createStyles, Theme } from '@material-ui/core/styles';
import { CounterProps } from './index';

export default (theme: Theme) => createStyles({
  root: {},
  counter: ({ maxTextSize, textSize }: CounterProps) => ({
    order: -3,
    width: '100%',
    flexBasis: '100%',
    flexDirection: 'row-reverse',
    display: 'flex',
    padding: 0,
    color: textSize > maxTextSize ? 'red' : theme.palette.common.black,
  }),
});
