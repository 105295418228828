import { createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import isEqual from 'react-fast-compare';
import { IncidentDetails } from 'services/incidents/types';
import arraySort from 'array-sort';

export interface EntityState<T, DT, F extends object = {}, C extends object = {}> {
  list: (T & { wasRead?: boolean })[];
  page?: number;
  pageSize?: number;
  pageStep?: number;
  loadingCount?: number;
  filters?: F;
  columns?: C | null;
  isLoading: boolean;
  count: number;
  error: string | null;
  details?: DT | null;
  isCreating?: boolean;
  isCreated?: boolean;
  isUpdating?: boolean;
  isUpdated?: boolean;
  isDeleting?: boolean;
  isDeleted?: boolean;
  search?: string;
  unreadCount?: number;
  incidents?: IncidentDetails[];
  users?: any;
  filteringEntities?: any;
  lastVisited?: string | null;
}

export type EntitySortingOrder = 'asc' | 'desc';

export type EntitySorting<T> = {
  field: keyof T;
  order?: EntitySortingOrder;
};

export enum EntityType {
  INCIDENT = 0,
  INCIDENT_ATTACHMENT = 1,
  INCIDENT_COMMENT = 2,
  INCIDENT_RESPONSE = 3,
  NOTIFICATIONS = 4,
}

export interface EntityColumn {
  hidden: boolean;
  order: number;
}

export const createEntitySlice = <T, DT, F extends object, C extends object,
  Reducers extends SliceCaseReducers<EntityState<T, DT, F, C>>>({
    name = '',
    initialState,
    reducers,
  }: {
    name: string;
    initialState: EntityState<T, DT, F, C>;
    reducers: ValidateSliceCaseReducers<EntityState<T, DT, F, C>, Reducers>;
}) => createSlice({
    name,
    initialState,
    reducers: {
      setPage(state, action: PayloadAction<number>) {
        state.pageStep = action.payload - (state.page || 1);
        state.page = action.payload;
        state.loadingCount = 0;
      },
      incrementLoadingCount(state: EntityState<T, DT, F, C>) {
        state.loadingCount = (state.loadingCount || 0) + 1;
      },
      setPageSize(state, action: PayloadAction<number>) {
        state.pageSize = action.payload;
      },
      setFilters(state: EntityState<T, DT, F, C>, action: PayloadAction<F>) {
        state.filters = action.payload;
      },
      setColumns(state: EntityState<T, DT, F, C>, action: PayloadAction<C | null>) {
        state.columns = action.payload;
      },
      setSearch(state, action: PayloadAction<string>) {
        state.search = action.payload;
      },
      listStart(state) {
        state.isLoading = true;
        // state.details = null;
        state.error = null;
        state.isCreated = false;
        state.isUpdated = false;
        state.isDeleted = false;
      },
      listSuccess(state: EntityState<T, DT, F, C>, action: PayloadAction<T[]>) {
        state.list = action.payload;
        state.isLoading = false;
      },
      listCount(state, action: PayloadAction<number>) {
        state.count = action.payload;
      },
      listSort(state: EntityState<T, DT, F, C>, action: PayloadAction<EntitySorting<T>>) {
        const { order, field } = action.payload;
        state.list = arraySort<T>(state.list, field as string, { reverse: order === 'desc' });
      },
      detailsStart(state) {
        state.loadingCount = 0;
        state.isLoading = true;
        state.details = null;
        state.error = null;
      },
      detailsSuccess(state: EntityState<T, DT, F, C>, action: PayloadAction<DT | null>) {
        state.details = action.payload;
        state.isLoading = false;
      },
      detailsSocket(state: EntityState<T, DT, F, C>, action: PayloadAction<DT>) {
        state.details = { ...state.details, ...action.payload };
      },
      createStart(state) {
        state.isCreating = true;
        state.isCreated = false;
        state.error = null;
      },
      createSuccess(state: EntityState<T, DT, F, C>,
        action: PayloadAction<T & {wasRead?: boolean; filtersSet: boolean | undefined}>) {
        const { filtersSet, ...createdItem } = action.payload; 
        if (!filtersSet) {
          if (state.list.every(item => !isEqual(item, { ...createdItem, wasRead: item.wasRead }))) {
            state.list.push(createdItem as (T & { wasRead?: boolean }));
          }
        }
        state.isCreating = false;
        state.isCreated = true;
        state.isUpdated = false;
        state.isDeleted = false;
      },
      createSocket(state: EntityState<T, DT, F, C>, action: PayloadAction<T & {wasRead?: boolean}>) {
        if (state.list.every(item => !isEqual(item, { ...action.payload, wasRead: item.wasRead }))) {
          state.list.push(action.payload);
          state.count += 1;
        }
      },
      createSocketUp(state: EntityState<T, DT, F, C>, action: PayloadAction<T>) {
        if (state.list.every(item => !isEqual(item, action.payload))) {
          state.list = [
            action.payload,
            ...state.list,
          ];
          state.count += 1;
        }
      },
      updateStart(state) {
        state.isUpdating = true;
        state.isUpdated = false;
        state.error = null;
      },
      updateSuccess(state: EntityState<T, DT, F, C>, action: PayloadAction<T & {idField: keyof T}>) {
        const { idField } = action.payload;
        const idx = state.list.findIndex(item => item[idField] === action.payload[idField]);
        state.list[idx] = action.payload;
        state.isUpdating = false;
        state.isUpdated = true;
        state.isCreated = false;
        state.isDeleted = false;
      },
      updateSocket(state: EntityState<T, DT, F, C>, action: PayloadAction<T & {idField: keyof T}>) {
        const { idField } = action.payload;
        const idx = state.list.findIndex(item => item[idField] === action.payload[idField]);
        state.list[idx] = action.payload;
      },
      deleteStart(state) {
        state.isDeleting = true;
        state.isDeleted = false;
        state.error = null;
      },
      deleteSuccess(state: EntityState<T, DT, F, C>, action: PayloadAction<{idField: keyof T; id: number | string}>) {
        const { idField, id } = action.payload;
        const idx = state.list.findIndex(item => String(item[idField]) === String(id));
        if (idx !== -1) {
          state.list.splice(idx, 1);
        }
        state.isDeleting = false;
        state.isDeleted = true;
        state.isCreated = false;
        state.isUpdated = false;
      },
      deleteSocket(state: EntityState<T, DT, F, C>, action: PayloadAction<{idField: keyof T; id: number | string}>) {
        const { idField, id } = action.payload;
        const idx = state.list.findIndex(item => String(item[idField]) === String(id));
        if (idx !== -1) {
          state.list.splice(idx, 1);
          state.count -= 1;
        }
      },
      setUnreadCount(state: EntityState<T, DT, F, C>, action: PayloadAction<number>) {
        state.unreadCount = action.payload;
      },
      incrementUnreadCount(state: EntityState<T, DT, F, C>) {
        state.unreadCount = (state.unreadCount || 0) + 1;
      },
      markAsRead(state: EntityState<T, DT, F, C>, action: PayloadAction<{idField: keyof T; entityIds: string[]}>) {
        const { entityIds, idField } = action.payload;
        entityIds.forEach(id => {
          const item = state.list.find(item => String(item[idField]) === String(id));
          if (item && !item.wasRead) {
            item.wasRead = true;
            state.unreadCount = state.unreadCount && state.unreadCount - 1;
          }
        });
      },
      setLastVisited(state: EntityState<T, DT, F, C>, action: PayloadAction<string | undefined | null>) {
        state.lastVisited = action.payload;
      },
      error(state, action: PayloadAction<string>) {
        state.isLoading = false;
        state.isCreating = false;
        state.isUpdating = false;
        state.isDeleting = false;
        state.error = action.payload;
      },
      ...reducers,
    },
  });
