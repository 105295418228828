import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {},
  popoverPaper: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 0,
    marginTop: 1,
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2, 2),
    '& button + button': {
      marginLeft: theme.spacing(1),
    },
  },
});
