import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import {
  APIkeysState,
  closeDrawer,
} from 'services/APIkeys/slice';

import {
  detailsSuccess,
} from 'services/tenants/slice';

import { useSelector } from 'store/hook';
import { useDispatch } from 'react-redux';
import { KLTabs } from 'components/KLTabs';
import { useConfirmation } from 'components/Confirmation';
import { usePermissions } from 'components/Permissions/hooks';
import { KLDrawer } from 'components/KLDrawer';
import { NotificationSettings } from './components/NotificationSettings';
import { IncidentSettings } from './components/IncidentSettings';
import { ReportSettingsTable } from './components/ReportSettingsTable';
import { APIkeys } from './components/APIkeys';
import { APIKeysControl } from './components/APIKeysControl';
import { TenantForm } from './components/TenantForm';
import styles from './styles';
import { UserListTab } from './components/UserListTab';
import { Tenants } from './components/Tenants';

// eslint-disable
import { Organizations } from './components/Organizations';
import { GeneralSettings } from './components/GeneralSettings';

const useStyles = makeStyles(styles);

export interface UserRoles {
  [roleId: number]: string;
}

export const SettingsPage: React.FC = () => {
  const { isOpen, drawer } = useSelector((state: { APIkeys: APIkeysState }) => state.APIkeys);
  const { details }: any = useSelector(state => state.tenants);
  const { isActiveOrganization } = useSelector(state => state.auth);
  const { t } = useTranslation(['SettingsPage']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [wasChanges, setWasChanges] = useState(false);
  const { openConfirmation, closeConfirmation } = useConfirmation();
  const [
    viewUserNotificationsSettingsPerm,
    viewAutoResponseSettingsPerm,
    viewOrganizationsPerm,
    viewOrganizationUsers,
    viewAutoReportSettings,
    viewOrganizationRobots,
    viewOrganizationTenants,
    editOrganizationLicense,
  ] = usePermissions([
    'view_per_organization_user_notifications_settings',
    'view_organization_auto_response_settings',
    'edit_processing_integration_settings',
    'view_organization_users',
    'view_organization_auto_reporting_settings',
    'view_organization_robots',
    'view_organization_tenants',
    'edit_organization_license',
  ]);

  const closeDrawerHandler = () => {
    dispatch(closeDrawer());
  };

  const closeTenantsForm = () => {
    dispatch(detailsSuccess(null));
  };

  const roles: UserRoles = {
    0: t('UserRoles.Administrator'),
    1: t('UserRoles.Senior Security Officer'),
    2: t('UserRoles.Security Officer'),
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <KLTabs
        tabs={[
          {
            label: t('User list'),
            component: <UserListTab roles={roles} />,
            path: '#user-list',
            isDefault: true,
            hidden: !viewOrganizationUsers,
          },
          {
            label: t('Notification settings'),
            component: <NotificationSettings setWasChanges={setWasChanges} />,
            path: '#notification-settings',
            hidden: !viewUserNotificationsSettingsPerm,
          },
          {
            label: t('Incidents'),
            component: <IncidentSettings setWasChanges={setWasChanges} />,
            path: '#incidents',
            hidden: !viewAutoResponseSettingsPerm,
          },
          {
            label: t('Organizations'),
            component: <Organizations />,
            path: '#organizations',
            hidden: !viewOrganizationsPerm,
          },
          {
            label: t('ReportSettings.Report settings'),
            component: <ReportSettingsTable />,
            path: '#report-settings',
            hidden: !isActiveOrganization || !viewAutoReportSettings,
          },
          {
            label: t('ReportSettings.API'),
            component: <APIkeys />,
            path: '#api-keys',
            hidden: !viewOrganizationRobots,
          },
          {
            label: t('Tenants.Tenants'),
            component: <Tenants />,
            path: '#tenants',
            hidden: !isActiveOrganization || !viewOrganizationTenants,
          },
          {
            label: t('GeneralSettings.General Settings'),
            component: <GeneralSettings setWasChanges={setWasChanges} />,
            path: '#general-settings',
            hidden: !editOrganizationLicense,
          },
        ]}
        withRouter
        noBorder
      />

      <KLDrawer
        onClose={closeDrawerHandler}
        open={!!isOpen}
        title={drawer?.status !== undefined ? t('API.Token info') : t('API.Generate token')}
        noPadding
        fullContentSpace
      >
        <APIKeysControl roles={roles} />
      </KLDrawer>

      <KLDrawer
        onClose={closeTenantsForm}
        open={!!details}
        title={t('Tenants.Tenant settings')}
        noPadding
        fullContentSpace
      >
        <TenantForm />
      </KLDrawer>

      <NavigationPrompt
        when={wasChanges}
        afterConfirm={() => {
          closeConfirmation();
          setWasChanges(false);
        }}
      >
        {({ onConfirm, onCancel }) => {
          openConfirmation({
            title: t('common:Leave this page'),
            content: t('common:Unsaved changes'),
            onConfirm,
            confirmText: t('common:Yes'),
            onDismiss: onCancel,
            dismissText: t('common:No'),
          });
          return false;
        }}
      </NavigationPrompt>
    </Grid>
  );
};
