import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4, 6),
  },
  group: {
    marginBottom: theme.spacing(3),
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    color: '#58595B',
  },
  email: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.black,
  },
  telegram: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  settings: {
    textAlign: 'left',
    '& th': {
      paddingBottom: theme.spacing(2),
    },
    '& td': {
      paddingRight: theme.spacing(6),
      paddingBottom: theme.spacing(2),
    },
  },
  saveButton: {
    marginRight: theme.spacing(3),
    minWidth: theme.spacing(13),
  },
  cancelButton: {
    minWidth: theme.spacing(13),
  },
  columnHeader: {
    maxWidth: 100,
    textAlign: 'left',
  },
  childRow: {
    paddingLeft: theme.spacing(4),
  },
});
