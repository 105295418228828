import yn from 'yn';

export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
export const SOCKET_IO = (window as any).REACT_APP_SOCKET_IO_SERVER || process.env.REACT_APP_SOCKET_IO_SERVER;
export const UIS_AUTH_URL = (window as any).REACT_APP_UIS_AUTH_URL || process.env.REACT_APP_UIS_AUTH_URL;
export const UIS_EU_AUTH_URL = (window as any).REACT_APP_UIS_EU_AUTH_URL || process.env.REACT_APP_UIS_EU_AUTH_URL;
export const UIS_PROFILE_URL = (window as any).REACT_APP_UIS_PROFILE_URL || process.env.REACT_APP_UIS_PROFILE_URL;
export const UIS_EU_PROFILE_URL = (window as any).REACT_APP_UIS_EU_PROFILE_URL
  || process.env.REACT_APP_UIS_EU_PROFILE_URL;
export const SUPPORT_URL_RU = (window as any).REACT_APP_SUPPORT_URL_RU
  || process.env.REACT_APP_SUPPORT_URL_RU
  || 'https://support.kaspersky.ru/';
export const SUPPORT_URL_EN = (window as any).REACT_APP_SUPPORT_URL_EN
  || process.env.REACT_APP_SUPPORT_URL_EN
  || 'https://support.kaspersky.com/';
export const PRIVATE_KSN_URL_EN = 'https://support.kaspersky.com/KSC/13.2/en-US/213008.htm';
export const PRIVATE_KSN_URL_RU = 'https://support.kaspersky.com/KSC/13.2/ru-RU/213008.htm';

export const HELP_URL = (window as any).REACT_APP_HELP_URL
  || process.env.REACT_APP_HELP_URL
  // todo: place prod link to dockerfile and set stage link here 'https://stage.help.kaspersky.com/MDR'
  // || 'https://help.kaspersky.com/MDR';
  || 'https://click.kaspersky.com';

export const TELEGRAM_BOT_NAME = (window as any).REACT_APP_TELEGRAM_BOT_NAME || process.env.REACT_APP_TELEGRAM_BOT_NAME;
export const BUILD_NUMBER = (window as any).REACT_APP_BUILD_NUMBER || process.env.REACT_APP_BUILD_NUMBER;
export const BUILD_DATE = (window as any).REACT_APP_BUILD_TIME || process.env.REACT_APP_BUILD_TIME;
export const BUILD_BRANCH = (window as any).REACT_APP_BUILD_BRANCH || process.env.REACT_APP_BUILD_BRANCH;
export const SKIP_TRANSLATION_DEBUG = (window as any).REACT_APP_SKIP_TRANSLATION_DEBUG
  || process.env.REACT_APP_SKIP_TRANSLATION_DEBUG;
export const IS_HIDE_TENANTS = yn((window as any).REACT_APP_IS_HIDE_TENANTS
  || process.env.REACT_APP_IS_HIDE_TENANTS);
export const IS_HIDE_ASSET_STATUSES = yn((window as any).REACT_APP_IS_HIDE_ASSET_STATUSES
  || process.env.REACT_APP_IS_HIDE_ASSET_STATUSES);
export const IS_HIDE_LATAM = yn((window as any).REACT_APP_IS_HIDE_LATAM
  || process.env.IS_HIDE_LATAM);
export const IS_HIDE_REST_WORLD_REGION = yn((window as any).REACT_APP_IS_HIDE_REST_WORLD_REGION
  || process.env.REACT_APP_IS_HIDE_REST_WORLD_REGION);
export const HIDE_ASSET_STATUSES_FILTER = yn((window as any).REACT_APP_HIDE_ASSET_STATUSES_FILTER
  || process.env.REACT_APP_HIDE_ASSET_STATUSES_FILTER);
export const HIDE_AGREEMENT_CONFIRMATION_DIALOG = yn((window as any).REACT_APP_HIDE_AGREEMENT_CONFIRMATION_DIALOG
  || process.env.REACT_APP_HIDE_AGREEMENT_CONFIRMATION_DIALOG);
