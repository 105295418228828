import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import styles from './styles';

const useStyles = makeStyles(styles);

export type MovePrevNextProps<T> = {
  list: T[];
  onClick: (id: string) => void;
  entityId: string;
  entityIdField: keyof T;
  page?: number;
  pageSize?: number;
  pageStep?: number;
  onPageChange?: (page?: number) => void;
  count?: number;
  short?: boolean;
  className?: string;
};

export function MovePrevNext<T extends { [key: string]: any }>(props: MovePrevNextProps<T>) {
  const {
    list,
    onClick,
    entityId,
    entityIdField,
    page = 1,
    pageSize = 10,
    pageStep = 0,
    onPageChange,
    count = list.length,
    short = false,
    className,
  } = props;
  const classes = useStyles(short);
  const { t } = useTranslation('pagination');

  const currentIndex = useMemo(() => list.findIndex(entity => entity[entityIdField] === entityId), [
    list, entityId, entityIdField,
  ]);

  useEffect(() => {
    if (list.length > 0 && currentIndex === -1 && pageStep !== 0) {
      onClick(list[pageStep > 0 ? 0 : pageSize - 1][entityIdField]);
    }
  }, [list, currentIndex, entityIdField, onClick, pageStep]);

  const handleClick = (step: number, ev: React.MouseEvent) => {
    ev.preventDefault();

    if (currentIndex < 0) {
      return;
    }

    const newIndex = currentIndex + step;

    if ((newIndex < 0 && page === 1) || (newIndex + (page - 1) * pageSize) >= count) {
      return;
    }

    if (newIndex < 0 || newIndex >= pageSize) {
      const newPage = newIndex < 0 ? page - 1 : page + 1;
      if (onPageChange) {
        onPageChange(newPage);
      }
    } else {
      onClick(list[newIndex][entityIdField]);
    }
  };

  const isPrevDisabled = useMemo(() => currentIndex === 0 && page === 1, [currentIndex, page]);
  const isNextDisabled = useMemo(() => (
    currentIndex + 1 + (page - 1) * pageSize === count
  ), [currentIndex, page, pageSize, count]);

  if (list.length === 0) {
    return null;
  }

  const PrevIcon = (
    <ArrowIcon
      direction="left"
      classes={{ root: classNames(classes.icon, classes.iconPrev, isPrevDisabled && classes.iconDisabled) }}
      withLine={short}
    />
  );

  const NextIcon = (
    <ArrowIcon
      direction="right"
      classes={{ root: classNames(classes.icon, classes.iconNext, isNextDisabled && classes.iconDisabled) }}
      withLine={short}
    />
  );

  return (
    <div className={classNames(classes.root, className)}>
      <a
        className={classNames(classes.link, isPrevDisabled && classes.linkDisabled)}
        href="#"
        onClick={(ev) => handleClick(-1, ev)}
      >
        {short ? (
          <IconButton color="secondary" size="small">
            {PrevIcon}
          </IconButton>
        ) : PrevIcon}
        {!short && t('Previous')}
      </a>
      <a
        className={classNames(classes.link, isNextDisabled && classes.linkDisabled)}
        href="#"
        onClick={(ev) => handleClick(1, ev)}
      >
        {!short && t('Next')}
        {short ? (
          <IconButton color="secondary" size="small">
            {NextIcon}
          </IconButton>
        ) : NextIcon}
      </a>
    </div>
  );
}
