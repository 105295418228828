import { Theme, createStyles } from '@material-ui/core/styles';
import { KLSelectProps } from 'components/KLSelect';

export default (theme: Theme) => createStyles({
  root: ({ dense }: KLSelectProps) => ({
    paddingRight: `${dense ? 18 : 30}px !important`,
    '&:focus': {
      backgroundColor: 'transparent',
    },
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: dense ? 0 : 8,
    marginLeft: -1,
    marginRight: -1,
  }),
  icon: ({ dense }: KLSelectProps) => ({
    fill: dense ? theme.palette.primary.main : '#979797',
    flexShrink: 0,
    position: 'absolute',
    right: dense ? 0 : 12,
    pointerEvents: 'none',
  }),
  input: ({ dense }: KLSelectProps) => ({
    fontSize: theme.typography.fontSize,
    fontWeight: dense ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
    color: dense ? theme.palette.primary.main : theme.palette.common.black,
    lineHeight: theme.typography.pxToRem(20),
    border: dense ? 'none' : '1px solid #B3B3B3',
    borderRadius: 3,
    boxShadow: dense ? 'none' : 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05);',
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  }),
  paper: {
    borderRadius: 0,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    marginTop: 2,
  },
  list: {
    padding: theme.spacing(1, 0),
  },
  item: {
    '&:hover': {
      backgroundColor: 'transparent',
      fontWeight: theme.typography.fontWeightBold,
    },
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(32),
    padding: theme.spacing(0, 1),
  },
  itemSelected: {
    backgroundColor: 'transparent !important',
    fontWeight: theme.typography.fontWeightBold,
    '&: hover': {
      backgroundColor: 'transparent !important',
    },
    '& svg': {
      display: 'inline-block',
    },
  },
  itemSelectedIcon: {
    display: 'none',
    marginLeft: theme.spacing(1),
  },
});
