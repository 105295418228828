import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0, 4),
  },
  formControl: {
    marginBottom: theme.spacing(3),
    '& label': {
      color: '#000000',
    },
    '& label.Mui-focused': {
      color: '#000000',
    },
  },
  label: {
    paddingBottom: 2,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
  },
  input: {
    '&:focus': {
      borderColor: '#B3B3B3',
    },
  },
});
