import { postRequest } from 'utils/http';
import {
  GeneralConfigSetPayload,
} from './types';

const url = 'settings';

export function getGeneralConfig(userId: string) {
  return postRequest(`${url}/getGeneral`, {
    userId,
  });
}

export function setGeneralConfig(payload: GeneralConfigSetPayload) {
  return postRequest(`${url}/setGeneral`, {
    ...payload,
  });
}
