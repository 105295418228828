import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import {
  ACCESS_DENIED,
  ALREADY_CHANGED,
  EMAIL_ALREADY_USED,
  INCIDENT_REVOKED,
  INVITE_ALREADY_ACTIVATED,
  INVITE_EMAIL_MISMATCH,
  INVITE_EXPIRED,
  NOT_FOUND,
  TENANT_EXISTS_ALREADY,
  UNAUTHORIZED,
  UNVERIFIED_USER_EMAIL,
  LICENSE_ALREADY_ACTIVATED, ORIGIN_MISMATCH, DUPLICATE_SCHEDULE, MAX_SCHEDULES,
} from 'global/errors';
import { getT } from 'utils/i18n';
import { load } from './storage';

const clientTzOffset = (new Date().getTimezoneOffset() || 0) * 60;

const api = axios.create({
  baseURL: '/api/',
  timeout: 300000, // 5 min
  headers: {
    Authorization: `Bearer ${load('jwt')}`,
    'client-tz-offset': clientTzOffset,
  },
  withCredentials: true,
});

// TODO: temporary solution. Then you will need to take the error text from translations
type KnownErrors = {
  [key: string]: string;
}

// todo: get error names from global/errors constants
const knownErrors: KnownErrors = {
  [ACCESS_DENIED]: ACCESS_DENIED,
  [ALREADY_CHANGED]: ALREADY_CHANGED,
  [EMAIL_ALREADY_USED]: EMAIL_ALREADY_USED,
  [INCIDENT_REVOKED]: INCIDENT_REVOKED,
  [INVITE_ALREADY_ACTIVATED]: INVITE_ALREADY_ACTIVATED,
  [INVITE_EMAIL_MISMATCH]: INVITE_EMAIL_MISMATCH,
  [INVITE_EXPIRED]: INVITE_EXPIRED,
  [NOT_FOUND]: NOT_FOUND,
  [TENANT_EXISTS_ALREADY]: TENANT_EXISTS_ALREADY,
  [UNAUTHORIZED]: UNAUTHORIZED,
  [UNVERIFIED_USER_EMAIL]: UNVERIFIED_USER_EMAIL,
  [LICENSE_ALREADY_ACTIVATED]: LICENSE_ALREADY_ACTIVATED,
  [ORIGIN_MISMATCH]: ORIGIN_MISMATCH,
  [DUPLICATE_SCHEDULE]: DUPLICATE_SCHEDULE,
  [MAX_SCHEDULES]: MAX_SCHEDULES,
};

function handleHttpError(error: AxiosError): string | { message: string; data?: any } {
  if (error && error.message === 'Network Error') {
    return getT('errors:Internet disconnected error');
  }

  const genericErrorMessage = getT('errors:Something failed try again');
  if (!error.response || !error.response.data) {
    return genericErrorMessage;
  }

  const { message, errorMessage } = error.response.data;
  if (message && knownErrors[message]) {
    if (message === ORIGIN_MISMATCH) {
      return {
        message,
        data: error.response.data,
      };
    }

    if (message === DUPLICATE_SCHEDULE) {
      return {
        message: getT('errors:Duplicate schedule'),
      };
    }

    if (message === MAX_SCHEDULES) {
      return {
        message: getT('errors:Max schedules'),
      };
    }
    return message;
  }

  return errorMessage || genericErrorMessage;
}

function makeHttpRequest<T>(apiCall: Function) {
  return new Promise<T>(async (resolve, reject) => {
    try {
      const data: AxiosResponse = await apiCall();
      resolve(data.data);
    } catch (e) {
      reject(handleHttpError(e));
    }
  });
}

export function downloadFile(path: string, data: any, options?: AxiosRequestConfig) {
  return api.post(path, {
    ...data,
    clientId: load('clientId'),
  }, {
    ...options,
    responseType: 'blob',
    timeout: 300000, // 5min
    headers: { Authorization: `Bearer ${load('jwt')}` },
  })
    .then(({ data, headers }) => {
      const filename = headers['content-disposition'].replace('attachment; filename=', '');
      const type = headers['content-type']
        .replace('; charset=utf-8', '') || 'application/octet-stream';
      
      const blob = new Blob([data], { type });
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}

export function getRequest(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest(() => api.get(path, {
    ...options,
    headers: { Authorization: `Bearer ${load('jwt')}` },
  }));
}

export function postRequest<T>(path: string, data?: any, options?: AxiosRequestConfig) {
  return makeHttpRequest<T>(() => api.post<T>(path, {
    clientId: load('clientId'),
    ...data,
  }, {
    ...options,
    headers: { Authorization: `Bearer ${load('jwt')}` },
  }));
}

export function putRequest<T>(path: string, data?: any, options?: AxiosRequestConfig) {
  return makeHttpRequest<T>(() => api.put<T>(path, {
    ...data,
    clientId: load('clientId'),
  }, {
    ...options,
    headers: { Authorization: `Bearer ${load('jwt')}` },
  }));
}

export function deleteRequest<T>(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest<T>(() => api.delete<T>(path, {
    ...options,
    data: {
      ...options?.data,
      clientId: load('clientId'),
    },
    headers: { Authorization: `Bearer ${load('jwt')}` },
  }));
}
