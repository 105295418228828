import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(1),
  },
  btn: {
    marginLeft: theme.spacing(2),
  },
  input: {
    minWidth: 250,
  },
  label: {
    paddingBottom: theme.spacing(1),
    lineHeight: 2,
  },
  left: {
    minWidth: 350,
  },
  table: {
    minWidth: 600,
    width: '40%',
    marginBottom: theme.spacing(2.5),
  },
  line: {
    minWidth: 600,
    width: '40%',
  },
});
