import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  formControl: {
    marginBottom: theme.spacing(2),
    '& label': {
      color: '#999999',
    },
  },
});
