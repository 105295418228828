import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CompositeTableCellProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const CompositeTableCell = (props: CompositeTableCellProps) => {
  const classes = useStyles();
  const { fields } = props;

  const items = fields.reduce((acc: ReactNode[], { label, value }) => {
    if (value === null || value === undefined) {
      return acc;
    }

    return [...acc, (
      <Box key={label}>
        <Typography component="span" className={classes.label}>{label}: </Typography>
        <Typography component="span" className={classes.value}> {String(value)}</Typography>
      </Box>
    )];
  }, []);

  return (
    <>
      {items.length ? items : '-'}
    </>
  );
};
