import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  spoiler: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '12px',
  },
  spoilerHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  spoilerLabel: {
    fontWeight: 700,
    marginRight: '16px',
  },
  spoilerBody: {
    display: 'none',
  },
  spoilerBodyOpen: {
    paddingTop: '8px',
    display: 'block',
  },
});
