import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { StandardProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export type KLChipProps = {
  borderColor?: string;
  bgColor?: string;
  fontColor?: string;
  fontSize?: number;
  lineHeight?: number;
  outlined?: boolean;
  bold?: boolean;
  uppercase?: boolean;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 3,
    height: 'auto',
    fontSize: (props: KLChipProps) => theme.typography.pxToRem(props.fontSize || 14),
    lineHeight: (props: KLChipProps) => theme.typography.pxToRem(props.lineHeight || 20),
    backgroundColor: (props: KLChipProps) => props.bgColor || 'inherit',
    borderColor: (props: KLChipProps) => props.borderColor || 'inherit',
    color: (props: KLChipProps) => props.fontColor || 'inherit',
    fontWeight: (props: KLChipProps) => (props.bold ? theme.typography.fontWeightBold : 'inherit'),
    textTransform: (props: KLChipProps) => (props.uppercase ? 'uppercase' : 'none'),
  },
  label: {
    marginTop: (props: KLChipProps) => (props.outlined ? 2 : 0),
  },
}));

export const KLChip: React.FC<StandardProps<KLChipProps & ChipProps, 'root' | 'label'>> = props => {
  const { borderColor, bgColor, fontColor, fontSize, lineHeight, outlined, bold, uppercase, ...chipProps } = props;
  const classes = useStyles({
    borderColor,
    bgColor,
    fontColor,
    fontSize,
    lineHeight,
    outlined,
    bold,
    uppercase,
    ...chipProps,
  });

  return (
    <Chip
      classes={{ root: classes.root, label: classes.label }}
      variant={outlined ? 'outlined' : 'default'}
      {...chipProps}
    />
  );
};
