import React, { useEffect } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSocket } from 'components/SocketIO';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hook';
import { CommentNotification } from 'services/comments/types';
import { ResponseNotification } from 'services/incidentResponses/types';
import { IncidentNotification } from 'services/incidents/types';
import { IncidentAttachmentNotification } from 'services/attachments/types';
import { addNotification, closeNotification } from 'services/system/slice';
import { newCountRequest } from 'services/incidents/slice';
import { agreementListRequest, infoSuccess } from 'services/activation/slice';
import { AgreementTitles, AgreementType, LicenseInfoSocketPayload } from 'services/activation/types';
import { UserRoleUpdateSocketPayload } from 'services/user/types';
import { logoutRequest } from 'services/auth/slice';
import { PrivateRoute } from 'components/PrivateRoute';
import { Notification } from 'components/Notification';
import { usePermissions } from 'components/Permissions/hooks';
import { IncidentListPage } from 'pages/IncidentListPage';
import { IncidentDetailsPage } from 'pages/IncidentDetailsPage';
import { AssetListPage } from 'pages/AssetListPage';
import { SettingsPage } from 'pages/SettingsPage';
import { ActivationPage } from 'pages/ActivationPage';
import { LicenseStatePage } from 'pages/LicenseStatePage';
import { GuidePage } from 'pages/GuidePage';
import { UserActivationPage } from 'pages/UserActivationPage';
import { ForbiddenErrorPage } from 'pages/ForbiddenErrorPage';
import { NotFoundErrorPage } from 'pages/NotFoundErrorPage';
import { StatisticsPage } from 'pages/StatisticsPage';
import { AboutPage } from 'pages/AboutPage';
import {
  ABOUT_PAGE,
  ACTIVATION_PAGE,
  ASSET_LIST_PAGE,
  FORBIDDEN_ERROR_PAGE,
  GUIDE_PAGE,
  INCIDENT_DETAILS_PAGE,
  INCIDENT_LIST_PAGE,
  LICENSE_STATE_PAGE,
  SETTINGS_PAGE,
  STATISTICS_PAGE,
  USER_ACTIVATION_PAGE,
} from 'global/routes';
import {
  INCIDENT_ATTACHMENT_CREATE_SOCKET,
  INCIDENT_ATTACHMENT_DELETE_SOCKET,
  INCIDENT_ATTACHMENT_UPDATE_SOCKET,
  INCIDENT_COMMENT_CREATE_SOCKET,
  INCIDENT_COMMENT_DELETE_SOCKET,
  INCIDENT_COMMENT_UPDATE_SOCKET,
  INCIDENT_CREATE_SOCKET,
  INCIDENT_DELETE_SOCKET,
  INCIDENT_RESPONSE_CREATE_SOCKET,
  INCIDENT_RESPONSE_UPDATE_SOCKET,
  INCIDENT_UPDATE_SOCKET,
  LICENSE_UPDATE_SOCKET,
  USER_ROLE_UPDATE_SOCKET,
} from 'global/sockets';
import { HIDE_AGREEMENT_CONFIRMATION_DIALOG } from 'global/environments';
import { GlobalNotifications } from './components/GlobalNotification';
import { Sidebar } from './components/Sidebar';
import { UpdateLicenseNotification } from './components/UpdateLicenseNotification';
import { AgreementDialog } from './components/AgreementDialog';
import styles from './styles';
import { isSa } from 'utils/help';

const useStyles = makeStyles(styles);

export const MainPage: React.FC = () => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { userId } = useSelector(state => state.auth);
  const { agreements } = useSelector(state => state.activation);

  const [
    isMdrAdmin,
    newIncidentsPerm,
    viewOrganizationLicense,
  ] = usePermissions(['edit_organization_license', 'view_incidents_list', 'view_organization_license']);

  const sendNotification = (message: string | React.ReactNode, to: string, replace = false) => {
    dispatch(addNotification({
      message,
      options: {
        variant: 'info',
        action: (key) => (
          <Button
            color="secondary"
            variant="text"
            component={Link}
            to={to}
            replace={replace}
            onClick={() => dispatch(closeNotification(key))}
          >
            {t('View')}
          </Button>
        ),
      },
    }));
  };

  const canReplaceHistory = (incidentId: string) => location.pathname.includes(incidentId);
  const isNotTheSameAuthor = (authorId: string) => authorId !== userId;

  useSocket(INCIDENT_COMMENT_CREATE_SOCKET, (data: CommentNotification) => {
    if (isNotTheSameAuthor(data.comment.authorId)) {
      sendNotification(
        t('New comment'),
        `${INCIDENT_LIST_PAGE}/${data.incidentId}?messageId=${data.comment.commentId}#communication`,
        canReplaceHistory(data.incidentId),
      );
    }
  });

  useSocket(INCIDENT_COMMENT_UPDATE_SOCKET, (data: CommentNotification) => {
    if (isNotTheSameAuthor(data.comment.authorId)) {
      sendNotification(
        t('Comment was updated'),
        `${INCIDENT_LIST_PAGE}/${data.incidentId}?messageId=${data.comment.commentId}#communication`,
        canReplaceHistory(data.incidentId),
      );
    }
  });

  useSocket(INCIDENT_COMMENT_DELETE_SOCKET, (data: CommentNotification) => {
    if (isNotTheSameAuthor(data.comment.authorId)) {
      sendNotification(
        t('Comment was deleted'),
        `${INCIDENT_LIST_PAGE}/${data.incidentId}#communication`,
        canReplaceHistory(data.incidentId),
      );
    }
  });

  useSocket(INCIDENT_RESPONSE_CREATE_SOCKET, (data: ResponseNotification) => {
    const canReplace = location.pathname.includes(data.incidentId);
    sendNotification(
      t('New response'),
      `${INCIDENT_LIST_PAGE}/${data.incidentId}#responses`, canReplace,
    );
  });

  useSocket(INCIDENT_RESPONSE_UPDATE_SOCKET, (data: ResponseNotification) => {
    const canReplace = location.pathname.includes(data.incidentId);
    sendNotification(
      t('Response was updated'),
      `${INCIDENT_LIST_PAGE}/${data.incidentId}#responses`, canReplace,
    );
  });

  useSocket(INCIDENT_CREATE_SOCKET, (data: IncidentNotification) => {
    if (isNotTheSameAuthor(data.incident.authorId)) {
      sendNotification(t('New incident'), `${INCIDENT_LIST_PAGE}/${data.incident.incidentId}`);
    }
    dispatch(newCountRequest());
  });

  useSocket(INCIDENT_UPDATE_SOCKET, (data: IncidentNotification) => {
    if (isNotTheSameAuthor(data.incident.authorId)) {
      sendNotification(t('Incident was updated'), `${INCIDENT_LIST_PAGE}/${data.incident.incidentId}`);
    }
    if (!canReplaceHistory(data.incident.incidentId)) { // check if incident card is already opened
      dispatch(newCountRequest());
    }
  });

  useSocket(INCIDENT_DELETE_SOCKET, (data: IncidentNotification) => {
    if (isNotTheSameAuthor(data.incident.authorId)) {
      sendNotification(t('Incident was deleted'), `${INCIDENT_LIST_PAGE}`);
    }
  });

  useSocket(INCIDENT_ATTACHMENT_CREATE_SOCKET, (data: IncidentAttachmentNotification) => {
    if (isNotTheSameAuthor(data.attachment.authorId)) {
      sendNotification(
        t('New attachment'),
        `${INCIDENT_LIST_PAGE}/${data.incidentId}?messageId=${data.attachment.attachmentId}#communication`,
        canReplaceHistory(data.incidentId),
      );
    }
  });

  useSocket(INCIDENT_ATTACHMENT_UPDATE_SOCKET, (data: IncidentAttachmentNotification) => {
    if (isNotTheSameAuthor(data.attachment.authorId)) {
      sendNotification(
        t('Attachment was updated'),
        `${INCIDENT_LIST_PAGE}/${data.incidentId}?messageId=${data.attachment.attachmentId}#communication`,
        canReplaceHistory(data.incidentId),
      );
    }
  });

  useSocket(INCIDENT_ATTACHMENT_DELETE_SOCKET, (data: IncidentAttachmentNotification) => {
    if (isNotTheSameAuthor(data.attachment.authorId)) {
      sendNotification(
        t('Attachment was deleted'),
        `${INCIDENT_LIST_PAGE}/${data.incidentId}#communication`,
        canReplaceHistory(data.incidentId),
      );
    }
  });

  useSocket(LICENSE_UPDATE_SOCKET, ({ license }: LicenseInfoSocketPayload) => {
    if (license && license.licenseId) {
      dispatch(infoSuccess(license));
    }
  });

  useSocket(USER_ROLE_UPDATE_SOCKET, ({ userId: roleUserId }: UserRoleUpdateSocketPayload) => {
    if (roleUserId === userId) {
      dispatch(logoutRequest());
    }
  });

  useEffect(() => {
    if (newIncidentsPerm) {
      dispatch(newCountRequest());
    }
  }, [dispatch, newIncidentsPerm]);

  useEffect(() => {
    if (viewOrganizationLicense && !HIDE_AGREEMENT_CONFIRMATION_DIALOG) {
      dispatch(agreementListRequest(AgreementType.MDR));
    }
  }, [viewOrganizationLicense, dispatch]);

  // Allow the client from Saudi Arabia to operate, provided they accept the MDRAgreement,
  // regardless of their decision on MDRDPA.
  const hasMDRAgreement = agreements?.every(agreement => agreement.meta.title !== AgreementTitles.Agreement);
  const isValidSA = isSa() && !hasMDRAgreement;
  // Clients not from Saudi Arabia must accept all agreements.
  const isNotSA = !isSa() && agreements?.length;

  return (
    <div className={classes.root}>
      <UpdateLicenseNotification />
      <GlobalNotifications />
      <Notification />
      <Sidebar disabled={isMdrAdmin && viewOrganizationLicense && !!agreements && (!!isValidSA || !!isNotSA)} />
      <main className={classes.content}>
        <Grid container spacing={0} className={classes.container}>
          {!HIDE_AGREEMENT_CONFIRMATION_DIALOG && isMdrAdmin && agreements && (!!isValidSA || !!isNotSA) ? (
            <AgreementDialog agreements={agreements} />
          ) : (
            <Switch>
              <PrivateRoute
                path={STATISTICS_PAGE} component={StatisticsPage} exact permission="view_organization_monitoring"
              />
              <PrivateRoute
                path={INCIDENT_LIST_PAGE} component={IncidentListPage} exact permission="view_incidents_list"
              />
              <PrivateRoute
                path={INCIDENT_DETAILS_PAGE} component={IncidentDetailsPage} exact permission="view_incident"
              />
              <PrivateRoute
                path={ASSET_LIST_PAGE} component={AssetListPage} exact permission="view_assets_list"
              />
              <PrivateRoute path={ACTIVATION_PAGE} component={ActivationPage} exact />
              <PrivateRoute path={`${ACTIVATION_PAGE}/:id`} component={ActivationPage} exact />
              <PrivateRoute
                path={LICENSE_STATE_PAGE} component={LicenseStatePage} exact permission="view_organization_license"
              />
              <PrivateRoute path={USER_ACTIVATION_PAGE} component={UserActivationPage} exact />
              <PrivateRoute path={LICENSE_STATE_PAGE} component={LicenseStatePage} exact />
              <PrivateRoute path={GUIDE_PAGE} component={GuidePage} exact />
              <PrivateRoute path={ABOUT_PAGE} component={AboutPage} exact />
              <PrivateRoute
                path={SETTINGS_PAGE}
                component={SettingsPage}
                exact
                permission={['view_organization_settings', 'view_portal_settings']}
              />
              <PrivateRoute path={FORBIDDEN_ERROR_PAGE} component={ForbiddenErrorPage} exact />
              <Route component={NotFoundErrorPage} />
            </Switch>
          )}
        </Grid>
      </main>
    </div>
  );
};
