import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  selectRoot: {
    border: 'none',
    boxShadow: 'none',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    fill: '#00A88E',
  },
});
