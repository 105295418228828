import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Column } from 'material-table';
import { SideDialog } from 'components/SideDialog';
import { TableColumnsForm } from './form';

type TableColumnsProps<T extends object, C extends object> = {
  open: boolean;
  onClose: () => void;
  columns: Column<T>[];
  setColumns?: ActionCreatorWithPayload<C | null>;
  locator?: string;
};

export function TableColumns<T extends object, C extends object>(props: TableColumnsProps<T, C>) {
  const { open, onClose, columns, setColumns, locator } = props;
  const { t } = useTranslation('columns');
  const dispatch = useDispatch();

  const handleColumnsSave = (columns: Column<T>[]) => {
    if (setColumns) {
      const visibleColumns = columns.reduce<C>((acc, cur, idx) => {
        if (cur.field) {
          return {
            ...acc,
            [cur.field]: {
              hidden: cur.hidden,
              order: idx,
            },
          };
        }
        return acc;
      }, {} as C);
      dispatch(setColumns(visibleColumns));
    }
    onClose();
  };

  const handleColumnsClear = () => {
    if (setColumns) {
      dispatch(setColumns(null));
    }
    onClose();
  };

  const isDisabled = useCallback((columns: Column<T>[]) => columns.every(({ hidden }) => !!hidden), []);

  return (
    <SideDialog<Column<T>[]>
      state={columns}
      open={open}
      title={t('Columns')}
      onClose={onClose}
      onCancel={handleColumnsClear}
      cancelText={t('Clear')}
      onSubmit={handleColumnsSave}
      submitText={t('Save')}
      disabled={isDisabled}
      locator={locator}
      render={(columns, setColumns) => (
        <TableColumnsForm
          locator={locator}
          columns={columns}
          setColumns={setColumns}
        />
      )}
    />
  );
}
