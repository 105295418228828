import React from 'react';
import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { AuthorIcon } from 'assets/icons/AuthorIcon';
import { DateTime } from 'components/DateTime';
import { CommentHeaderProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const CommentHeader = (props: CommentHeaderProps) => {
  const classes = useStyles();

  const { authorName, date, compact = false } = props;

  return (
    <Box className={classes.info}>
      <Box className={classes.author} component="span">
        <AuthorIcon className={classes.authorIcon} color="#00000" />
        <Typography
          className={classNames({ [classes.compact]: compact })}
          component="span"
        >
          {authorName}
        </Typography>
      </Box>
      {date ? (
        <Box
          className={classNames({ [classes.compact]: compact, [classes.dateTime]: true })}
          component="span"
        >
          <DateTime timestamp={date} />
        </Box>
      ) : null}
    </Box>
  );
};
