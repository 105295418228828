import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { MDRLogo } from 'assets/icons/MDRLogo';
import { Title } from 'components/Title';
import { KLButton } from 'components/KLButton';
import styles from './styles';

const useStyles = makeStyles(styles);

interface ProductOverviewSectionProps {
  onUserCreateClick: () => void;
  onSignInClick: () => void;
  outerClassname?: string;
  innerClassname?: string;
}

export const ProductOverviewSection: React.FC<ProductOverviewSectionProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('LoginPage');
  const { innerClassname, outerClassname, onUserCreateClick, onSignInClick } = props;

  return (
    <Grid container className={classNames(classes.root, outerClassname)}>
      <Grid container className={innerClassname}>
        <Grid container>
          <Grid item xs={2} sm={2} md={1}>
            <MDRLogo width="100%" color="white" />
          </Grid>
          <Grid item className="product-overview" xs={8} sm={6} md={6}>
            <Title component="h3">{t('Kaspersky Managed Detection and Response')}</Title>
            <p>{t('Continuously hunting')}</p>
          </Grid>
        </Grid>
        <Grid item xs={12} className="button-grid">
          <Grid item xs={12} sm={10} md={11}>
            <KLButton
              variant="contained"
              className="red-bg no-border-radius"
              onClick={onUserCreateClick}
            >
              {t('Create account')}
            </KLButton>
            <KLButton
              variant="outlined"
              color="primary"
              className={classes.loginButton}
              onClick={onSignInClick}
            >
              {t('Sign In')}
            </KLButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

};
