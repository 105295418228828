import { createMuiTheme, SimplePaletteColorOptions } from '@material-ui/core/styles';

interface BackgroundColors {
  primary?: SimplePaletteColorOptions;
  secondary?: SimplePaletteColorOptions;
  success?: SimplePaletteColorOptions;
  error?: SimplePaletteColorOptions;
  warning?: SimplePaletteColorOptions;
  info?: SimplePaletteColorOptions;
  gray?: SimplePaletteColorOptions;
}

export const backgroundColors: BackgroundColors = {
  primary: {
    main: '#DAFFED',
    dark: '#D3F6E9',
  },
  secondary: {
    main: '#E9EFF7',
    dark: '#D6DFEB',
  },
  error: {
    main: '#FFEEEE',
    dark: '#FFDBD9',
  },
  warning: {
    main: '#FFF8E0',
    dark: '#FFECBE',
  },
  info: {
    main: '#ECFAFF',
  },
  gray: {
    main: '#F2F2F2',
    dark: '#CCCCCC',
  },
};

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'MuseoSansCyrl',
      'sans-serif',
    ].join(','),
    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: 100,
  },
  palette: {
    primary: {
      light: '#23D1AE',
      main: '#00A88E',
      dark: '#006D5C',
    },
    secondary: {
      light: '#8897A9',
      main: '#535F70',
      dark: '#3D4658',
    },
    error: {
      light: '#FF4455',
      main: '#ED2939',
      dark: '#BD1C29',
    },
    warning: {
      light: '#FFBE26',
      main: '#FFAA00',
      dark: '#E9980A',
    },
    info: {
      light: '#51AEE2',
      main: '#0080C7',
      dark: '#2867A2',
      contrastText: '#2735448C',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#979797',
    },
    action: {
      active: '#00A88E',
      hover: '#23D1AE',
      selected: '#006D5C',
      disabled: '#CCCCCC',
      disabledBackground: '#F2F2F2',
    },
  },
  shape: {
    borderRadius: 3,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

theme.typography.h1 = {
  fontSize: theme.typography.pxToRem(36),
  lineHeight: theme.typography.pxToRem(40),
  fontWeight: theme.typography.fontWeightBold,
};

theme.typography.h2 = {
  fontSize: theme.typography.pxToRem(28),
  lineHeight: theme.typography.pxToRem(32),
  fontWeight: theme.typography.fontWeightBold,
};

theme.typography.h3 = {
  fontSize: theme.typography.pxToRem(20),
  lineHeight: '150%',
  fontWeight: theme.typography.fontWeightBold,
};

theme.typography.h4 = {
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(20),
  fontWeight: theme.typography.fontWeightBold,
};

theme.typography.body1 = {
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(20),
};

theme.overrides = {
  MuiButton: {
    root: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'none',
      padding: theme.spacing(1, 2),
      maxHeight: theme.typography.pxToRem(32),
    },
    sizeSmall: {
      maxHeight: theme.typography.pxToRem(24),
    },
    sizeLarge: {
      maxHeight: theme.typography.pxToRem(40),
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.MuiButton-loading': {
        borderColor: 'inherit',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&.MuiButton-loading': {
        borderColor: 'inherit',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    containedSizeSmall: {
      padding: theme.spacing(0.5, 1.5),
      fontSize: theme.typography.pxToRem(12),
    },
    containedSizeLarge: {
      padding: theme.spacing(1.5, 3),
      fontSize: theme.typography.pxToRem(14),
    },
    outlined: {
      padding: theme.spacing(1, 2),
    },
    outlinedPrimary: {
      borderColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: backgroundColors?.primary?.main,
      },
      '&:active': {
        backgroundColor: backgroundColors?.primary?.dark,
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
      },
      '&.MuiButton-loading': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
      },
    },
    outlinedSecondary: {
      borderColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: backgroundColors?.secondary?.main,
      },
      '&:active': {
        backgroundColor: backgroundColors?.secondary?.dark,
        borderColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.dark,
      },
      '&.MuiButton-loading': {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.common.white,
      },
    },
    outlinedSizeSmall: {
      padding: theme.spacing(0.5, 1.5),
      fontSize: theme.typography.pxToRem(12),
    },
    outlinedSizeLarge: {
      padding: theme.spacing(1.5, 3),
      fontSize: theme.typography.pxToRem(14),
    },
    text: {
      padding: theme.spacing(1, 2),
    },
    textPrimary: {
      '&:hover': {
        backgroundColor: backgroundColors?.primary?.main,
      },
      '&:active': {
        backgroundColor: backgroundColors?.primary?.dark,
        color: theme.palette.primary.dark,
      },
      '&.MuiButton-loading': {
        borderColor: 'inherit',
        color: theme.palette.primary.main,
        backgroundColor: backgroundColors?.primary?.dark,
      },
    },
    textSecondary: {
      '&:hover': {
        backgroundColor: backgroundColors?.secondary?.main,
      },
      '&:active': {
        backgroundColor: backgroundColors?.secondary?.dark,
        color: theme.palette.secondary.dark,
      },
      '&.MuiButton-loading': {
        borderColor: 'inherit',
        color: theme.palette.secondary.main,
        backgroundColor: backgroundColors?.secondary?.dark,
      },
    },
    textSizeSmall: {
      padding: theme.spacing(0.5, 1.5),
      fontSize: theme.typography.pxToRem(12),
    },
    textSizeLarge: {
      padding: theme.spacing(1.5, 3),
      fontSize: theme.typography.pxToRem(14),
    },
    disabled: {
      backgroundColor: backgroundColors?.gray?.main,
    },
  },
  MuiLink: {
    root: {
      '&:link': {
        color: theme.palette.info.main,
      },
      '&:visited': {
        color: '#9B3FD4',
        '&:hover': {
          color: '#B565E7',
        },
        '&:active': {
          color: '#6B189E',
        },
      },
      '&:hover': {
        color: theme.palette.info.light,
      },
      '&:active': {
        color: theme.palette.info.dark,
      },
    },
  },
  MuiCheckbox: {
    root: {
      padding: 0,
      marginRight: theme.spacing(1),
      color: '#CCCCCC',
    },
  },
  MuiSwitch: {
    root: {
      width: 24,
      height: 16,
      padding: 0,
      marginRight: theme.spacing(1),
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: '0px 3px 1px rgba(0, 0, 0, 0.15)',
    },
    switchBase: {
      color: theme.palette.common.white,
      padding: 2,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-checked': {
        transform: 'translateX(8px)',
      },
    },
    colorSecondary: {
      '&.Mui-checked': {
        color: theme.palette.common.white,
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      marginRight: 0,
    },
    label: {
      marginTop: 2,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  MuiInputLabel: {
    shrink: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      transform: 'translate(0, 1.5px)',
    },
  },
  MuiIconButton: {
    sizeSmall: {
      padding: theme.spacing(1),
    },
  },
  MuiPickersDesktopDateRangeCalendar: {
    calendar: {
      minWidth: 184,
      minHeight: 168,
      '& [role=row]': {
        margin: 0,
      },
    },
    arrowSwitcher: {
      '& + div > span': {
        width: 28,
        height: 28,
        fontSize: theme.typography.pxToRem(12),
        margin: 0,
        color: '#999999',
      },
      '& h6': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
    },
    rangeCalendarContainer: {
      '&:not(:last-child)': {
        borderRight: 'none',
        marginRight: theme.spacing(3),
      },
    },
    dateRangeContainer: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  MuiPickersDay: {
    day: {
      width: 28,
      height: 28,
      transform: 'scale(1) !important',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(28),
      borderRadius: 3,
      color: '#333333',
      '&:focus.MuiPickersDay-daySelected': {
        backgroundColor: `${theme.palette.primary.dark} !important`,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#333333',
      },
    },
    daySelected: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    today: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      '&:not(.MuiPickersDay-daySelected)': {
        border: 'none',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  MuiPickersDateRangeDay: {
    rangeIntervalPreview: {
      border: 'none',
    },
    rangeIntervalDayHighlight: {
      backgroundColor: '#E1F4F0',
      '&:first-child': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '&:last-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    rangeIntervalDayHighlightStart: {
      marginLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    rangeIntervalDayHighlightEnd: {
      marginRight: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    dayOutsideRangeInterval: {
      '&:hover': {
        border: 'none',
      },
    },
    rangeIntervalDayPreview: {
      border: 'none',
      backgroundColor: '#E1F4F0',
      '&.MuiPickersDateRangeDay-rangeIntervalDayPreviewStart': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '&.MuiPickersDateRangeDay-rangeIntervalDayPreviewEnd': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    rangeIntervalDay: {
      '&:first-child .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '&:last-child .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
  MuiInputBase: {
    input: {
      '&:-ms-input-placeholder': {
        color: '#999999',
        opacity: 1,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      backgroundColor: '#1D1D1B',
    },
    arrow: {
      color: '#1D1D1B',
    },
  },
};

export default theme;
