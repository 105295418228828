import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';

type LoadingProps = {
  size?: number | string;
}

export const Loading: React.FC<LoadingProps> = ({ size }) => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
    <CircularProgress color="primary" size={size} />
  </Box>
);
